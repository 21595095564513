import { ChartComponent } from './chartInstance';
import { Label } from '@amcharts/amcharts5';
import * as am from '@amcharts/amcharts5';
import { T } from '../../../../helpers/translationsProvider';
/** Draw a no data message when no data was present when rendering the chart */
export class NoDataMessage extends ChartComponent {
    getTags() {
        return ['message'];
    }
    constructor() {
        super();
        this.addDependency('chart');
    }
    render(context) {
        const chart = context.get('chart');
        const allSeries = context.get('seriesComponents') || [];
        const hasData = allSeries.some(series => series.data.length !== 0);
        if (hasData)
            return;
        // ensure we render last, alternative would be adding an 'order' property or something of the likes
        // using a microtask at least scopes our rendering shenanigans to our component 
        // can see if we can think of a better way to handle this later on (optional dependencies, '*' as 'match all'?)
        queueMicrotask(() => {
            // remove all previously added elements
            chart.children.clear();
            chart.children.push(Label.new(context.root, {
                text: T('WIDGET_NO_DATA'),
                x: am.p50,
                y: am.p50,
                centerX: am.p50,
                centerY: am.p50,
                // mimic echarts look
                fill: 'var(--text-primary, #313741)',
                fontSize: 18,
                fontWeight: 'bold',
                fontFamily: 'Microsoft YaHei',
            }));
        });
    }
}

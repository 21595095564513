import theme from '@dnx/theme';

/* 
  Adds vue-theming support.
  Wrapping a component withTheme, it will add theme support to it.
  Colors can be remapped using the current theme.
*/

class ThemeBase {
  colors = {};

  constructor() {
    // Add a variation. A variation on the same theme (like dark/light)
    // Controls are for elements such as Input / Select / Slider etc
    // Use colors/images etc as default, not specific _selected variants. Light might not be there....
    this.$ = {
      _selected: 'light',

      // poor mans deep clone
      ...JSON.parse(JSON.stringify(theme)),
    };
  }

  replaceVariantColorsByAppTheme(variants) {
    Object.entries(this.$).forEach(([key, value]) => {
      if (Object.keys(variants).includes(key)) {
        this.$[key] = variants[key];
      }
    });

    this.applyTo();
  }

  // Set css vars on element(or entire document) so they apply to all children.
  applyTo(obj) {
    obj ??= document.documentElement;
    let css = {};
    this.toCssVars(
      {
        ...this.colors,
        ...(this.$._selected && this.$[this.$._selected].colors),
      },
      '',
      css
    );
    Object.entries(css).map(([name, value]) => obj.style.setProperty(name, value));
  }

  // Allow refering to other css vars by name (--blah == var(blah))
  parse(css) {
    try {
      if (css.substring(0, 2) == '--') return `var(${css})`;
    } catch {
      // Ignore for now
    }
    return css;
  }

  // Convert the colors to css vars.
  // It will turn nested colors { a { b : '#fff'} } into named vars --a-b: #fff;
  toCssVars(obj, prefix = '', css = {}) {
    Object.entries(obj).map(([name, value]) => {
      if (value instanceof Object) this.toCssVars(value, `${prefix}-${name}`, css);
      else css[`-${prefix}-${name}`] = this.parse(value);
    });
  }
}

const Themes = {
  default: new ThemeBase(),
  red: new ThemeBase(),
  other: new ThemeBase(),
};

function withTheme(obj) {
  obj._mounted = obj.mounted;
  obj.mounted = function () {
    if (typeof obj._mounted === 'Function') obj._onMount();

    if (this.$attrs.themed) {
      console.info(`Theme override: ${this.$attrs.themed}`);
      Themes[this.$attrs.themed].applyTo(this.$el);
    }
  };

  Themes.default.applyTo();
  return obj;
}
const ActiveTheme = Themes['default'];

const Theme = new Proxy(ActiveTheme, {
  get(src, prop) {
    // Theme.variant returns the current variant
    if (prop === 'variant') return src.$._selected ?? 'default';
    // Theme.variants returns the available variants.
    if (prop === 'variants') {
      const result = Object.keys(src.$);
      result.shift();
      return result;
    }

    // Anything else will return the css value.
    if (src.$._selected && src.$[src.$._selected] && src.$[src.$._selected][prop]) return src.$[src.$._selected][prop];
    return src[prop];
  },

  set(src, prop, value) {
    // Use Theme.variant='dark' to switch variants.
    if (prop == 'variant') {
      // Unwrap any reactive elements
      const name = `${value}`;
      src.$._selected = src.$.hasOwnProperty(name) ? name : undefined;
      src.applyTo();
    }
    if (prop == 'theme') {
      const name = `${value}`;
      if (Themes[name]) {
        src = Themes[name];
        src.applyTo();
      }
    }

    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Proxy/Proxy/set#return_value
    // true should be returned to indicate success, otherwise browsers may generate errors
    return true;
  },
});

export {withTheme, Theme, Themes};

<!-- Inspired by Naive UI InputGroup -->
<template>
  <div class="wrapper"><slot></slot></div>
</template>
<script setup lang="ts"></script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  &:deep {
    > :not(:last-child) {
      fieldset,
      button {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: none;
      }
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: none;
    }

    > :not(:first-child) {
      fieldset,
      button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: none;
      }
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: none;
    }
  }
}
</style>

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createEventHook, invoke, until } from '@vueuse/core';
import { driver } from 'driver.js';
import 'driver.js/dist/driver.css';
import { inject, onMounted, provide, ref } from 'vue';
import { $can, addErrorToast, T } from '..';
import { useUserMutations, useUser } from '../composables/useUser';
import { useTourStore } from './../stores/tour';
import { onBeforeRouteLeave } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useRouteQuery } from '@vueuse/router';
import { useStore } from 'vuex';
export const createTour = (steps, options) => {
    const refs = new Map(steps.map(step => [step.id, ref()]));
    const stepEvent = createEventHook();
    const closeEvent = createEventHook();
    const startEvent = createEventHook();
    const tourQuery = useRouteQuery('tour');
    const store = useStore();
    const { isTourPlaying } = storeToRefs(useTourStore());
    const getElement = (id) => {
        const ref = refs.get(id);
        if (!ref.value)
            return undefined;
        if ('$el' in ref.value) {
            return ref.value.$el;
        }
        else {
            return ref.value;
        }
    };
    const registerElement = (id, element) => {
        if (!refs.has(id))
            return;
        if (refs.get(id).value) {
            return;
        }
        refs.get(id).value = element;
    };
    const start = () => {
        if (isTourPlaying.value == true || !canStartTour(options.id)) {
            return;
        }
        tourQuery.value = options.id;
        // mark tour as viewed
        store.dispatch('users/loadCurrentUser')
            .then(() => completeTour(options.id));
        isTourPlaying.value = true;
        const allSteps = steps.map((step, index) => ({
            element: getElement(step.id),
            popover: Object.assign(Object.assign({}, step.popover), { onPrevClick: () => {
                    //Disabled for now, too much of a headache :D also design doesn't require it.
                    return;
                }, onNextClick: () => {
                    invoke(() => __awaiter(void 0, void 0, void 0, function* () {
                        if (!driverObj.hasNextStep()) {
                            driverObj.destroy();
                            return;
                        }
                        stepEvent.trigger(steps[index + 1].id);
                        waitForStepAvailability(index + 1);
                    }));
                } }),
        }));
        const waitForStepAvailability = (index) => __awaiter(void 0, void 0, void 0, function* () {
            if (!steps[index].noElement) {
                try {
                    yield until(refs.get(steps[index].id)).toBeTruthy({ timeout: 4000, throwOnTimeout: true });
                    allSteps[index].element = getElement(steps[index].id);
                    if (index == 0) {
                        driverObj.drive();
                    }
                    else {
                        driverObj.moveNext();
                    }
                }
                catch (e) {
                    if (index != 0)
                        addErrorToast(T('TOUR_INCOMPLETE_DATA'));
                    driverObj.destroy();
                }
            }
        });
        onBeforeRouteLeave(() => {
            isTourPlaying.value = false;
        });
        const driverObj = driver({
            steps: allSteps,
            showButtons: ['next', 'close'],
            nextBtnText: T('NEXT'),
            showProgress: true,
            progressText: '{{current}}/{{total}}',
            onDestroyed: () => {
                closeEvent.trigger();
                isTourPlaying.value = false;
                tourQuery.value = null;
            },
        });
        setTimeout(() => {
            stepEvent.trigger(steps[0].id);
            startEvent.trigger();
        }, 200);
        waitForStepAvailability(0);
    };
    const state = { start, registerElement, onStep: stepEvent.on, onClose: closeEvent.on, onStart: startEvent.on, isTourPlaying };
    if (canStartTour(options.id) && options.permissionId
        ? $can.read[options.permissionId]
        : true && options.autoStart != false) {
        onMounted(start);
    }
    provide('tour-context', state);
    return state;
};
/**
 * Mark the given tour as completed
 * @param {string} id
 * @return {any}
 */
export const completeTour = (id) => {
    const { setMetadataValue } = useUserMutations();
    return setMetadataValue(`tour-${id}`, '1');
};
export const canStartTour = (id) => {
    const { isTourPlaying } = storeToRefs(useTourStore());
    const tourQuery = useRouteQuery('tour');
    const user = useUser();
    if (isTourPlaying.value)
        return false;
    if (tourQuery.value) {
        return tourQuery.value == id;
    }
    return !user.value.metadata['tour-' + id];
};
export const useTourContext = () => {
    return inject('tour-context', undefined);
};

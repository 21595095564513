import { Forecast } from './forecasting';
import { useChartComponent, useSeries } from './composables';
export default {
    name: 'XChartForecasting',
    // being a 'forecast' datapoint should just be embedded in the datapoint itself
    // datapoints should have some form of metadata/tags associated with them instead of this flaky way of passing through data
    props: {
        /** Amount of datapoints generated by forecasting feature, count starts at the end */
        forecastDataPoints: {
            type: Number,
            required: true,
        },
    },
    setup(props) {
        const series = useSeries();
        useChartComponent(() => new Forecast({
            series: series.value,
            forecastDataPoints: props.forecastDataPoints,
        }));
    },
    render() { },
};

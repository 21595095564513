import { onUnmounted } from 'vue';
import { useTooltip } from '../stores/tooltip';
/**
 * Show a tooltip on the position of the given element
 *
 * ```javascript
 * const myElement = document.getElementById('myElement');
 *
 * // display tooltip with text content of myElement
 * showTooltipFor(myElement);
 *
 * // display tooltip with a piece of content differing from the text content of myElement
 * showTooltipFor(myElement, { body: 'other content' });
 * ```
 */
export function showTooltipFor(target, options) {
    var _a;
    const body = (_a = options === null || options === void 0 ? void 0 : options.body) !== null && _a !== void 0 ? _a : target.innerText;
    if (!body)
        return;
    const tooltip = useTooltip();
    tooltip.show = true;
    tooltip.body = target.innerText;
    // show horizontally centered on top of element
    const { top, left, width } = target.getBoundingClientRect();
    tooltip.x = left + width / 2;
    tooltip.y = top;
    // depending on what we're doing, hiding of our tooltip may take up to ~500ms-1s
    // we rely on Vue to performing the hiding, if Vue is clogged with render requests
    // the tooltip will remain visible until hiding of the tooltip has been processed
    // if the hindrance of this becomes too severe, we'll have to change our API
    // to manually hide/show the DOM element associated with our tooltip (make show a function, let the store know of the tooltip DOM element)
    onElementRemoval(target, () => tooltip.show = false);
    // autohide on mouseleave
    target.addEventListener('mouseleave', () => tooltip.show = false, { once: true });
}
/**
 * Fire a callback when the given HTML element is removed from the DOM
 * @param {HTMLElement} target
 * @param {() => any} cb
 */
function onElementRemoval(target, cb) {
    // fire for all dom node insertions/removals until our target is no longer part of the current document
    // should be fairly consistent in detecting removal of our target element
    new MutationObserver((_, observer) => {
        if (target.isConnected)
            return;
        cb();
        observer.disconnect();
    }).observe(document, { childList: true, subtree: true });
}
/**
 * Dangerous, attempts to lookup the closest component in the dom tree and fires an event on removal of
 *
 * This relies heavily on undocumented behavior and should thus not be used in production
 */
function onContainingComponentRemoval(target, cb) {
    const component = resolveElementVueComponent(target);
    console.log('Component: ', component);
    if (component)
        onUnmounted(cb, component);
}
/**
 * Dangerous, attempts to lookup the closest vnode -> component
 *
 * This relies heavily on undocumented behavior and should thus not be used in production
 */
function resolveElementVueComponent(target) {
    while (target) {
        if (target.__vnode)
            return target.__vnode.ctx;
        target = target.parentElement;
    }
    return undefined;
}

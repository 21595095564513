﻿<template>
  <Component :is="rendererComponent" v-bind="$props" />
</template>

<script>
import {MixedChartMixin} from './mixins';

import XApexMixedChart from './XApexMixedChart.vue';
import XEchartsMixedChart from './XEchartsMixedChart.vue';
import XAmMixedChart from './AmCharts/XAmMixedChart.vue';

const rendererLookup = {
  apex: XApexMixedChart,
  echarts: XEchartsMixedChart,
  amcharts: XAmMixedChart,

  get: type => rendererLookup[type] ?? rendererLookup['apex'],
};

export default {
  name: 'XMixedChart',
  mixins: [MixedChartMixin],

  props: {
    renderer: {
      type: String,
      required: false,
      default: 'amcharts',
    },
  },

  computed: {
    rendererComponent() {
      return rendererLookup.get(this.renderer);
    },
  },
};
</script>

<docs>
  ```jsx
  import largeDataset from '../../../../datasets/timeseries-large.json';
  import dayRoundedDataset from '../../../../datasets/timeseries-rounded-by-day.json';

  // throw away some data, working with the full dataset slows down our examples too much
  const largeTimeDataSet = largeDataset.series
    .map((x, index) => ({
      data: x.data.slice(0, 150).concat(x.data.slice(10000, 10150)),
      name: x.name || `Series - ${index}`
    }));
  
  const categoryDataSet = [
    {name: 'Conversie',type: 'column',data: [{x: 'DM',y: 249,},{x: 'DU',y: 792,},{x: 'EM',y: 454,},{x: 'OL',y: 1472},{x: 'PG',y: 1774},{x: 'Retentie',y: 2800},{x: 'Spontaan',y: 743},]},
    {name: 'Target',type: 'column',data: [{x: 'DM',y: 230,},{x: 'DU',y: 190,}, {x: 'EM',y: 410,},{x: 'OL',y: 1200,},{x: 'PG',y: 1600},{x: 'Retentie',y: 2977},{x: 'Spontaan',y: 491},]},
    {name: 'Vorig week deze periode',type: 'line',data: [{x: 'DM',y: 330,},{x: 'DU',y: 845,},{x: 'EM',y: 810,},{x: 'OL',y: 1600,},{x: 'PG',y: 1800},{x: 'Retentie',y: 3000},{x: 'Spontaan',y: 501},]},
    {name: 'Vorig week deze periode',type: 'area',data: [{x: 'DM',y: 330,},{x: 'DU',y: 845,},{x: 'EM',y: 810,},{x: 'OL',y: 1600,},{x: 'PG',y: 1800},{x: 'Retentie',y: 3000},{x: 'Spontaan',y: 501},]}
  ];
  
  const timeDataSet = [
    {"name":"Winst per maand",    "type": "colunm", "data":[{"x":1646089200000,"y":182941.48},{"x":1648764000000,"y":559835.24},{"x":1651356000000,"y":570559.83},{"x":1654034400000,"y":528767.62},{"x":1656626400000,"y":616267.65},{"x":1659304800000,"y":578612.23},{"x":1661983200000,"y":561100.84},{"x":1664575200000,"y":629745.78},{"x":1667257200000,"y":573412.61},{"x":1669849200000,"y":602567.55},{"x":1672527600000,"y":598051.01},{"x":1675206000000,"y":526719.46},{"x":1677625200000,"y":432094.28}]},
    {"name":"Turnover per maand", "type": "line", "data":[{"x":1646089200000,"y":494515.04},{"x":1648764000000,"y":1569015.77},{"x":1651356000000,"y":1589163.54},{"x":1654034400000,"y":1486863.86},{"x":1656626400000,"y":1705629.09},{"x":1659304800000,"y":1619057.08},{"x":1661983200000,"y":1557124.55},{"x":1664575200000,"y":1735910.04},{"x":1667257200000,"y":1597954.19},{"x":1669849200000,"y":1689468.01},{"x":1672527600000,"y":1662474.23},{"x":1675206000000,"y":1475488.64},{"x":1677625200000,"y":1201428.26}]}
  ];

  const smallTimeDataSet = [
    { "type": "column", "data":[{"x":1640995200000,"y":5029683.24},{"x":1672531200000,"y":1930992.34}],"forecastCount":0,"name":"Winst per dag","isInvisible":false},
    { "type": "line", "data":[{"x":1640995200000,"y":5029683.24},{"x":1672531200000,"y":1930992.34}],"forecastCount":0,"name":"Winst per dag","isInvisible":false}
  ];

  const numericDataSet = [
    {"name":"Sales",    "type": "line", "data":[{"x":0, "y":15}, {"x":1,"y":38},{"x":2,"y":29},{"x":3,"y":2},{"x": 4,"y":75},{"x":5,"y":57}]},
    {"name":"Turnover", "type": "column", "data":[{"x":0, "y":75}, {"x":1,"y":42},{"x":2,"y":24},{"x":3,"y":31},{"x":4,"y":51},{"x":5,"y":17}]}
  ];

  const smallChartDataSet = [
    { "type": "area", "data":[{"x":"Bouwstoffen","y":6},{"x":"Elektrisch gereedschap","y":5},{"x":"Opbergen","y":7},{"x":"Planten","y":4},{"x":"Schuttingen","y":8},{"x":"Verlichting binnen","y":6}] },
    { "type": "column", "data":[{"x":"Bouwstoffen","y":6},{"x":"Elektrisch gereedschap","y":5},{"x":"Opbergen","y":7},{"x":"Planten","y":4},{"x":"Schuttingen","y":8},{"x":"Verlichting binnen","y":6}] }
  ];


  const annotations = {
    point:            { type: 1, x: 60, y: 300, text: 'Point', color: 'green' },
    horizontal:       { type: 2, x: 60, text: 'H - Line', color: 'green' },
    vertical:         { type: 4, y: 180, text: 'V - Line', color: '#00ffff' },
    horizontalRange:  { type: 3, x: 20, x2: 300, text: 'H - Range', color: '#ff00ff', },
    verticalRange:    { type: 5, y: 60, y2: 300, text: 'V - Range', color: '#00ff00' },
  };

  const emptyDataSet = [];

  const chartConfiguration = {
    height: '500',
    width: '100%',
    group: undefined,
    series: categoryDataSet,
    xaxisType: 'category',
    dataLabels: true,
    stacked: false,
    zoom: true,
    lineType: undefined,
    annotations: [],
    
    forecastDataPoints: 2,
    theme: ['#2EB2FF', '#10CB73', '#09115b', '#D32D85', '#0DD2D2', '#E04E2A', '7731BD', '#1FBB97'],
    name: 'Mixie'
  };

  const smallChartConfiguration = Object.assign({}, chartConfiguration, { _width: '368px', _height: '222px' });
  
  const baseConfigurations = [
    Object.assign({}, chartConfiguration, { _name: 'smooth' }),
    // Object.assign({}, chartConfiguration, { _name: 'annotated', annotations: Object.values(annotations) }),
    // Object.assign({}, chartConfiguration, { _name: 'straight', lineType: 'straight', }),
    // Object.assign({}, chartConfiguration, { _name: 'solid', lineType: 'stepline' }),
    // Object.assign({}, chartConfiguration, { stacked: true, forecastDataPoints: 0, _name: 'stacked' }),
    // Object.assign({}, smallChartConfiguration, { _name: 'small chart' }),
    // Object.assign({}, chartConfiguration, { group: 'group', forecastDataPoints: 2, _name: 'linked' }),
  ];

  const configurations = [];

  baseConfigurations.forEach((configuration, index) => {
    const group = configuration.group && `${configuration.group}_${index}`;
  
    configurations.push(Object.assign({}, configuration, { group: group }));
    // configurations.push(Object.assign({}, configuration, { series: dayRoundedDataset, group: group, xaxisType: 'datetime', dataLabels: false }));
    // configurations.push(Object.assign({}, configuration, { group: group, _name: configuration._name + ' - time' }, { series: timeDataSet, xaxisType: 'datetime' }))
    // configurations.push(Object.assign({}, configuration, { group: group, _name: configuration._name + ' - time, small' }, { series: smallTimeDataSet, xaxisType: 'datetime' }))
    // configurations.push(Object.assign({}, configuration, { group: group, _name: configuration._name + ' - time, large' }, { series: largeTimeDataSet, xaxisType: 'datetime', dataLabels: false }))
    // configurations.push(Object.assign({}, configuration, { group: group, _name: configuration._name + ' - numeric' }, { series: numericDataSet, xaxisType: 'numeric' }))
    // configurations.push(Object.assign({}, configuration, { group: group, _name: configuration._name + ' - empty' }, { series: emptyDataSet }))
    // configurations.push(Object.assign({}, configuration, { group: group, _name: configuration._name + ' - small' }, { series: smallChartDataSet }))
  });
  
    console.log(configurations);

  <XStack direction="vertical">
    <template v-for="configuration in configurations">
      <XGraphWidget :name="`ECharts variant (${configuration._name})`" :style="{ height: configuration._height, width: configuration._width }">
        <XMixedChart v-bind="configuration" renderer="echarts" />
      </XGraphWidget>

      <template v-if="configuration.group">
        <XGraphWidget :name="`ECharts variant (${configuration._name} (2))`" :style="{ height: configuration._height, width: configuration._width }">
          <XMixedChart v-bind="configuration" renderer="echarts" />
        </XGraphWidget>
      </template>

      <XGraphWidget :name="`Apex variant (${configuration._name})`" :style="{ height: configuration._height, width: configuration._width }">
        <XMixedChart v-bind="configuration" renderer="apex" />
      </XGraphWidget>
      
      <template v-if="configuration.group">
        <XGraphWidget :name="`Apex variant (${configuration._name} (2))`" :style="{ height: configuration._height, width: configuration._width }">
          <XMixedChart v-bind="configuration" renderer="apex" />
        </XGraphWidget>
      </template>
    </template>
  </XStack>
</docs>

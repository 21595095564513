<template>
  <div class="active-bar">
    <div class="line" />
    <div class="dot" />
  </div>
</template>

<script>
export default {
  name: 'XSideNavActiveBar',
};
</script>

<style lang="scss" scoped>
.active-bar {
  display: flex;
  width: 0.5rem;
  height: 0.2rem;
  background: transparent;
  position: absolute;
  top: 1rem;
  left: 0;

  .line {
    width: 0.4rem;
    height: 100%;
    background-color: var(--sideBar-menuLine, #2783c6);
  }

  .dot {
    width: 0.2rem;
    height: 100%;
    background-color: var(--sideBar-menuDot, #ffffff);
    border-radius: 50%;
    margin-left: -2px;
  }
}
</style>

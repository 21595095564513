import { h, ref } from 'vue';
import * as util from './util';
import NumberFilter from './NumberFilter.vue';
import DateFilter from './DateFilter.vue';
const filterDefinitions = {
    number: NumberFilter,
    datetime: DateFilter,
};
/** Assign a custom component as editor for selecting the given columns filters */
function assignCustomFilterEditor(column, component, emits) {
    const columnKey = util.keyOf(column);
    // underlying component owns the data definition, keep track of the state that produced our current filter
    let model = undefined;
    let activeFilterFunction = undefined;
    let dirtyMarker = 0;
    column.renderFilterMenu = ({ hide }) => h(component, Object.assign({ 
        // track state between open <> close actions
        modelValue: model }, {
        'onUpdate:modelValue': value => (model = value),
        'onUpdate:remoteFilter': value => emits('update:filters', { columnKey: column.key, value }),
        'onUpdate:filter': value => {
            activeFilterFunction = value;
            // having >any value< will make naive run the columns `filter` function
            // simply increase our marker value by 1 to 'retrigger' naives running of our filter function
            // do keep in mind, for this to work, we expect the passed in column to have been wrapped in a reactive proxy
            column.filterOptionValue = value ? ++dirtyMarker : undefined;
            hide();
        },
    }));
    column.filter = (filterValue, row) => activeFilterFunction(row[columnKey]);
}
/** Assign a basic filter editor to the given column, basic filter editors consist of a list of checkboxes where individual values can be selected */
function assignBasicFilterEditor(props, column) {
    if (!Array.isArray(props.data))
        return;
    const columnKey = util.keyOf(column);
    // collect filter options
    const filterOptions = props.data.slice(0, 200).reduce((filterOptions, row) => {
        const option = row[columnKey];
        // skip duplicate/null/undefined values
        if ((option !== null && option !== void 0 ? option : undefined) === undefined)
            return filterOptions;
        if (filterOptions.includes(option))
            return filterOptions;
        filterOptions.push(option);
        return filterOptions;
    }, []);
    // no values found, disable filtering
    if (!filterOptions.length)
        return;
    // Filtering function when options are selected in the dropdown
    column.filterOptions = filterOptions.map(x => ({ label: x, value: x }));
    column.filter = (value, row) => ~String(row[columnKey]).indexOf(value);
}
/* Composable providing filter functionality for a datatable* */
export function useFilters(props, emits, getColumnType) {
    function assignFilter(column) {
        const component = filterDefinitions[getColumnType(column.propertyName)];
        if (component)
            assignCustomFilterEditor(column, component, emits);
        else if (!props.remote)
            assignBasicFilterEditor(props, column);
    }
    return { assignFilter };
}
/** Composable providing the logic etc. for a single filter */
export function useFilterOptions(modelValue, emit, filterGetter, remoteFilterGetter) {
    const newFilterData = ref(modelValue.value);
    return {
        filterWrapperProps: {
            onApply: () => {
                emit('update:modelValue', newFilterData.value);
                emit('update:remoteFilter', remoteFilterGetter(newFilterData.value));
                emit('update:filter', filterGetter(newFilterData.value));
            },
            onRemove: () => {
                emit('update:remoteFilter', undefined);
                emit('update:filter', undefined);
            },
        },
        newFilterData,
    };
}

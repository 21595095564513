/** Make the given element fit into the current viewport */
export function fitInViewport(element, opts) {
    if (!element)
        return;
    const { gap = 0, offsetParent = element.offsetParent || document.body } = opts !== null && opts !== void 0 ? opts : {};
    // account for scrollbars by using our document body as 'viewport'
    // incorporate gap in available viewport directly
    const viewport = {
        left: gap,
        top: gap,
        right: window.innerWidth - gap,
        bottom: window.innerHeight - gap,
    };
    const { top, bottom, left, right, width, height } = element.getBoundingClientRect();
    // if we already fit in the viewport, kewl
    if (left > viewport.left && right < viewport.right && top > viewport.top && bottom < viewport.bottom) {
        return;
    }
    const { top: parentTop = 0, left: parentLeft = 0 } = offsetParent === null || offsetParent === void 0 ? void 0 : offsetParent.getBoundingClientRect();
    element.style.position = element.style.position || 'absolute';
    const computedStyle = getComputedStyle(element);
    // are we exceeding our viewport on the right?
    if (right > viewport.right) {
        if (computedStyle.getPropertyValue('right')) {
            element.style.right = '0px';
        }
        // boundary - element size - relative starting point
        element.style.left = `${viewport.right - width - parentLeft}px`;
    }
    // are we exceeding our viewport on the left?
    if (left < viewport.left) {
        if (computedStyle.getPropertyValue('right')) {
            element.style.right = '0px';
        }
        // negative parentLeft -> relative viewport left
        element.style.left = `${-parentLeft}px`;
    }
    // are we exceeding our viewport bottom?
    if (bottom > viewport.bottom) {
        if (element.style.bottom) {
            element.style.bottom = '0px';
        }
        // boundary - element height - relative starting point
        element.style.top = `${viewport.bottom - height - parentTop}px`;
    }
    // are we exceeding our viewport top?
    if (top < viewport.top) {
        if (element.style.bottom) {
            element.style.bottom = '0px';
        }
        // negative parentTop -> relative viewport top
        element.style.top = `${-parentTop}px`;
    }
}
export function centerInViewport(element) {
    const contentBoxSize = element.getBoundingClientRect();
    element.style.top = window.innerHeight / 2 - contentBoxSize.height / 2 + 'px';
    element.style.left = window.innerWidth / 2 - contentBoxSize.width / 2 + 'px';
}
export function centerHorizontallyInViewport(element) {
    const contentBoxSize = element.getBoundingClientRect();
    element.style.left = window.innerWidth / 2 - contentBoxSize.width / 2 + 'px';
}
export const moveToElement = (element, toElement, options) => {
    const { alignment = 'center', position = 'top' } = options || {};
    const rect = element.getBoundingClientRect();
    const toRect = toElement.getBoundingClientRect();
    const alignHorizontally = () => {
        if (alignment === 'start') {
            element.style.left = `${toRect.left}px`;
        }
        else if (alignment === 'end') {
            element.style.left = '';
            element.style.right = `${window.innerWidth - toRect.right}px`;
        }
        else if (alignment === 'center') {
            element.style.left = `${toRect.left + (toRect.width - rect.width) / 2}px`;
        }
    };
    const alignVertically = () => {
        if (alignment === 'start') {
            element.style.top = `${toRect.top}px`;
        }
        else if (alignment === 'end') {
            element.style.top = '';
            element.style.bottom = `${window.innerHeight - toRect.bottom}px`;
        }
        else if (alignment === 'center') {
            element.style.top = `${toRect.top + (toRect.height - rect.height) / 2}px`;
        }
    };
    if (position === 'bottom') {
        element.style.top = `${toRect.bottom + 3}px`;
        alignHorizontally();
    }
    else if (position === 'top') {
        element.style.top = `${toRect.top - rect.height - 3}px`;
        alignHorizontally();
    }
    else if (position === 'right') {
        element.style.left = `${toRect.right + 3}px`;
        alignVertically();
    }
    else if (position === 'left') {
        element.style.left = `${toRect.left - rect.width - 3}px`;
        alignVertically();
    }
};
export const moveToViewportEdge = (element, position = 'top') => {
    if (position == 'top') {
        element.style.top = `${12}px`;
        element.style.left = `${window.innerWidth - element.clientWidth / 2}px`;
    }
    else if (position == 'right') {
        element.style.top = `${window.innerHeight - element.clientHeight / 2}px`;
        element.style.left = `${window.innerWidth - element.clientWidth - 12}px`;
    }
    else if (position == 'bottom') {
        element.style.top = `${window.innerHeight - element.clientHeight - 12}px`;
        element.style.left = `${window.innerWidth - element.clientWidth / 2}px`;
    }
    else {
        element.style.top = `${window.innerHeight - element.clientHeight / 2}px`;
        element.style.left = `${12}px`;
    }
};
/**
 * Ensure the given element has the highest z-index of all elements matching the given selector
 *
 * @param {HTMLElement} element
 * @param {string} selector
 */
export function moveToFront(element, selector) {
    //@ts-ignore
    const elements = [...document.querySelectorAll(selector), element];
    const zIndexOf = (e) => {
        const zIndex = parseInt(window.getComputedStyle(e).zIndex);
        if (Number.isFinite(zIndex))
            return zIndex;
        return 0;
    };
    // each invocation will bump the max z-index by 1, should be fine
    const highestZIndex = Math.max(...elements.map(x => zIndexOf(x)));
    element.style.zIndex = `${highestZIndex + 1}`;
}

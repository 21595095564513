<template>
  <label class="input-placeholder">
    {{ placeholder }}
  </label>
</template>

<script>
export default {
  name: 'XInputPlaceholder',
  props: {
    placeholder: {
      type: String,
      default: 'normal',
    },
  },
};
</script>

<style lang="scss" scoped>
@use '../../assets/styles/variables.scss' as *;
label.input-placeholder {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  padding: var(--spacing-md) var(--spacing-sm) var(--spacing-md) calc(var(--spacing-sm) + 1px);
  pointer-events: none;
  color: var(--control-placeholder, #868686);
  transition: all 0.2s ease-in-out;
  font-size: var(--font-md);
  line-height: 1rem;
  margin: 1px 0;
  width: 100%;
  font-family: $font-text;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.input-box {
  &:focus-within,
  &.has-value {
    label.input-placeholder {
      top: calc(-1 * var(--spacing-sm));
      font-size: var(--font-xs);
      opacity: 1;
      color: var(--control-focusColor, #2783c6);
    }
  }

  &.disabled {
    label.input-placeholder {
      color: var(--control-disabledColor, #7f8389);
      opacity: 0.6;
    }
  }
}
.input.warning {
  .input-container {
    .input-box {
      label.input-placeholder {
        color: var(--main-warning, #f1a400);
      }
    }
  }
}
</style>

<docs>
  ```jsx
<XInput placeholder="okidoki">

</XInput>
<XInput placeholder="Write your text" type="number" :model-value="value" @update:modelValue="value = $event" />
  ```
</docs>

<template>
  <XPopup v-bind="$props" @close="$emit('close')">
    <slot />
    <template #footer>
      <XParagraph :color="color" class="reset-filters" @click="$emit('reset')">
        {{ T('FILTER_POPUP_RESET') }}
      </XParagraph>
      <XStack>
        <XButton componentStyle="white" style="margin-left: auto" @click="$emit('close')">
          {{ cancelButtonText }}
        </XButton>
        <XButton componentStyle="primary-success" @click="$emit('apply')">
          <XIcon name="check-circle" />
          {{ applyButtonText }}
        </XButton>
      </XStack>
    </template>
  </XPopup>
</template>
<script>
import {T} from '../../helpers/translationsProvider';

export default {
  name: 'XFilterPopup',
  emits: ['close', 'reset', 'apply'],
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    titleIconName: {
      type: String,
    },
    setWidth: {
      type: String,
      default: '27.5rem',
    },
    // needs to be the maxHeight (body) + title
    maxHeight: {
      type: String,
      default: '50vh',
    },
    maxWidth: {
      type: String,
    },
    currentEvent: {
      type: Object,
    },
    bodyOverflow: {
      type: Boolean,
      default: true,
    },
    showBackground: {
      type: Boolean,
      default: false,
    },
    titleBackgroundColor: {
      type: String,
      default: 'var(--container-secondaryBackground-200, #fafbfc)',
    },
    /**
     * icons and paragraph color
     */
    color: {
      type: String,
      default: 'var(--text-secondary-500, #2783C6)',
    },
    cancelButtonText: {
      type: String,
      default: T('CANCEL'),
    },
    applyButtonText: {
      type: String,
      default: T('APPLY'),
    },
  },
};
</script>
<style lang="scss" scoped>
.reset-filters {
  cursor: pointer;
}
:deep(.popup-footer.stack.stack-end) {
  justify-content: space-between;
}
</style>

<docs>
  ```jsx

  let showPopup = false;

  const reset = () => {
    console.log('kadsklad')
  }

  <XButton @click="showPopup = !showPopup">Toggle popup</XButton>

  <XFilterPopup 
    title="Widget information - this is just a test"
    subtitle="sub title for other things"
    :bodyOverflow="false"
    titleIconName="gear"
    v-if="showPopup"
    color="green"
    @close="showPopup = false"
    @reset="reset"
  >
    <XParagraph> 
      content slot
    </XParagraph>
  </XFilterPopup>
  ```
</docs>

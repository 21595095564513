<template>
  <div>
    <div class="dashboard-top-bar">
      <!-- For dashboard selection input fields -->
      <XBox class="top-container" :boxTitleLarge="boxTitleLarge" :boxLogo="true">
        <div class="top-items">
          <div class="top-filters">
            <slot name="top-filters" />
          </div>
          <div class="top-buttons">
            <slot name="top-buttons" />
          </div>
          <div class="top-extra-filters">
            <slot name="top-extra-filters" />
          </div>
        </div>
      </XBox>
      <!-- A submenu for the chosen dashboard, only displayed when the menu-slot has content to display -->
      <XBox :boxBelow="true" class="top-menu">
        <slot name="menu" />
      </XBox>
    </div>
    <!-- Container with the widget section layout  -->
    <slot name="widgets" />
  </div>
</template>

<script>
export default {
  name: 'XDashboardingLayout',
  props: {
    boxTitleLarge: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.top-container {
  .top-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .top-filters,
    .top-buttons,
    .top-extra-filters {
      display: flex;
      flex-wrap: wrap;
      column-gap: 0.75rem;
      row-gap: 0.75rem;
    }

    .top-extra-filters {
      width: 100%;

      :deep(.multiselect-container) {
        &:last-child {
          margin-right: 0;
        }
      }
    }

    .top-buttons {
      margin-top: 1.8rem;
      justify-content: space-between;
      align-items: center;
      margin-left: auto;

      :deep(button) {
        margin-top: 0.7rem;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.dashboard-top-bar {
  margin-bottom: 1rem;
}
</style>

<docs>
  ```jsx
  <XDashboardingLayout
    boxTitleLarge="Titel"
  >
    <template #top-filters>
    </template>
    <template #top-extra-filters>
    </template>
    <template #top-buttons>
    </template>
    <template #menu>
    </template>
    <template #widgets>
    </template>
  </XDashboardingLayout>
  ```
</docs>

import { DateTime } from 'luxon';
const _customFormatters = new Map();
const _defaultFormatters = new Map();
let currentFormatterType = '';
let counterForMonths = 0;
function registerDefaultFormatter(type, formatter) {
    _defaultFormatters.set(type, formatter);
}
//Expose + make layered (module based? apex:label -> apexcharts label specific)?
function registerCustomFormatter(type, formatter) {
    _customFormatters.set(type, formatter);
}
//Read preferred locale id, can be used with Intl API for browser formatting
function getCurrentLCID() {
    return Window.runtime.store.getters['locale/preferredLanguage'] || 'nl';
}
/**
 * Attempt to convert the given value to a Luxon DateTime object
 *
 * @return {DateTime|undefined}
 * */
function toLuxon(value) {
    // formatting.ts is being used at more spots, we wont only get epoch values but also datestrings numberic values etc. Therefor to transform strings in int to parse them
    // a small validation is added by +value. Meaning characters other than numbers will give back NaN and follow the if statement tree.
    if (typeof value === 'string' && Number.isFinite(parseInt(value, 10)) && Number.isFinite(+value))
        value = parseInt(value, 10); // if int is given as string
    if (typeof value === 'string')
        value = DateTime.fromISO(value); //Infer format?
    if (typeof value === 'number')
        value = DateTime.fromMillis(value); //Unix timestamp
    if (value instanceof Date)
        value = DateTime.fromJSDate(value);
    if (!DateTime.isDateTime(value))
        console.warn(`Failed to parse value: ${value} as Luxon DateTime`, value);
    return value === null || value === void 0 ? void 0 : value.setLocale(getCurrentLCID()); //Ensure user locale
}
/**
 * Force numeric days/months to 2-digit (aka 9-1-2022 -> 09-01-2022)
 *
 * @param {Intl.DateTimeFormatOptions} luxonFormat
 * @return {Intl.DateTimeFormatOptions}
 */
function fixNumericFormats(luxonFormat) {
    if ((luxonFormat === null || luxonFormat === void 0 ? void 0 : luxonFormat.day) === 'numeric')
        luxonFormat = Object.assign(Object.assign({}, luxonFormat), { day: '2-digit' });
    if ((luxonFormat === null || luxonFormat === void 0 ? void 0 : luxonFormat.month) === 'numeric')
        luxonFormat = Object.assign(Object.assign({}, luxonFormat), { month: '2-digit' });
    return luxonFormat;
}
/**
 * Attempt to format the given input as currency
 *
 * Any input parseable as float is accepted, null and undefined are treated as `0` values
 *
 * @param {*} value
 * @return {any}
 */
function formatCurrency(value) {
    //Treat null/undefined as 0
    const numericValue = parseFloat(value !== null && value !== void 0 ? value : '0');
    //Not numeric so not formattable as currency, ignore
    if (!Number.isFinite(numericValue))
        return value;
    //Take user preferred locale, format as EUR
    const formattedValue = numericValue % 1 === 0
        ? numericValue
            .toLocaleString(getCurrentLCID(), {
            style: 'currency',
            currency: 'EUR',
        })
            .slice(0, -3) + ',-'
        : numericValue.toLocaleString(getCurrentLCID(), {
            style: 'currency',
            currency: 'EUR',
        });
    return formattedValue;
}
/**
 * Attempt to format the given value as widget datalabel, enforcing a max of 1 decimal point if the given value is numeric
 *
 * @param {*} value
 * @return {any}
 */
function formatWidgetDataLabel(value) {
    //Should use a more generic name once more usecases arrive for numbers with an optional single floating point when not rounded
    //Should we allow non-numeric values?
    //Currently tailored towards dashboard widgets, null may be supplied as value to enforce an empty label
    const numericValue = Number(value);
    if (!Number.isFinite(numericValue))
        return value;
    if (numericValue === 0 || numericValue % 1 === 0)
        return numericValue;
    //Number has decimal point, round to 1 digit
    return numericValue.toFixed(1);
}
/**
 * Attempt to format the given value as a number, max fraction may optionally be provided
 *
 * @param {*} value Value to format
 * @param opts Object containing extra options for formatting
 */
function formatNumber(value, opts) {
    const numericValue = Number(value);
    if (!Number.isFinite(numericValue))
        return value;
    // is our number rounded? -> return as is
    if (Number.isInteger(numericValue))
        return numericValue.toString();
    // does our number contain a fraction? round (should add a default at later stage)
    if ((opts === null || opts === void 0 ? void 0 : opts.maxFraction) !== undefined)
        return numericValue.toFixed(opts.maxFraction);
    return numericValue.toString();
}
/**
 * Attempt to format a number by adding a suffix (such as 'k', 'm', 'b', or 't') based on the magnitude of the number (with an option to
 *  specify the number of decimal places)
 *
 * @param {*} value value to be formatted
 * @param numberDecimal number of decimal places (defaulting to 1)
 * @return string
 */
function formatNumberSuffix(value, opts) {
    var _a, _b;
    const suffixes = ['', 'k', 'm', 'b', 't'];
    const absValue = Math.abs(value);
    // suffix should be assigned only to numbers above 100k
    const noSuffix = absValue < 100000;
    if (noSuffix) {
        return value.toFixed((_a = opts === null || opts === void 0 ? void 0 : opts.numberDecimal) !== null && _a !== void 0 ? _a : 1);
    }
    // count the amount of sets of 3 digits to know which suffix to add to the number
    const threeDigitSetCount = Math.min(Math.floor((Math.floor(absValue).toString().length - 1) / 3), suffixes.length - 1);
    const scaledNum = value / Math.pow(10, threeDigitSetCount * 3);
    const roundedNum = Math.round(scaledNum * 10) / 10;
    return `${roundedNum.toFixed((_b = opts === null || opts === void 0 ? void 0 : opts.numberDecimal) !== null && _b !== void 0 ? _b : 1)}${suffixes[threeDigitSetCount]}`;
}
;
/**
 * Attempt to format the given value as short date (eg. 18-11-2022)
 *
 * @param {*} value
 * @return {any}
 */
function formatDate(value) {
    const dt = toLuxon(value);
    if (!dt)
        return value;
    return dt.toLocaleString(fixNumericFormats(DateTime.DATE_SHORT));
}
/**
 * Attempt to format the given value as short date including time (eg. 18-11-2022 13:37:42)
 *
 * @param {*} value
 * @param opts
 * @return {any}
 */
function formatDateTime(value, opts) {
    const dt = toLuxon(value);
    if (!dt)
        return value;
    let outputFormat = fixNumericFormats(DateTime.DATETIME_SHORT);
    // Strip time component if marked optional when midnight
    if ((opts === null || opts === void 0 ? void 0 : opts.optionalTime) && dt.hour === 0 && dt.minute === 0) {
        outputFormat = fixNumericFormats(DateTime.DATE_SHORT);
    }
    return dt.toLocaleString(outputFormat);
}
function formatDateTimeNoSeconds(value) {
    const dt = toLuxon(value);
    if (!dt)
        return value;
    return dt.toLocaleString(DateTime.DATETIME_SHORT);
}
function formatDateFromWidget(value) {
    const dt = toLuxon(value);
    if (!dt)
        return value;
    return widgetDateTime(currentFormatterType, dt);
}
function widgetDateTime(value, dt) {
    if (value === 'hours') {
        // return dt.hour.toString() + ':' + dt.minute.toString();  daysExtra
        return dt.toLocaleString(fixNumericFormats(DateTime.TIME_24_SIMPLE));
    }
    if (value === 'days' || value === 'daysExtra') {
        return dt.day.toString() + '\u00A0' + dt.monthShort.toString();
    }
    if (value === 'months') {
        return dt.monthShort.toString() + '\u00A0' + dt.year.toString();
    }
    if (value === 'years') {
        return dt.year.toString();
    }
    if (value === 'datetime') {
        return dt.toLocaleString(fixNumericFormats(DateTime.DATETIME_SHORT_WITH_SECONDS));
    }
}
/**
 * Attempt to format the given value as time (eg. 13:37:42)
 *
 * @param {*} value
 * @param opts
 * @return {any}
 */
function formatTime(value, opts) {
    var _a, _b, _c, _d, _e, _f, _g;
    //<-- rename to timespan?
    if (value === null || value === undefined)
        return value;
    // simply use luxon's built-in formatting
    if (DateTime.isDateTime(value))
        return (_a = toLuxon(value)) === null || _a === void 0 ? void 0 : _a.toLocaleString(DateTime.TIME_WITH_SECONDS);
    let unit = opts === null || opts === void 0 ? void 0 : opts.unit;
    // are we dealing with a timespan like object?
    if (typeof value === 'object') {
        const hour = (_c = (_b = value.hours) !== null && _b !== void 0 ? _b : value.hour) !== null && _c !== void 0 ? _c : 0;
        const minute = (_e = (_d = value.minutes) !== null && _d !== void 0 ? _d : value.minute) !== null && _e !== void 0 ? _e : 0;
        const second = (_g = (_f = value.seconds) !== null && _f !== void 0 ? _f : value.second) !== null && _g !== void 0 ? _g : 0;
        // convert to seconds
        unit = 'seconds';
        value = hour * 3600;
        value += minute * 60;
        value += second;
    }
    if (typeof value === 'string')
        value = parseInt(value, 10);
    if (!Number.isFinite(value))
        return value;
    // unknown format, ensure our value consists of at least 2 characters with a leading zero
    if (!unit)
        return value.toString().padStart(2, '0');
    if (unit === 'milliseconds')
        value /= 1000; // to seconds
    const hours = Math.floor(value / 3600).toString().padStart(2, '0');
    const minutes = Math.floor((value % 3600) / 60).toString().padStart(2, '0');
    const seconds = Math.floor((value % 3600) % 60).toString().padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
}
/**
 * Attempt to format the given value as username
 *
 * When an object is provided as value, will attempt to format using `firstName`, `prefix`, and `lastName`
 *
 * @param {object} value
 * @return {any}
 */
function formatUserName(value) {
    if (typeof value !== 'object')
        return value;
    //Assume user object
    return [value.firstName, value.prefix, value.lastName].filter(x => x).join(' ');
}
/**
 * Attempt to format the given value as initials
 *
 * When an object is provided as value, will attempt to use `firstName` and `lastName`
 *
 * @param value
 * @return {any}
 */
function formatInitials(value) {
    var _a, _b;
    if (typeof value !== 'object')
        return value;
    return [(_a = value.firstName) === null || _a === void 0 ? void 0 : _a.at(0), (_b = value.lastName) === null || _b === void 0 ? void 0 : _b.at(0)]
        .filter(x => x)
        .join('')
        .toLowerCase();
}
registerDefaultFormatter('date', formatDate);
registerDefaultFormatter('time', formatTime);
registerDefaultFormatter('datetime', formatDateTime);
registerDefaultFormatter('datetimeWithoutSeconds', formatDateTimeNoSeconds);
registerDefaultFormatter('hours', formatDateFromWidget);
registerDefaultFormatter('daysExtra', formatDateFromWidget);
registerDefaultFormatter('days', formatDateFromWidget);
registerDefaultFormatter('months', formatDateFromWidget);
registerDefaultFormatter('years', formatDateFromWidget);
registerDefaultFormatter('currency', formatCurrency);
registerDefaultFormatter('widgetdatalabel', formatWidgetDataLabel);
registerDefaultFormatter('username', formatUserName);
registerDefaultFormatter('initials', formatInitials);
registerCustomFormatter('number', formatNumber);
registerCustomFormatter('numberSuffix', formatNumberSuffix);
/**
 * Format the given value using the formatter registered for the given type
 *
 * @param value
 * @param {string} type
 * @param opts
 * @return {any}
 */
function format(value, type, opts) {
    //Attempt to infer type when not provided?
    currentFormatterType = type;
    const formatter = _customFormatters.get(type) || _defaultFormatters.get(type);
    if (formatter)
        return formatter(value, opts);
    return value;
}
//Register default formatters on format object? -> format.asDate = formatDate
//Should we export individual formatters? :thonking:
/**
 * Plugin for exposing `$format` function to all components contained by the app
 * @type {{install(*): void}}
 */
const formattingPlugin = {
    install(app) {
        app.config.globalProperties.$format = format;
    },
};
export { format as default, formattingPlugin };

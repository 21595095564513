import { ChartComponent } from './chartInstance';
import { ready } from './events';
import { AxisBullet } from "@amcharts/amcharts5/xy";
import { ClockHand as AMClockHand } from '@amcharts/amcharts5/radar';
import { Label } from '@amcharts/amcharts5';
import * as am from '@amcharts/amcharts5';
const clamp = (value, min, max) => Math.min(max, Math.max(value, min));
/** Clockhand pointing to current value for a radar/gauge charts */
export class ClockHand extends ChartComponent {
    getTags() {
        return ['axis-enhancer', 'clockhand'];
    }
    constructor(settings) {
        super();
        this.value = settings.value;
        this.color = settings.color;
        this.addDependency(['radarchart', 'valueaxis']);
    }
    render(context) {
        const chart = context.get('chart');
        const axis = context.get('valueaxis');
        const clockHandItem = axis.createAxisRange(axis.makeDataItem({ value: 0 }));
        const min = axis.get('min');
        const max = axis.get('max');
        // for large ranges a label is rendered at the location our hand points to, disable
        clockHandItem.get('label').set('visible', false);
        const numberFormatter = context.root.numberFormatter;
        axis.createAxisRange(clockHandItem);
        const clockHandSprite = AMClockHand.new(context.root, {
            radius: am.percent(90),
        });
        clockHandSprite.hand.set('fill', am.color(this.color));
        clockHandSprite.pin.set('fill', am.color(this.color));
        const bullet = clockHandItem.set('bullet', AxisBullet.new(context.root, {
            sprite: clockHandSprite
        }));
        const label = chart.radarContainer.children.push(Label.new(context.root, {
            fontSize: "2em",
            centerX: am.p50,
            centerY: am.p0,
            fill: am.color(this.color),
            text: '0'
        }));
        const setLabelValue = (value) => {
            value = Math.round(value);
            label.set('text', numberFormatter.format(value));
        };
        // animate hand moving to initial value
        ready(context, () => {
            const animation = clockHandItem.animate({
                key: 'value',
                to: clamp(this.value, min, max),
                duration: 1000, // <-- move to settings.ts/defaults.ts/globals.ts?
                easing: am.ease.out(am.ease.cubic)
            });
            // workaround for when our value is beyond the configured min/max bounds
            // while our label should indicate the given value, our hand sprite disappears when trying to point such a location
            // should research why this happens -> implement a better solution
            // not the prettiest solution
            animation.waitForStop().then(() => setLabelValue(this.value));
        });
        // animate text alongside hand rotation
        bullet.get('sprite').on('rotation', () => setLabelValue(clockHandItem.get('value')));
    }
}

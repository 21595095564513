// barSeriesComponent.prepare() -> context.series.push(mySeries)
// categoryAxis: dependsOn -> chart, provides: axis
// series: dependsOn -> chart, axis, provides: series
// datalabels: dependsOn: series, provides: []
// context.register(labelComponent, dependsOn: [seriesComponent])  context.find(feature)
// startup/we -> run in any order, fill context
// build/we -> run in order of dependsOn, track what depends on what, can make use of (readonly?) version of previous build context
// manager knows of series etc, so could push updates to the individual components
// chart component <> vue component, vue components simply act as a way to push components into our manager
import './license';
import { Root } from '@amcharts/amcharts5';
import { cleanKey, intersects } from '../../../../helpers/util';
import { chartManager } from './chartManager';
/** Helper class for obtaining unique keys on a per-object basis  */
class KeyHelper {
    static prefixedKeyFor(prefix, obj) {
        return prefix + this.keyFor(obj);
    }
    static keyFor(obj) {
        if (obj === undefined)
            return `${++this._current}`;
        if (this._keys.has(obj))
            return this._keys.get(obj);
        const key = `${++this._current}`;
        this._keys.set(obj, key);
        return key;
    }
}
KeyHelper._current = 0;
KeyHelper._keys = new WeakMap();
// should either add an explicit xy property to our chart
// register xy values (not typesafe?)
// or subclass our context for differing chart types
export class ChartRenderingContext {
    constructor(chart, root) {
        this._variables = new Map();
        /** All components that have finished rendering */
        this.renderedComponents = [];
        this.chart = chart;
        this.root = root;
    }
    /** Resolve the variable with the given key */
    get(key) {
        var _a, _b;
        // if ( !this._requireRenderingComponent('get') ) return;
        key = cleanKey(key);
        // prefer contextual values
        const leftover = this.renderingComponent ? [this.renderingComponent] : [];
        // prefer parents over siblings
        for (let cmp = leftover.pop(); cmp; cmp = leftover.pop()) {
            const cmpKey = this._key(key, cmp);
            if (this._variables.has(cmpKey))
                return this._variables.get(cmpKey);
            leftover.push(...cmp.dependencies.filter(dep => typeof dep !== 'string'));
        }
        if (this._variables.has(key))
            return this._variables.get(key);
        // below code can be used for debugging
        const debug = false;
        if (debug) {
            console.warn(`Attempted to read unknown variable ${key} in ${((_b = (_a = this.renderingComponent) === null || _a === void 0 ? void 0 : _a.constructor) === null || _b === void 0 ? void 0 : _b.name) || 'unknown component'}. Was 'addDependency(...)' invoked with the correct dependencies?`);
        }
        /////////////
        return undefined;
    }
    /** Set a contextual value, only accessible from within the current dependency graph */
    set(key, value) {
        /** @ts-ignore */
        if (!this._requireRenderingComponent('set'))
            return;
        key = cleanKey(key);
        this._variables.set(this._key(key, this.renderingComponent), value);
        return value;
    }
    /** Set a global variable, accessible even outside of the current dependency graph */
    setGlobal(key, value) {
        // if ( !this._requireRenderingComponent('setGlobal') ) return;
        key = cleanKey(key);
        this._variables.set(key, value);
        return value;
    }
    // todo: preserve dependency graph post-render, allow binding functions to be executed with current chart render context
    /** Wrap the given callback in a function executing using the current renderingComponent */
    // runInCurrentContext<R>(cb: (...args: any[]) => R): (...args: Parameters<typeof cb>) => R { // <-- todo: think of a better name
    //   const capturedRenderingComponent = this.renderingComponent;
    //
    //   return (...args) => {
    //     const currentRenderingComponent = this.renderingComponent;
    //
    //     try {
    //       this.renderingComponent = capturedRenderingComponent;
    //       return cb(...args);
    //     } finally {
    //       this.renderingComponent = currentRenderingComponent;
    //     }
    //   };
    // }
    /** Generate a context dependent key */
    _key(key, cmp) {
        return KeyHelper.prefixedKeyFor(key, cmp);
    }
    _requireRenderingComponent(method) {
        if (this.renderingComponent === undefined) {
            console.warn(`Attempted to invoke ${method} without rendering component. Please only access the context from within the 'setup' and 'render' methods from chart components.`);
            return false;
        }
        return true;
    }
}
/**
 * Interface representing an individual component of a chart, components may be standalone functionality, or enhancers on top of other components
 *
 * When attempting to access other components, make sure to register them as dependencies. The dependency graph determines rendering order and what has access to what.
 */
export class ChartComponent {
    constructor() {
        // introduce concept of optional dependencies? -> if present, treat as dependency, if not, still allow component to render
        /** All components this component depends on, may be a type/tag */
        this.dependencies = []; // <-- should likely split up tag dependencies/component dependencies
        /** All components depending on this component */
        this.dependends = [];
        // events? -> afterRender
    }
    /** Add one or many dependencies for this component */
    addDependency(dependencies) {
        dependencies = [dependencies].flat();
        this.dependencies.push(...dependencies);
        for (const dependency of dependencies) {
            // tag based dependencies are resolved when necessary
            if (typeof dependency === 'string')
                continue;
            // mark self as dependend (allow navigating upwards/downwards)
            dependency.dependends.push(this);
        }
    }
    /** Remove one or many dependencies */
    removeDependency(dependencies) {
        dependencies = [dependencies].flat();
        for (const dependency of dependencies) {
            const dependencyIdx = this.dependencies.indexOf(dependency);
            if (dependencyIdx === -1)
                continue;
            this.dependencies.splice(dependencyIdx, 1);
            if (typeof dependency === 'string')
                continue;
            // also unmark self as dependend on
            const dependendIdx = dependency.dependends.indexOf(this);
            if (dependendIdx === -1)
                continue;
            dependency.dependends.splice(dependendIdx, 1);
        }
    }
    /** Setup the context, register state etc. */
    setup(context) {
        /* noop by default */
    } // <-- rename to beforeRender
    // cleanup, dispose, oid -> toolbar integration, remove listeners
    dispose() {
        /* noop by default */
    }
}
export class ChartInstance {
    constructor(rootElement, settings) {
        // track active context
        // dependency can be either a specific component, or a name
        // when a name is provided, all components with said name are processed before (eg. legend -> series)
        /** Collection of all components belonging to the current chart */
        this._components = [];
        /** Current rendering context */
        this._activeContext = undefined;
        /** Id yielded by the setTimeout call scheduling the next render */
        this._nextScheduledRenderTimeoutId = undefined;
        /** Whether or not dispose has been called, when disposed, all operations become noops */
        this._isDisposed = false;
        this._rootElement = rootElement;
        // just ensure its something unique, doesn't have to match with our rootElement id
        // upon render we could assign a `data-chart="id"` attribute to our element though
        this.id = ChartInstance.generateId();
        this.settings = settings || {};
        chartManager.register(this);
    }
    /** Generate a unique id, aimed to be used as selector for instance creation */
    static generateId() {
        return KeyHelper.prefixedKeyFor('__am__chart__');
    }
    /** Add a component to the chart instance */
    add(component) {
        if (this._isDisposed)
            return;
        this._components.push(component);
        // is scheduling a render on addition/removal of a component desired behavior?
        // put rerenders behind a flag?
        this.scheduleRender();
    }
    /** Remove the given component from the chart instance */
    remove(component) {
        if (this._isDisposed)
            return;
        const idx = this._components.indexOf(component);
        if (idx === -1)
            return;
        // sever relations with existing components, may lead to dangling components -> perform second cleanup round after?
        for (const dependency of [...component.dependencies])
            component.removeDependency(dependency);
        for (const dependend of [...component.dependends])
            dependend.removeDependency(component);
        component.dispose();
        this._components.splice(idx, 1);
        this.scheduleRender();
    }
    /** Replace the given component with an updated version for the chart instance */
    replace(oldValue, newValue) {
        // should our param order be old/new, or new old? 🤔
        // replace: old with new does seem like the more natural option
        this.remove(oldValue);
        this.add(newValue);
    }
    /** Resolve the first encountered component with the given tag */
    resolveComponent(tag) {
        // should we give components names (static `name` property?), base lookup on name
        // todo: once we track rendered components, only search in rendered components
        return this._components.find(x => x.getTags().includes(tag));
    }
    // update hook? -> allow a component to tell it needs to update
    /** Cancel the next scheduled render */
    cancelScheduledRender() {
        clearTimeout(this._nextScheduledRenderTimeoutId);
    }
    /** Schedule the chart to render at the next earliest available time */
    scheduleRender() {
        this.cancelScheduledRender();
        // we've already been disposed, ignore
        if (this._isDisposed)
            return;
        //@ts-ignore
        this._nextScheduledRenderTimeoutId = setTimeout(() => this.render());
    }
    /** Render the chart, invoking after the initial render causes the chart to re-render */
    render() {
        if (this._isDisposed)
            return;
        // todo: error handling
        this.cancelScheduledRender(); // <-- when called after scheduleRender, ignore said schedule call
        // cleanup previous
        if (this._activeContext)
            this._activeContext.root.dispose();
        if (typeof this._rootElement === 'string' && !document.getElementById(this._rootElement)) {
            console.warn(`Failed to locate AM root element with id: ${this._rootElement}`);
            return;
        }
        const root = Root.new(this._rootElement); // <-- should this be a ChartComponent?
        const context = new ChartRenderingContext(this, root);
        // track all 'active' charts -> required for linking charts together
        // track last used/active context for use in cleanup
        this._activeContext = context;
        // resolve tag based dependencies -> explicitly add as dependencies
        for (const cmp of this._components)
            cmp.addDependency(this.resolveTagBasedDependencies(cmp));
        // first: determine our root components, these can be rendered without having to rely on other components having been rendered
        const leftover = this._components.filter(x => x.dependencies.length === 0);
        const rendered = new Set();
        // prepare context for render (allow defining globals, etc.)
        for (const cmp of this._components) {
            context.renderingComponent = cmp;
            cmp.setup(context);
            context.renderingComponent = undefined;
        }
        // work from start to end
        for (let cmp = leftover.shift(); cmp; cmp = leftover.shift()) {
            if (rendered.has(cmp))
                continue;
            // not all dependencies have rendered yet, skip
            const hasUnfulfilledDependencies = cmp.dependencies.some(dep => typeof dep !== 'string' && !rendered.has(dep));
            if (hasUnfulfilledDependencies)
                continue;
            // mark our 'rendering' component in our context, allows for smarter resolving of context based variables (eg. current series)
            // by binding our variable sets to ourselves (so only child components can access them)
            context.renderingComponent = cmp;
            cmp.render(context);
            context.renderedComponents.push(cmp);
            context.renderingComponent = undefined;
            rendered.add(cmp);
            // not the most efficient way of handling things, acceptable on small scale
            leftover.push(...cmp.dependends);
        }
        // fixme (v2?): explicit 'update'/'set' data methods? -> will require some rewrites, data is advised to be set
        // as late as possible
        // cleanup tag based dependencies, they're only known at render time
        for (const cmp of this._components)
            cmp.removeDependency(this.resolveTagBasedDependencies(cmp));
    }
    /** Dispose the instance, cleaning up resources and rendering it unusable */
    dispose() {
        if (this._isDisposed)
            return;
        // first give components themselves the chance to cleanup
        for (const cmp of this._components) {
            try {
                cmp.dispose();
            }
            catch (e) {
                /* Should not fail */
            }
        }
        // finally dispose our am root, losing our am context
        //TODO: Discuss this with Danny, seems to work fine for now.
        // if (this._activeContext) this._activeContext.root.dispose();
        this.cancelScheduledRender();
        this._isDisposed = true;
        chartManager.unRegister(this);
    }
    /** Resolve a list of components matching tag based dependencies */
    resolveTagBasedDependencies(cmp) {
        const tags = cmp.dependencies.filter(dep => typeof dep === 'string');
        return this._components.filter(cmp => intersects(cmp.getTags(), tags));
    }
}

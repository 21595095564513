﻿<template>
  <div class="chart" :key="xaxisType">
    <apexchart ref="chart" type="bar" :height="height" :width="width" :options="chartOptions" :series="dataSeries" />
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';

import {BarChartMixin} from './mixins';
import {autoFitToGrid, normalizeApexSeries, widgetDataLabelContrastColor} from './util';
import {ToolbarProxy} from './toolbarProxy';

let _chartcounter = 0;

export default {
  name: 'XApexBarChart',
  components: {
    apexchart: VueApexCharts,
  },
  mixins: [BarChartMixin],
  data() {
    return {
      modifyHeight: this.height,
      chartOptions: this.generateChartOptions(),
    };
  },
  computed: {
    updateChart() {
      const {stacked, stackType, horizontal, series, xaxisType, dataLabels, zoom, forecastDataPoints, theme, group} =
        this;
      return {
        stacked,
        stackType,
        horizontal,
        series,
        xaxisType,
        dataLabels,
        zoom,
        forecastDataPoints,
        theme,
        group,
      };
    },
    dataSeries() {
      return normalizeApexSeries(this.series);
    },
  },
  watch: {
    updateChart(val) {
      this.chartOptions = {...this.generateChartOptions()};
    },
    dataUpdate(newVal, oldVal) {
      if (newVal?.update || JSON.stringify(oldVal) !== JSON.stringify(newVal)) {
        this.updateData(normalizeApexSeries(newVal));
      }
    },
  },
  methods: {
    ensureDataLabels() {
      // all the path elements and their value
      const barValues = [];
      this.$el
        ?.querySelectorAll('.apexcharts-bar-area')
        .forEach(bar => bar.attributes.val && barValues.push(bar.attributes.val?.value));

      // text elements that are supposed to contain the dataLabel
      const textElements = [];
      this.$el
        ?.querySelectorAll('.apexcharts-datalabels')
        .forEach(serie => serie.childNodes.forEach(child => textElements.push(child.children[0])));

      textElements.forEach((el, index) => {
        if (!el.textContent) el.textContent = barValues[index];
      });
    },
    updateData(data) {
      //DEV: Paths get morphed, so dont animate.
      //  The slide effect in the apex realtime demo is the result of a svgjs bug,
      //  When xaxis is fixed & larger than the series (using xaxis.range) svgjs will slide in stead of morph the data.
      //  When the series stops growing, or draws outside the graph boundary the data will start morphing.
      //  So keep adding datapoints AND set series head (data[0].x=data[1].x) to prevent drawing will keep svgjs in slide mode.
      //
      //  Ensuring this in the data is too much work, so we do not animate.
      if (data.append) this.$refs.chart.chart?.appendSeries(data.series, false);
      else this.$refs.chart.chart?.updateSeries(data.series, false);
    },
    generateChartOptions() {
      return autoFitToGrid({
        chart: {
          type: 'bar',
          group: this.group ?? undefined,
          id: `bar_${++_chartcounter}`,
          stacked: this.stacked,
          stackType: this.stackType,
          // note that there is a bug with the tool bar and barcharts (also dynamic and other related bar charts)
          // meaning that when horizontal bars are being show the menu in Apex is bugged and wont be shown (no workaround)
          toolbar: {
            tools: {
              customIcons: [
                {
                  icon: '',
                  // The 5 and 1 are the indexes of the icons in the toolbar depending if its in zoom state or horizontal, otherwise there will be an empty index and apex will break.
                  index: -1,
                  title: 'Export Excel',
                  class: 'custom-icon exportexcel',
                  click: () => {
                    this.$emit('export');
                  },
                },
              ],
            },
            export: {
              csv: {filename: this.name},
              svg: {filename: this.name},
              png: {filename: this.name},
            },
          },
          zoom: {
            type: 'xy',
            enabled: this.zoom,
          },
          events: {
            mounted: ctx => {
              this.ensureDataLabels();
              return this[ToolbarProxy.InjectionKey]?.update(ctx.el);
            },
            updated: ctx => {
              this.ensureDataLabels();
              return this[ToolbarProxy.InjectionKey]?.update(ctx.el);
            },
          },
        },
        forecastDataPoints: {
          count: this.forecastDataPoints,
        },
        colors: this.theme,
        plotOptions: {
          bar: {
            horizontal: this.horizontal,
            borderRadius: 4,
            columnWidth: '90%',
          },
        },
        dataLabels: {
          enabled: this.dataLabels,
          hideOverflowingLabels: true,
          style: {
            colors: widgetDataLabelContrastColor(this.series, this.theme),
            fontSize: 10,
          },
          formatter: val => this.$format(val, 'widgetdatalabel'),
        },
        xaxis: {
          type: this.xaxisType,
          tickAmount: 'dataPoints',
          min: undefined,
          max: undefined,

          //Using a tickplacement not equal to `between` for a non `horizontal` bar without an xaxis type of `numeric`/`datetime`
          //will cause apex to apply a different calculation for positioning the bars, this is especially noticeable for smaller
          //datasets with larger bars, as the outermost bars may be rendered partially outside of the chart
          //as a workaround, we'll force our tickplacement to be `between` when our bars aren't marked as `horizontal`
          // note note due to top toolbar rendering, it needs to be 'on' if you want to use the toolbar (bug in Apex)
          tickPlacement: this.horizontal ? 'on' : 'between',
        },
        yaxis: {
          zoom: this.zoom,
          max: this.stackType === '100%' ? 100 : undefined,
          axisBorder: {
            show: true,
          },
          labels: {
            formatter: val => this.$format(val, 'widgetdatalabel'),
          },
        },
        fill: {
          opacity: 0.9,
        },
        noData: {
          text: this.$t('WIDGET_NO_DATA'),
        },
        legend: {
          //By default Apex shows a legend when the chart has at least two series with at least one containing data
          //The legend may break in certain circumstances when collapsing a series (https://github.com/apexcharts/apexcharts.js/issues/3156)
          //As a workaround, we'll manually force our legend to be displayed when matching Apex' default conditions
          showForSingleSeries: this.series.length > 1 && this.series.some(series => series.data.length),
        },
      });
    },
  },
};
</script>

<style lang="scss">
.apexcharts-toolbar {
  z-index: 0 !important;
}

.apexcharts-toolbar-custom-icon.custom-icon {
  background: url('~source/assets/icons/file-download.svg?url') no-repeat;
  background-size: 1.15rem;
  margin-left: 0.3rem;
}

.apexcharts-legend-text {
  color: var(--text-primary, #313741) !important;
}

.apexcharts-xaxis-label,
.apexcharts-yaxis-label {
  fill: var(--text-primary, #313741);
}
</style>

import { onUnmounted } from 'vue';
export default function () {
    /**
     * Proxy for window.setTimeout binding timeout to consuming component lifecycle, timeout will automatically be cancelled on component unmount
     * @param args
     */
    return function lifecycleBoundTimeout(...args) {
        const id = setTimeout(...args);
        onUnmounted(() => clearTimeout(id));
        return id;
    };
}

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useConfirmStore } from '../stores/confirmation';
import { T } from '../helpers/translationsProvider';
import { useNotifications } from '../composables/notification';
import { useRouter } from 'vue-router';
import { h, resolveComponent } from 'vue';
/**
 * Create a generic resource deletion flow, concrete deletion is expected to be supplied in the options object
 * @param options
 */
function useDelete(options) {
    var _a;
    const showToast = (_a = options.showToast) !== null && _a !== void 0 ? _a : true;
    // anything dependent component context needs to execute prior to going async
    const router = useRouter();
    const confirmStore = useConfirmStore();
    const { successNotification, errorNotification } = useNotifications();
    const XLink = resolveComponent('XLink');
    function startDeleteFlow(target) {
        return __awaiter(this, void 0, void 0, function* () {
            // ConfirmStep -> { title, message }
            const didConfirm = yield confirmStore.prompt(T(options.promptTitle), T(options.promptMessage), 'error', {
                denyButton: confirmStore.defaultButton.cancel,
                confirmButton: Object.assign(Object.assign({}, confirmStore.defaultButton.delete), { name: options.promptButtonText ? T(options.promptButtonText) : confirmStore.defaultButton.delete.name }),
            });
            if (!didConfirm)
                return;
            // should we try to check for locks prior to attempting to delete? popup after user attempts to delete could be perceived as frustrating
            // can do this after switching to the apploader, more contextual information available (eg. entitytype)
            const id = typeof target === 'object' ? target.id : target;
            const deleteResponse = yield options.delete(id);
            if ((deleteResponse === null || deleteResponse === void 0 ? void 0 : deleteResponse.success) === false)
                return handleFailure(options, target, deleteResponse);
            if (options.deleted)
                yield options.deleted();
            if (showToast)
                successNotification(T(options.notificationSuccess || 'SAVED_SUCCESS'));
        });
    }
    function handleFailure(configuration, target, response) {
        var _a, _b, _c, _d, _e;
        if (response === undefined)
            return; // never?
        // prefer custom handlers
        if ((_a = configuration.failed) === null || _a === void 0 ? void 0 : _a.shouldHandle(response)) {
            return (_b = configuration.failed) === null || _b === void 0 ? void 0 : _b.handle(response);
        }
        if (!('statusCode' in response)) {
            return errorNotification(response.reason || T(configuration.notificationFailed || 'ERROR_DELETE_FAILED'));
        }
        // locked -> usage lock
        if (response.statusCode === 423 && ((_c = response.value.data) === null || _c === void 0 ? void 0 : _c.lockHolders)) {
            return handleLockedResource(response);
        }
        // generic failed
        errorNotification(T((_d = response.value) === null || _d === void 0 ? void 0 : _d.resourceKey) ||
            ((_e = response.value) === null || _e === void 0 ? void 0 : _e.message) ||
            T(configuration.notificationFailed || 'ERROR_DELETE_FAILED'));
    }
    function handleLockedResource(response) {
        const lockHolders = response.value.data.lockHolders.value;
        confirmStore.$reset(); // clean slate
        confirmStore.updateBoxProperties({
            title: T('ERROR_DELETE_FAILED_USAGE_LOCKS_DETECTED_TITLE'),
            message: T('ERROR_DELETE_FAILED_USAGE_LOCKS_DETECTED_MESSAGE'),
            status: 'warning',
            componentName: 'NodeList',
            data: {
                nodes: lockHolders.map((lockHolder) => buildLockHolderLink(lockHolder)),
            },
            buttons: [confirmStore.defaultButton.ok],
        });
    }
    // should be registered on a per-loaded app basis? generic linkbuilder?
    function buildLockHolderLink(lockHolder) {
        const buildDashboardLink = () => {
            return router.resolve({
                name: 'dashboard',
                params: {
                    dashboard_id: lockHolder.metadata.dashboardId,
                    group_id: lockHolder.metadata.widgetGroupId,
                },
            }).fullPath;
        };
        const buildGenericLink = () => '#';
        return h(XLink, { href: lockHolder.type === 'widget' ? buildDashboardLink() : buildGenericLink() }, () => `${lockHolder.metadata.label || lockHolder.id}`);
    }
    return startDeleteFlow;
}
export { useDelete };

<template>
  <div id="wrapper" class="brush-chart" :key="chartOptions1.xaxis.type">
    <div class="chart-line1">
      <apexchart
        ref="chartPrimary"
        type="line"
        :height="height"
        :width="width"
        :options="chartOptions1"
        :series="dataSeries"
      />
    </div>
    <div class="chart-line2">
      <apexchart
        ref="chartSecondary"
        type="area"
        height="140"
        :width="width"
        :options="chartOptions2"
        :series="dataSeries"
      />
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import {autoFitToGrid, normalizeApexSeries} from './util';
import _ from 'lodash';
import {ToolbarProxy} from './toolbarProxy';

export default {
  name: 'XBrushChart',
  components: {
    apexchart: VueApexCharts,
  },
  inject: {
    ...ToolbarProxy.InjectionDefinition,
  },
  props: {
    height: {
      type: String,
      default: '220',
    },
    width: {
      type: String,
      default: '100%',
    },
    series: {
      type: [Array, Object],
      default: () => [],
    },
    xaxisType: {
      type: String,
      default: 'category',
    },
    zoom: {
      type: Boolean,
      default: false,
    },
    dataLabels: {
      type: Boolean,
      default: false,
      style: {
        colors: ['var(--widget-dataLabelColor, #FFFFFF)'],
      },
    },
    lineType: {
      type: String,
      default: 'smooth',
    },
    forecastDataPoints: {
      type: Number,
      default: 0,
    },
    theme: {
      type: Array,
      default: () => ['#cccccc', '#bbbbbb', '#eeeeee'],
    },
    dataUpdate: {
      type: [Array, Object],
      default: () => {},
    },
    /**
     * Name of the chart used in export filenames
     */
    name: {
      type: String,
      required: false,
    },
  },
  emits: ['export'],
  data() {
    const chartOne = _.uniqueId();
    const chartTwo = _.uniqueId();

    return {
      chartOne,
      chartTwo,
      chartOptions1: this.generateChartOptions1(chartOne),
      chartOptions2: this.generateChartOptions2(chartTwo, chartOne),
    };
  },
  computed: {
    updateChart() {
      const {series, xaxisType, dataLabels, lineType, forecastDataPoints, theme} = this;
      return {
        series,
        xaxisType,
        dataLabels,
        lineType,
        forecastDataPoints,
        theme,
      };
    },
    dataSeries() {
      return normalizeApexSeries(this.series);
    },
  },
  watch: {
    updateChart(val) {
      this.chartOptions1 = {...this.generateChartOptions1(this.chartOne)};
      this.chartOptions2 = {
        ...this.generateChartOptions2(this.chartTwo, this.chartOne),
      };
    },
    dataUpdate(newVal, oldVal) {
      if (newVal?.update || JSON.stringify(oldVal) !== JSON.stringify(newVal)) {
        this.updateData(normalizeApexSeries(newVal));
      }
    },
  },
  created() {
    this[ToolbarProxy.InjectionKey].addActionListener('reset', () => {
      if (this.$refs.chartSecondary) {
        //Step 1: remove selection from our secondary chart, primary chart selection is tied to secondary chart selection
        this.$refs.chartSecondary.chart?.updateOptions({
          chart: {
            selection: {
              enabled: true,
              xaxis: {
                min: undefined,
                max: undefined,
              },
            },
          },
        });
      }

      //Step 2 reset the series of our primary chart, resetSeries by default also resets zoom
      if (this.$refs.chartPrimary) this.$refs.chartPrimary.chart?.resetSeries();
    });
  },
  methods: {
    updateData(data) {
      //DEV: Paths get morphed, so dont animate.
      //  The slide effect in the apex realtime demo is the result of a svgjs bug,
      //  When xaxis is fixed & larger than the series (using xaxis.range) svgjs will slide in stead of morph the data.
      //  When the series stops growing, or draws outside the graph boundary the data will start morphing.
      //  So keep adding datapoints AND set series head (data[0].x=data[1].x) to prevent drawing will keep svgjs in slide mode.
      //
      //  Ensuring this in the data is too much work, so we do not animate.
      if (data.append) this.$refs.chartPrimary.chart?.appendSeries(data.series, false);
      else this.$refs.chartPrimary.chart?.updateSeries(data.series, false);

      if (data.append) this.$refs.chartSecondary.chart?.appendSeries(data.series, false);
      else this.$refs.chartSecondary.chart?.updateSeries(data.series, false);
    },
    generateChartOptions1(chartOne) {
      return autoFitToGrid({
        chart: {
          id: chartOne,
          type: 'line',
          zoom: {
            enabled: this.zoom,
          },
          toolbar: {
            autoSelected: 'pan',
            show: true,
            tools: {
              customIcons: [
                {
                  icon: '',
                  // fixed position for download button
                  index: -1,
                  title: 'Export Excel',
                  class: 'custom-icon exportexcel',
                  click: () => {
                    this.$emit('export');
                  },
                },
              ],
            },
            export: {
              csv: {filename: this.name},
              svg: {filename: this.name},
              png: {filename: this.name},
            },
          },
          events: {
            mounted: ctx => this[ToolbarProxy.InjectionKey]?.update(ctx.el),
            updated: ctx => this[ToolbarProxy.InjectionKey]?.update(ctx.el),
          },
        },
        forecastDataPoints: {
          count: this.forecastDataPoints,
        },
        colors: this.theme,
        stroke: {
          width: 3,
          curve: this.lineType,
        },
        dataLabels: {
          enabled: this.dataLabels,
        },
        fill: {
          opacity: 0.9,
        },
        markers: {
          size: 1,
          strokeWidth: 3,
        },
        xaxis: {
          type: this.xaxisType,
        },
        noData: {
          text: this.$t('WIDGET_NO_DATA'),
          style: {
            color: 'var(--text-primary, #313741)',
          },
        },
      });
    },

    generateChartOptions2(chartTwo, chartOne) {
      return autoFitToGrid({
        chart: {
          id: chartTwo,
          type: 'area',
          brush: {
            target: chartOne,
            enabled: true,
            autoScaleYaxis: false,
          },
          selection: {
            enabled: true,
          },
        },
        forecastDataPoints: {
          count: this.forecastDataPoints,
        },
        colors: this.theme,
        fill: {
          type: 'gradient',
          gradient: {
            opacityFrom: 0.91,
            opacityTo: 0.1,
          },
        },
        xaxis: {
          type: this.xaxisType,
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          tickAmount: 2,
        },
        noData: {
          text: this.$t('WIDGET_NO_DATA'),
          style: {
            color: 'var(--text-primary, #313741)',
          },
        },
      });
    },
  },
};
</script>

<style scoped>
.brush-chart {
  display: flex;
  flex-direction: column;
  margin-top: -2rem;
}
.chart-line1 {
  flex: 2;
}
.chart-line2 {
  flex: 1;
  margin-top: -2rem;
}
</style>

<docs>
  ```jsx

      <XBrushChart
        height="220"
        width="100%"
        :series="[
          {
            name: 'Line 1',
            data: [
              {
                x: 'South Korea',
                y: 400
              },
              {
                x: 'Canada',
                y: 430  
              },
              {
                x: 'United Kingdom',
                y: 448 
              },
              {
                x: 'Netherlands',
                y: 470
              },
              {
                x: 'Italy',
                y: 540
              },
              {
                x: 'France',
                y: 580
              },
              {
                x: 'Japan',
                y: 690
              },
              {
                x: 'United States',
                y: 1100
              },
              {
                x: 'China',
                y: 1200
              },
              {
                x: 'Germany',
                y: 1380
              }
            ]
          }
        ]"
        xaxisType="category"
        :zoom="true"
        :dataLabels="true"
        lineType="smooth"
        :forecastDataPoints="2"
        :theme="['#2EB2FF', '#10CB73', '#F2DF32', '#D32D85', '#0DD2D2', '#E04E2A', '7731BD', '#1FBB97']"
        name="Pino"
      />

    ```
</docs>

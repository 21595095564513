import { p100, Bullet, Circle, LinearGradient, Template } from '@amcharts/amcharts5';
import { LineSeries as AMLineSeries, ColumnSeries, StepLineSeries, SmoothedXLineSeries, } from '@amcharts/amcharts5/xy';
import { ChartComponent } from './chartInstance';
import { categoryAxisSwitch, ensureCursor } from './util';
import { PieSeries as AmPieSeries } from '@amcharts/amcharts5/percent';
/** Find the index of the series the given dataitem belongs to */
function seriesIndex(dataItem) {
    // todo: check if we're dealing with a series chart, item.classNames.contains(SeriesChart.className)
    const series = dataItem.component;
    const chart = series.chart;
    return chart.series.indexOf(series);
}
/** Find the index of the given dataitem */
function itemIndex(dataItem) {
    return dataItem.component.dataItems.indexOf(dataItem);
}
export class BarSeries extends ChartComponent {
    getTags() {
        return ['series', 'columnseries', 'barseries', 'xy-series'];
    }
    constructor(settings) {
        super();
        this.series = undefined;
        this.name = settings.name;
        this.stackType = settings.stackType;
        this.data = settings.data;
        // we require at least a valueaxis and categoryaxis to be present
        this.addDependency(['valueaxis', 'categoryaxis']);
    }
    setup(context) {
        var _a;
        const allSeries = (_a = context.get('seriesComponents')) !== null && _a !== void 0 ? _a : context.setGlobal('seriesComponents', []);
        allSeries.push(this);
    }
    render(context) {
        const chart = context.get('chart');
        const categoryAxis = context.get('categoryAxis');
        // Add series
        // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
        const series = chart.series.push(ColumnSeries.new(context.root, Object.assign(Object.assign({}, getXYSharedSettings(context, this)), { baseAxis: categoryAxis, 
            // Only show tooltip for hovered column
            // https://www.amcharts.com/docs/v5/reference/columnseries/#seriesTooltipTarget_setting
            // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/#limiting-number-of-tooltips-shown-at-a-time
            seriesTooltipTarget: 'series', 
            // follow mouse cursor
            tooltipPosition: 'pointer', tooltipText: '{categoryX}: {valueY}' })));
        // hide items with a `_hide` attribute
        series.columns.template.adapters.add('forceHidden', (value, target) => { var _a, _b, _c; return (_c = (_b = (_a = target.dataItem) === null || _a === void 0 ? void 0 : _a.dataContext) === null || _b === void 0 ? void 0 : _b._hide) !== null && _c !== void 0 ? _c : value; });
        // leave no gap between bars
        series.columns.template.setAll({ [categoryAxisSwitch(context, 'width', 'height')]: p100 });
        // rounded corners
        series.columns.template.adapters.add(categoryAxisSwitch(context, 'cornerRadiusTR', 'cornerRadiusTR'), (value, target) => {
            // could take context.chart.series from target instead of from context
            if (this.stackType &&
                seriesIndex(target.dataItem) !== context.get('seriesComponents').length - 1) {
                return 0;
            }
            return 5;
        });
        series.columns.template.adapters.add(categoryAxisSwitch(context, 'cornerRadiusTL', 'cornerRadiusBR'), (value, target) => {
            if (this.stackType &&
                seriesIndex(target.dataItem) !== context.get('seriesComponents').length - 1) {
                return 0;
            }
            return 5;
        });
        this.series = series;
        series.data.setAll(this.data);
        series.appear(1000); // <-- should this be part of our chart itself, or our chartinstance?
        // expose for underlying components
        context.set('series', series);
        context.set('seriesComponent', this);
    }
}
export class PieSeries extends ChartComponent {
    getTags() {
        return ['series', 'pieseries'];
    }
    constructor(settings) {
        super();
        this.name = settings.name;
        this.data = settings.data;
        this.addDependency('chart');
    }
    setup(context) {
        var _a;
        const allSeries = (_a = context.get('seriesComponents')) !== null && _a !== void 0 ? _a : context.setGlobal('seriesComponents', []);
        allSeries.push(this);
    }
    render(context) {
        const chart = context.get('chart');
        const series = chart.series.push(AmPieSeries.new(context.root, {
            name: this.name,
            valueField: 'value',
            categoryField: 'category',
            alignLabels: false,
        }));
        // amcharts shows ticks/labels by default, they should be explicitly enabled via our 'seriesLabels' component
        series.ticks.template.set('forceHidden', true);
        series.labels.template.set('forceHidden', true);
        context.set('series', series);
        context.set('seriesComponent', this);
        series.data.setAll(this.data);
        series.appear(1000);
    }
}
// data: {value: number, category: string}[];
export class LineSeries extends ChartComponent {
    constructor(settings) {
        super();
        this.series = undefined;
        this.name = settings.name;
        this.stackType = settings.stackType;
        this.data = settings.data;
        this.lineStyle = settings.lineStyle || 'straight';
        // we require at least a valueaxis and categoryaxis to be present
        this.addDependency(['valueaxis', 'categoryaxis']);
    }
    getTags() {
        return ['series', 'lineseries', 'line-like-series', 'xy-series'];
    }
    setup(context) {
        var _a;
        const allSeries = (_a = context.get('seriesComponents')) !== null && _a !== void 0 ? _a : context.setGlobal('seriesComponents', []);
        allSeries.push(this);
    }
    render(context) {
        const series = createAMLineSeries(context, this);
        series.data.setAll(this.data);
        series.appear(1000); // <-- should this be part of our chart itself, or our chartinstance?
        this.series = series;
        // expose for underlying components
        context.set('series', series);
        context.set('seriesComponent', this);
    }
}
export class AreaSeries extends ChartComponent {
    constructor(settings) {
        super();
        this.series = undefined;
        this.name = settings.name;
        this.stackType = settings.stackType;
        this.data = settings.data;
        this.lineStyle = settings.lineStyle || 'straight';
        // we require at least a valueaxis and categoryaxis to be present
        this.addDependency(['valueaxis', 'categoryaxis']);
    }
    getTags() {
        return ['series', 'areaseries', 'line-like-series', 'xy-series'];
    }
    setup(context) {
        var _a;
        const allSeries = (_a = context.get('seriesComponents')) !== null && _a !== void 0 ? _a : context.setGlobal('seriesComponents', []);
        allSeries.push(this);
    }
    render(context) {
        const series = createAMLineSeries(context, this);
        series.fills.template.setAll({
            fillOpacity: 1,
            visible: true,
            fillGradient: LinearGradient.new(context.root, {
                stops: [
                    {
                        opacity: 1,
                    },
                    {
                        opacity: 0.25,
                    },
                ],
                // from top to bottom
                rotation: 90,
            }),
        });
        series.data.setAll(this.data);
        series.appear(1000); // <-- should this be part of our chart itself, or our chartinstance?
        this.series = series;
        // expose for underlying components
        context.set('series', series);
        context.set('seriesComponent', this);
    }
}
export class BubbleSeries extends ChartComponent {
    constructor(settings) {
        super();
        /** Series stack type */
        this.stackType = undefined;
        this.series = undefined;
        this.name = settings.name;
        this.data = settings.data;
        // we require at least a valueaxis and categoryaxis to be present
        this.addDependency(['valueaxis', 'categoryaxis']);
    }
    getTags() {
        return ['series', 'bubbleseries', 'xy-series', 'line-like-series'];
    }
    setup(context) {
        var _a;
        const allSeries = (_a = context.get('seriesComponents')) !== null && _a !== void 0 ? _a : context.setGlobal('seriesComponents', []);
        allSeries.push(this);
    }
    render(context) {
        const chart = context.get('chart');
        // bubble series plot their bubbles on an invisible line
        const series = chart.series.push(AMLineSeries.new(context.root, Object.assign(Object.assign({}, getXYSharedSettings(context, this)), { 
            // expose 'z' as 'value' in our heat rules 
            valueField: 'z', 
            // required for heat rules
            calculateAggregates: true, maskBullets: true })));
        ensureCursor(chart).lineX.set('visible', true);
        series.strokes.template.setAll({ visible: false });
        const circleTemplate = Template.new({});
        series.bullets.push(root => Bullet.new(root, {
            sprite: Circle.new(root, {
                fill: series.get('fill'),
                fillOpacity: 0.8,
            }, circleTemplate),
        }));
        const clamp = (value, min, max) => Math.min(max, Math.max(value, min));
        // scale based on X
        series.set('heatRules', [
            {
                target: circleTemplate,
                // should we make this dynamic -> widget size?
                min: 1,
                max: 60,
                dataField: 'value',
                key: 'radius',
                // simple logarithmic scale
                customFunction: (target, minValue, maxValue, value) => {
                    if (!Number.isFinite(value))
                        return;
                    // @ts-ignore
                    value = clamp(value, 1, value);
                    // @ts-ignore
                    target.set('radius', clamp(value / Math.log(value), 1, 60));
                }
            },
        ]);
        this.series = series;
        series.data.setAll(this.data);
        series.appear(1000); // <-- should this be part of our chart itself, or our chartinstance?
        // expose for underlying components
        context.set('series', series);
        context.set('seriesComponent', this);
    }
}
/** Create a basic AMLineSeries usable for both area and line charts */
function createAMLineSeries(context, cmp) {
    // should maybe merge line/area series components into one
    // for now, start off with two series utilizing a single function for creating the concrete series
    const chart = context.get('chart');
    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    const series = chart.series.push(determineAMLineComponent(cmp.lineStyle).new(context.root, getXYSharedSettings(context, cmp)));
    // small dots indicating where datapoints are located
    // ensure our bullets are first to render
    // noinspection PointlessBooleanExpressionJS
    series.bullets.unshift((root, series, dataItem) => {
        const dot = Circle.new(root, {
            radius: 2,
            fill: series.get('fill'),
            visible: dataItem.dataContext._hide !== true,
        });
        confineToGrid(chart, dot);
        return Bullet.new(root, {
            sprite: dot,
        });
    });
    // line charts always have a cursor visible to indicate the active datapoint
    // use an adapter to force our cursor to show; our toolbox may conditionally take over
    ensureCursor(chart).lineX.set('visible', true);
    // // hide items with a `_hide` attribute
    // series.strokes.adapters.add('forceHidden', (value, target) => target.dataItem?.dataContext?._hide ?? value);
    // stroke adapters etc. dont have direct access to dataitems, they can however be supplied with data via a template field
    // this field will be pulled from our datacontext and is considered optional
    series.strokes.template.set('templateField', '_strokeSettings');
    return series;
    function determineAMLineComponent(lineStyle) {
        if (lineStyle === 'smooth')
            return SmoothedXLineSeries;
        if (lineStyle === 'stepline')
            return StepLineSeries;
        return AMLineSeries;
    }
}
/** Generate an object containing a set of base/shared settings for all XYSeries */
function getXYSharedSettings(context, cmp) {
    const chart = context.get('chart');
    // simply paint ourselves on the first x/y axes...
    const xAxis = chart.xAxes.values[0];
    const yAxis = chart.yAxes.values[0];
    return Object.assign(Object.assign({ name: determineSeriesName(context, cmp), xAxis: xAxis, yAxis: yAxis, [categoryAxisSwitch(context, 'valueYField', 'valueXField')]: 'y', [categoryAxisSwitch(context, 'valueXField', 'valueYField')]: 'x', 
        // specifically for category axis
        [categoryAxisSwitch(context, 'categoryXField', 'categoryYField')]: 'x', 
        // enable stacking
        // May need to supplement missing datapoints https://www.amcharts.com/docs/v5/charts/xy-chart/series/#Stacked_series
        stacked: cmp.stackType !== undefined }, (cmp.stackType === '100%'
        ? categoryAxisSwitch(context, { valueYShow: 'valueYTotalPercent' }, { valueXShow: 'valueXTotalPercent' })
        : undefined)), { 
        // allow bullets to exceed plot area by default, 'masking' etc should be handled by implementor (not configurable on a per-bullet level)
        maskBullets: false });
}
/** Determine the name of the given series, taking either the name of the component or a default name */
function determineSeriesName(context, cmp) {
    if (cmp.name)
        return cmp.name;
    const allSeries = context.get('seriesComponents');
    const seriesCount = allSeries.length;
    return seriesCount >= 1 ? `Series ${allSeries.indexOf(cmp)}` : undefined;
}
/** Constrain the given sprite to the chart's grid, automatically hiding it when falling out of bounds */
function confineToGrid(chart, sprite) {
    // mask when out of bounds
    // see: https://www.amcharts.com/docs/v5/tutorials/handling-bullet-masking/
    sprite.on('x', x => {
        if (isOutOfBounds())
            sprite.hide(0);
        else
            sprite.show(0);
    });
    sprite.on('y', () => {
        if (isOutOfBounds())
            sprite.hide(0);
        else
            sprite.show(0);
    });
    function isOutOfBounds() {
        const x = sprite.x();
        const y = sprite.y();
        const gridWidth = chart.gridContainer.width();
        const gridHeight = chart.gridContainer.height();
        return (x < 0 ||
            y < 0 ||
            x > gridWidth ||
            y > gridHeight);
    }
}

import {translate} from './translationsProvider';
import _ from 'lodash';

/**
 * @typedef CascaderOption
 *
 * @property {string} value
 * @property {label} value
 * @property {CascaderOption[]} [children]
 * @property {boolean} [disabled]
 */

/**
 * @typedef TreeOption
 *
 * @property {string} key
 * @property {string} label
 * @property {TreeOption[]} [children]
 */

// Is the object empty (null,undefined,empty array, empty guid, empty string, empty object)
export function isEmpty(id) {
  if (undefined === id) return true;
  if (null === id) return true;
  if ('00000000-0000-0000-0000-000000000000' === id) return true;
  if (typeof id === 'string') return '' === id;
  if (Array.isArray(id)) return 0 === ~~id.length;
  if (id instanceof Map) return id.size === 0;
  if (id instanceof Set) return id.size === 0;
  if (typeof id === 'object') return ~~Object.keys(id)?.length === 0;
  return false;
}

// Create a slug from a string. slug('appeltaart',6) => 'ap..rt'
export function slug(value, len) {
  if (typeof value !== 'string' || value.length <= len || len < 4) return value;
  const lhs = Math.floor((len - 1) / 2);
  return `${value.substring(0, lhs)}..${value.substring(value.length - (len - 2 - lhs))}`;
}

/**
 * Try to convert an object to an array with value/label as xselect expects it to.
 *
 * @template TValue
 * @template TLabel
 * 
 * @param { Array<
 *  ({ value: TValue } | { id: TValue } | { name: TValue }) &
 *  ({ translation: string } | { translationKey: string } | { label: TLabel } | { displayName: TLabel } | { name: TLabel } | { title: TLabel } | { value: TLabel }) &
 *  { order?: number }
 * > } [arr]
 * @param { { enableSorting: boolean } | null } [options]
 *
 * @returns { { label: TLabel, value: TValue, order: number | undefined }[] }
 */
export function toOptions(arr, options = null) {
  if (!Array.isArray(arr) || ~~arr.length == 0) return [];

  if (arr[0].value && typeof arr[0].label === 'string' && arr[0].label === arr[0].label.toUpperCase()) return arr;

  arr = arr.map(obj => ({
    value: obj.value ?? obj.id ?? obj.name,
    label:
      translate(obj.translation ?? obj.translationKey) ??
      obj.label ??
      obj.displayName ??
      obj.name ??
      obj.title ??
      obj.value,
    order: Number.isFinite(obj.order) ? obj.order : undefined,
  }));

  if (!options || options.enableSorting) {
    arr = arr.sort((l, r) => {
      // Explicit ordering
      if (l.order !== undefined) return l.order < r.order ? -1 : l.order > r.order ? 1 : 0;

      //When our left label implements localeCompare, attempt to use that (eg. strings)
      if (l.label.localeCompare) return l.label.localeCompare(r.label, undefined, {sensitivity: 'base'});

      //Otherwise, fallback to a direct value based sorting strategy (eg. numeric labels)
      if (l.label > r.label) return 1;
      if (l.label < r.label) return -1;
      return 0;
    });
  }

  // noinspection JSValidateTypes
  return arr;
}

// Specific to the XTreeSelect to convert it to the right format and also do this for it's children

/** @returns {TreeOption[]} */
export function toTreeOptions(payload) {
  payload = _.cloneDeep(payload);

  const converter = value => {
    if (isEmpty(value)) {
      return [];
    }

    if (typeof value === 'object') {
      value = [value];
    }

    if (typeof value === 'string' || !Array.isArray(value) || value === undefined) {
      console.error('Treeoptions expects an array or an object');
      return;
    }
    if (Array.isArray(value[0])) {
      value = value[0];
    }

    return value.map(item => {
      if (Object.hasOwn(item, 'children') && item.children.length > 0) {
        item.children = converter(item.children);
      } else {
        delete item.children;
      }

      const converted = {
        ...item,
        key: item.key ?? item.id ?? item.value,
        label: item.name ?? item.label ?? item.id,
      };

      return converted;
    });
  };

  return converter(payload);
}

// type CascaderOption = {value: string; label: string; children?: CascaderOption[]; disabled?: boolean};

/** @returns {CascaderOption[]} */
export function toCascaderOptions(payload) {
  payload = _.cloneDeep(payload);

  const converter = value => {
    if (isEmpty(value)) {
      return [];
    }

    if (typeof value === 'object') {
      value = [value];
    }

    if (typeof value === 'string' || !Array.isArray(value) || value === undefined) {
      console.error('Cascaderoptions expects an array or an object');
      return;
    }
    if (Array.isArray(value[0])) {
      value = value[0];
    }

    return value.map(item => {
      if (Object.hasOwn(item, 'children') && item.children.length > 0) {
        item.children = converter(item.children);
      } else {
        delete item.children;
      }

      const converted = {
        ...item,
        value: item.key ?? item.id ?? item.value,
        label: item.name ?? item.label ?? item.id,
      };

      return converted;
    });
  };

  return converter(payload);
}

export const wait = ms => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

export function calculateTextDimensions(text, opts = {}) {
  //https://erikonarheim.com/posts/canvas-text-metrics/

  const {font, rotation} = opts ?? {};

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  if (font) ctx.font = font;
  ctx.rotate(rotation);
  const textMetrics = ctx.measureText(text);

  // actualBoundingBox properties take overflow into account
  // https://stackoverflow.com/a/66846914
  let width = Math.abs(textMetrics.actualBoundingBoxLeft) + Math.abs(textMetrics.actualBoundingBoxRight);
  let height = Math.abs(textMetrics.actualBoundingBoxAscent) + Math.abs(textMetrics.actualBoundingBoxDescent);
  if (rotation) {
    // inaccurate - should calculate rotated bounding box -> take width from there
    // assume we're working with angles below 90 degrees for ease...
    const rotationPct = rotation / 180;
    const originalWidth = width;
    const originalHeight = height;

    height += originalWidth * rotationPct;
    width -= originalHeight * rotationPct;
  }

  return {width, height};
}

// Reverse key<->value so object becomes lookup
export const asLookup = obj => Object.entries(obj).reduce((acc, [key, value]) => ((acc[value] = key), acc), {});

// Clean a value for use as a key (lowercase, remove space & dashes)
export const cleanKey = value => value?.toLowerCase().replace(/ -/gi, '');

// Allow maps to be stringified/parsed (JSON.stringify(data, mapReplacer))
export function mapReplacer(key, value) {
  if (value instanceof Map) {
    return {
      dataType: 'Map',
      value: Array.from(value.entries()),
    };
  } else {
    return value;
  }
}

// Allow maps to be stringified/parsed (JSON.parse(data, mapReviver))
export function mapReviver(key, value) {
  if (typeof value === 'object' && value !== null) {
    if (value.dataType === 'Map') {
      return new Map(value.value);
    }
  }
  return value;
}

// Toggle or set stylesheet
export function enableStyleSheet(name, setting) {
  const sheets = [...document.styleSheets];
  const sheet = sheets.find((_, css) => css.title === name);
  if (sheet) {
    sheet.disabled = setting === undefined ? !sheet.disabled : setting;
  }
}

/** Hydrate a field by parsing the json string and assigning it to the same field. */
export function hydrate(obj, field = 'config') {
  if (isEmpty(obj)) return obj;
  if (isEmpty(obj[field])) return obj;

  if (typeof obj[field] === 'string') {
    try {
      const config = JSON.parse(obj[field]);
      obj[field] = config;
    } catch (e) {
      console.error('Invalid config in brick definitions. Config ignored');
    }
  }
  return obj;
}

/** Pick a selection of fields from an object Ex: for(f in pickFrom({a,b,c:2}, 'a', 'c') console.log(f)) prints a,2*/
export function* pickFrom(obj, ...fields) {
  if (isEmpty(obj) || isEmpty(fields)) {
    return;
  }

  for (const field of fields) {
    yield obj[field];
  }
}

/**
 * Return an array containing all items contained in both the left and right array
 *
 *  @template T
 *  @param left {Array<T>}
 *  @param right {Array<T>}
 *  @return {Array<T>}
 */
export function intersection(left, right) {
  return left.filter(item => right.includes(item));
}

/**
 * Return whether or not at least one item exists in both the left and the right array
 *
 *  @template T
 *  @param left {Array<T>}
 *  @param right {Array<T>}
 *  @return {Array<T>}
 */
export function intersects(left, right) {
  return left.some(item => right.includes(item));
}

/**
 * Return an array containing all items only present in the left array
 *
 *  @template T
 *  @param left {Array<T>}
 *  @param right {Array<T>}
 *  @return {Array<T>}
 */
export function except(left, right) {
  return left.filter(item => !right.includes(item));
}

/**
 * Return an array containing all items only present in only the left or right array
 *
 *  @template T
 *  @param left {Array<T>}
 *  @param right {Array<T>}
 *  @return {Array<T>}
 */
export function difference(left, right) {
  const unique = [...left];

  for (const value of right) {
    const idx = unique.indexOf(value);

    if (idx !== -1) unique.splice(idx, 1);
    else unique.push(value);
  }

  return unique;
}

/** Sanitze the given text, escaping HTML symbols etc
 *
 * @param {string} text Unsanitized text
 * @returns {string} Sanitized text
 */
export function sanitizeText(text) {
  if (!text) return '';

  const target = document.createElement('span');
  target.innerText = text;
  return target.innerHTML;
}

/** Capitalize the first letter of the input text */
export function capitalize(text) {
  if (!text || typeof text !== 'string') return text;
  return text[0].toUpperCase() + text.substring(1);
}

/**
 * Case insensitive text replacement
 *
 * @param text {string}
 * @param predicate {string}
 * @param replacement {string | function(match: string): string}
 *
 * @returns {string}
 */
export function replaceAll(text, predicate, replacement = '') {
  // normalize by converting to lowercase, we'll be using the normalized variants for determining our indice
  const normalizedText = text.toLowerCase();
  const normalizedPredicate = predicate.toLowerCase();

  replacement = typeof replacement === 'string' ? () => replacement : replacement;

  let pos = normalizedText.indexOf(normalizedPredicate);

  // no match
  if (pos === -1) return text;

  let output = '';

  // text does not start with our predicate
  if (pos !== 0) output += text.substring(0, pos);

  while (pos !== undefined) {
    const end = pos + predicate.length;
    const next = normalizedText.indexOf(normalizedPredicate, pos + 1);
    output += replacement(text.substring(pos, end));
    pos = next;

    // no more matches, consume until the end
    if (pos === -1) pos = undefined;

    // sibling character also marks the start of a predicate match
    if (end !== pos) output += text.substring(end, pos);
  }

  return output;
}

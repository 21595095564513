import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["href", "target"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    var _a;
    return (_openBlock(), _createElementBlock("a", {
        href: $setup.href,
        target: (_a = $setup.route.meta) === null || _a === void 0 ? void 0 : _a.target,
        class: _normalizeClass({
            ['router-link-active']: $setup.isActive,
            ['router-link-exact-active']: $setup.isExactActive,
        }),
        onClick: _cache[0] || (_cache[0] =
            //@ts-ignore
            (...args) => ($setup.navigate && $setup.navigate(...args)))
    }, [
        _renderSlot(_ctx.$slots, "default")
    ], 10, _hoisted_1));
}

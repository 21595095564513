import { computed } from 'vue';
import { useRouter } from 'vue-router';
const useApp = () => {
    const router = useRouter();
    const app = computed(() => router.currentRoute.value.meta.app);
    //TODO: implementation
    // const name = computed(() => T(app.value.replace('app', 'menu').toUpperCase().replaceAll('-', '_')));
    return { app, name };
};
export default useApp;

import { isEmpty } from './util';
import { z } from 'zod';
import i18next from 'i18next';
import dutchTranslation from 'zod-i18n-map/locales/nl/zod.json';
import englishTranslation from 'zod-i18n-map/locales/en/zod.json';
import { makeZodI18nMap } from 'zod-i18n-map';
let _translateInput = (...args) => {
    return args || args[0];
};
i18next.init({
    lng: 'nl',
    resources: {
        nl: { zod: dutchTranslation },
        en: { zod: englishTranslation },
    },
});
z.setErrorMap(makeZodI18nMap({ ns: 'zod', t: i18next.t }));
document.addEventListener('localeChange', event => {
    i18next.changeLanguage(event.detail.newLocale);
});
export function translate(...args) {
    if (isEmpty((args && args[0]) || [])) {
        return undefined;
    }
    else {
        try {
            return _translateInput(...args);
        }
        catch (e) { }
    }
}
export function setTranslationProvider(translateInput) {
    if (translateInput)
        _translateInput = translateInput;
}
// preferred alias
export { translate as T };

<template>
  <th>
    <div class="header-container">
      <XParagraph componentStyle="medium" color="var(--text-primary-500)" v-if="$resolveSlot($slots['headerItems'])">
        <slot name="headerItems" />
      </XParagraph>
      <slot name="headerIcons" />
      <div v-if="sort" class="arrow">
        <!-- :class="{ arrowup: index }" -->
        <XIcon name="chevron-down" size="small" color="var(--icon-primary, #868686)" />
      </div>
      <XParagraph componentStyle="medium" color="var(--text-primary-500)">
        <slot name="headerActions" />
      </XParagraph>
    </div>
  </th>
</template>

<script>
import resolveSlots from '@src/helpers/vue-slots';
export default {
  name: 'XTableHeader',
  props: {
    sort: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.$resolveSlot = resolveSlots;
  },
};
</script>

<style lang="scss" scoped>
.header-container {
  display: flex;
  justify-content: center;

  .paragraph {
    margin-bottom: 1rem;
  }
}

th {
  margin: 0;
  text-align: left;
  padding: 0.3rem 1.3rem 0.1rem 1.3rem;

  &:first-child {
    padding-left: 1rem;
  }
}

th > div {
  display: flex;
  justify-content: space-between;
  width: max-content;
}

:deep(.arrow) {
  height: 1.1rem;
  margin-left: 0.7rem;
  cursor: pointer;
  transition: 0.3s all;
}
</style>

<docs>
  ```jsx
    <XTableHeader>
      <template #headerItems>ID</template>
    </XTableHeader>
    <XTableHeader>
      <template #headerItems>First name</template>
      <template #headerIcons>*</template>
    </XTableHeader>
    <XTableHeader>
      <template #headerItems>Last name</template>
      <template #headerIcons>*</template>
    </XTableHeader>
    <XTableHeader>
      <template #headerActions>Actions</template>
    </XTableHeader>
  ```
</docs>

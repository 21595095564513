<template>
  <div class="chart" :key="chartOptions.xaxis.type">
    <apexchart ref="chart" type="line" :height="height" :width="width" :options="chartOptions" :series="dataSeries" />
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import {LineChartMixin} from './mixins';
import {autoFitToGrid, normalizeApexSeries} from './util';
import {ToolbarProxy} from './toolbarProxy';
import parseAnnotations from './parseGraphAnnotations.js';

// Synchronized charts require an id for the chart
let _chartcounter = 0;

/**
 * lineType has 3 types to choose from: 'smooth', 'straight' and 'stepline'
 */
export default {
  name: 'XApexLineChart',
  components: {
    apexchart: VueApexCharts,
  },
  mixins: [LineChartMixin],
  data() {
    return {
      chartOptions: this.generateChartOptions(),
    };
  },
  computed: {
    updateChart() {
      const {series, xaxisType, dataLabels, stacked, zoom, lineType, forecastDataPoints, theme, group} = this;

      return {
        series,
        xaxisType,
        dataLabels,
        stacked,
        zoom,
        lineType,
        forecastDataPoints,
        theme,
        group,
      };
    },
    dataSeries() {
      return normalizeApexSeries(this.series);
    },
  },
  watch: {
    updateChart() {
      this.chartOptions = {...this.generateChartOptions()};
    },
    annotations: {
      handler() {
        this.chartOptions = {...this.generateChartOptions()};
      },
      deep: true,
    },
    dataUpdate(newVal, oldVal) {
      if (newVal?.update || JSON.stringify(oldVal) !== JSON.stringify(newVal)) {
        this.updateData(normalizeApexSeries(newVal));
      }
    },
  },
  methods: {
    updateData(data) {
      //DEV: Paths get morphed, so dont animate.
      //  The slide effect in the apex realtime demo is the result of a svgjs bug,
      //  When xaxis is fixed & larger than the series (using xaxis.range) svgjs will slide in stead of morph the data.
      //  When the series stops growing, or draws outside the graph boundary the data will start morphing.
      //  So keep adding datapoints AND set series head (data[0].x=data[1].x) to prevent drawing will keep svgjs in slide mode.
      //
      //  Ensuring this in the data is too much work, so we do not animate.
      if (data.append) this.$refs.chart.chart?.appendSeries(data.series, false);
      else this.$refs.chart.chart?.updateSeries(data.series, false);
    },
    showDecimal(val) {
      if (val % 1 != 0) {
        return val.toFixed(1);
      }
      return val;
    },
    generateChartOptions() {
      return autoFitToGrid({
        chart: {
          type: 'line',
          stacked: this.stacked,
          group: this.group ?? undefined,
          id: `line_${++_chartcounter}`,
          animations: {
            enabled: true,
            easing: 'linear',
            dynamicAnimation: {
              enabled: true,
              speed: 250,
            },
          },
          toolbar: {
            show: true,
            tools: {
              customIcons: [
                {
                  icon: '',
                  // The 5 and 1 are the indexes of the icons in the toolbar depending if its in zoom state or horizontal, otherwise there will be an empty index and apex will break.
                  index: this.zoom && !this.horizontal ? 5 : 1,
                  title: 'Export Excel',
                  class: 'custom-icon exportexcel',
                  click: () => {
                    this.$emit('export');
                  },
                },
              ],
            },
            export: {
              csv: {filename: this.name},
              svg: {filename: this.name},
              png: {filename: this.name},
            },
          },
          zoom: {
            enabled: this.zoom,
          },
          events: {
            mounted: ctx => this[ToolbarProxy.InjectionKey]?.update(ctx.el),
            updated: ctx => this[ToolbarProxy.InjectionKey]?.update(ctx.el),
          },
        },
        annotations: parseAnnotations(this.annotations),
        forecastDataPoints: {
          count: this.forecastDataPoints,
        },
        colors: this.theme,
        dataLabels: {
          enabled: this.dataLabels,
          hideOverflowingLabels: true,
          formatter: val => this.$format(val, 'widgetdatalabel'),
        },
        stroke: {
          curve: this.lineType,
        },
        xaxis: {
          type: this.xaxisType,
          tickAmount: 'dataPoints',
          tickPlacement: 'on',
        },
        yaxis: {
          max: this.stackType === '100%' ? 100 : undefined,
          axisBorder: {
            show: true,
          },
          labels: {
            formatter: val => this.$format(val, 'widgetdatalabel'),
          },
        },
        fill: {
          opacity: 0.9,
        },
        noData: {
          text: this.$t('WIDGET_NO_DATA'),
          style: {
            color: 'var(--text-primary, #313741)',
          },
        },
        legend: {
          //By default Apex shows a legend when the chart has at least two series with at least one containing data
          //The legend may break in certain circumstances when collapsing a series (https://github.com/apexcharts/apexcharts.js/issues/3156)
          //As a workaround, we'll manually force our legend to be displayed when matching Apex' default conditions
          showForSingleSeries: this.series.length > 1 && this.series.some(series => series.data.length),
        },
      });
    },
  },
};
</script>

<docs>
  ```jsx
  
  //lineType has 3 types to choose from: 'smooth', 'straight' and 'stepline'
    <XApexLineChart
       height="360"
      width="400"
      :series="[
        {
          name: 'Bar 1',
          data: [
            {
              x: 'South Korea',
              y: 400
            },
            {
              x: 'Canada',
              y: 430  
            },
            {
              x: 'United Kingdom',
              y: 448 
            },
            {
              x: 'Netherlands',
              y: 470
            },
            {
              x: 'Italy',
              y: 540
            },
            {
              x: 'France',
              y: 580
            },
            {
              x: 'Japan',
              y: 690
            },
            {
              x: 'United States',
              y: 1100
            },
            {
              x: 'China',
              y: 1200
            },
            {
              x: 'Germany',
              y: 1380
            }
          ]
        }
      ]"
      xaxisType="category"
      :dataLabels="false"
      :stacked="false"
      :zoom="true"
      lineType="straight"
      :annotations="[
        {
          x: 20,
          x2: 300,
          text: 'First',
          color: '#ff00ff',
        }
      ]"
      :forecastDataPoints="2"
      :theme="['#2EB2FF', '#10CB73', '#F2DF32', '#D32D85', '#0DD2D2', '#E04E2A', '7731BD', '#1FBB97']"
      name="Snake"
    />
    ```
</docs>

//Submodules can push settingroutes, which will get consumed in appfullgit push --set-upstream origin feature/MAN-522-billing
/**
 * Collection of menu group root route names, when visiting a route lacking its own menu item but belonging to a group, the group root will be marked as active in the menu
 */
const groupRootNames = Object.freeze({
    dashboard: 'dashboards-overview',
    configuration: 'user-configuration',
});
const authenticationState = {
    onlyUnauthenticated: 'only accessible when not authenticated',
    onlyAuthenticated: 'only accessible when authenticated',
    any: '🤷‍♂️',
};
const routes = [];
export default routes;

<template>
  <component
    :is="type"
    :icon="icon"
    :class="['menu-item', itemClass, {'item-active': active}, {disabled: disabled}]"
    :disabled="!!disabled || undefined"
    @click="handleClick(clickTarget)"
  >
    <component :is="contentWrapper.cmp" v-bind="contentWrapper.props" class="content-wrapper">
      <XIcon
        v-if="icon"
        :name="icon"
        size="small"
        :color="!!disabled || undefined ? 'var(--icon-disabled, #7F8389)' : iconColor"
      />
      <span v-if="resolveSlot($slots.default)">
        <slot />
      </span>
    </component>
  </component>
</template>

<script>
import resolveSlots from '../../helpers/vue-slots';
import {computed} from 'vue';
import {RouterLink} from 'vue-router';

export default {
  /**
   * Can be used in dropdown menu and navigation menu
   */
  name: 'XMenuItem',
  emits: ['input'],
  props: {
    /**
     * Default a
     */
    type: {
      type: String,
      default: 'div',
    },
    /**
     * optional extra class for menu item
     */
    itemClass: {
      type: String,
      default: '',
    },
    /**
     * Use this option to give a href.
     */
    href: {
      type: [String, Object],
      default: '',
    },
    /**
     * Use this option to specify the icon, when left blank no icon will be used
     */
    icon: {
      type: String,
      default: '',
    },
    iconColor: {
      type: String,
      default: 'var(--icon-primary)',
    },
    /**
     * Whether the dropdown item is disabled or not.
     * `true, false`
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * If you need
     */
    clickTarget: {
      type: [String, Number],
      default: '',
    },
    active: {
      type: Boolean,
      default: null,
    },
  },

  setup(props, {emit}) {
    const contentWrapper = computed(() => {
      if (!props.href) return {cmp: 'div', props: {}};

      return {
        cmp: RouterLink,
        props: {
          to: props.href,
          disabled: !!props.disabled,
        },
      };
    });

    return {
      contentWrapper: contentWrapper,
      handleClick: event => !props.disabled && emit('input', event),
      resolveSlot: resolveSlots,
    };
  },
};
</script>

<style lang="scss">
@use '../../assets/styles/variables.scss' as *;
.menu-item {
  color: var(--text-primary, #313741);
  text-decoration: none;
  max-height: 3.75rem;
  box-sizing: border-box;
  outline: 3px solid transparent;
  font-family: $font-text;
  font-weight: 400;
  font-size: var(--font-lg);
  line-height: initial;
  color: var(--text-primary, #313741);
  display: flex;
  align-items: center;
  padding: 0 var(--spacing-lg);
  height: 2.5rem;
  flex: none;
  white-space: nowrap;
  overflow: hidden;

  &:hover {
    cursor: pointer;
  }

  &.disabled {
    cursor: not-allowed;
    background-color: var(--control-disabledBackground);
    color: var(--control-disabledColor);
  }

  .icon {
    box-sizing: border-box;
    padding-right: 0.5rem;
  }

  .content-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    color: var(--text-primary, #313741);
    text-decoration: none;

    &[disabled] {
      color: var(--text-disabled, #7f8389) !important;
      fill: var(--text-disabled, #7f8389) !important;
    }
  }

  &.active {
    box-shadow: inset 0px -3px 0px var(--control-selectedBorder);

    .paragraph {
      color: var(--control-focusColor);
      font-weight: 600;
    }

    .content-wrapper {
      color: var(--control-focusColor);
      font-weight: 600;
      border-bottom-color: var(--control-selectedBorder);
    }
  }

  &.disabled {
    a {
      cursor: not-allowed;
    }
  }

  .content-wrapper {
    font-family: $font-text;
    font-weight: 400;
    font-size: var(--font-lg);
    line-height: 1rem;
    text-decoration: none;
    justify-content: center;

    span:not(.icon) {
      display: flex;
      flex-direction: column;

      .description {
        font-size: var(--font-xs);
        line-height: 0.75rem;
        font-weight: 400;
        color: var(--text-primary-400, #a5a5a5);
      }
    }
  }
}

.item-active {
  font-weight: $weight-bold;
  background-color: var(--container-background, #dde9f2);
  border-radius: var(--radius-md);
}
</style>

<docs>
```jsx
<XMenuItem icon="journey" iconColor="var(--icon-primary)" href="/journey">Item value</XMenuItem>
<br/>
<br/>
<XMenuItem icon="edit" disabled>Item value</XMenuItem>
```
</docs>

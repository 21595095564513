﻿<template>
  <Component :is="rendererComponent" v-bind="$props" />
</template>

<script>
import {GaugeChartMixin} from './mixins';

import XEchartsGaugeChart from './XEchartsGaugeChart.vue';
import XAmGaugeChart from './AmCharts/XAmGaugeChart.vue';

const rendererLookup = {
  echarts: XEchartsGaugeChart,
  amcharts: XAmGaugeChart,

  get: type => rendererLookup[type] ?? rendererLookup['echarts'],
};

export default {
  name: 'XGaugeChart',
  mixins: [GaugeChartMixin],

  props: {
    renderer: {
      type: String,
      required: false,
      default: 'amcharts',
    },
  },

  computed: {
    rendererComponent() {
      return rendererLookup.get(this.renderer);
    },
  },
};
</script>


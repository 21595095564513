<template>
  <component :is="type">
    <slot name="tableTools" />
    <XBox class="table-container" :boxLine="false">
      <table>
        <thead>
          <tr>
            <slot name="tableHeader" />
          </tr>
        </thead>
        <tbody>
          <slot name="tableRow" />
        </tbody>
      </table>
    </XBox>
    <XBox v-if="pagination">
      <slot name="tableFooter" />
    </XBox>
  </component>
</template>

<script>
export default {
  name: 'XTableSection',
  props: {
    type: {
      type: String,
      default: 'div',
    },
    pagination: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.table-container {
  padding: 1rem 0 0 0;

  :deep(> .slot-content) {
    padding: 0;
    overflow-x: auto;
  }
}

table {
  border-spacing: 0;
  width: 100%;
}

.header-slot {
  display: flex;
  justify-content: space-between;
}
</style>

<docs>
  ```jsx
    <XTableSection>
      <template #tableTools>
        <XTableTools>
          <template #tableName>
            Table naam
          </template>
          <template #tableSearch>
            <XInput label="Search box" placeholder="Search in table" id="input-1" />
          </template>
          <template #tableAddRow>
            <XButton size="medium">+ Add row</XButton>
          </template>
        </XTableTools>
      </template>
      <template #tableHeader>
        <XTableHeader>
          <template #headerItems>#</template>
        </XTableHeader>
        <XTableHeader :sort="true">
          <template #headerItems> head 1</template>
        </XTableHeader>
        <XTableHeader :sort="true">
          <template #headerItems> head 2</template>
        </XTableHeader>
        <XTableHeader :sort="true">
          <template #headerItems> head 3</template>
        </XTableHeader>
        <XTableHeader :sort="true">
          <template #headerItems> head 4</template>
        </XTableHeader>
        <XTableHeader :sort="true">
          <template #headerItems> head 5</template>
        </XTableHeader>
        <XTableHeader>
          <template #headerActions>Actions</template>
        </XTableHeader>
      </template>
      <template #tableRow>
        <XTableRow>
          <XTableData>
            <template #rowItems>1</template>
          </XTableData>
          <XTableData>
            <template #rowItems>Row 1 data 2</template>
          </XTableData>
          <XTableData>
            <template #rowItems>Row 1 data 3</template>
          </XTableData>
          <XTableData>
            <template #rowItems>Row 1 data 4</template>
          </XTableData>
          <XTableData>
            <template #rowItems>Row 1 data 5</template>
          </XTableData>
          <XTableData>
            <template #rowItems>Row 1 data 6</template>
          </XTableData>
          <XTableData>
            <template #rowActions>
              <XIcon name="edit" size="small" />
              <XIcon name="delete" size="small" />
            </template>
          </XTableData>
        </XTableRow>
        <XTableRow>
          <XTableData>
            <template #rowItems>2</template>
          </XTableData>
          <XTableData>
            <template #rowItems>Row 2 data 2</template>
          </XTableData>
          <XTableData>
            <template #rowItems>Row 2 data 3</template>
          </XTableData>
          <XTableData>
            <template #rowItems>Row 2 data 4</template>
          </XTableData>
          <XTableData>
            <template #rowItems>Row 2 data 5</template>
          </XTableData>
          <XTableData>
            <template #rowItems>Row 2 data 6</template>
          </XTableData>
          <XTableData>
            <template #rowActions>
              <XIcon name="edit" size="small" />
              <XIcon name="delete" size="small" />
            </template>
          </XTableData>
        </XTableRow>
      </template>
      <template #tableFooter>
        <XTableFooter>
          <template #previousButton>
            <XButton component-style="secondary-action" size="small">Previous</XButton>
          </template>
          <template #currentButton>
            <XButton size="small">1</XButton>
            <XButton size="small">2</XButton>
            <XButton size="small">3</XButton>
          </template>
          <template #nextButton>
            <XButton component-style="secondary-action" size="small">Next</XButton>
          </template>
        </XTableFooter>
      </template>
    </XTableSection>
  ```
</docs>

<!--
  De arrow heeft een @click="setArrow(col.value)" de XTableHeader moet dan een value mee sturen naar de 
  XTableHeader component zodat de class arrowup word gezet voor de animatie voorbeeld: :class="{ arrowup: arrow === col.value }"

-->

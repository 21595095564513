/**
 * Wrap the given component providing webpack context into a generic format useable by both Vue2/Vue3
 *
 * @param {webpackContext} context
 * @return {function(...arguments): Component}
 */
function toComponentProviderContext(context) {
  const providerContext = function () {
    return context(...arguments).default;
  };

  Object.keys(context).forEach(key =>
    Object.defineProperty(providerContext, key, Object.getOwnPropertyDescriptor(context, key))
  );

  return providerContext;
}

const assetSvgContext = toComponentProviderContext(
  //require.context('@src/assets/icons/', true, /^\.\/.*\.svg$/)
  require.context('../assets/icons/', true, /^\.\/.*\.svg$/)
);

const designSystemContext = toComponentProviderContext(
  //require.context('@src', true, /.vue$/)
  require.context('../', true, /.vue$/)
);

export {assetSvgContext, designSystemContext};

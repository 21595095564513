import {T} from '../helpers/translationsProvider';

export const calculateRemainingTime = (startTime, taskProgress) => {
  const currentTime = new Date().getTime();
  const timeDifference = currentTime - new Date(startTime).getTime();

  if (taskProgress) {
    const averageTimePerPercentage = timeDifference / taskProgress;
    const remainingTime = (100 - taskProgress) * averageTimePerPercentage;

    const formatTime = milliseconds => {
      const seconds = Math.floor(milliseconds / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);

      const formattedParts = [];

      if (hours > 0) {
        formattedParts.push(`${hours} ${T('HOURS')}`);
      }

      if (minutes % 60 > 0) {
        formattedParts.push(`${minutes % 60} ${T('MINUTES')}`);
      }

      if (seconds % 60 > 0) {
        formattedParts.push(`${seconds % 60} ${T('SECONDS')}`);
      }

      return formattedParts.join(' ') || `0 ${T('SECONDS')}`;
    };

    return formatTime(remainingTime);
  }

  return `0 ${T('SECONDS')}`;
};

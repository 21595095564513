<template>
  <component :is="type" :class="['text-style', componentStyle]">
    <slot />
  </component>
</template>

<script>
/**
 * Text style enhances text with additional visual meaning. For example, using
 * disabled text to de-emphasize it from its surrounding text. Don’t use text
 * styles only for aesthetic effect.
 */
export default {
  name: 'XTextStyle',
  props: {
    /**
     * The html element used for the text style.
     * `span, em, i, strong`
     */
    type: {
      type: String,
      default: 'span',
      validator: value => {
        return value.match(/(span|em|i|strong)/);
      },
    },
    /**
     * Style style to give additional meaning.
     * `default, disabled, strong, positive, negative`
     */
    componentStyle: {
      type: String,
      default: 'default',
      validator: value => {
        return value.match(/(default|disabled|strong|positive|negative)/);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@use '../../assets/styles/mixins.scss' as *;
@use '../../assets/styles/variables.scss' as *;
// Design Tokens with local scope
$positive-text: #7cb518;

.text-style {
  @include reset;
  color: var(--text-primary, #313741);
  font-family: $font-text;
  font-weight: $weight-normal;
  font-size: var(--font-md);
  line-height: $line-height-m;
  &.disabled {
    color: var(--text-disabled, #7f8389);
    text-decoration: line-through;
  }
  &.strong {
    font-weight: $weight-semi-bold;
  }
  &.positive {
    color: shade($positive-text, 20%);
  }
  &.negative {
    color: var(--main-error, #d21460);
  }
}
</style>

<docs>
  ```jsx
  <div>
    <XTextStyle component-style="default">Design isn’t just about the look and feel.</XTextStyle>
    <br />
    <XTextStyle component-style="disabled">Design isn’t just about</XTextStyle>
    <br />
    <XTextStyle component-style="strong">Design isn’t</XTextStyle>
  </div>
  ```
</docs>

import { except } from '../../../../helpers/util';
import { XYCursor } from '@amcharts/amcharts5/xy';
/** Find the index of the series the given dataitem belongs to */
export function seriesIndex(dataItem) {
    // todo: check if we're dealing with a series chart, item.classNames.contains(SeriesChart.className)
    const series = dataItem.component;
    const chart = series.chart;
    return chart.series.indexOf(series);
}
/** Find the index of the given dataitem */
export function itemIndex(dataItem) {
    return dataItem.component.dataItems.indexOf(dataItem);
}
/** Locate the component associated with the given series */
export function getSeriesComponent(context, series) {
    return context.renderedComponents.find((x) => {
        if (!('series' in x))
            return false;
        return x.series === series;
    });
}
export function categoryAxisSwitch(context, whenX, whenY) {
    return context.get('categoryaxistype') === 'x' ? whenX : whenY;
}
export function collectUniqueCategories(allSeries) {
    var _a;
    if (!allSeries)
        return [];
    allSeries = [allSeries].flat();
    const data = new Set();
    if (!allSeries || allSeries.length === 0)
        return [];
    for (const seriesOrData of allSeries) {
        for (const datapoint of (_a = seriesOrData === null || seriesOrData === void 0 ? void 0 : seriesOrData.data) !== null && _a !== void 0 ? _a : seriesOrData) {
            data.add(datapoint.x);
        }
    }
    return [...data];
}
/** Ensure there's a cursor present for the given xychart, will automatically create an invisible cursor when no cursor was found */
export function ensureCursor(chart) {
    let cursor = chart.get('cursor');
    if (cursor)
        return cursor;
    cursor = XYCursor.new(chart.root, {});
    chart.set('cursor', cursor);
    // make invisible by default
    cursor.lineX.set('visible', false);
    cursor.lineY.set('visible', false);
    return cursor;
}
/**
 * Ensure each encountered category has a value associated with it
 *
 * @see https://github.com/amcharts/amcharts4/issues/3804
 */
export function ensureCategorySeriesDataCorrectness(allSeries) {
    // nothing to fix when dealing with a single series
    if (allSeries.length <= 1)
        return allSeries;
    // copying our series should be cheap, copying our data... slightly less, only do when necessary
    const newSeries = allSeries.map(s => (Object.assign(Object.assign({}, s), { data: s.data })));
    const allCategories = collectUniqueCategories(allSeries);
    // step 1: fix our data
    // assume each category occurs a maximum of 1 time/series
    for (let idx = 0; idx < newSeries.length; idx++) {
        const series = newSeries[idx];
        if (series.data.length === allCategories.length)
            continue;
        series.data = [...series.data];
        const missingCategories = except(allCategories, collectUniqueCategories(series));
        // simply insert our datapoint, sorting will have to take place anyway
        for (const category of missingCategories) {
            series.data.push({
                // don't actually render
                _hide: true,
                x: category,
                y: 0, // <-- assume value = numeric
            });
        }
    }
    // step 2: sort our data
    // if our data is not properly sorted, amcharts will still render gaps in our stacks
    for (const series of newSeries) {
        series.data = [...series.data].sort((left, right) => allCategories.indexOf(left.x) > allCategories.indexOf(right.x) ? 1 : -1);
    }
    return newSeries;
}

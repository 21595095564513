<template>
  <component
    :is="type"
    class="main-box"
    :class="[
      initialized ? 'initialized' : null,
      blueBorder ? 'blue-border' : {'border-bottom': borderBottom},
      boxDropdown ? (toggle ? 'box-dropdown-open' : 'box-dropdown-closed') : null,
    ]"
  >
    <transition name="delay-before-v-if-removed">
      <div v-if="fixedBorder && toggle" :class="[blueBorder ? 'fixed-border-blue' : 'fixed-border']" />
    </transition>
    <div class="box-body" :style="cursorType" @click.self="hasContent ? (toggle = !toggle) : null">
      <div class="header-icon">
        <slot name="headerIcons" />
        <XIcon
          v-if="!toggle"
          :class="{disabled: !hasContent}"
          name="chevron-down"
          :hoverEffect="hasContent"
          style="margin-left: 0.5rem"
          @click="hasContent ? setToggle(true) : null"
        />
        <XIcon
          v-if="toggle"
          name="chevron-up"
          :hoverEffect="true"
          style="margin-left: 0.5rem"
          @click="setToggle(false)"
        />
      </div>

      <div>
        <div class="main-title" @click="hasContent ? (toggle = !toggle) : null" :style="cursorType">
          <XIcon
            v-if="mainIcon"
            :name="mainIcon"
            :color="mainIconColor"
            style="margin-top: -0.25rem; padding-left: 1rem"
          />
          <XHeading v-if="mainTitle" level="h4" :style="{'padding-left': mainIcon ? null : '1rem'}">
            {{ mainTitle }}
          </XHeading>
        </div>
      </div>
    </div>
    <div class="slot-content">
      <slot />
    </div>
  </component>
</template>

<script>
import dropdownMixin from '../Mixins/dropdown-mixin';

export default {
  name: 'XDropdownBox',
  mixins: [dropdownMixin],
  props: {
    type: {
      type: String,
      default: 'div',
    },
    fixedBorder: {
      type: Boolean,
      default: false,
    },
    borderBottom: {
      type: Boolean,
      default: false,
    },
    boxDropdown: {
      type: Boolean,
      default: true,
    },
    mainTitle: {
      type: String,
      default: '',
    },
    mainIcon: {
      type: String,
      default: '',
    },
    mainIconColor: {
      type: String,
      default: '',
    },
    iconLeft: {
      type: String,
      default: '',
    },
    iconCenter: {
      type: String,
      default: '',
    },
    iconRight: {
      type: String,
      default: '',
    },
    blueBorder: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@use '../../assets/styles/variables.scss' as *;
.main-box {
  position: relative;
  background: var(--main-background, #ffffff);
  width: 100%;
  box-sizing: border-box;
  word-break: break-all;

  &.blue-border {
    border-bottom: 1px solid var(--main-primary, #2783c6);
    border-top: 1px solid var(--main-primary, #2783c6);
  }

  &.border-bottom {
    border-bottom: 1px solid var(--container-border, #e8e8e8);
  }

  .delay-before-v-if-removed-leave-active {
    transition: opacity 0.3s;
    transition-delay: 300ms;
    transition-duration: 0;
  }

  .fixed-border,
  .fixed-border-blue {
    height: 1px;
    position: absolute;
    border-right: 0;
    border-left: 0;
    width: 100%;
    margin-top: 3.55rem;
  }

  .fixed-border {
    background-color: var(--container-border, #e8e8e8);
  }

  .fixed-border-blue {
    background-color: var(--main-primary, #2783c6);
    transition: opacity 0.5s;
  }

  .box-body {
    box-sizing: border-box;
    border-top: 1px solid var(--container-border, #e8e8e8);
    display: flex;
    align-items: center;
    height: 3.6rem;

    .icon {
      padding-left: 0.5rem;
    }
  }

  .box-dropdown-closed,
  .box-dropdown-open {
    .box-body {
      .header {
        user-select: none;
      }
    }
  }

  .header-icon {
    display: flex;
    gap: 1rem;
    position: absolute;
    top: 1.2rem;
    right: 1.2rem;

    .icon.color-default.disabled {
      cursor: default;

      :deep(svg) {
        path {
          stroke: var(--icon-primary, #868686);
        }
      }
    }
  }

  .main-title {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
  }

  .slot-content {
    box-sizing: border-box;
    padding: 1rem;
  }
}

.box-dropdown-open {
  height: fit-content;
  &.initialized {
    animation-duration: 0.5s;
  }
  animation: SlideOut ease-in-out;
}

@keyframes SlideOut {
  from {
    max-height: 3.6rem;
    overflow: hidden;
  }
  to {
    max-height: 100vh;
    overflow: unset;
  }
}

.box-dropdown-closed {
  max-height: 3.6rem;
  overflow: hidden;

  &.initialized {
    animation-duration: 0.5s;
  }
  animation: SlideIn ease-in-out;
}

@keyframes SlideIn {
  from {
    max-height: 100vh;
  }
  to {
    max-height: 3.6rem;
  }
}

@media screen and #{$media-query-xs} {
  .box {
    & .box-body {
      padding: 1.35rem;
    }
  }
}
</style>

<docs>
  ```jsx
  <XDropdownBox 
    mainTitle="Dropdown 01"
    mainIcon="home"
    mainIconColor="green"
    :toggleOnHeaderClick="true"
    :borderBottom="true"
    :open="true"
  >
    <template #headerIcons>
      <XIcon name="delete" size="medium" title="journey" :hoverEffect="true" />
      <XIcon name="eye" size="medium" title="journey" :hoverEffect="true" />
      <XIcon name="screen-zoom" size="medium" title="journey" :hoverEffect="true" />
    </template>
    <XHeading level="h3" style="margin-top: 1rem;">This is a dropdown box with a border on the bottom.</XHeading>
  </XDropdownBox>

  <div style="margin-top: 3rem;">
    <XDropdownBox 
      mainTitle="Dropdown 02"
      mainIcon="home"
      :toggleOnHeaderClick="true"
      :fixedBorder="true"
    >
      <template #headerIcons>
        <XIcon name="delete" size="medium" title="journey" :hover-effect="true" />
        <XIcon name="eye" size="medium" title="journey" :hover-effect="true" />
        <XIcon name="screen-zoom" size="medium" title="journey" :hover-effect="true" />
      </template>
      <XHeading level="h3" style="margin-top: 1rem;">This is a dropdown box with a fixed border.</XHeading>
    </XDropdownBox>
  </div>

  <div style="margin-top: 3rem;">
    <XDropdownBox 
      mainTitle="Dropdown 03"
      mainIcon="home"
      :toggleOnHeaderClick="true"
      :blueBorder="true"
      :borderBottom="true"
      :fixedBorder="true"
    >
      <template #headerIcons>
        <XIcon name="delete" size="medium" title="journey" :hover-effect="true" />
        <XIcon name="eye" size="medium" title="journey" :hover-effect="true" />
        <XIcon name="screen-zoom" size="medium" title="journey" :hover-effect="true" />
      </template>
      <XHeading level="h3" style="margin-top: 1rem;">This is a dropdown box with a blue border.</XHeading>
    </XDropdownBox>
  </div>

  <div style="margin-top: 3rem;">
    <XDropdownBox 
      mainTitle="Dropdown 04"
      mainIcon="home"
      :toggleOnHeaderClick="true"
      :borderBottom="true"
    >
      <template #headerIcons>
        <XIcon name="delete" size="medium" title="journey" :hover-effect="true" />
        <XIcon name="eye" size="medium" title="journey" :hover-effect="true" />
        <XIcon name="screen-zoom" size="medium" title="journey" :hover-effect="true" />
      </template>
      <XColorPicker customWidth="12rem" />
    </XDropdownBox>
  </div>

  <div style="margin-top: 3rem;">
    <XDropdownBox 
      mainTitle="Dropdown 05"
      mainIcon="home"
      :borderBottom="true"
    >
      <template #headerIcons>
        <XIcon name="delete" size="medium" title="journey" :hover-effect="true" />
        <XIcon name="eye" size="medium" title="journey" :hover-effect="true" />
        <XIcon name="screen-zoom" size="medium" title="journey" :hover-effect="true" />
      </template>
    </XDropdownBox>
  </div>
  
  ```
</docs>

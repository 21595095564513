<template>
  <div :class="['XMenuBox', !padding && 'no-padding']">
    <slot name="button">
      <XIcon :name="iconName" :color="iconColor" @click="toggle" :hoverEffect="true" />
    </slot>

    <div ref="toggleMenu" class="togglemenu" v-if="menuToggle" @click="closeMenu">
      <div class="slot-content">
        <slot :close="closeMenu" />
      </div>
    </div>
  </div>
</template>

<script>
import {fitInViewport} from '../../helpers/positioning';

export default {
  name: 'XMenuBox',
  emits: ['update:modelValue', 'clickOutside', 'escapeClick', 'menuClick', 'close'],
  props: {
    iconName: {
      type: String,
      default: 'dots-vertical',
    },
    padding: {
      type: Boolean,
      default: true,
    },
    iconColor: {
      type: String,
      default: 'var(--icon-primary, #868686)',
    },
    openMenu: {
      type: Boolean,
      default: undefined,
    },
  },
  data() {
    return {
      menuToggle: false,
      menuPosition: null,
    };
  },
  watch: {
    menuToggle(newVal) {
      if (newVal) {
        document.body.addEventListener('click', this.checkMouseClick);
        document.body.addEventListener('keydown', this.checkMouseClick);
      } else {
        document.body.removeEventListener('click', this.checkMouseClick);
        document.body.removeEventListener('keydown', this.checkMouseClick);
      }
    },
    openMenu(newVal) {
      this.menuToggle = newVal;
    },
  },
  mounted() {
    if (typeof this.openMenu === 'boolean') this.menuToggle = this.openMenu;
  },
  methods: {
    openContextMenu(absoluteTop, absoluteLeft) {
      this.menuToggle = true;
      // making sure that the context menu will not exceed the viewport height and width.
      this.$nextTick(() => {
        const {left: parentLeft, top: parentTop} = this.$el.parentElement.getBoundingClientRect();

        this.$refs.toggleMenu.style.left = `${absoluteLeft - parentLeft}px`;
        this.$refs.toggleMenu.style.top = `${absoluteTop - parentTop}px`;

        fitInViewport(this.$refs.toggleMenu, {gap: 15, offsetParent: this.$el.parentElement});
      });
    },
    toggle() {
      this.menuPosition = null;
      this.menuToggle = !this.menuToggle;
      this.$emit('menuClick');
    },
    closeMenuOnParentEvent() {
      this.menuToggle = false;
    },
    checkMouseClick(event) {
      if (!this.$el.contains(event.target) || event.key?.toLowerCase() === 'escape') {
        this.$emit('close');
        this.closeMenu();
      }
    },
    closeMenu() {
      this.menuToggle = false;
    },
  },
  beforeUnmount() {
    document.body.removeEventListener('click', this.checkMouseClick);
    document.body.removeEventListener('keydown', this.checkMouseClick);
  },
};
</script>

<style lang="scss" scoped>
.XMenuBox {
  width: fit-content;
  position: relative;

  .togglemenu {
    position: absolute;
    top: calc(100% + 0.5rem);
    right: -0.5rem;
    z-index: var(--zindex-popover);
    background: var(--main-background, #ffffff);
    border: 1px solid var(--main-border, #c9d3df);
    box-sizing: border-box;
    box-shadow: var(--main-shadow, 0px 0px 7px 0px rgba(71, 82, 97, 0.2));
    border-radius: 5px;
    width: max-content;
    overflow: hidden;

    .list {
      margin: 0;
      padding: 0;

      h5 {
        font-size: var(--font-sm);
        color: var(--text-primary-400, #a5a5a5);
        font-weight: normal;
        padding: 0.5rem 0.75rem;
        margin: 0;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }

      li {
        margin: 0;
        transition: background-color 0.2s ease-in-out;
        list-style: none;

        :deep(.checkbox-container) {
          width: 100%;
          cursor: pointer;
          padding: 0.5rem 0.75rem;

          label {
            cursor: pointer;
          }
        }

        &:hover {
          background: var(--control-hoverBackground, #e7f5ff);
        }
      }
    }
  }

  &.no-padding {
    .slot-content {
      padding: 0;
    }
  }

  .slot-content {
    padding: 0.5rem 0.75rem;
  }
}
</style>

<docs>
  ```jsx
  let listItemsArray = [
    {
      label: 'Hello world1',
      value: false
    },
    {
      label: 'Hello world2',
      value: false
    },
    {
      label: 'Hello world3',
      value: false
    },
    {
      label: 'Hello world4',
      value: false
    }
  ];
  
  <div style="width: 100%; display: flex; justify-content: flex-end;">
    <XMenuBox
      :listTitle="'Hello world'"
      :listItems="listItemsArray"
      iconColor="var(--icon-primary)"
      @update:modelValue="listItemsArray = $event"
    >
      <XSlider
        label="Slider 1"
        size="small"
        :modelValue="false"
      />
      <XSlider
        label="Slider 2"
        size="small"
        :modelValue="true"
      />
    </XMenuBox>
  </div>
  ```
</docs>

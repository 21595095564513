<template>
  <component
    :is="wrapper"
    :class="[
      'checkbox-container',
      {
        disabled: disabled,
        hover: hoverEffect,
        showHover: showHover,
        customColor: backgroundColor,
        active: !disabled ? checked : undefined,
      },
      size,
    ]"
    @click="toggleCheckbox()"
  >
    <XStack :class="['checkbox', {checked: checked, disabled: disabled}]" alignment="center" crossAlignment="center">
      <XIcon v-if="checked" name="check" size="xxs" :color="'var(--control-selectedColor, #ffffff)'" />
    </XStack>
    <label v-if="label" :title="label">
      <XText>{{ label }}</XText>
    </label>
  </component>
</template>

<script>
import createIdPrefixedDispenser from '@src/helpers/create-prefixed-id-dispenser';

const getId = createIdPrefixedDispenser('CHECKBOX_');

export default {
  name: 'XCheckbox',
  emits: ['update:modelValue', 'change'],
  computed: {
    /**
     * Get: Returns value
     * Set: Emits input to parent.
     */
    checked: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
    cssBackgroundColor() {
      return this.backgroundColor;
    },
  },
  props: {
    /**
     * The label of the form input field.
     */
    label: {
      type: String,
      default: null,
    },
    /**
     * Hover effect on or off (default: on)
     */
    hoverEffect: {
      type: Boolean,
      default: true,
    },
    showHover: {
      type: Boolean,
      default: false,
    },
    /**
     * Custom background color
     */
    backgroundColor: {
      type: String,
      default: undefined,
    },
    /**
     * Value for v-model
     */
    modelValue: {
      type: Boolean,
      default: false,
    },
    /**
     * The html element name used for the wrapper.
     * `div, section`
     */
    wrapper: {
      type: String,
      default: 'div',
      validator: value => {
        return value.match(/(div|section)/);
      },
    },
    /**
     * Unique identifier of the form input field.
     */
    id: {
      type: String,
      default: getId,
    },
    /**
     * Whether the form input field is disabled or not.
     * `true, false`
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Make the checkbox bigger.
     * `M, XL`
     */
    size: {
      type: String,
      default: 'medium',
      validator: value => {
        return value.match(/(large|medium|small)/);
      },
    },
    /**
     * @deprecated This prop is deprecated.
     */
    title: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toggleCheckbox() {
      if (!this.disabled) {
        this.checked = !this.checked;
        this.$emit('change', this.checked);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.checkbox-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0.5rem;
  width: fit-content;
  text-overflow: ellipsis;

  &:hover {
    cursor: pointer;
  }

  .checkbox {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    min-width: 1rem;
    width: 1rem;
    height: 1rem;
    border-radius: var(--radius-xs);
    position: relative;
    transition: all 0.2s linear;
    border: 1px solid var(--control-border, #c9d3df);
    background-color: var(--control-background, #ffffff);
    cursor: pointer;
    box-sizing: border-box;

    &.checked {
      background: var(--control-selectedBackground, #2783c6);
      border-color: var(--control-selectedBorder, #2783c6);
    }
  }

  label {
    color: var(--control-color, #313741);
    line-height: 0.9rem;
    font-size: var(--font-md);
    overflow: hidden;

    &:hover {
      cursor: pointer;
    }
  }

  &.showHover {
    .checkbox {
      &.checked {
        &:not(.disabled) {
          border-color: var(--control-hoverBorder, #2783c6);
        }
      }
      &:not(.checked):not(.disabled) {
        border: 1px solid var(--control-hoverBorder, #2783c6);
        background: var(--control-hoverBackground, #e7f5ff);
      }
    }
  }

  &.hover {
    .checkbox {
      &.checked {
        &:not(.disabled):hover {
          border-color: var(--control-hoverBorder, #2783c6);
        }
      }

      &:not(.checked):not(.disabled):hover {
        border: 1px solid var(--control-hoverBorder, #2783c6);
        background: var(--control-hoverBackground, #e7f5ff);
      }
    }
  }

  &.disabled {
    cursor: not-allowed;

    .checkbox {
      background: var(--control-disabledBackground, #f5f5f5);

      &.checked {
        background: var(--control-selectedBackground, #2783c6);
        opacity: 0.6;
      }
    }

    label {
      opacity: 0.6;
    }

    * {
      cursor: not-allowed;
    }
  }

  &.customColor {
    .checkbox {
      &.checked {
        background: v-bind('cssBackgroundColor');
        border-color: v-bind('cssBackgroundColor');
      }
    }
  }

  &.hover {
    &.customColor {
      &.checked {
        background: v-bind('cssBackgroundColor');
        border-color: v-bind('cssBackgroundColor');
      }
    }
  }
}

:deep .markdown-body {
  background-color: transparent;
  font-family: inherit;
  font-size: inherit;

  p {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
</style>

<docs>
  ```jsx
  let checkedState = false;

  <div style="margin-bottom: 1rem;">
    <XCheckbox :label="checkedState ? 'selected' : 'deselected' " :modelValue="checkedState" @update:modelValue="checkedState = $event" />
  </div>
  <div style="margin-bottom: 1rem;">
    <XCheckbox label="hover" :modelValue="checkedState" @update:modelValue="checkedState = $event" backgroundColor="#FFFFFF" :showHover="true"/>
  </div>
  <div style="margin-bottom: 1rem;">
    <XCheckbox disabled label="disabled" :modelValue="checkedState" @update:modelValue="checkedState = $event" />
  </div>
  ```
</docs>

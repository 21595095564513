import { moveToFront, fitInViewport } from '../helpers/positioning';
/**
 * Make the given element draggable, an optional handle may be defined in one of its child elements via the `v-draghandle` directive
 *
 * @param {HTMLElement} draggable
 * @param {DraggableBinding} binding
 */
export const draggable = (draggable, binding) => {
    // when boolean -> explicitly false, simply disable draggability
    if (binding.value === false)
        return;
    draggable.setAttribute('data-draggable', 'true');
    const draghandle = draggable.querySelector('[data-draghandle]') || draggable;
    // force absolute positioning, set it immediately to prevent 'jump' bugs from occurring later on when users try to drag
    draggable.style.position = 'absolute';
    // disable text selection on draghandle, make it clear element is interactable
    draghandle.style.userSelect = 'none';
    draghandle.style.cursor = 'pointer';
    let offset = {
        left: 0,
        top: 0,
    };
    draghandle.addEventListener('mousedown', (e) => {
        // distance between mouse <> borders of our handle
        offset = {
            left: e.clientX - draggable.getBoundingClientRect().left,
            top: e.clientY - draggable.getBoundingClientRect().top,
        };
        moveToFront(draggable, '[data-draggable]');
        // should we also force our element to the front (see XPopup)?
        document.addEventListener('mousemove', drag);
        document.addEventListener('mouseup', () => document.removeEventListener('mousemove', drag), { once: true });
    });
    const drag = (e) => {
        e.preventDefault();
        const offsetParent = draggable.offsetParent || document.body;
        const { top: parentTop = 0, left: parentLeft = 0 } = offsetParent === null || offsetParent === void 0 ? void 0 : offsetParent.getBoundingClientRect();
        draggable.style.top = `${e.clientY - parentTop - offset.top}px`;
        draggable.style.bottom = 'unset';
        draggable.style.left = `${e.clientX - parentLeft - offset.left}px`;
        draggable.style.right = 'unset';
        // prevent from being dragged outside screen (make this an option?)
        fitInViewport(draggable);
    };
};
/**
 * Make this element the drag handle for a `v-draggable` element
 *
 * @param {HTMLElement} el
 */
export const draghandle = (el) => el.setAttribute('data-draghandle', 'true');

/**
 Keep track of the current size of our table, send notifications when a change occurs
 
 should be able to force updates in certain scenarios ( eg. column change )
 
 
 Our preferred table width is determined by the largest of two: our largest (configured) column, or our container element
 
 
 original pieces of code (context?):

 
 
 // Set the length in px of the widest column for checking of the horizontal scrollbar calc
 newCol.width > state.columnWidth ? (state.columnWidth = newCol.width) : undefined;

 // Get the width of the table data which is deeply nested via ref dataTable
 const getTableWidth = () => {
  if (!props.data || !props.data.length) return;

  observeTableWidth();

  const tableWidth = getTableWidthProvidingElement()?.clientWidth;

  if (tableWidth > state.columnWidth) {
    state.tableWidth = tableWidth;
  }
};

 const getTableWidthProvidingElement = () =>
 dataTable.value?.$el.children[0].children[0].children[1].children[0].children[0].firstElementChild;

 state.resizeObserver = new ResizeObserver(entries => {
    // Wrap it in requestAnimationFrame to avoid this error: ResizeObserver loop limit exceeded
    window.requestAnimationFrame(() => {

 */
import { onMounted, onBeforeUnmount, shallowRef, unref } from 'vue';
export function useResizeMonitor(target, columns) {
    return {
        width: shallowRef(undefined),
    };
    const width = shallowRef(undefined);
    const observer = new ResizeObserver(entries => {
        if (!entries.length)
            return;
        updateSizes();
    });
    onMounted(() => {
        // observer.observe(target.value.$el);
        updateSizes();
    });
    onBeforeUnmount(() => observer.disconnect());
    function updateSizes() {
        width.value = Math.max(target.value.$el.getBoundingClientRect().width, unref(columns)
            .filter((x) => typeof x.width === 'number')
            .map((x) => x.width));
    }
    return {
        updateSizes,
        width,
    };
}

<template>
  <div :class="['menu-button', border && 'border', disabled && 'disabled']" @click="!disabled && $emit('click')">
    <XIcon :name="icon || 'cross'" :color="iconColor" size="small" />
    <XParagraph componentStyle="medium" :color="textColor">
      <slot></slot>
    </XParagraph>
  </div>
</template>

<script>
export default {
  name: 'XMenuBoxButton',
  emits: ['click'],
  props: {
    icon: {
      type: String,
    },
    textColor: {
      type: String,
    },
    iconColor: {
      type: String,
    },
    border: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  column-gap: 0.5rem;
  padding: var(--spacing-sm) var(--spacing-lg);

  &:hover {
    background-color: var(--control-hoverBackground, #e7f5ff);
  }

  &.border {
    border-top: 1px solid var(--container-border, #e8e8e8);
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 30%;
  }
}
</style>

<docs>
  ```jsx
    <XMenuBoxButton>Button content</XMenuBoxButton>
    <XMenuBoxButton disabled>Button content</XMenuBoxButton>
  ```
</docs>

import * as util from './util';
import { z } from 'zod';
import { unref, toRefs } from 'vue';
import range from '../../../helpers/range';
const dateTimeSchema = z.string().datetime();
/** Check if the value is a datetime */
function isDateTime(value) {
    return dateTimeSchema.safeParse(value).success;
}
/** Check if the value is numerc */
function isNumber(value) {
    return util.isFinite(value);
}
/** Infer the datatype for the data in the given column */
function inferType(column, data) {
    // todo: add the ability to explicitly specify datatypes on columns
    data = unref(data);
    if (column.type)
        return column.type;
    for (let idx of range(0, Math.min(5, data.length))) {
        const value = data[idx][util.keyOf(column)];
        if (Time.isTime(value))
            return 'time';
        if (isDateTime(value))
            return 'datetime';
        if (isNumber(value))
            return 'number';
    }
    return undefined;
}
export const useColumnDataTypes = (props) => {
    const { columns, data } = toRefs(props);
    const columnTypes = new Map();
    const getColumnType = (columnKey) => {
        if (columnTypes.has(columnKey)) {
            return columnTypes.get(columnKey);
        }
        else {
            const column = columns.value.find(x => x.propertyName == columnKey);
            const columnType = inferType(column, data);
            if (columnType) {
                columnTypes.set(columnKey, columnType);
                return columnType;
            }
            else {
                //return string when unsure, will attempt to infer type next time.
                return 'string';
            }
        }
    };
    return getColumnType;
};
/** Helper class for parsing times */
class Time {
    /** Check if the given value is parsable as time */
    static isTime(value) {
        return this._timeRegex.test(value);
    }
    constructor(hour, minute, second) {
        this.hour = hour;
        this.minute = minute;
        this.second = second;
    }
    /** Parse the given timestring as a time object */
    static parse(value) {
        const [, hour, minute, second = '0'] = this._timeRegex.exec(value) || [];
        // match failed
        if (!util.isFinite(hour))
            return undefined;
        return new Time(parseInt(hour), parseInt(minute), parseInt(second));
    }
    toMilliseconds() {
        return this.hour * 60 * 60 * 1000 + this.minute * 60 * 1000 + this.second * 1000;
    }
}
Time._timeRegex = /^(\d{1,2}):(\d{1,2}):?(\d{1,2})?$/;
export { isNumber, isDateTime, inferType, Time };

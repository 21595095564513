import { h } from 'vue';
import { resolveSlotTextContent } from '../../helpers/vue-slots';
import { TextStyles } from '../../helpers/text';
//MarkdownEngine.Lite -> regex based
//MarkdownEngine.Full -> markdown it
/**
 * Text with basic styling support (**bold**, __italic__, {color:red}red{color})
 */
export default {
    name: 'XText',
    setup(props, context) {
        let contentNode = h('span');
        let plainText = '';
        // try to take content directly from default slot
        // otherwise take from prop, whatever works best...
        return () => {
            // slot content should be read within render function, this marks the slot as a reactive dependency
            // if not, slot content changes will not trigger any lifecycle hooks to rerun
            const newPlainText = resolveSlotTextContent(context.slots.default);
            // only update our contained content when an actual update took place
            if (newPlainText !== plainText) {
                plainText = newPlainText;
                const contentHTML = TextStyles.render(plainText);
                contentNode = h('span', { innerHTML: contentHTML });
            }
            return contentNode;
        };
    },
};

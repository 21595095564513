import { shallowRef, watch } from 'vue';
import * as util from './util';
/** Composable controlling which columns are shown/hidden */
export function useColumnHiding(props) {
    var _a;
    /** Collection of all hidden columns */
    const hiddenColumnKeys = shallowRef(((_a = props.columns) === null || _a === void 0 ? void 0 : _a.filter(column => column.hideByDefault).map(column => util.keyOf(column))) || []);
    /** Check if the given column is marked as hidden */
    const isHidden = (column) => props.enableColumnVisibilityToggle && hiddenColumnKeys.value.includes(util.keyOf(column));
    watch(() => props.columns, (newColumns, oldColumns) => {
        (newColumns || []).forEach(column => {
            //check if column is new
            const oldColumn = oldColumns === null || oldColumns === void 0 ? void 0 : oldColumns.find(x => util.keyOf(x) == util.keyOf(column));
            if (oldColumn)
                return;
            //Add key to hiddenColumnkeys if it should be hidden by default;
            if (!column.hideByDefault)
                return;
            hiddenColumnKeys.value.push(util.keyOf(column));
        });
        // update our hiddenkeys with our new columnset
        hiddenColumnKeys.value = (newColumns !== null && newColumns !== void 0 ? newColumns : []).filter(column => isHidden(column)).map(x => util.keyOf(x));
    }, { immediate: true });
    return {
        hiddenColumnKeys,
        isHidden,
    };
}

/*

Differing plugins/features should be able to append/modify a list of existing rowclasses
the individual features should ideally be able to provide their own styling for the row classes, for now this styling can be handled in the XDataTable component however
 
can we scope a style tag to our current component type? -> inject styles from here
 
TableBuilder/TableOptionsBuilder/TableConfigurationBuilder
TableBuilder.addClassProvider({
  getClass( data, index ) => string | undefined;
})
 
*/
/** Apply highlighting to selected elements */
export function useRowHighlights(props) {
    return {
        rowClass(rowData, rowIndex) {
            if (!Array.isArray(props.checkedRowKeys))
                return;
            const isChecked = props.checkedRowKeys.includes(rowData === null || rowData === void 0 ? void 0 : rowData.id);
            if (isChecked)
                return 'row-highlighted';
            return '';
        },
    };
}

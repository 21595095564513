import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_XChartSeriesTooltips = _resolveComponent("XChartSeriesTooltips");
    const _component_XChartLabels = _resolveComponent("XChartLabels");
    const _component_XChartPieSeries = _resolveComponent("XChartPieSeries");
    const _component_XChartTheme = _resolveComponent("XChartTheme");
    const _component_XChartLegend = _resolveComponent("XChartLegend");
    const _component_XChartToolbarExport = _resolveComponent("XChartToolbarExport");
    const _component_XChartNoDataMessage = _resolveComponent("XChartNoDataMessage");
    const _component_XChartUserLocale = _resolveComponent("XChartUserLocale");
    const _component_XAmPieChartRoot = _resolveComponent("XAmPieChartRoot");
    return (_openBlock(), _createBlock(_component_XAmPieChartRoot, { categoryType: _ctx.xaxisType }, {
        default: _withCtx(() => [
            _createVNode(_component_XChartPieSeries, { data: $setup.seriesCorrected }, {
                default: _withCtx(() => [
                    _createVNode(_component_XChartSeriesTooltips),
                    (_ctx.dataLabels)
                        ? (_openBlock(), _createBlock(_component_XChartLabels, { key: 0 }))
                        : _createCommentVNode("", true)
                ]),
                _: 1
            }, 8, ["data"]),
            _createVNode(_component_XChartTheme, { colors: _ctx.theme }, null, 8, ["colors"]),
            _createVNode(_component_XChartLegend),
            _createVNode(_component_XChartToolbarExport, { name: _ctx.name }, null, 8, ["name"]),
            _createVNode(_component_XChartNoDataMessage),
            _createVNode(_component_XChartUserLocale)
        ]),
        _: 1
    }, 8, ["categoryType"]));
}

<template>
  <div :class="['wrapper', componentStyle]">
    <component
      :is="type"
      :href="href"
      :type="submit"
      :class="['button', size, componentStyle, state === 'active' && 'active', options.length && 'has-options']"
      :disabled="disabled"
      @click="$emit('click', $event)"
    >
      <div v-if="bubbleCount > 0" class="notifications">
        {{ bubbleCount }}
      </div>
      <div class="inner" :class="[{active: state === 'active'}]" ref="loading">
        <div v-if="state === 'loading'" class="loading-dots">
          <div class="dot1" />
          <div class="dot2" />
          <div class="dot3" />
        </div>

        <slot />
      </div>
      <div class="active-bar" />
    </component>
    <XMenuBox v-if="options.length" ref="menuBox" :padding="false">
      <template #button>
        <button :class="['options-button', componentStyle]" @click="$refs.menuBox.toggle()">
          <XIcon name="chevron-down" color="#fff" size="xxsmall" />
        </button>
      </template>
      <XMenuBoxButton
        v-for="option in options"
        :icon="option.icon"
        @click="option.handler(), $refs.menuBox.toggle()"
        :disabled="option.disabled"
      >
        {{ option.label }}
      </XMenuBoxButton>
    </XMenuBox>
  </div>
</template>

<script>
/**
 * Buttons are generally used for interface actions. Use buttons to signal clickable actions.
 *
 * Primary style should be used only once per view for main call-to-action.
 */
export default {
  name: 'XButton',
  emits: ['click'],
  props: {
    /**
     * The html element used for the button.
     * `button, a`
     */
    type: {
      type: String,
      default: 'button',
      validator: value => {
        return value.match(/(button|a)/);
      },
    },
    /**
     * The size of the button. Defaults to medium.
     * `small, medium, large`
     */
    size: {
      type: String,
      default: 'small',
            validator: value => {
        return value.match(/(sm|small|md|medium|large)/);
      },
    },
    bubbleCount: {
      type: Number,
    },
    /**
     * When setting the button’s type to a link, use this option to give a href.
     */
    href: {
      type: String,
      default: null,
    },
    /**
     * Set the button’s type to “submit”.
     */
    submit: {
      type: String,
      default: null,
      validator: value => {
        return value.match(/(null|submit)/);
      },
    },
    /**
     * Manually trigger various states of the button.
     * `hover`
     */
    state: {
      type: String,
      default: null,
      validator: value => {
        return value.match(/(hover|active|loading|null)/);
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
    /**
     * Style style to give additional meaning.
     */
    componentStyle: {
      type: String,
      default: 'primary',
      validator: value => {
        return value.match(
          /(primary|branded|white|secondary-action|secondary-success|secondary-danger|secondary-warning|primary-action|primary-success|primary-danger|primary-warning|link|button|wide-button)/
        );
      },
    },
  },
  mounted() {
    this.disabledIconInSlot();
  },
  watch: {
    state(newVal) {
      if (newVal === 'loading') {
        this.disabledIconInSlot();
      } else {
        this.enableIconInSlot();
      }
    },
  },
  methods: {
    disabledIconInSlot() {
      if (this.state !== 'loading' || this.$refs.loading.children.length === 0) {
        return;
      }

      // Remove the XIcon from the html inside the slot to only show the loading animation
      const children = Array.from(this.$refs.loading.children).forEach(element => {
        if (element.ariaLabel === 'icon') {
          element.style.display = 'none';
          return;
        }
      });
    },
    enableIconInSlot() {
      const children = Array.from(this.$refs.loading.children).forEach(element => {
        if (element.ariaLabel === 'icon') {
          element.style.display = 'block';
          return;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@use 'sass:map';
@use '../../assets/styles/colors.scss' as *;
@use '../../assets/styles/variables.scss' as *;

$border-radius: 5px;
.button {
  border: none;
  color: var(--button-color);
  height: 2.5rem;
  padding: 0 0.875rem;
  font-size: var(--font-h5);
  border-radius: $border-radius;
  transition: all 0.2s ease-in-out;
  position: relative;
  cursor: pointer;
  display: flex;
  text-decoration: none;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;

  background-color: var(--button-background-color);

  .inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 0.5rem;

    .loading-dots {
      width: 1rem;
      height: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: nowrap;

      div {
        border-radius: 100%;
        width: 0.25rem;
        height: 0.25rem;
        background-color: #ffffff;
      }

      .dot1,
      .dot2,
      .dot3 {
        animation: dot 1.6s ease-in-out;
        animation-iteration-count: infinite;
      }
      .dot1 {
        animation-delay: 0s;
      }

      .dot2 {
        animation-delay: 0.3s;
      }

      .dot3 {
        animation-delay: 0.8s;
      }
    }

    @keyframes dot {
      0% {
        opacity: 0.1;
      }

      50% {
        opacity: 1;
      }

      100% {
        opacity: 0.1;
      }
    }

    :deep(.icon) {
      display: flex;

      svg {
        height: map.get($icon-sizes, 'small');
        width: map.get($icon-sizes, 'small');
      }
    }
    :deep(.icon) {
      path:not(.iconDot),
      circle:not(.iconDot) {
        stroke: var(--button-color);
      }

      path.iconDot,
      circle.iconDot {
        fill: var(--button-color);
      }
    }
  }

  .notifications {
    position: absolute;
    translate: 50% -50%;
    background: #dde9f2;
    border-radius: var(--radius-lg);
    color: #313741;
    font-size: var(--font-xs);
    min-width: 1.25rem;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    padding: var(--spacing-xs);
    top: 0;
    right: 0;
  }

  &:active,
  &.active {
    .active-bar {
      height: 100%;
      width: 100%;
      border: 1px solid var(--button-activeBorder, #ffffff);
      border-radius: 4px;
      position: absolute;
      top: 0px;
      left: 0px;
      display: none;
    }
  }

  &:not(:disabled) {
    outline: none;

    .active-bar {
      display: initial;
    }
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &.has-options {
    border-top-right-radius: initial;
    border-bottom-right-radius: initial;
  }

  &:active,
  &.active,
  &:hover,
  &.hover {
    background: var(--button-background-hover-color);
    color: var(--button-hover-color);

    .inner {
      :deep(.icon) {
        path:not(.iconDot),
        circle:not(.iconDot) {
          stroke: var(--button-hover-color);
        }

        path.iconDot,
        circle.iconDot {
          fill: var(--button-hover-color);
        }
      }
    }
  }
}

.wrapper {
  display: inline-flex;

  @each $state, $colors in $button-colors {
    &.#{'' + $state} {
      @if map-get($colors, color) {
        --button-color: #{map-get($colors, color)};
      } @else {
        --button-color: var(--button-text-primary);
      }
      @if map-get($colors, color-hover) {
        --button-hover-color: #{map-get($colors, color-hover)};
      } @else {
        --button-hover-color: var(--button-text-primary);
      }
      --button-background-color: #{map-get($colors, background-color)};
      --button-background-hover-color: #{map-get($colors, background-hover-color)};
    }
  }

  .button.white,
  &.button.secondary-action {
    border: 1px solid var(--container-border, #e8e8e8);
  }

  :deep() {
    .XMenuBox {
      .togglemenu {
        top: calc(100% + 0.25rem);
        right: 0;
      }
    }
  }

  &.wide-button {
    width: 100%;
    .button {
      width: 100%;
      height: 3.7rem;
      background-color: var(--container-secondaryBackground, #edf3f8);
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--button-text-secondary, #2783c6);
      font-weight: 600;

      &:hover {
        background: var(--button-primaryHover, #083856);
      }

      .inner {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 0.5rem;

        :deep(.icon) {
          path:not(.iconDot),
          circle:not(.iconDot) {
            stroke: var(--button-text-secondary, #2783c6);
            transition: 0.2s ease-in-out all;
          }

          path.iconDot,
          circle.iconDot {
            fill: var(--button-text-secondary, #2783c6);
            transition: 4s ease-in-out all;
          }
        }
      }
    }
  }
}

.options-button {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 0.75rem;
  border: none;
  border-left: 1px solid var(--container-border, #e8e8e8);
  transition: all 0.2s ease-in-out;
  border-top-right-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  cursor: pointer;

  background-color: var(--button-background-color);
  &:hover,
  &.hover {
    background-color: var(--button-background-hover-color);
  }
}
</style>

<docs>
  ```jsx

  const test = () => {
    console.log('This is just a test')
  }
  <div>
    <XButton component-style="white">
      <XIcon name="gear" size="custom" />
      Action
    </XButton>
    <XButton type="a" href="www.google.com" component-style="primary-action">
      <XIcon name="gear" size="custom" />
      Action
    </XButton>
    <XButton size="small">
      <XIcon name="information-circle" size="custom" />
    </XButton>
    <XButton component-style="primary-success">
      <XIcon name="plus-circle" size="custom" />
      Success
    </XButton>
    <XButton component-style="primary-warning">
      <XIcon name="edit" size="custom" />
      Warning
    </XButton>
    <XButton component-style="primary-danger" :bubbleCount="9">
      <XIcon name="clear" size="custom" />
      Danger
    </XButton>
    <br />
    <br />
    <XButton component-style="branded">
      <XIcon name="gear" size="custom" />
      Branded button
    </XButton>
    <br />
    <br />
    <XButton state="active">:active</XButton>
    <XButton disabled>disabled</XButton>
    <XButton :options="[{label: 'Opslaan & afsluiten', icon: 'house', handler: test}, {label: 'Opslaan als', icon: 'gear'}]">Opslaan</XButton>
    <p style="font-size: 13px">Om de loading uit te zetten geef je state: null mee</p>
    <XButton state="loading">
      <XIcon name="plus-circle" size="custom"/>
      Loading
    </XButton>
    <br />
    <br />
    <div style="width: 20rem">
      <XButton component-style="wide-button" >
        <XIcon name="plus-circle" size="custom"/>
        Tabblad toevoegen
      </XButton>
    </div>
  </div>
  ```
</docs>

/** Parse annotations list to apex annotations format. Merge the result with the dataseries */
const parseAnnotations = annotations => {
  if (!(annotations?.length > 0)) {
    return {};
  }

  const xaxis = [];
  const yaxis = [];
  const points = [];

  for (const annotation in annotations) {
    const {x, x2, y, y2, horizontal, text, color, isInvisible} = annotations[annotation];

    if (isInvisible) {
      continue;
    }

    const chartAnnotation = {
      fillColor: color,
      borderColor: color,
      strokeDashArray: 0,
      label: {
        text: text,
        style: {
          color: '#FFFFFF',
          background: color,
        },
      },
    };

    if (horizontal !== null && horizontal !== undefined) {
      chartAnnotation.label.orientation = horizontal ? 'horizontal' : 'vertical';
    }
    if (x && y) {
      points.push({
        ...chartAnnotation,
        x,
        y,
        marker: {
          strokeColor: color,
          strokeWidth: 3,
          size: 6,
        },
      });
      continue;
    }
    if (x) {
      xaxis.push({...chartAnnotation, x, x2});
    }
    if (y) {
      yaxis.push({...chartAnnotation, y, y2});
    }
  }

  let result = {
    points,
    xaxis,
    yaxis,
  };

  return result;
};

export default parseAnnotations;

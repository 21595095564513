<template>
  <div class="flex-container">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'XFlexLayout',
};
</script>

<style scoped lang="scss">
@use '../../assets/styles/variables.scss' as *;
.flex-container {
  display: flex;
  flex-wrap: wrap;

  :deep(.flex-row) {
    width: 100%;
    display: flex;
    margin-bottom: 1rem;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 1rem;
    row-gap: 1rem;

    &:last-child {
      margin-bottom: 0;
    }

    @for $flex-grow-size from 1 through 10 {
      .flex-grow-#{$flex-grow-size} {
        flex: $flex-grow-size;

        &:last-child {
          margin-right: 0;
        }

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}

@media screen and #{$media-query-s} {
  .flex-container {
    :deep(.flex-row) {
      flex-direction: column;

      @for $flex-grow-size from 1 through 10 {
        .flex-grow-#{$flex-grow-size} {
          flex: 1;
        }
      }
    }
  }
}
</style>

<docs>
```jsx
<!-- flex-grow-{var} where var is the flex: value :) -->
  <XFlexLayout >
    <div class="flex-row">
      <XBox boxTitle="Hello" class="flex-grow-1" />
      <XBox boxTitle="Hello" class="flex-grow-1" />
      <XBox boxTitle="Hello" class="flex-grow-5" />
      <XBox boxTitle="Hello" class="flex-grow-10" />
    </div>
    <div class="flex-row">
      <XBox boxTitle="Hello" class="flex-grow-1" />
      <XBox boxTitle="Hello" class="flex-grow-1" />
    </div>
  </XFlexLayout>
```
</docs>

import { z } from 'zod';
import { T } from '../helpers/translationsProvider';
export const phone = z
    .string()
    .regex(/^(\+\s?)?(([^+].*)\(\+?\d+([\s\-\.]?\d+)?\)|\d+)([\s\-\.]?(\(\d+([\s\-\.]?\d+)?\)|\d+))*(\s?(x|ext\.?)\s?\d+)?$/, T('VALIDATION_INVALID_PHONE'));
//No special characters, but allow characters like "ô" and "û"
export const name = z.string().regex(/^[A-Za-zÀ-ÖØ-öø-ÿ]+(?:[-\s][A-Za-zÀ-ÖØ-öø-ÿ]+)*$/);
export const street = z.string().regex(/^[A-Za-zÀ-ÖØ-öø-ÿ]+(?:[-\s][A-Za-zÀ-ÖØ-öø-ÿ]+)*$/);
export const initials = z.string().regex(/(([A-Za-zÀ-ÖØ-öø-ÿ]{1})?\.?){1,10}/);
export const houseNumber = z.string().regex(/^[0-9]+$/);
export const city = z.string().regex(/^[A-Za-zÀ-ÖØ-öø-ÿ]+(?:[-\s][A-Za-zÀ-ÖØ-öø-ÿ]+)*$/);
export const houseNumberAdd = z
    .string()
    .max(7)
    .regex(/^[a-zA-Z0-9\-\\/]*$/);
export const zipCode = z
    .string()
    .regex(/^[a-zA-Z0-9\s]*$/)
    .max(7);
export const chamberOfCommerce = z.string().regex(/^[0-9]+$/);
export const vat = z.string().regex(/^[a-zA-Z0-9\s]*$/);
export const website = z
    .string()
    .regex(/^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z0-9-]+(\/\S*)?$/, T('VALIDATION_INVALID_URL'));
export const iban = z.string().regex(/^[a-zA-Z0-9]*$/);
export const referenceNumber = z.string();

import { ChartComponent } from './chartInstance';
import { categoryAxisSwitch } from './util';
import { AxisRendererCircular } from "@amcharts/amcharts5/radar";
import { ValueAxis as AMValueAxis } from "@amcharts/amcharts5/.internal/charts/xy/axes/ValueAxis";
/** Add ticks to the category axis */
export class Ticks extends ChartComponent {
    getTags() {
        return ['axis-enhancer', 'ticks'];
    }
    constructor(settings) {
        super();
        // dependency: axis + ticks-target tags? figure out target automatically instead of requiring differing components
        this.target = settings.target;
    }
    /**
     * Create general ticks, not bound to an axis etc.
     */
    static ValueAxis() {
        const instance = new Ticks({ target: 'valueAxis' });
        instance.addDependency('valueaxis');
        return instance;
    }
    /**
     * Create ticks to be placed on a category axis
     */
    static CategoryAxis() {
        const instance = new Ticks({ target: 'categoryAxis' });
        instance.addDependency('categoryaxis');
        return instance;
    }
    render(context) {
        if (this.target === 'categoryAxis')
            this.renderOnCategoryAxis(context);
        else
            this.renderOnCircularAxis(context);
    }
    /**
     * Render ticks on the inside of a circular axis
     *
     * Major and minor ticks both will get assigned a new axis
     */
    renderOnCircularAxis(context) {
        // we currently only support circular axis, can add support for other axis at a later stage
        const majorTicksRenderer = this.createRadarTicksAxis(context);
        majorTicksRenderer.set('minGridDistance', 50);
        majorTicksRenderer.ticks.template.setAll({
            visible: true,
            inside: true,
            length: 10,
            strokeWidth: 1.25,
            crisp: true
        });
        const minorTicksRenderer = this.createRadarTicksAxis(context);
        minorTicksRenderer.set('minGridDistance', 12.5);
        minorTicksRenderer.ticks.template.setAll({
            visible: true,
            inside: true,
            // position in between major ticks
            location: 0.5,
            multiLocation: 0.5,
            length: 5,
            crisp: true
        });
    }
    /**
     * Render ticks on an XY Category axis
     */
    renderOnCategoryAxis(context) {
        const categoryAxisRenderer = context
            .get(this.target) //<-- accept name as key?
            .get('renderer');
        // Enable ticks
        // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/#ticks
        // For reference see:  https://www.amcharts.com/docs/v5/reference/AxisTick/
        categoryAxisRenderer.ticks.template.setAll({
            // could be part of our theme?
            visible: true,
        });
        categoryAxisSwitch(context, positionOnXAxis, positionOnYAxis)(categoryAxisRenderer);
        function positionOnXAxis(renderer) {
            // render inside plot area, required for correct positioning
            renderer.ticks.template.set('inside', true);
            // should be a better way to do this consistently, fine for now
            renderer.ticks.template.adapters.add('dy', (value, target) => {
                const gridStroke = renderer.grid.template.get('strokeWidth', 1);
                // place on top of gridline, first pixel overlapping with grid
                let position = -target.parent.getPrivate('y');
                // yoink downards, topmost pixel overlapping with grid line
                position += target.get('length');
                // yoink downards, don't overlap with gridline
                position += gridStroke;
                return position;
            });
        }
        function positionOnYAxis(renderer) { }
    }
    createRadarTicksAxis(context) {
        const chart = context.get('chart');
        // assume we're placed on a valueAxis
        const mainAxis = context.get(this.target);
        const renderer = AxisRendererCircular.new(context.root, {
            radius: -15
        });
        // hide datalabels/grid
        renderer.labels.template.set('visible', false);
        renderer.grid.template.set('visible', false);
        chart.xAxes.push(AMValueAxis.new(context.root, {
            renderer: renderer,
            // ensure we span the same range as our main axis
            min: mainAxis.get('min'),
            max: mainAxis.get('max')
        }));
        return renderer;
    }
}

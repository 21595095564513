var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
let parser = new DOMParser();
//DEV: Switch between red dot placeholder and empty image when testing loading.
const reddot = '<svg viewPort="0 0 18 18"><circle r="6" cx="9" cy="9" fill="#ff0000" stroke="#ff0000" /></svg>';
const empty = '<svg />';
const placeholder = (_a = parser.parseFromString(empty, 'image/svg+xml')) === null || _a === void 0 ? void 0 : _a.querySelector('svg');
export default class Repository {
    constructor(options) {
        var _a;
        this.items = new Map();
        this.pending = {};
        this.removeStyle = true;
        this.baseUrl = (_a = options === null || options === void 0 ? void 0 : options.baseUrl) !== null && _a !== void 0 ? _a : '/assets/icons';
        this.baseUrl = this.baseUrl.replace(/(\/|\\|\n|\t| )*$/, '');
    }
    clear() {
        this.items.clear();
    }
    /** Clean key */
    clean(key) {
        var _a;
        return (_a = (Array.isArray(key) && ~~(key === null || key === void 0 ? void 0 : key.length) > 0 ? key[0] : key)) !== null && _a !== void 0 ? _a : '';
    }
    get(name, notify) {
        const key = this.clean(name);
        // Not found? => start (pending) request and return placeholder. When request resolves it will notify all the callers.
        return this.items.has(key)
            ? this.items.get(key)
            : ((this.pending[key] || (this.pending[key] = this.add(key).finally(() => delete this.pending[key]))).then(notify),
                placeholder);
    }
    load(...names) {
        return __awaiter(this, void 0, void 0, function* () {
            const tasks = (names || []).map((key) => __awaiter(this, void 0, void 0, function* () { return yield this.add(key.replace(/.svg$/, '').replace(/^[\.\\\/]*/, '')); }));
            return Promise.allSettled(tasks).catch(err => console.log(err));
        });
    }
    add(name) {
        return __awaiter(this, void 0, void 0, function* () {
            let icon = undefined;
            try {
                let content = yield fetch(`${this.baseUrl}/${name}.svg`)
                    .then(resp => (resp.ok && resp.text()) || 'error')
                    .catch(error => {
                    // Network or cors headers. 4XX is not an error!
                    process.env.isProd && console.error(error);
                    return undefined;
                });
                if (this.removeStyle)
                    content = content === null || content === void 0 ? void 0 : content.replace(/<style[^>]*>[^>]*<\/style>/gim, '');
                if (!content)
                    return;
                let svg = parser.parseFromString(content, 'image/svg+xml');
                const error = svg instanceof XMLDocument && (svg === null || svg === void 0 ? void 0 : svg.querySelector('parsererror'));
                if (error) {
                    process.env.verbose && console.log(`${name}.svg is invalid: ${error.textContent}`);
                    return;
                }
                icon = svg === null || svg === void 0 ? void 0 : svg.querySelector('svg');
            }
            finally {
                this.items.set(name, icon);
                return icon;
            }
        });
    }
}

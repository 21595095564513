<template>
  <div :class="['box', {'custom-color': color}]">
    <div class="items-left">
      <h4 class="total-title">
        {{ totalTitle }}
      </h4>
      <div class="counters">
        <h1 class="big-count">
          {{ value.toLocaleString(locale) }}
        </h1>
      </div>
      <p class="small-text-left">
        {{ trendType }}
      </p>
    </div>
    <div class="items-right">
      <div class="dropdown-period" ref="trendwidget-dropdown">
        <p class="small-text-right" @click="isOpen = !isOpen">
          {{ selectedPeriodValue ? selectedPeriodValue.label : '' }}
          <XIcon
            name="chevron-down"
            color="#ffffff"
            size="xxsmall"
            :style="{transform: isOpen ? 'rotate(180deg)' : undefined}"
          />
        </p>
        <ol :class="['list', {open: isOpen}]">
          <li class="list-title">
            {{ listTitle }}
          </li>
          <li
            v-for="(period, index) in periodList"
            :key="index"
            @click="selectedPeriodValue = period"
            :class="[{selected: itemSelected(period)}]"
          >
            {{ period.label }}
          </li>
        </ol>
      </div>
      <div :class="['small-count-container', {negative: valueOverPeriod < 0}]">
        <h4 class="small-count">
          {{ Math.abs(valueOverPeriod) }}
        </h4>
        <div class="symbol"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'XTrendWidget',
  emits: ['update:modelValue'],
  props: {
    value: {
      type: Number,
      default: null,
    },
    valueOverPeriod: {
      type: Number,
      default: null,
    },
    selectedPeriod: {
      type: Object,
      default: null,
    },
    listTitle: {
      type: String,
      default: 'Filter on',
    },
    color: {
      type: String,
      default: undefined,
    },
    periodList: {
      type: Array,
      default() {
        return [
          {
            label: 'Vandaag',
            value: {
              startDate: 349857534,
              endDate: 893749587,
            },
          },
        ];
      },
    },
    totalTitle: {
      type: String,
      default: 'Total',
    },
    trendType: {
      type: String,
      default: null,
    },
    locale: {
      type: String,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    selectedPeriodValue: {
      get() {
        let selPeriod;
        selPeriod = this.periodList.find(period => {
          if (period && this.selectedPeriod) {
            return (
              period.value.startDate === this.selectedPeriod.startDate &&
              period.value.endDate === this.selectedPeriod.endDate
            );
          }
          return false;
        });

        if (!selPeriod && this.periodList) {
          selPeriod = this.periodList[0];
        }

        return selPeriod;
      },
      set({value}) {
        this.isOpen = false;
        this.$emit('update:modelValue', value);
      },
    },
    cssCustomColor() {
      return this.color ? this.color : 'transparent';
    },
  },
  watch: {
    isOpen(newVal) {
      if (newVal) {
        document.body.addEventListener('click', this.closeDropdown);
      } else {
        document.body.removeEventListener('click', this.closeDropdown);
      }
    },
  },
  beforeUnmount() {
    document.body.removeEventListener('click', this.closeDropdown);
  },
  methods: {
    closeDropdown(event) {
      if (event.target.contains(this.$refs['trendwidget-dropdown'])) {
        this.isOpen = false;
      }
    },
    itemSelected(period) {
      if (this.selectedPeriod && period) {
        return (
          this.selectedPeriod.endDate === period.value.endDate &&
          this.selectedPeriod.startDate === period.value.startDate
        );
      }

      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
@use '../../assets/styles/mixins.scss' as *;
@use '../../assets/styles/variables.scss' as *;
.box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: var(--radius-md);

  width: 100%;
  height: 9.375rem;
  padding: 1.1875rem 1.5rem 1.1875rem 2rem;
  box-sizing: border-box;

  color: #ffffff; // Always white
  background-color: var(--main-primary, #2783c6);

  font-family: $font-heading;

  * {
    box-sizing: border-box;
  }

  .items-left {
    display: flex;
    flex-direction: column;

    .small-text-left {
      padding: 0;
      margin: 0.2rem 0 0 0;
      color: #bedaee;
    }

    .counters {
      display: flex;
      flex-direction: row;

      .big-count {
        margin: 0.26rem 0 0 0;
        padding: 0;
        font-family: $font-heading;
        font-weight: $weight-semi-bold;
        font-size: var(--font-5xl);
        line-height: 2.75rem;
      }
    }

    .total-title {
      margin: 0.4rem 0 0 0;
      padding: 0;
      font-size: var(--font-2xs);
      text-transform: uppercase;
    }
  }

  .items-right {
    position: relative;

    .dropdown-period {
      position: relative;

      .small-text-right {
        margin: 0;
        padding: 0.3rem 0 0 0;

        line-height: $line-height-2xs;
        font-family: $font-heading;
        font-weight: $weight-light;
        font-size: var(--font-md);

        display: flex;
        align-items: center;
        column-gap: 0.2rem;

        cursor: pointer;
        border-radius: 5px;

        transition: border 0.2s ease-in-out;

        :deep(.icon) {
          @include XIcon-colors(#ffffff);
        }
      }

      .list {
        background: white;
        color: $light-text-color;
        margin: 0;
        padding: 0;
        position: absolute;
        right: -1rem;
        top: calc(#{$size-m} + 0.9rem);
        box-shadow: $dark-shadow-color;
        border: 1px solid lightgrey;
        border-radius: 5px;
        overflow: hidden;
        display: none;
        z-index: 10;

        &.open {
          display: block;
        }

        li {
          list-style: none;
          padding: 0.5rem;
          margin: 0;
          white-space: nowrap;
          font-size: var(--font-md);
          line-height: $size-m;
          cursor: pointer;
          color: #838384;

          &:not(.list-title):hover {
            background: #e7f5ff;
          }

          &:not(.list-title).selected {
            background: var(--main-primary, #2783c6);
            color: #ffffff; // Always white
          }

          &.list-title {
            cursor: default;
            font-size: var(--font-xs);
          }

          &:last-child {
            margin-bottom: 0.3rem;
          }
        }
      }
    }

    .small-count-container {
      right: 0;
      margin: 0;
      display: flex;

      flex-direction: row-reverse;
      align-items: center;
      column-gap: 0.5rem;

      .symbol {
        background: white;
        height: fit-content;
        border-radius: 50%;
        padding: 0.35rem;

        &::before {
          content: '';
          display: block;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-bottom: 7px solid #50b736;
        }
      }

      .small-count {
        font-family: $font-heading;
        font-weight: $weight-semi-bold;
        font-size: var(--font-3xl);
        line-height: 2.75rem;

        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 0.4rem;

        margin: 0;
        padding: 0;
      }

      &.negative {
        .symbol {
          &::before {
            border-bottom: 7px solid #c74343;
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  &.custom-color {
    background: v-bind('cssCustomColor');
  }
}
</style>

<docs>
  ```jsx
  let periodList = [
    {
      label: 'Vandaag',
      value: {
        startDate: 349857534,
        endDate: 893749587,
      }
    },
    {
      label: 'Deze week',
      value: {
        startDate: 12346568789,
        endDate: 12346568789,
      }
    },
    {
      label: 'Deze maand',
      value: {
        startDate: 123465,
        endDate: 123465,
      }
    },
  ];
  
  let selectedPeriod = {
    startDate: 349857534,
    endDate: 893749587,
  };
  
  <XTrendWidget
    :value="12345"
    :valueOverPeriod="-59"
    locale="nl-NL"
    trendType="Customers"
    :periodList="periodList"
    :selectedPeriod="selectedPeriod"
    @update:modelValue="selectedPeriod = $event"
  />
  ```
</docs>

<template>
  <component :is="type" class="breadcrumb">
    <XRouterLink
      :to="{
        name: breadcrumb.name,
        params: breadcrumb.params,
      }"
    >
      {{ breadcrumb.label }}
    </XRouterLink>
  </component>
</template>

<script>
/**
 *Breadcrumbs are small text paths indicating where the user is on the site.
 */
export default {
  name: 'XBreadcrumb',
  props: {
    type: {
      type: String,
      default: 'div',
    },
    breadcrumb: {
      type: Object,
      default: () => ({
        name: '/',
        params: undefined,
        label: 'Define name...',
      }),
    },
  },
};
</script>

<style lang="scss" scoped>
@use '../../assets/styles/variables.scss' as *;
@use '../../assets/styles/mixins.scss' as *;
.breadcrumb {
  @include reset;
  display: flex;
  align-items: center;
  font-size: var(--font-sm);

  a {
    text-decoration: none;
    color: var(--heading-primary, #083856);
  }
}
</style>

<docs>
  ```jsx
  <div>
    <XBreadcrumb>path-to-page</XBreadcrumb>
  </div>
  ```
</docs>

import { h, isVNode } from 'vue';
/** Utility class for more ergonomically creating complex VNode structures. Structures are created from top down */
export class NodeBuilder {
    constructor(root, content, parent) {
        this.root = root;
        this.node = content;
        this.parent = parent;
    }
    /** Add child node to the current node, return a builder containing the newly created node as content */
    child(type, propsOrChildren, children) {
        const node = NodeBuilder.createInternalNode(type, propsOrChildren, children);
        this.node.children.push(node);
        return new NodeBuilder(this.root, node, this.node);
    }
    /** Add the current content as a sibling to its parent, return a builder containing the  */
    sibling(type, propsOrChildren, children) {
        const node = NodeBuilder.createInternalNode(type, propsOrChildren, children);
        this.parent.children.push(node);
        return new NodeBuilder(this.root, node, this.node);
    }
    /** Append a CSS class to the current nodes props */
    appendClass(className) {
        if (this.node.props.class)
            this.node.props.class += ' ' + className;
        else
            this.node.props.class = className;
    }
    /** Merge the given attributes into the nodes properties, will overwrite existing properties */
    mergeAttributes(attributes) {
        this.node.props = Object.assign(Object.assign({}, this.node.props), attributes);
    }
    /** Replace the current item with a group */
    // group(type: NodeType, propsOrChildren?: PropsOrChildren): NodeBuilder {
    //
    // }
    /** Create a new vnode based on the root item */
    build() {
        // work upwards from bottom most node
        const isInternalNode = (child) => child._internalNodeMarker;
        const toVNode = (params) => {
            if (!params.children.length)
                return this.createVNode(params.type, params.props);
            const children = params.children.flatMap(child => (isInternalNode(child) ? toVNode(child) : child));
            // vnodify self
            const vNode = this.createVNode(params.type, params.props, 
            // children may be either vnodes etc., or internal content. When internal content, we'll need to vnodify them prior to being able to use them
            children);
            return vNode;
        };
        // return this.createVNode(this.root.type, this.root.props);
        return toVNode(this.root);
    }
    /** Create a new layout with the provided parameters as root node */
    static create(type, propsOrChildren = {}, children) {
        const root = this.createInternalNode(type, propsOrChildren, children);
        return new NodeBuilder(root, root);
    }
    /** Create a VNode with the given options */
    createVNode(type, props, children) {
        // component children need to be returned from a function for Vue not to whine
        // html tag children need to be passed in directly for Vue to properly render them
        // preserve undefined children
        const normalizedChildren = !children || typeof type === 'string' ? children : () => children;
        return h(type, props, normalizedChildren);
    }
    /** Create a normalized internal node for the given VNode parameters */
    static createInternalNode(type, propsOrChildren, children) {
        // normalize
        if (typeof propsOrChildren === 'string' || Array.isArray(propsOrChildren) || isVNode(propsOrChildren)) {
            children = propsOrChildren;
            propsOrChildren = {};
        }
        const normalizedProps = propsOrChildren;
        const normalizedChildren = [];
        if (children) {
            if (Array.isArray(children))
                normalizedChildren.push(...children);
            else
                normalizedChildren.push(children);
        }
        return {
            _internalNodeMarker: true,
            type: type,
            props: normalizedProps,
            children: normalizedChildren,
        };
    }
}

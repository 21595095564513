var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { unrefElement, useThrottledRefHistory } from '@vueuse/core';
import { useConfirmStore } from './../stores/confirmation';
import { nextTick, onUnmounted } from 'vue';
import { ref, computed, watch, provide, inject } from 'vue';
import { T } from './../helpers/translationsProvider';
import { useEditorStore } from './../stores/editor';
import anime from 'animejs';
import * as _ from 'lodash';
export const createEditor = (config) => {
    const disabled = ref(false);
    const confirmStore = useConfirmStore();
    const editorStore = useEditorStore();
    const state = config.ref || ref(config.defaultState);
    const { history, undo, redo, canRedo, canUndo, clear } = useThrottledRefHistory(state, {
        deep: true,
        clone: val => {
            //Make sure val is an object before deepcloning it
            if (_.isObject(val))
                return _.cloneDeep(val);
            return _.clone(val);
        },
        throttle: 3000,
    });
    const isDisabled = computed(() => {
        return (config.isDisabled != undefined ? config.isDisabled() : false) || isSaving.value || disabled.value;
    });
    const dirty = ref(typeof config.dirtyAtStart == 'boolean' ? config.dirtyAtStart : false);
    const reset = () => {
        state.value = config.defaultState;
        clear();
        nextTick(() => (dirty.value = false));
    };
    const isSaving = ref(false);
    const isDirty = computed(() => dirty.value);
    //@ts-ignore
    editorStore.registerEditor(isDirty);
    onUnmounted(() => {
        //@ts-ignore
        cleanup();
    });
    const cleanup = () => editorStore.unregisterEditor(isDirty);
    watch(state, (newState, oldState) => {
        if (oldState == undefined)
            return;
        dirty.value = true;
    }, { deep: true });
    function isPromise(func) {
        return 'then' in func;
    }
    const canSave = computed(() => {
        return (config.canSave ? config.canSave(state.value) : true) && dirty.value;
    });
    const save = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        //Dont save if the validator fails
        if (config.validator && !config.validator(state.value)) {
            const element = unrefElement(config.element);
            element &&
                anime({
                    targets: element,
                    translateX: [
                        { value: -5, duration: 50 },
                        { value: 5, duration: 50 },
                        { value: -5, duration: 50 },
                        { value: 5, duration: 50 },
                        { value: 0, duration: 50 },
                    ],
                    easing: 'easeInOutQuad', // You can change the easing function if needed
                });
            return false;
        }
        isSaving.value = true;
        if (isPromise(config.save)) {
            yield config.save(state.value).catch(() => {
                isSaving.value = false;
                return;
            });
        }
        else {
            config.save(state.value);
        }
        isSaving.value = false;
        dirty.value = false;
        if (!((_a = config.keepOpenAfterSaving) === null || _a === void 0 ? void 0 : _a.value) && config.close)
            close();
    });
    const close = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!config.close)
            throw 'No close functionality';
        if (dirty.value) {
            const confirmed = yield confirmStore.prompt(T('ROUTE_LEAVE_CONFIRMATION_TITLE'), T('ROUTE_LEAVE_CONFIRMATION_MESSAGE'), 'warning', {
                denyButton: confirmStore.defaultButton.cancel,
                confirmButton: Object.assign(Object.assign({}, confirmStore.defaultButton.close), { iconName: 'clear' }),
            });
            if (!confirmed)
                return;
        }
        cleanup();
        config.close();
    });
    const clearButtonProps = computed(() => {
        return { disabled: !dirty.value || isDisabled.value, onClick: reset };
    });
    const undoButtonProps = computed(() => {
        return { disabled: !canUndo.value || isDisabled.value, onClick: undo };
    });
    const redoButtonProps = computed(() => {
        return { disabled: !canRedo.value || isDisabled.value, onClick: redo };
    });
    const saveButtonProps = computed(() => {
        return { disabled: !canSave.value || isDisabled.value, onClick: save, color: 'success', icon: 'check-circle' };
    });
    const provided = {
        disabled,
        isDisabled,
        history,
        undo,
        redo,
        canRedo,
        canUndo,
        undoButtonProps,
        redoButtonProps,
        saveButtonProps,
        clearButtonProps,
        isDirty,
        save,
        state,
        close,
        canSave,
        reset,
    };
    provide('editor', provided);
    return provided;
};
export const injectEditor = () => inject('editor');

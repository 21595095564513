import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, mergeProps as _mergeProps, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, normalizeStyle as _normalizeStyle } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_XSideNavActiveBar = _resolveComponent("XSideNavActiveBar");
    const _component_XIcon = _resolveComponent("XIcon");
    const _component_XHeading = _resolveComponent("XHeading");
    const _component_XStack = _resolveComponent("XStack");
    const _component_XSideNavMenuItem = _resolveComponent("XSideNavMenuItem", true);
    const _component_NPopover = _resolveComponent("NPopover");
    const _component_XTooltipNaive = _resolveComponent("XTooltipNaive");
    return (_openBlock(), _createBlock(_component_XTooltipNaive, {
        showArrow: false,
        componentStyle: "black",
        placement: "right",
        trigger: "manual",
        show: $data.showPopover && !$props.children.length
    }, {
        content: _withCtx(() => [
            _createVNode(_component_XHeading, {
                color: "var(--sideBar-selectedItemColor, #FFFFFF)",
                style: _normalizeStyle($data.tooltipText)
            }, {
                default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t($props.item.displayNameResourceKey)), 1)
                ]),
                _: 1
            }, 8, ["style"])
        ]),
        default: _withCtx(() => [
            (_openBlock(), _createBlock(_resolveDynamicComponent($setup.renderedWrapper), null, {
                default: _withCtx(() => [
                    _createVNode(_component_NPopover, {
                        trigger: "hover",
                        disabled: !$setup.hasChildren,
                        placement: "right-end",
                        showArrow: false,
                        class: "submenu",
                        animated: false,
                        "theme-overrides": { padding: 'none', color: 'var(--sideBar-background)', space: 'none', borderRadius: 'none' }
                    }, {
                        trigger: _withCtx(() => [
                            _createVNode(_component_XStack, _mergeProps({
                                gap: $props.collapsedView ? undefined : 'sm',
                                alignment: $props.collapsedView ? 'center' : 'start',
                                crossAlignment: "center",
                                class: ['menu-item-container', { active: $props.active, alternativeHover: $props.alternativeHover }],
                                onMouseenter: _cache[0] || (_cache[0] = ($event) => ($props.collapsedView && ($data.showPopover = true))),
                                onMouseleave: _cache[1] || (_cache[1] = ($event) => ($props.collapsedView && ($data.showPopover = false))),
                                onClick: _cache[2] || (_cache[2] = ($event) => { var _a; return ((_a = $props.onClick) === null || _a === void 0 ? void 0 : _a.call($props, $event)); })
                            }, _ctx.$attrs), {
                                default: _withCtx(() => [
                                    ($props.active)
                                        ? (_openBlock(), _createBlock(_component_XSideNavActiveBar, { key: 0 }))
                                        : _createCommentVNode("", true),
                                    ($props.item.icon)
                                        ? (_openBlock(), _createBlock(_component_XIcon, {
                                            key: 1,
                                            name: $props.item.icon,
                                            color: "var(--sideBar-itemColor, #9CAFBB)"
                                        }, null, 8, ["name"]))
                                        : _createCommentVNode("", true),
                                    (!$props.collapsedView)
                                        ? (_openBlock(), _createBlock(_component_XHeading, {
                                            key: 2,
                                            color: "var(--sideBar-itemColor, #9CAFBB)"
                                        }, {
                                            default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(_ctx.$t($props.item.displayNameResourceKey)), 1)
                                            ]),
                                            _: 1
                                        }))
                                        : _createCommentVNode("", true),
                                    ($setup.hasChildren && !$props.collapsedView)
                                        ? (_openBlock(), _createBlock(_component_XIcon, {
                                            key: 3,
                                            size: "xxxxs",
                                            name: "chevron-right",
                                            color: "var(--sideBar-itemColor, #9CAFBB)",
                                            style: { "margin-left": "auto" }
                                        }))
                                        : _createCommentVNode("", true)
                                ]),
                                _: 1
                            }, 16, ["gap", "alignment", "class"])
                        ]),
                        default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.children, (child) => {
                                return (_openBlock(), _createBlock(_component_XSideNavMenuItem, _mergeProps({ ref_for: true }, Object.assign(Object.assign({}, child), { level: $props.level + 1 })), null, 16));
                            }), 256)),
                            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
                        ]),
                        _: 3
                    }, 8, ["disabled"])
                ]),
                _: 3
            }))
        ]),
        _: 3
    }, 8, ["show"]));
}

<template>
  <Component :is="rendererComponent" v-bind="$props" />
</template>

<script>
import {PieChartMixin} from './mixins';

import XApexPieChart from './XApexPieChart.vue';
import XAmPieChart from './AmCharts/XAmPieChart.vue';

const rendererLookup = {
  apex: XApexPieChart,
  amcharts: XAmPieChart,

  get: type => rendererLookup[type] ?? rendererLookup['apex'],
};

export default {
  name: 'XPieChart',
  mixins: [PieChartMixin],

  props: {
    renderer: {
      type: String,
      required: false,
      default: 'amcharts',
    },
  },

  computed: {
    rendererComponent() {
      return rendererLookup.get(this.renderer);
    },
  },
};
</script>

<template>
  <component :is="checkIfLink" :to="link" style="cursor: pointer">
    <div
      :class="['preview-container', highlighted && 'highlighted']"
      :style="onHover(hoverColor)"
      @click="$emit('onBoxClick', true)"
      @click.right="$resolveSlot($slots['menuItems']) && rightClickHandler($event)"
      @contextmenu.prevent
    >
      <div class="preview-wrapper">
        <img :src="imageUrl" alt="preview-image" class="preview-image" v-if="imageUrl" />
        <slot name="preview" v-if="!imageUrl" />
        <div class="preview-content-wrapper">
          <div class="top-content">
            <div class="left-content">
              <slot name="top-left" />
            </div>
            <div class="right-content">
              <slot name="top-right" />
              <XMenuBox
                ref="menuBox"
                :openMenu="openMenu"
                listTitle=""
                v-if="link && $resolveSlot($slots['menuItems'])"
                @menuClick="menuClickHandler($event)"
                @click.native="preventNonLinkNavigation($event)"
                @clickOutside="openMenu = false"
                @escapeClick="openMenu = $event"
              >
                <slot name="menuItems" />
                <div class="delete-item" v-if="deleteMenuItem" @click="$emit('delete', true)">
                  <XIcon name="delete" color="var(--button-danger, #D21460)" />
                  <XParagraph componentStyle="medium" color="var(--button-danger, #D21460)">
                    {{ $t('DELETE') }}
                  </XParagraph>
                </div>
              </XMenuBox>
              <XMenuBox
                ref="menuBox"
                :openMenu="openMenu"
                :menuPosition="JSON.parse(JSON.stringify(menuPosition))"
                listTitle=""
                v-if="!link && $resolveSlot($slots['menuItems'])"
                @menuClick="$emit('onMenuClick', $event), menuClickHandler($event)"
                @click.stop
                @clickOutside="openMenu = false"
                @escapeClick="openMenu = $event"
              >
                <slot name="menuItems" />
                <div class="delete-item" v-if="deleteMenuItem" @click="$emit('delete', true)">
                  <XIcon name="delete" color="var(--button-danger, #D21460)" />
                  <XParagraph componentStyle="medium" color="var(--button-danger, #D21460)">
                    {{ $t('DELETE') }}
                  </XParagraph>
                </div>
              </XMenuBox>
            </div>
          </div>
          <div class="bottom-content">
            <div class="left-content">
              <slot name="bottom-left" />
            </div>
            <div class="right-content">
              <slot name="bottom-right" />
            </div>
          </div>
        </div>
      </div>
      <div class="details">
        <slot name="details" />
      </div>
    </div>
  </component>
</template>

<script>
import resolveSlots from '@src/helpers/vue-slots';

export default {
  name: 'XPreviewBox',
  props: {
    imageUrl: {
      type: String,
      default: '',
    },
    link: {
      type: Object,
    },
    hoverColor: {
      type: String,
      default: 'var(--main-primary, #2783c6)',
    },
    deleteMenuItem: {
      type: Boolean,
      default: false,
    },
    highlighted: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['onBoxClick', 'onMouseRightClick', 'delete', 'onMenuClick'],
  data() {
    return {
      openMenu: false,
      menuPosition: null,
      rightClick: false,
    };
  },
  created() {
    this.$resolveSlot = resolveSlots;
  },
  computed: {
    checkIfLink() {
      return this.link ? 'XRouterLink' : 'div';
    },
  },
  methods: {
    menuClickHandler() {
      this.$emit('onMenuClick');
      document.body.addEventListener('click', this.clickOutside);
      document.body.addEventListener('contextmenu', this.clickOutside);
    },
    rightClickHandler(e) {
      this.$refs.menuBox.openContextMenu(e.clientY, e.clientX);
      document.body.addEventListener('contextmenu', this.clickOutside);
      document.body.addEventListener('click', this.clickOutside);
    },
    clickOutside(e) {
      if (!this.$el.contains(e.target)) {
        this.$refs.menuBox.closeMenuOnParentEvent();
      }
    },
    onHover(hoverColor) {
      return {
        '--hover-color': hoverColor,
      };
    },

    /** Prevent clicks on non-link context menu items from navigating */
    preventNonLinkNavigation(e) {
      if (!this.$refs.menuBox) {
        e.preventDefault();
        return;
      }

      // temporary workaround, a more permanent fix would be having a generic context menu which we could slap on our previewbox
      // said context menu would be rendered at location X, but outside of our previewbox (simply append to the body?)

      // only allow anchor tags explicitly part of our context menu
      const anchor = e.target.closest('a');
      if (anchor && !this.$refs.menuBox.$el.contains(anchor)) e.preventDefault();
    },
  },
  beforeUnmount() {
    document.body.removeEventListener('contextmenu', this.clickOutside);
    document.body.removeEventListener('click', this.clickOutside);
  },
};
</script>

<style lang="scss" scoped>
@use '../../assets/styles/mixins.scss' as *;
.preview-container {
  background: var(--main-background, #ffffff);
  height: 12.5rem;
  width: 12.5rem;
  padding: 0.5rem;
  box-sizing: border-box;
  border-radius: 0.75rem;
  display: inline-flex;
  flex-direction: column;
  row-gap: 0.5rem;
  box-shadow: inset 0 0 0 2px transparent;
  transition: box-shadow 0.25s ease;

  &.highlighted {
    box-shadow: inset 0 0 0 1px var(--hover-color);
  }

  &:hover {
    box-shadow: inset 0 0 0 2px var(--hover-color);
  }

  * {
    box-sizing: border-box;
  }

  .preview-wrapper {
    position: relative;
    height: 8.5rem;

    .preview-content-wrapper {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      padding: var(--spacing-xs);
      display: flex;
      flex-direction: column;
      gap: var(--spacing-xs);
      pointer-events: none; // prevent wrapper from blocking click events

      > * > * {
        pointer-events: initial; // but allow click events on child components
      }

      .top-content {
        display: flex;
        gap: var(--spacing-xs);

        .right-content {
          align-items: start;
        }
      }

      .bottom-content {
        display: flex;
        gap: var(--spacing-xs);
        margin-top: auto;
      }

      .left-content {
        display: flex;
        gap: var(--spacing-xs);
        flex-wrap: wrap;
      }

      .right-content {
        display: flex;
        gap: var(--spacing-xs);
        margin-left: auto;
        flex-wrap: wrap;
        justify-content: end;
        flex-wrap: wrap;
      }
    }

    .XMenuBox {
      background: var(--main-background, #ffffff);
      border-radius: 0.25rem;
      padding-top: var(--spacing-xs);
      padding-bottom: var(--spacing-xs);

      ::v-deep .menu-item {
        padding: 0.5rem 1rem;

        &:hover {
          background: var(--control-hoverBackground, #e7f5ff);
        }
      }

      .delete-item {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        column-gap: 0.5rem;
        padding: 0.5rem 1rem;
        border-top: 1px solid var(--main-border, #dde9f2);

        &:hover {
          background-color: var(--control-hoverBackground, #e7f5ff);
        }
      }

      ::v-deep .slot-content {
        padding: 0;

        // style menuitems
        > * {
          display: flex;
          align-items: center;
          column-gap: var(--spacing-xs);
        }
      }

      ::v-deep {
        > .icon {
          @include XIcon-colors(var(--main-border, #c9d3df));

          //Removed for the time being - potentially bugged in firefox 106, causes our top-right icon to get a width of 0
          //svg {
          //  width: fit-content;
          //}
        }

        .togglemenu {
          white-space: nowrap;
        }
      }
    }

    &::v-deep img {
      width: 100%;
      height: 100%;
      border-radius: 0.5rem;
    }
  }

  .details {
    padding: 0 calc(0.75rem - 6px);
  }
}
</style>

<docs>
```jsx

  <div style="display: flex; gap: 1rem;">
    <XPreviewBox
      imageUrl="https://www.tekstmaatje.nl/wp-content/uploads/2020/06/placeholder.png"
      hoverColor="var(--main-success)"
    >
      <template #details>
        Hello world
      </template>
    </XPreviewBox>

    <XPreviewBox imageUrl="https://www.tekstmaatje.nl/wp-content/uploads/2020/06/placeholder.png">
      <template #menuItems>
        Hello world 
      </template>
      <template #details>
        Hello world
      </template>
    </XPreviewBox>

    <XPreviewBox hoverColor="var(--main-warning)">
      <template #menuItems>
        Hello world
      </template>
      <template #preview>
        <img src="https://www.tekstmaatje.nl/wp-content/uploads/2020/06/placeholder.png" alt="" />
        
      </template>
      <template #top-left>
        <XIcon name="arrow-circle-down" size="medium" title="arrow-circle-down" :hoverEffect="true" background />
      </template>
      <template #details>
        <XParagraph componentStyle="medium" color="#000000">Hello world</XParagraph>
        <XParagraph componentStyle="small" color="#000000">Hello world</XParagraph>
      </template>
    </XPreviewBox>
    <XPreviewBox
      imageUrl="https://www.tekstmaatje.nl/wp-content/uploads/2020/06/placeholder.png"
      hoverColor="var(--main-success)"
    >
    <template #menuItems>
        Hello world
      </template>
      <template #top-left>
        <XIcon name="arrow-circle-down" size="medium" title="arrow-circle-down" :hoverEffect="true" background="#D21460" class="bottom-left" />
        <XIcon name="arrow-circle-down" size="medium" title="arrow-circle-down" :hoverEffect="true" background="#00B866" class="bottom-right" />
        <XIcon name="arrow-circle-down" size="medium" title="arrow-circle-down" :hoverEffect="true" background="#D21460" />
      </template>
      <template #top-right>
        <XIcon name="arrow-circle-down" size="medium" title="arrow-circle-down" :hoverEffect="true" background="#D21460" />
      </template>
      <template #bottom-left>
        <XIcon name="arrow-circle-down" size="medium" title="arrow-circle-down" :hoverEffect="true" background="#D21460" />
      </template>
      <template #bottom-right>
        <XIcon name="arrow-circle-down" size="medium" title="arrow-circle-down" :hoverEffect="true" background="#D21460" />
      </template>
      <template #details>
        <XParagraph componentStyle="medium" color="#000000"">Hello world</XParagraph>
        <XParagraph componentStyle="small" color="#000000">Hello world</XParagraph>
      </template>
    </XPreviewBox>
  </div>
  </div>

```
</docs>

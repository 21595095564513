import { ChartComponent } from './chartInstance';
import { Exporting } from '@amcharts/amcharts5/plugins/exporting';
import { categoryAxisSwitch, ensureCursor } from './util';
/** Provide bindings for toolbar initiated exports */
export class ToolbarExport extends ChartComponent {
    getTags() {
        return ['toolbar-action'];
    }
    constructor(settings) {
        super();
        this.toolbar = settings.toolbar;
        this.name = settings.name; // <-- should we pull this from our context?
    }
    render(context) {
        // https://www.amcharts.com/docs/v5/concepts/exporting/exporting-api/
        // https://www.amcharts.com/docs/v5/concepts/exporting/
        var _a;
        const name = `${(_a = this === null || this === void 0 ? void 0 : this.name) !== null && _a !== void 0 ? _a : 'download'}`;
        const exporter = Exporting.new(context.root, { title: name + '.png', filePrefix: name });
        this.toolbar.toggleFeature('png', true);
        this.toolbar.addActionListener('exportPNG', () => {
            exporter.download('png').catch(e => console.error('Export failed', e));
        });
    }
    dispose() {
        this.toolbar.toggleFeature('png', false);
        // fixme: should not be handled in the chart component itself, chart component should take a toolbar from context
        this.toolbar.clearActionListeners('export');
        this.toolbar.clearActionListeners('exportPNG');
    }
}
/** Provide bindings for toolbar initiated pan and zoom */
export class ToolbarZoom extends ChartComponent {
    getTags() {
        return ['toolbar-action'];
    }
    constructor(settings) {
        super();
        this.toolbar = settings.toolbar;
        // require at least an axis chart
        this.addDependency('categoryaxis');
    }
    render(context) {
        // notify toolbar of our activation
        this.toolbar.toggleFeature('pan', true);
        this.toolbar.toggleFeature('zoom', true);
        const chart = context.get('chart');
        const axis = context.get('categoryaxis');
        this.toolbar.addActionListener('zoom-in', () => {
            const start = axis.get('start', 0);
            const end = axis.get('end', 1);
            // lock zoom between 0.4 and 0.6
            zoomToRange(Math.min(start + 0.1, 0.4), Math.max(end - 0.1, 0.6));
        });
        this.toolbar.addActionListener('zoom-out', () => {
            const start = axis.get('start', 0);
            const end = axis.get('end', 1);
            // lock zoom between 0 and 1
            zoomToRange(Math.max(start - 0.1, 0), Math.min(end + 0.1, 1));
        });
        this.toolbar.addActionListener('reset', () => zoomToRange(0, 1));
        this.toolbar.addActionListener('pan', () => toggleCursorMode('pan'));
        this.toolbar.addActionListener('zoom-selection', () => toggleCursorMode('zoom'));
        // we handle zooming ourselves for a consistent experience
        chart.zoomOutButton.set('forceHidden', true);
        const cursor = ensureCursor(chart);
        cursor.set('behavior', 'none');
        const visibilityHelper = new CursorVisibilityHelper(cursor);
        let currentSelectionMode = 'none';
        // alter user cursor on hover when in pan mode?
        chart.adapters.add(categoryAxisSwitch(context, 'panX', 'panY'), () => currentSelectionMode === 'pan');
        cursor.adapters.add('behavior', () => currentSelectionMode === 'zoom' ? categoryAxisSwitch(context, 'zoomX', 'zoomY') : 'none');
        function toggleCursorMode(mode) {
            currentSelectionMode = currentSelectionMode === mode ? 'none' : mode;
            if (currentSelectionMode === 'zoom')
                visibilityHelper.switchToForcedVisibility();
            else
                visibilityHelper.switchToDefault();
        }
        function zoomToRange(start, end) {
            axis.animate({
                key: 'start',
                to: start,
                duration: 300,
            });
            axis.animate({
                key: 'end',
                to: end,
                duration: 300,
            });
        }
    }
    dispose() {
        this.toolbar.toggleFeature('pan', false);
        this.toolbar.toggleFeature('zoom', false);
        this.toolbar.clearActionListeners('pan');
        this.toolbar.clearActionListeners('zoom-in');
        this.toolbar.clearActionListeners('zoom-selection');
        this.toolbar.clearActionListeners('zoom-out');
    }
}
/**
 * Small helper for managing cursor visibility
 *
 * Can force cursor to be visible while retaining previous state as to be able to easily revert back to its original state
 */
class CursorVisibilityHelper {
    constructor(cursor) {
        this.isForcedVisible = false;
        this.cursor = cursor;
    }
    /** Force both the cursor X and Y lines to be visible */
    switchToForcedVisibility() {
        this.isForcedVisible = true;
        // temporarily disable adapters to read actual value, not something potentially affected by an adapter
        this.cursor.lineX.adapters.disable('visible');
        this.cursor.lineY.adapters.disable('visible');
        this.xVisible = this.cursor.lineX.get('visible');
        this.yVisible = this.cursor.lineY.get('visible');
        this.cursor.lineX.set('visible', true);
        this.cursor.lineY.set('visible', true);
        this.cursor.lineX.adapters.enable('visible');
        this.cursor.lineY.adapters.enable('visible');
    }
    /** Revert back to visibility settings prior to invocation of `switchToVisible` */
    switchToDefault() {
        if (!this.isForcedVisible)
            return;
        this.isForcedVisible = false;
        this.cursor.lineX.set('visible', this.xVisible);
        this.cursor.lineY.set('visible', this.yVisible);
    }
}

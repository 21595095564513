<template>
  <XBox :boxLine="false" style="margin-bottom: 1rem">
    <XHeading level="h3">
      <slot name="tableName" />
    </XHeading>
    <div class="tools-container">
      <slot name="tableAmount" />
      <slot name="tableSearch" />
      <slot name="tableAddRow" />
    </div>
    <div class="input-fields">
      <slot name="tableAddRowInputs" />
    </div>
  </XBox>
</template>

<script>
export default {
  name: 'XTableTools',
};
</script>

<style lang="scss" scoped>
.tools-container {
  display: flex;
  align-items: center;
  column-gap: 0.7rem;
  margin-top: 1rem;
}

.input-fields {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 0.7rem;
  margin-top: 1rem;
}
</style>

<docs>
  ```jsx
  <XTableTools>
    <template #tableName>
      Table naam
    </template>
    <template #tableAmount>
      <x-select :options="[{ value: 10, label: 10 },{ value: 15, label: 15 },{ value: 25, label: 25 },{ value: 50, label: 50 },{ value: 100000, label: 'Alles' },]" label="Aantal rijen" />
    </template>
    <template #tableSearch>
      <XInput placeholder="Search in table" id="input-1" />
    </template>
    <template #tableAddRow>
      <XButton size="medium">+ Add row</XButton>
    </template>
  </XTableTools>
  ```
</docs>

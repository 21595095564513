<template>
  <Component :is="rendererComponent" v-bind="$props" />
</template>

<script>
import {BubbleChartMixin} from './mixins';

import XApexBubbleChart from './XApexBubbleChart.vue';
import XAmBubbleChart from './AmCharts/XAmBubbleChart.vue';

const rendererLookup = {
  apex: XApexBubbleChart,
  amcharts: XAmBubbleChart,

  get: type => rendererLookup[type] ?? rendererLookup['apex'],
};

export default {
  name: 'XBubbleChart',
  mixins: [BubbleChartMixin],

  props: {
    renderer: {
      type: String,
      required: false,
      default: 'amcharts',
    },
  },

  computed: {
    rendererComponent() {
      return rendererLookup.get(this.renderer);
    },
  },
};
</script>

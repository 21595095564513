<template>
  <div class="wrapper">
    <XIcon name="no-data" size="medium" />
    <XParagraph>
      {{ placeholderText }}
    </XParagraph>
  </div>
</template>

<script>
export default {
  name: 'XNoData',
  props: {
    /**
     * to be used within a search as a placeholder for no results
     */
    noResults: {
      type: Boolean,
      default: false,
    },
    /**
     * to override the provided resources for the placeholder text with another string
     */
    emptyText: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    placeholderText() {
      return this.emptyText ?? (this.noResults ? this.$t('SEARCH_NO_RESULTS') : this.$t('NO_DATA'));
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 0.3rem;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .paragraph {
    font-weight: 600;
  }
}
</style>

<docs>
  ```jsx
  <div style="height: 50vh; background-color: #fff">
    <XNoData />
  </div>
  <br/>
  <div style="height: 50vh; background-color: #fff">
    <XNoData :noResults="true" />
  </div>
  ```
</docs>

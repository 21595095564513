<template>
  <div class="mainBox">
    <div class="titleBar" @click="toggle = !toggle" :class="{expand: toggle}">
      <div class="leftItems">
        <XIcon v-if="!!iconName" :name="iconName" :title="iconName" color="var(--icon-primary, #2783C6)" />
        <div v-if="!!amount" class="bubble">
          <p>
            {{ amount }}
          </p>
        </div>
        <XParagraph v-if="!!title" componentStyle="small" color="var(--text-secondary-500, #2783C6)">
          {{ title }}
        </XParagraph>
      </div>
      <div class="rightItems">
        <XIcon
          v-if="deleteIcon"
          name="delete"
          title="delete"
          size="small"
          color="var(--icon-primary, #2783C6)"
          @click="$emit('delete', true)"
        />
        <XIcon
          :name="toggle ? 'chevron-up' : 'chevron-down'"
          size="small"
          :title="toggle ? 'chevron-up' : 'chevron-down'"
          color="var(--icon-primary, #2783C6)"
          @click="setToggle(toggle ? true : false)"
        />
      </div>
    </div>
    <div class="contentBox" :class="{isClosed: toggle === false, initialized}">
      <Transition name="slide">
        <div v-if="toggle">
          <slot />
        </div>
      </Transition>
    </div>
  </div>
</template>

<script>
import dropdownMixin from '../Mixins/dropdown-mixin';

export default {
  name: 'JSettingsDropdown',
  emits: ['delete'],
  mixins: [dropdownMixin],
  props: {
    amount: {
      type: [String, Number],
    },
    iconName: {
      type: String,
    },
    title: {
      type: String,
      default: undefined,
    },
    deleteIcon: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.mainBox {
  display: flex;
  flex-direction: column;

  .titleBar {
    border-radius: 0.25rem;
    background: var(--container-secondaryBackground, #edf3f8);
    padding: 0.75rem 0.75rem 0.75rem 0.875rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    .leftItems {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      :deep(.paragraph) {
        font-weight: 500;
      }

      .bubble {
        background: var(--container-progressBar, #a8cde8);
        border-radius: 50%;
        height: 20px;
        width: 20px;

        p {
          display: flex;
          justify-content: center;
          font-weight: 500;
          font-size: var(--font-sm);
          color: #ffffff; // Always white
          margin-top: 1px;
        }
      }
    }

    .rightItems {
      display: flex;
      align-items: center;
      gap: 0.75rem;
      z-index: 10;

      .icon {
        cursor: pointer;
      }

      :deep(.icon.small:last-child svg) {
        width: 60%;
      }

      :deep(.icon.hex-color:last-child svg path) {
        stroke-width: 2.5px;
      }
    }
  }

  .expand {
    border-radius: 0.25rem 0.25rem 0 0;
  }

  .contentBox {
    border-radius: 0;
    max-height: fit-content;
    &.initialized {
      animation-duration: 1s;
    }

    animation: Slideout ease-in-out;
    animation-fill-mode: forwards;
  }

  @keyframes Slideout {
    from {
      margin-top: 2px;
      max-height: 0rem;
      overflow: hidden;
    }
    to {
      margin-top: 2px;
      max-height: 100vh;
      overflow: unset;
      border-radius: 0 0 0.25rem 0.25rem;
    }
  }

  .isClosed {
    max-height: 0rem;
    overflow: hidden;

    &.initialized {
      animation-duration: 0.3s;
    }

    animation: SlideIn ease-out;
  }

  @keyframes SlideIn {
    from {
      margin-top: 2px;
      max-height: 100vh;
    }
    to {
      margin-top: 2px;
      max-height: 0rem;
    }
  }

  .slide-enter-active,
  .slide-leave-active {
    transition: opacity 0.5s ease;
  }

  .slide-enter-from,
  .slide-leave-to {
    opacity: 0;
  }
}
</style>

<docs>
  ```jsx

  <JSettingsDropdown
    title="Test title"
    iconName="gear"
    amount="1"
    :deleteIcon="true"
  >
   <div style="display: flex; flex-direction: column; justify-content: center; padding: 1rem; gap: 1rem;"> 
      <XInput placeholder="User Input 1"/>
      <XInput placeholder="User Input 2"/>
      <XInput placeholder="User Input 3"/>
    </div>
  </JSettingsDropdown>

  <JSettingsDropdown
    title="Test title"
    iconName="gear"
    amount="1"
    :deleteIcon="true"
    :open="true"
  >
   <div style="display: flex; flex-direction: column; justify-content: center; padding: 1rem; gap: 1rem;"> 
      <XInput placeholder="User Input 1"/>
      <XInput placeholder="User Input 2"/>
      <XInput placeholder="User Input 3"/>
    </div>
  </JSettingsDropdown>
  ```
</docs>

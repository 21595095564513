<template>
  <div class="XTreeMenu">
    <div class="all-users">
      <div class="label-container">
        <XIcon name="customers" />
        <div class="label">
          {{ $t('AREA_MANAGER_ALL_USERS') }}
        </div>
      </div>
      <div class="count">
        {{ menuItems.totalUserCount }}
      </div>
    </div>
    <div class="items">
      <transition-group name="list" tag="div">
        <div
          :class="['XTreeMenuItem', {active: menuItem.id === activeItem}]"
          :style="{
            'padding-left': `${menuItem.level}rem`,
            width: `calc(100% - ${menuItem.level}rem)`,
          }"
          v-for="menuItem in flatMenuItems"
          :key="menuItem.id"
          @click="openArea(menuItem)"
        >
          <div class="information">
            <div class="name-wrapper">
              <XIcon
                :name="`chevron-${visibleItems.includes(menuItem.children[0].id) ? 'up' : 'down'}`"
                v-if="menuItem.children.length >= 1"
                @click.stop="openSubItems(menuItem)"
              />
              <XIcon name="user-scan" class="area" v-if="menuItem.children.length <= 0" />
              <div class="name">
                {{ menuItem.name }}
              </div>
            </div>
            <div class="user-count" v-if="menuItem.children.length >= 1">
              {{ `${menuItem.ownUserCount} / ${menuItem.totalUserCount}` }}
            </div>
            <div class="user-count" v-else>
              {{ menuItem.ownUserCount }}
            </div>
          </div>
        </div>
      </transition-group>
    </div>
  </div>
</template>

<script>
export default {
  name: 'XTreeMenu',
  emits: ['click'],
  props: {
    menuItems: {
      type: Object,
    },
  },
  data() {
    return {
      visibleItems: [],
      activeItem: '',
    };
  },
  computed: {
    flatMenuItems() {
      const indentationLevels = {
        [this.menuItems.parentId]: {
          level: -1,
        },
      };
      const flatMenu = [];
      const queuedItems = [this.menuItems];
      let tmpVisibleItems = [this.menuItems.id, ...this.visibleItems];

      while (queuedItems.length) {
        let tmpItem = queuedItems.shift();
        if (tmpVisibleItems.includes(tmpItem.id)) {
          flatMenu.push({
            ...tmpItem,
            level: indentationLevels[tmpItem.parentId].level + 1,
            visible: indentationLevels[tmpItem.parentId].level + 1 <= 0,
          });
          queuedItems.unshift(...tmpItem.children);
        }

        indentationLevels[tmpItem.id] = {
          level: indentationLevels[tmpItem.parentId].level + 1,
        };
      }

      return flatMenu;
    },
  },
  methods: {
    openSubItems(menuItem) {
      menuItem.children.forEach(({id}) => {
        if (this.visibleItems.includes(id)) {
          this.visibleItems.splice(this.visibleItems.indexOf(id), 1);
        } else {
          this.visibleItems.push(id);
        }
      });
    },
    openArea(menuItem) {
      this.activeItem = menuItem.id;
      this.$emit('click', menuItem);
    },
  },
};
</script>

<style lang="scss" scoped>
@use '../../assets/styles/mixins.scss' as *;
.XTreeMenu {
  width: 100%;

  .all-users {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 1rem;
    border-bottom: 1px solid var(--container-border, #e8e8e8);
    background: var(--control-background, #ffffff);

    .label-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;

      .label {
        color: var(--text-secondary, #083856);
        font-size: var(--font-md);
        line-height: 1rem;
      }
    }

    .count {
      color: var(--control-color, #313741);
      font-size: var(--font-md);
      line-height: 0.875rem;
    }
  }

  .items {
    position: relative;

    .XTreeMenuItem {
      height: 3.25rem;

      &.list-leave,
      &.list-enter-to {
        opacity: 1;
        transform: scale(1);
      }

      &.list-leave-to,
      &.list-enter {
        opacity: 0;
        transform: scale(0.6);
      }

      &.list-leave-active {
        position: absolute;
      }

      &.list-move,
      &.list-enter-active,
      &.list-leave-active {
        transition: all 0.25s ease-in-out;
      }

      .information {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0;
        gap: 0.5rem;
        height: 100%;

        .name-wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;

          .icon {
            padding: 1rem;

            &:not(.area) :deep(svg) {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 0.5rem;
              width: 0.5rem;
            }
          }

          .name {
            color: var(--control-color, #313741);
            font-size: var(--font-md);
            line-height: 1rem;
          }
        }

        .user-count {
          color: var(--control-color, #313741);
          font-size: var(--font-sm);
          line-height: 0.875rem;
          padding-right: 1rem;
        }
      }
    }
  }

  :nth-child(even).XTreeMenuItem {
    background: var(--control-background, #ffffff);
  }

  :nth-child(odd).XTreeMenuItem {
    background: var(--container-secondaryBackground-200, #fafbfc);
  }

  .XTreeMenuItem.active:not(:hover) {
    background: var(--control-selectedBackground, #2783c6);
    cursor: pointer;

    .information {
      .user-count {
        color: var(--control-selectedColor, #ffffff);
      }

      .name-wrapper {
        .name {
          color: var(--control-selectedColor, #ffffff);
        }

        .icon {
          @include XIcon-colors(#ffffff);
        }
      }
    }
  }

  .XTreeMenuItem:hover {
    background: var(--control-hoverBackground, #e7f5ff);
    cursor: pointer;
  }
}
</style>

<docs>
  ```jsx
    let apiResponse = {
      "id": "26a87f13-ab5f-447e-87bc-cbb5af338ef0",
      "parentId": "c298a0e7-6f52-41e0-a3fb-67e005b12d41",
      "name": "LeadQ",
      "description": "Master division",
      "enabled": true,
      "totalUserCount": 80,
      "ownUserCount": 39,
      "children": [
        {
          "id": "74bc4c8e-887b-4761-b4c9-020dff497715",
          "parentId": "26a87f13-ab5f-447e-87bc-cbb5af338ef0",
          "name": "Testje123",
          "description": null,
          "enabled": true,
          "totalUserCount": 80,
          "ownUserCount": 0,
          "children": [
            {
              "id": "74bc4c8e-887b-4761-b4c9-020df",
              "parentId": "74bc4c8e-887b-4761-b4c9-020dff497715",
              "name": "Testje123897",
              "description": null,
              "enabled": true,
              "totalUserCount": 80,
              "ownUserCount": 0,
              "children": []
            },
            {
              "id": "74bc4c8e-8-4761-b4c9-020df",
              "parentId": "74bc4c8e-887b-4761-b4c9-020dff497715",
              "name": "Testje123897",
              "description": null,
              "enabled": true,
              "totalUserCount": 80,
              "ownUserCount": 0,
              "children": []
            }
          ]
        },
        {
          "id": "74bc4c8e-887b-4761-b4c9-02497715",
          "parentId": "26a87f13-ab5f-447e-87bc-cbb5af338ef0",
          "name": "Testje123456",
          "description": null,
          "enabled": true,
          "totalUserCount": 80,
          "ownUserCount": 0,
          "children": []
        }
      ]
  };
<!--    Div for displaying purposes only -->
    <div style="width: 17.75rem;">
      <XTreeMenu :menuItems="apiResponse"></XTreeMenu>
    </div>
  ```
</docs>

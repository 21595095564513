import { useDelete } from '../composables/delete';
import { usePopupService } from '../stores/popup';
import { useProgressStore } from '../stores/progress';
import { h, resolveComponent } from 'vue';
import { T } from './translationsProvider';
export const createUploadProgressPopup = (id, target, position, alignment) => {
    const popupService = usePopupService();
    const progressService = useProgressStore();
    popupService.showAt({
        title: T('UPLOAD_PROGRESS_POPUP_TITLE'),
        foreground: false,
        closeIcon: 'minus',
        body: {
            render() {
                return h(resolveComponent('XUploadProgressPopup'), {
                    appName: id,
                    onRemoveCompletedTask: (val) => progressService.remove(val),
                    onCancelTask: (val) => {
                        const deletePrompt = useDelete({
                            promptTitle: T('UPLOAD_PROGRESS_POPUP_REMOVE'),
                            promptMessage: T('UPLOAD_PROGRESS_POPUP_CONFIRM_REMOVE'),
                            promptButtonText: T('DELETE'),
                            notificationSuccess: T('FILE_UPLOAD_IS_CANCELLED'),
                            delete: (id) => {
                                progressService.cancel(id);
                                progressService.remove(id);
                            },
                        });
                        deletePrompt(val);
                    },
                });
            },
        },
        target,
        position,
        alignment,
    });
};

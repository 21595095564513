<template>
  <div class="message-container" :style="{background: getColor(backgroundColor)}">
    <div class="message-content">
      <XIcon v-if="iconName" size="small" :name="iconName" :color="iconColor" />
      <slot />
    </div>
  </div>
</template>

<script>
import getColor from '../../helpers/getColor';

export default {
  name: 'XMessage',
  props: {
    iconName: {
      type: String,
    },
    iconColor: {
      type: String,
      default: '#fff',
    },
    backgroundColor: {
      type: String,
      default: '#075087',
    },
  },
  setup() {
    return {
      getColor,
    };
  },
};
</script>

<style lang="scss" scoped>
.message-container {
  height: 2.5rem;
  width: inherit;
  background-color: #075087;
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;

  .message-content {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
  }
}
</style>
<script>
export default {
  props: {
    composerDropdownMenuItems: {
      type: Array,
      default: () => [
        {name: 'Mixin-first', icon: 'journey', href: '#'},
        {name: 'Mixin-second', icon: 'check-circle', href: '', disabled: true},
        {name: 'Mixin-third', icon: 'edit', clickTarget: 'parent-bla'},
      ],
    },
  },
};
</script>

import { defineStore } from 'pinia';
/**
 * Container for tooltip state (x, y, shown, content)
 */
const useTooltip = defineStore('tooltip', {
    // copy of app-full -> help TS understand our structure
    state: () => ({
        x: 0,
        y: 0,
        body: '',
        show: false,
    })
});
export { useTooltip };

import resolveSlot from '../../helpers/vue-slots';

export default {
  props: {
    toggleOnHeaderClick: {
      type: Boolean,
      default: false,
    },
    open: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['toggle'],
  data() {
    return {
      toggle: this.boxDropdown ? false : true,
      initialized: false,
    };
  },
  computed: {
    hasContent() {
      return resolveSlot(this.$slots.default);
    },
    cursorType() {
      return this.hasContent && this.boxDropdown ? {cursor: 'pointer'} : {cursor: 'default'};
    },
  },
  created() {
    setTimeout(() => {
      this.initialized = true;
    }, 300);
  },
  watch: {
    toggle(newValue) {
      this.$emit('toggle', newValue);
    },
    open(newValue) {
      if (newValue !== undefined) this.toggle = newValue;
    },
  },
  methods: {
    setToggle(toggle) {
      //If there is no content, ignore.
      if (!this.hasContent) return;

      //Set toggle.
      this.toggle = toggle;
    },
    toggleFromHeader() {
      //If there is no content, or toggle from header isn't enagbled, ignore.
      if (!this.hasContent || !this.toggleOnHeaderClick) return;

      //Set toggle.
      this.setToggle(!this.toggle);
    },
  },
  mounted() {
    if (this.open !== undefined) {
      this.$nextTick(() => {
        this.setToggle(this.open);
      });
    }
  },
};

<template>
  <div>
    <div class="input-container">
      <XInput :placeholder="label" :customWidth="customWidth" v-model="color" />
      <div class="input">
        <div class="overflow-box" :style="[color ? {background: color} : {background: '#000000'}]" />
        <input
          :id="id"
          ref="input"
          type="color"
          v-model="color"
          :style="[color ? {background: color} : {background: '#000000'}]"
        />
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'XColorPicker',
  emits: ['change'],
  props: {
    modelValue: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    customWidth: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      id: null,
    };
  },
  mounted() {
    this.id = _.uniqueId();
  },
  computed: {
    color: {
      get() {
        return this.modelValue;
      },
      set(value) {
        value = value[0] === '#' ? value : `#${value}`;
        const regex = /^#[A-Fa-f0-9]{6}$/;

        if (!regex.test(value)) {
          return;
        }

        this.$emit('change', value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.input-container {
  display: flex;
  flex-direction: row;
  align-items: center;

  .input {
    z-index: 4;
    display: flex;
  }
  input {
    opacity: 0;
    cursor: pointer;
    margin-left: -1.75rem;
    border-radius: var(--radius-xs);
    width: 1.5rem;
    height: 1.625rem;
  }

  .overflow-box {
    border-radius: var(--radius-xs);
    margin-left: -1.75rem;
    width: 1.5rem;
    height: 1.625rem;
    outline: 1px solid var(--control-border, #c9d3df);
  }
}
</style>

<docs>
  ```jsx
  <XColorPicker 
    label="Color" 
    modelValue="#ff0000"
    customWidth="12rem" />
  ```
</docs>

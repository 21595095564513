import { ChartComponent } from "./chartInstance";
import { WordCloud as AmWordCloud } from "@amcharts/amcharts5/wc";
/** Simple WordCloud chart/series */
export class WordCloud extends ChartComponent {
    getTags() {
        return ['chart', 'wordcloud', 'series'];
    }
    constructor(settings) {
        var _a;
        super();
        this.data = (_a = settings.data) !== null && _a !== void 0 ? _a : [];
        // when dealing with large datasets, take the first 1500 records sorted by weight
        if (this.data.length > 1500) {
            this.data = this.data
                .slice()
                .sort((left, right) => {
                if (left.value === right.value)
                    return 0;
                return left.value > right.value ? 1 : -1;
            })
                .splice(0, 15);
        }
    }
    setup(context) {
        var _a;
        super.setup(context);
        const allSeries = (_a = context.get('seriesComponents')) !== null && _a !== void 0 ? _a : context.setGlobal('seriesComponents', []);
        allSeries.push(this);
    }
    render(context) {
        const chart = context.root.container.children.push(AmWordCloud.new(context.root, {
            // see: WordCloudData
            categoryField: 'name',
            valueField: 'value',
            // don't animate the individual words popping in
            stateAnimationDuration: 0,
        }));
        // match echarts style
        chart.labels.template.setAll({
            fontFamily: 'Barlow',
            fontWeight: '500'
        });
        chart.data.setAll(this.data);
        // The borders between the 'series' and 'chart' concepts are vague for wordclouds, expose ourself as both
        context.set('chart', chart);
        context.set('series', chart);
    }
}

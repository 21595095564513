import { ClockHand } from "./clockhand";
import { useChartComponent } from "./composables";
export default {
    name: 'XChartClockHand',
    props: {
        value: {
            value: Number,
            required: true
        },
        color: {
            value: String,
            default: '#000000'
        }
    },
    setup(props, ctx) {
        useChartComponent(() => new ClockHand({ value: props.value, color: props.color }));
    },
    render() { }
};

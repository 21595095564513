import { ChartComponent } from './chartInstance';
import { PieChart } from '@amcharts/amcharts5/percent';
import * as am from '@amcharts/amcharts5';
export class PieChartComponent extends ChartComponent {
    getTags() {
        return ['chart', 'piechart'];
    }
    constructor(settings) {
        var _a;
        super();
        this.categoryType = (_a = settings.categoryType) !== null && _a !== void 0 ? _a : 'category';
    }
    setup(context) {
        super.setup(context);
        context.setGlobal('categorytype', this.categoryType);
    }
    render(context) {
        const chart = context.root.container.children.push(PieChart.new(context.root, {
            layout: context.root.verticalLayout,
            innerRadius: am.percent(66),
            // should provide ample radius for expanding slices without falling outside our container
            paddingTop: 15,
        }));
        context.set('chart', chart);
        // should this be part of our chart?
        chart.appear(1000, 100);
    }
}

import { DateTime } from 'luxon';
import * as util from './util';
import { Time } from './datatypes';
import { watch } from 'vue';
/**
 * Determine a sorting strategy usable with Array.prototype.sort based on the given columnDefinition.type or type of the sampled data
 *
 * @param type Datatype for which to retrieve sorter
 * @return {(function(any, any): number)} Function determining sort weight, compatible with Array.prototype.sort
 */
function getSortingStrategy(type) {
    switch (type) {
        case 'number':
            return numericSortingStrategy;
        case 'datetime':
            return dateSortingStrategy;
        case 'time':
            return timeSortingStrategy;
        default:
            return defaultSortingStrategy;
    }
}
function numericSortingStrategy(left, right) {
    //For an initial implementation, assume the data may be stringified, check in every invocation
    //at a later stage we can make a string type check + parse optional
    //we use parseFloat due to it being compatible with both regular integers and floating point numbers
    left = util.parseFloat(left);
    right = util.parseFloat(right);
    if (!util.isFinite(left))
        left = Number.MIN_VALUE;
    if (!util.isFinite(right))
        right = Number.MIN_VALUE;
    return defaultSortingStrategy(left, right);
}
function dateSortingStrategy(left, right) {
    left = DateTime.fromISO(left);
    right = DateTime.fromISO(right);
    //One of our two dates is invalid, simply sort by validness as no date comparison is possible
    if (!left.isValid || !right.isValid)
        return defaultSortingStrategy(left.isValid, right.isValid);
    //While Luxon contains built in comparison, equality is something we'll still have to do manually
    if (left.equals(right))
        return 0;
    return defaultSortingStrategy(left, right);
}
function timeSortingStrategy(left, right) {
    var _a, _b;
    left = (_a = Time.parse(left)) === null || _a === void 0 ? void 0 : _a.toMilliseconds();
    right = (_b = Time.parse(right)) === null || _b === void 0 ? void 0 : _b.toMilliseconds();
    return numericSortingStrategy(left, right);
}
function defaultSortingStrategy(left, right) {
    //Convert nulls and undefined values to undefined
    //null > 'string' and null < 'string' both result in a false, weird.
    left = left !== null && left !== void 0 ? left : '';
    right = right !== null && right !== void 0 ? right : '';
    if (left === right)
        return 0;
    return left > right ? 1 : -1;
}
function useSorting(props, getColumnType) {
    const sorters = new Map();
    function updateSorters() {
        var _a;
        const columns = (_a = props.columns) !== null && _a !== void 0 ? _a : [];
        sorters.clear();
        for (const column of columns) {
            const dataType = getColumnType(column.propertyName);
            sorters.set(util.keyOf(column), getSortingStrategy(dataType));
        }
    }
    function getSorter(column) {
        return (left, right) => sorters.get(util.keyOf(column))(left[util.keyOf(column)], right[util.keyOf(column)]);
    }
    // upon dataset/column updates, refresh our sorters
    // eg. table is rendered without data -> update after we receive our data
    watch(() => [props.data, props.columns], () => updateSorters(), { immediate: true });
    return {
        getSorter,
    };
}
export { useSorting };

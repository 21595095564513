<template>
  <component
    :is="type"
    :class="['main-box', {active: active, 'is-open': open}]"
    @click="(open = !open), $emit('toggle', open)"
  >
    <div class="time-line">
      <div class="line" />
      <XIcon :name="icon" size="medium" :color="iconColor" />
    </div>
    <div class="content">
      <div class="global-info">
        <div class="activity">
          <XParagraph class="title" size="lg" color="var(--text-secondary)">
            {{ title }}
          </XParagraph>
          <XParagraph class="time" componentStyle="medium" color="var(--text-primary)">
            {{ date }}
          </XParagraph>
          <XParagraph v-if="createdBy" class="time" componentStyle="medium" color="var(--text-primary)">
            {{ createdBy }}
          </XParagraph>
        </div>
        <XParagraph class="instruction" componentStyle="medium" color="var(--text-primary)">
          {{ description }}
        </XParagraph>
      </div>
      <div class="details">
        <slot />
      </div>
    </div>
    <div class="edit" v-if="editable">
      <XIcon name="edit-square" :hover-effect="true" @click.stop="$emit('edit')" />
    </div>
    <div class="chevron">
      <XIcon :name="!open ? 'chevron-down' : 'chevron-up'" v-if="!open || open" :hover-effect="true" />
    </div>
  </component>
</template>

<script>
export default {
  name: 'XTimelineBox',
  props: {
    type: {
      type: String,
      default: 'div',
    },
    icon: {
      type: String,
      default: 'dummy',
    },
    iconColor: {
      type: String,
      default: undefined,
    },
    title: {
      type: String,
      default: '',
    },
    createdBy: {
      type: String,
      default: '',
    },
    date: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    active: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['toggle', 'edit'],
  data() {
    return {
      open: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.main-box {
  background: var(--main-background, #ffffff);
  min-height: 4.5rem;
  max-height: 4.5rem;
  border-radius: 0.5rem;
  display: flex;
  position: relative;
  width: 100%;

  &.is-open {
    max-height: fit-content;
    min-height: fit-content;

    .details {
      padding-bottom: 1rem;
    }
  }

  .time-line {
    position: relative;
    min-width: 4rem;
    max-width: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      position: absolute;
      height: 4.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0;

      :deep(svg) {
        background: var(--main-background, #ffffff);
      }
    }

    .line {
      display: block;
      height: 100%;
      width: 1px;
      background: var(--main-border, #c9d3df);
    }
  }

  .content {
    flex-grow: 1;
    overflow: hidden;

    .global-info {
      display: flex;
      align-items: baseline;
      column-gap: 2rem;
      margin: 0.5rem 0;
      height: calc(4.5rem - 1rem);
      overflow: hidden;

      .activity {
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 100%;
        min-width: 10rem;

        * {
          white-space: nowrap;
          margin: 0;
        }

        .title {
          font-size: var(--font-h4);
          line-height: 1.25rem;
          margin: 0;
        }

        .time {
          font-size: var(--font-sm);
          line-height: 0.875rem;
        }
      }

      .instruction {
        font-size: var(--font-sm);
        line-height: 0.875rem;
        height: calc(0.875rem * 2);
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }

  .chevron {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4.5rem;
    margin: 0 0.75rem;

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;

      :deep(svg) {
        height: 0.75rem;
        width: 0.75rem;
      }
    }
  }

  .edit {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4.5rem;
    margin-left: 0.75rem;

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &:hover {
    transition: background-color 0.2s ease-in-out;
    background: var(--control-hoverBackground, #e7f5ff);

    .time-line .icon:deep(svg) {
      transition: background-color 0.2s ease-in-out;
      background: var(--control-hoverBackground, #e7f5ff);
    }
  }

  &.active {
    background: var(--container-background, #dde9f2);

    .time-line .icon:deep(svg) {
      background: var(--container-background, #dde9f2);
    }
  }
}
</style>

<docs>
  ```jsx
  <div style="width: 480px;">
    <XTimelineBox icon="dummy" title="Klant bezoek" date="5 December 2021" description="Was gezellig in de winkel">
      Klant heeft 10 items gekocht :)
    </XTimelineBox>
    <XTimelineBox icon="dummy" iconColor="red" title="Klant bezoek" date="5 December 2021" description="Was gezellig in de winkel" :active="true">
      Klant heeft 10 items gekocht :)
    </XTimelineBox>
    <XTimelineBox icon="dummy" iconColor="orange" title="Klant bezoek" date="5 December 2021" description="Was gezellig in de winkel">
      Klant heeft 10 items gekocht :)
    </XTimelineBox>
    <XTimelineBox createdBy="Name Lastname" icon="dummy" iconColor="green" @edit="alert('edit')" :editable="true" title="Klant bezoek" date="5 December 2021" description="Was gezellig in de winkel">
      Klant heeft 10 items gekocht :)
    </XTimelineBox>
  </div>
  ```
</docs>

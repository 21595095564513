import {cleanKey, mapReplacer, mapReviver, isEmpty} from './util';

const cacheKey = 'DNX_CACHE';

/** A session storage based cache */
class Cache {
  constructor() {
    this.items = new Map();
  }

  save() {
    if (sessionStorage) {
      sessionStorage.setItem(cacheKey, JSON.stringify(this.items, mapReplacer));
    }
  }

  destroy() {
    this.items = new Map();
    this.save();
  }

  has(key) {
    return this.items.has(cleanKey(key));
  }

  async load() {
    const blob = sessionStorage?.getItem(cacheKey);
    if (blob) {
      const data = JSON.parse(blob, mapReviver);
      for (const [k, v] of data.entries()) {
        if (k && v) this.items.set(k, v);
      }
    }
  }

  async delete(key) {
    this.items.delete(cleanKey(key));
  }

  /** update item in cache. Returns data if it was actually updated. */
  async update(key, data) {
    key = cleanKey(key);
    const current = this.items.get(key);
    this.items.set(key, data);
    return isEmpty(current) || current !== data ? data : undefined;
  }

  async getFromApi(key, api, ...args) {
    if (this.items.has(cleanKey(key))) {
      return this.items.get(cleanKey(key));
    }

    const [method] = key.split('.').reverse();

    try {
      if (api.paths[method]) {
        const response = await api[method](...(args ?? []));
        if (response.success) {
          const data = response.value;
          this.items.set(cleanKey(key), data);
          return data;
        }
      }
    } catch (e) {
      console.error(`Api error(${key})`, e);
    }

    return null;
  }
}

const cache = new Cache();
cache.load();
export {cache as default};

<template>
  <div class="gaugechart-container" ref="gauge" />
</template>

<script>
import * as echarts from 'echarts';
import { GaugeChartMixin } from './mixins';

const cssVariableColors = getComputedStyle(document.documentElement);

export default {
  name: 'XEchartsGaugeChart',
  mixins: [GaugeChartMixin],

  mounted() {
    this.myChart = echarts.init(this.$el);

    // Resizes the graph based on the window width
    window.addEventListener('resize', this.handler);
    this.setGaugeConfig();

    this.myChart.resize({
      width: this.$refs.gauge.clientWidth + 75,
      height: this.$refs.gauge.clientHeight + 75,
    });
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handler);
    this.myChart.dispose();
  },
  watch: {
    gaugeConfig: {
      deep: true,
      handler() {
        this.setGaugeConfig();
      },
    },
    dataLabels() {
      this.setGaugeConfig();
    },
    series: {
      deep: true,
      handler() {
        this.setGaugeConfig();
      },
    },
    dataUpdate: {
      deep: true,
      handler() {
        this.setGaugeConfig();
      },
    },
  },
  methods: {
    handler() {
      this.myChart.resize({
        width: this.$refs.gauge.clientWidth + 75,
        height: this.$refs.gauge.clientHeight + 75,
      });
    },
    // Sort color values to be ordered from 0 to 1 depending on their value
    sortColors(colors) {
      return colors.sort();
    },
    setGaugeConfig() {
      // Define pointcolor to be updated based on value
      let pointcolor = '#0090d1';

      const currentVal = this.dataUpdate?.data.length > 0 ? this.dataUpdate.data[0].value : this.series.data[0].value;

      const gaugeColors = [
        {
          value: this.gaugeConfig.green,
          color: '#17d71f',
        },
        {
          value: this.gaugeConfig.orange,
          color: '#f08f00',
        },
        {
          value: this.gaugeConfig.red,
          color: '#f0454c',
        },
      ];

      gaugeColors.sort((left, right) => {
        if (left.value > right.value) {
          return 1;
        }
        if (right.value > left.value) {
          return -1;
        }
        return 0;
      });

      // Set default gaugeColor
      pointcolor = gaugeColors[0].color;

      for (let i = 1; i < gaugeColors.length; i++) {
        if (currentVal >= gaugeColors[i - 1].value && currentVal <= gaugeColors[i].value) {
          pointcolor = gaugeColors[i].color;
        }
      }

      // If currentVal is greater than the biggest color
      if (currentVal > gaugeColors[gaugeColors.length - 1].value) {
        pointcolor = gaugeColors[gaugeColors.length - 1].color;
      }

      // Use configuration item and data specified to show chart
      this.myChart.setOption({
        color: '#00000030',
        tooltip: {
          formatter: '{b} : {c}',
        },
        stateAnimation: {
          duration: 350,
        },
        animationDuration: 1300,
        series: [
          {
            type: 'gauge',
            progress: {
              show: true,
              roundCap: true,
              width: 5,
            },
            pointer: {
              icon: 'path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z',
              length: '89%',
              width: 10,
              offsetCenter: [0, '5%'],
              itemStyle: {
                color: pointcolor,
              },
            },
            axisLine: {
              roundCap: true,
              lineStyle: {
                width: 11,
                color: this.sortColors([
                  [
                    (this.gaugeConfig.green - this.gaugeConfig.min) / (this.gaugeConfig.max - this.gaugeConfig.min),
                    '#17d71f',
                  ],
                  [
                    (this.gaugeConfig.orange - this.gaugeConfig.min) / (this.gaugeConfig.max - this.gaugeConfig.min),
                    '#f08f00',
                  ],
                  [
                    (this.gaugeConfig.red - this.gaugeConfig.min) / (this.gaugeConfig.max - this.gaugeConfig.min),
                    '#f0454c',
                  ],
                ]),
              },
            },
            axisTick: {
              distance: 4,
              splitNumber: 5,
              lineStyle: {
                width: 1,
                color: cssVariableColors.getPropertyValue('--widget-border'),
              },
              show: this.dataLabels,
            },
            splitLine: {
              distance: 4,
              length: 13,
              lineStyle: {
                width: 1.3,
                color: cssVariableColors.getPropertyValue('--widget-border'),
              },
              show: this.dataLabels,
            },
            axisLabel: {
              distance: 16,
              color: cssVariableColors.getPropertyValue('--text-primary-500'),
              fontSize: 10,
              show: this.dataLabels,
              formatter(value) {
                if (value >= 1000) {
                  if (value > 100000 && value < 1000000) {
                    return `${Math.round(value / 100000) * 100}K`;
                  }
                  if (value >= 1000000) {
                    return `${Math.round((value / 1000000) * 100) / 100}M`;
                  } else {
                    return `${Math.round((value / 1000) * 100) / 100}K`;
                  }
                } else {
                  return value;
                }
              },
            },
            min: this.gaugeConfig.min,
            max: this.gaugeConfig.max,
            title: {
              color: cssVariableColors.getPropertyValue('--text-primary'),
              fontSize: 11,
              offsetCenter: [0, '45%'],
            },
            detail: {
              fontSize: 25,
              fontWeight: 500,
              valueAnimation: true,
              offsetCenter: [0, '70%'],
              formatter: value => {
                return value.toLocaleString(this.locale);
              },
              color: pointcolor,
            },
            data: this.dataUpdate?.data.length > 0 ? this.dataUpdate.data : this.series.data,
          },
        ],
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.gaugechart-container {
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;

  // We have to override its styling
  > :deep(div) {
    transform: scale(1.2);
    position: inherit !important;
    width: auto !important;
    height: auto !important;
  }

  :deep(canvas) {
    position: inherit !important;
    margin-top: 0.65rem !important;
    width: 100% !important;
    height: 100% !important;
  }
}
</style>

<docs>
  ```jsx
  <div style="width: 250px; height: 250px;">
    <XGaugeChart
      :dataLabels="true"
      locale="nl-NL"
      :gaugeConfig="{
        min: 0,
        max: 110,
        green: 30,
        orange: 50,
        red: 120
      }"
      :series="{
        data: [
          {
            name: 'Current',
            value: 20,
          }
        ],
      }"
    />
  </div>
    ```
</docs>

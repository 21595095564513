﻿<template>
  <div class="wordcloud-container" ref="wordCloud" />
</template>

<script>
import * as echarts from 'echarts';
import 'echarts-wordcloud';
import circleImage from '../../../assets/images/circle.png';
import { WordCloudChartMixin } from './mixins';

export default {
  name: 'XEchartsWordCloudChart',
  mixins: [WordCloudChartMixin],
  
  mounted() {
    this.myChart = echarts.init(this.$el);

    this.resizeObserver = new ResizeObserver(entries => {
      this.handler();
    });

    this.resizeObserver.observe(this.$refs.wordCloud);

    this.handler();
  },
  beforeUnmount() {
    this.myChart.dispose();
    this.resizeObserver?.disconnect();
  },
  data() {
    return {
      resizeObserver: undefined,
    };
  },
  watch: {
    series: {
      deep: true,
      handler() {
        this.handler();
      },
    },
    theme() {
      this.handler();
    },
  },
  methods: {
    handler() {
      const wordCloudElement = this.$refs.wordCloud;

      if (!wordCloudElement) return;

      const graphContainer = wordCloudElement.parentElement;
      const canvas = wordCloudElement.children[0];

      if (!canvas) return;

      this.setWordCloudConfig();

      this.myChart.resize({
        width: graphContainer.clientWidth,
        height: graphContainer.clientHeight,
      });
    },
    setWordCloudConfig() {
      let maskImage = new Image();

      const option = {
        tooltip: {
          formatter: '{b} : {c}',
        },
        series: [
          {
            type: 'wordCloud',

            // The shape of the "cloud" to draw. Can be any polar equation represented as a
            // callback function, or a keyword present. Available presents are circle (default),
            // cardioid (apple or heart shape curve, the most known polar equation), diamond (
            // alias of square), triangle-forward, triangle, (alias of triangle-upright, pentagon, and star.
            shape: 'circle',

            // Keep aspect ratio of maskImage or 1:1 for shapes
            // This option is supported since echarts-wordcloud@2.1.0
            keepAspect: false,

            // A silhouette image which the white area will be excluded from drawing texts.
            // The shape option will continue to apply as the shape of the cloud to grow.
            maskImage: maskImage,

            // Folllowing left/top/width/height/right/bottom are used for positioning the word cloud
            // Default to be put in the center and has 75% x 80% size.
            left: 'center',
            top: 'center',
            width: '75%',
            height: '80%',
            right: null,
            bottom: null,

            // Text size range which the value in data will be mapped to.
            // Default to have minimum 12px and maximum 60px size.
            sizeRange: [16, 55],

            // Text rotation range and step in degree. Text will be rotated randomly in range [-90, 90] by rotationStep 45
            rotationRange: this.rotationRange,
            rotationStep: 45,

            // size of the grid in pixels for marking the availability of the canvas
            // the larger the grid size, the bigger the gap between words.
            gridSize: 12,

            // set to true to allow word to be drawn partly outside of the canvas.
            // Allow word bigger than the size of the canvas to be drawn
            // This option is supported since echarts-wordcloud@2.1.0
            drawOutOfBound: false,

            // if the font size is too large for the text to be displayed,
            // whether to shrink the text. If it is set to false, the text will
            // not be rendered. If it is set to true, the text will be shrinked.
            // This option is supported since echarts-wordcloud@2.1.0
            shrinkToFit: true,

            // If perform layout animation.
            // NOTE disable it will lead to UI blocking when there is lots of words.
            layoutAnimation: true,

            // Global text style
            textStyle: {
              fontFamily: 'Barlow',
              fontWeight: '500',
              color: () => {
                return this.theme[Math.round(Math.random() * (this.theme.length - 1))];
              },
            },
            emphasis: {
              focus: 'self',
              textStyle: {
                textShadowBlur: 1,
                textShadowColor: '#999',
              },
            },
            data: this.series.data,
          },
        ],
      };

      maskImage.onload = () => {
        this.myChart.setOption(option);
      };
      maskImage.src = circleImage;
    },
  },
}

</script>

<style scoped>
.wordcloud-container {
  overflow: hidden;
  width: 750px;
  height: 650px;
  margin-left: -0.5rem;
}
</style>

import { cleanKey } from '../helpers/util';
import { T } from '../helpers/translationsProvider';
import { $can } from '../utils/dnxacl';
// do we even need a store for this? this seems like the task of a registry
/** Generate a default configuration for the given module */
function defaultModuleConfiguration(module) {
    const topBarButtons = [];
    if ($can.create[module]) {
        topBarButtons.push({
            name: `create-${module}`,
            label: T(`${module.toUpperCase()}_OVERVIEW_CREATE`),
            link: `/${module}/create/`,
        });
    }
    return {
        module: module,
        color: `var(--module-${module})`,
        badge: { name: module, color: `var(--module-${module}, #2783c6)` },
        placeholderImage: `/assets/${module}-placeholder.png`,
        topBarButtons: topBarButtons,
        permission: module,
        searchableProperties: [],
    };
}
/** Generate a default configuration for the given record */
function defaultRecordConfiguration(module, record) {
    return {
        name: record.name || record.label || module,
        description: record.description,
        additionalBadges: [],
        contextMenuOptions: [],
        editRoute: `/${module}/edit/${record.id}`,
    };
}
/** Generic entrypoint for registering/retrieving configurations for overview items */
export class OverviewProvider {
    /** Register a configuration provider for records of a given module, will be invoked per record */
    static registerRecordConfigurationProvider(module, provider) {
        this._configurationProviders.set(cleanKey(module), provider);
    }
    /** Register a configuration for a specific module */
    static registerModuleConfiguration(module, configuration) {
        this._configurations.set(cleanKey(module), configuration);
    }
    /** Read the configuration for the given module */
    static moduleConfiguration(module) {
        module = cleanKey(module);
        return Object.assign(Object.assign({}, defaultModuleConfiguration(module)), this._configurations.get(module));
    }
    /** Read the configuration for the given record */
    static recordConfiguration(module, record) {
        var _a;
        module = cleanKey(module);
        return Object.assign(Object.assign(Object.assign({}, this.moduleConfiguration(module)), defaultRecordConfiguration(module, record)), (_a = this._configurationProviders.get(module)) === null || _a === void 0 ? void 0 : _a(record));
    }
}
OverviewProvider._configurationProviders = new Map();
OverviewProvider._configurations = new Map();

<template>
  <div class="sidebar-wrapper" :style="{width: collapsedView ? '3.5rem' : '12.5rem'}">
    <XIcon name="DNX-header-cornerradius" color="var(--sideBar-background, #083856)" />
    <Transition
      name="fade"
      duration="100"
      @after-enter="isTransitioning = false"
      @after-leave="isTransitioning = false"
    >
      <XStack
        v-if="!isTransitioning"
        direction="vertical"
        gap="xxl"
        class="nav-outer-container"
        :class="{'toggle-container': !toggle}"
        ref="sideBar"
      >
        <XStack direction="vertical" gap="xs">
          <div :class="['nav-logo', collapsedView && 'collapsedLogo']" @click="$emit('logo-clicked')">
            <XHeroImage :src="navImage" />
          </div>
          <div class="view-toggle" @click="setSideBarView">
            <XIcon
              :name="collapsedView ? 'expand-right' : 'expand-left'"
              color="var(--sideBar-selectedItemColor, #ffffff)"
              size="xxsmall"
            />
          </div>
        </XStack>
        <XStack id="menu-items" direction="vertical" gap="xxl">
          <div class="side-menu-item" v-for="(item, index) in navItems" :key="index">
            <div v-if="item.subItems.length" v-for="item in item.subItems">
              <XSideNavMenuItem
                :item="item"
                :collapsedView="collapsedView"
                :active="item.routeName == activeRouteName"
              />
            </div>
            <XSideNavMenuItem
              v-else
              :item="item"
              :collapsedView="collapsedView"
              :active="item.routeName == activeRouteName"
            />
          </div>
        </XStack>
        <div v-show="transparentTrigger" class="side-nav-translucent-menu-bottom" />
        <div class="slot-content">
          <slot />
        </div>
      </XStack>
    </Transition>
  </div>
</template>

<script>
/**
 * Used as main page navigation in templates and as category navigation in audience manager.
 */

export default {
  name: 'XSideNav',
  emits: ['update:modelValue', 'logo-clicked', 'setView'],
  model: {
    prop: 'active',
  },
  props: {
    /**
     * The html element name used for the nav bar.
     */
    type: {
      type: String,
      default: 'nav',
    },
    /**
     * State which tab is active when initiated (using name of the component).
     */
    active: {
      required: true,
      type: String,
    },
    /**
     * Menu items to be displayed on the nav bar.
     */
    navItems: {
      required: true,
      type: Array,
    },
    navImage: {
      type: String,
      default: null,
    },
    activeRouteName: {
      type: String,
      default: '',
    },
    openExpanded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      toggle: true,
      transparentTrigger: true,
      collapsedView: false,
      isTransitioning: false,
    };
  },
  computed: {
    localActive: {
      get() {
        return this.active;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      },
    },
  },
  mounted() {
    document.getElementById('menu-items').addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    document.getElementById('menu-items').removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    setSideBarView() {
      this.collapsedView = !this.collapsedView;
      this.isTransitioning = true;
      this.$emit('setView', this.collapsedView);
    },
    handleScroll() {
      const elementId = document.getElementById('menu-items');
      elementId.scrollHeight - elementId.clientHeight > elementId.scrollTop &&
      elementId.scrollHeight - elementId.clientHeight < elementId.scrollTop + 20
        ? (this.transparentTrigger = false)
        : (this.transparentTrigger = true);
    },
    menuToggle(value) {
      this.toggle = value;
    },
    expandAll() {
      this.$refs.dropdowns.forEach(dropdown => {
        dropdown.toggleClass({
          target: dropdown.$el.querySelector('.dropdown-item'),
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar-wrapper {
  background-color: var(--sideBar-background, #083856);
  transition: all 0.2s ease-in-out;
  position: sticky;
  top: 0;
  max-height: 100vh;
  z-index: var(--zindex-sidenav);

  .icon.DNX-header-cornerradius {
    position: absolute;
    top: 0;
    left: 100%;
    fill: var(--sideBar-background, #083856);
    --hex-color: transparent !important;
  }

  .nav-outer-container {
    padding-top: var(--spacing-xxl);
    padding-bottom: var(--spacing-lg);
    position: relative;
    height: 100vh;

    &:hover {
      .view-toggle {
        border-radius: var(--radius-xs) 0 0 var(--radius-xs);
        visibility: visible;
      }
    }

    .view-toggle {
      width: fit-content;
      padding: var(--spacing-xs);
      background-color: var(--sideBar-hoverBackground, #214c67);
      margin-left: auto;
      visibility: hidden;

      .icon {
        cursor: pointer;
      }
    }

    .side-nav-translucent-menu-bottom {
      pointer-events: none;
      position: absolute;
      bottom: 0;
      background: linear-gradient(180deg, transparent, var(--sideBar-background, #083856));
      width: 100%;
      height: 5.75rem;
    }
    .nav-logo {
      cursor: pointer;
      padding-left: var(--spacing-lg);
      max-height: 6rem;
      max-width: 8.5rem;
    }

    .nav-logo.collapsedLogo {
      max-height: 3rem;
      max-width: 2.5rem;
    }

    #menu-items::-webkit-scrollbar {
      display: none;
    }
    #menu-items {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      overflow-x: hidden;
      overflow-y: scroll;

      .side-menu-item {
        :deep .menu-item-container:hover {
          transition: background-color 250ms linear;
        }
      }
    }

    .slot-content {
      display: flex;
      flex-direction: column;
      margin-top: auto;
    }
  }

  .fade-enter-active {
    opacity: 0;
    transition: all 0.2s ease-out;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
}
</style>

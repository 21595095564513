<template>
  <div class="label">
    <XParagraph :color="labelColor">
      {{ label }}
    </XParagraph>
  </div>
</template>

<script>
export default {
  name: 'JTouchpointTypeLabel',
  props: {
    label: {
      type: String,
    },
    labelColor: {
      type: String,
      default: 'var(--text-secondary-500, #2783C6)',
    },
  },
};
</script>

<style lang="scss" scoped>
.label {
  background-color: var(--main-background, #ffffff);
  width: fit-content;
  padding: 1rem;
  border-radius: 0.25rem;
  box-shadow: var(--main-shadow, 0px 0px 7px 0px rgba(71, 82, 97, 0.2));

  .paragraph {
    font-weight: 600;
  }
}
</style>

<docs>
  ```jsx
  <div>
    <JTouchpointTypeLabel label="E-mail" labelColor="var(--datanext-blue)"/>
  </div>

  ```
</docs>

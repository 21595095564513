import { ChartComponent } from './chartInstance';
import { Legend as AMLegend, percent } from '@amcharts/amcharts5';
import { PieChart } from '@amcharts/amcharts5/percent';
import format from '../../../../utils/formatting';
/** Append a legend to the given chart */
export class Legend extends ChartComponent {
    getTags() {
        return ['legend'];
    }
    constructor() {
        super();
        // wait for all series to be rendered
        this.addDependency('series');
    }
    render(context) {
        const chart = context.get('chart');
        if (chart.isType(PieChart.className)) {
            this.pieLegend(context, chart);
            return;
        }
        this.xyLegend(context, chart);
    }
    /** Create a legend for a piechart */
    pieLegend(context, chart) {
        var _a;
        const categoryType = context.get('categorytype');
        const legend = chart.children.push(AMLegend.new(context.root, {
            nameField: 'category', // PieData
            centerX: percent(50),
            marginTop: 30,
            x: percent(50),
        }));
        // formatting information etc. is not provided by our axis when working with piecharts, do it manually
        if (categoryType === 'datetime') {
            legend.labels.template.adapters.add('text', (value, target) => {
                var _a, _b;
                const category = (_b = (_a = target.dataItem) === null || _a === void 0 ? void 0 : _a.dataContext) === null || _b === void 0 ? void 0 : _b.category;
                return Number.isFinite(category) ? format(category, 'date') : value;
            });
        }
        // for now, assume we're working with a single series on our pie charts
        legend.data.setAll(((_a = chart.series.values[0]) === null || _a === void 0 ? void 0 : _a.dataItems) || []);
        legend.valueLabels.template.set('forceHidden', true);
        this.preventWatermarkOverlap(context, legend);
    }
    /** Create a legend for an xy chart */
    xyLegend(context, chart) {
        const legend = chart.children.push(AMLegend.new(context.root, {
            nameField: 'x', // XyData
            centerX: percent(50),
            x: percent(50),
        }));
        legend.data.setAll(chart.series.values);
        legend.valueLabels.template.set('forceHidden', true);
        this.preventWatermarkOverlap(context, legend);
    }
    /** Ensure a minimum distance between AM Watermark/our legend to prevent overlap */
    preventWatermarkOverlap(context, legend) {
        const watermark = context.root._logo;
        if (!watermark || !watermark.get('visible'))
            return;
        // actual dimensions only become available after render
        watermark.appear().then(() => legend.set('paddingBottom', watermark.height()));
    }
}

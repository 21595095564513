import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_XChartSeriesTooltips = _resolveComponent("XChartSeriesTooltips");
    const _component_XChartLabels = _resolveComponent("XChartLabels");
    const _component_XChartBubbleSeries = _resolveComponent("XChartBubbleSeries");
    const _component_XChartCategoryAxisTicks = _resolveComponent("XChartCategoryAxisTicks");
    const _component_XChartLegend = _resolveComponent("XChartLegend");
    const _component_XChartTheme = _resolveComponent("XChartTheme");
    const _component_XChartToolbarExport = _resolveComponent("XChartToolbarExport");
    const _component_XChartToolbarZoom = _resolveComponent("XChartToolbarZoom");
    const _component_XChartNoDataMessage = _resolveComponent("XChartNoDataMessage");
    const _component_XChartUserLocale = _resolveComponent("XChartUserLocale");
    const _component_XXyChart = _resolveComponent("XXyChart");
    return (_openBlock(), _createBlock(_component_XXyChart, {
        categoryAxis: "x",
        categoryAxisType: _ctx.xaxisType,
        group: _ctx.group
    }, {
        default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.seriesCorrected, (serie) => {
                return (_openBlock(), _createBlock(_component_XChartBubbleSeries, {
                    data: serie.data,
                    name: serie.name
                }, {
                    default: _withCtx(() => [
                        _createVNode(_component_XChartSeriesTooltips),
                        (_ctx.dataLabels)
                            ? (_openBlock(), _createBlock(_component_XChartLabels, { key: 0 }))
                            : _createCommentVNode("", true)
                    ]),
                    _: 2
                }, 1032, ["data", "name"]));
            }), 256)),
            _createVNode(_component_XChartCategoryAxisTicks),
            ($setup.showLegend)
                ? (_openBlock(), _createBlock(_component_XChartLegend, { key: 0 }))
                : _createCommentVNode("", true),
            _createVNode(_component_XChartTheme, { colors: _ctx.theme }, null, 8, ["colors"]),
            _createVNode(_component_XChartToolbarExport, { name: _ctx.name }, null, 8, ["name"]),
            _createVNode(_component_XChartToolbarZoom),
            _createVNode(_component_XChartNoDataMessage),
            _createVNode(_component_XChartUserLocale)
        ]),
        _: 1
    }, 8, ["categoryAxisType", "group"]));
}

import { Ticks } from './ticks';
import { useChartComponent } from './composables';
export default {
    name: 'XChartValueAxisTicks',
    setup() {
        // should we determine tick type based on a prop or being nested in some other element instead of requiring two different vue components?
        useChartComponent(Ticks.ValueAxis());
    },
    render: () => undefined,
};

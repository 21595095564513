import { ChartComponent } from './chartInstance';
import { RadarChart as AMRadarChart } from '@amcharts/amcharts5/radar';
import { p100 } from '@amcharts/amcharts5';
/** Base for a radar chart */
export class RadarChart extends ChartComponent {
    getTags() {
        return ['chart', 'radarchart'];
    }
    render(context) {
        const chart = context.root.container.children.push(AMRadarChart.new(context.root, {
            // disable zoom
            panX: false,
            panY: false,
            startAngle: 160,
            endAngle: 380,
            // defaults to 80 to give space for labels, our labels are rendered inside so we can take 100% of the allotted space
            radius: p100
        }));
        context.set('chart', chart);
        chart.appear(1000, 100);
    }
}

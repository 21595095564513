﻿<template>
  <div class="chart" :style="{height: `${height}px`}" />
</template>

<script>
/*
  
  Onderzoeken:
  
  meerdere manieren om datasources te specificeren/aan te spreken, wat is praktisch?
  
  dataset: { source: [ [...], [...] ] },
  
  data per series?
  
  
  Zoom lijkt vrij arbitrair geimplementeerd, selectie tussen wat wel/niet getoond wordt lijkt vrij vaag
  EDIT: Zoom toont geen bars die (gedeeltelijk) buiten beeld zouden komen te vallen
  
  
  Scroll geforceerd overgenomen, hoe uitzetten?
  
  IConfigurationEnhancer/IConfigurationTransformer
    -> stack = true, applyEnhancer( StackEnhancer, configuration )
  easier grouping of configuration functionality
  
  Allow enhancers to hook into specific aspects of config building?
    -> EnhanceConfiguration
    -> EnhanceSeries
    -> EnhanceDatapoint
   
  
 */

import {BarChartMixin} from './mixins';
import {mapBarSeries, createEChartsComponent} from './echarts';
import {
  zoomFeature,
  panFeature,
  exportFeature,
  axisFeature,
  tooltipFeature,
  legendFeature,
  gridLinesFeature,
  dataLabelsFeature,
  stackFeature,
  stack100Feature,
  forecastingFeature,
  horizontalPrimaryAxisFeature,
  noDataMessageFeature,
} from './chartFeatures';

export default createEChartsComponent({
  type: 'bar',
  optionsMixin: BarChartMixin,
  mapSeries: mapBarSeries,
  features: [
    horizontalPrimaryAxisFeature,

    zoomFeature,
    panFeature,
    exportFeature,

    axisFeature,
    tooltipFeature,
    legendFeature,
    gridLinesFeature,
    dataLabelsFeature,

    stackFeature,
    stack100Feature,

    forecastingFeature,
  ],
});
</script>

<style scoped>
.chart {
  padding: 0.5rem;

  /* FIXME: undo .5rem removal by XGraphWidget */
  height: calc(100% + 0.5rem);
}
</style>

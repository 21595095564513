const SYMBOL_UUID_VALUE = Symbol('UUID_VALUE');
const Empty = '00000000-0000-0000-0000-000000000000';
const UuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

const s4 = () => {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
};

const generateUuid = () => {
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
};

class Uuid {
  /**
   * Create a new UUID
   */
  constructor(uuid = Empty) {
    //Empty guid
    this[SYMBOL_UUID_VALUE] = uuid;
  }

  /**
   * Generate a new UUID
   * @returns {Uuid} UUID with a random value
   */
  static NewUuid() {
    return new Uuid(generateUuid());
  }

  /**
   * Get an empty UUID
   * @returns {Uuid} Empty uuid
   */
  static Empty() {
    return new Uuid();
  }

  /**
   * Compare whether two Uuids are equal
   * @param {Uuid} value
   */
  equals(value) {
    if (!value) {
      return false;
    }

    return value.valueOf() === this.valueOf();
  }

  /**
   * Verify whether or not the given string is a UUID
   * @param {string?} value to verify
   * @returns {Boolean} True when value is a uuid
   */
  static IsUuid(value) {
    if (!value || value.length <= 36) {
      return false;
    }

    return UuidRegex.test(value);
  }

  /**
   * Get the value of the current UUID, used for comparisons
   * @returns {string} stringified UUID
   */
  valueOf() {
    return this[SYMBOL_UUID_VALUE];
  }

  /**
   * Get a stringified version of the current UUID value
   * @returns {string} stringified UUID
   */
  toString() {
    return this[SYMBOL_UUID_VALUE];
  }
}

export default Uuid;

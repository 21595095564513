/**
 * Generate a range of numbers from start to end, defaults to excluding end
 *
 * When a single value is provided, it is considered to be the upper bound of a range from 0 to the given value
 *
 * Usage:
 * ```javascript
 * [...range(3)] // [3, 2, 1]
 * [...range(3, 0)] // [3, 2, 1]
 * [...range(3, 0, { includeStart: false })] // [2, 1]
 * [...range(3, 0, { includeEnd: true })] // [3, 2, 1, 0]
 * [...range(0, 3, { includeStart: false })] // [1, 2]
 * [...range(0, 3, { includeEnd: true })] // [0, 1, 2, 3]
 * [...range(0, 6, { step: 2 })] // [0, 2, 4, 6]
 *
 * const myArray = [5, 6, 7];
 * for ( const idx of range(myArray.length, 0) ) console.log(myArray[idx]) //7, 6, 5
 * ```
 *
 * @param {number} start Range start, not included when `options.includeStart` is false
 * @param {number} end Range end, not included when `options.includeEnd` is false
 * @param {RangeOptions} options Additional parameters
 * @param {Boolean} [options.includeStart=true] Include the start value in the generated range, defaults to true
 * @param {Boolean} [options.includeEnd=false] Include the end value in the generated range, defaults to false
 * @param {Number} [options.step=1] Amount to increment/decrement value by for each iteration, defaults to 1
 */
export default function* range(start, end, options) {
    // when our end is not a number, it is either undefined or an options object
    // force a range starting from 0 to 'start'
    if (typeof end !== 'number') {
        options !== null && options !== void 0 ? options : (options = end);
        end = start;
        start = 0;
    }
    const { includeStart = true, includeEnd = false, step = 1 } = options !== null && options !== void 0 ? options : {};
    const lower = Math.min(start, end);
    const upper = Math.max(start, end);
    const increase = (current) => current + step;
    const decrease = (current) => current - step;
    const next = start < end ? increase : decrease;
    start = next === increase ? lower + ~~!includeStart : upper - ~~!includeStart;
    end = next === increase ? upper + ~~includeEnd : lower - ~~includeEnd;
    const isCompleted = next === increase
        ? (current) => current >= end
        : (current) => current <= end;
    // reversible iterator? -> previous() method
    // https://medium.com/@chanakyabhardwaj/es6-reverse-iterable-for-an-array-5dae91c02904
    for (let current = start; !isCompleted(current); current = next(current)) {
        yield current;
    }
}

import { ChartComponent } from './chartInstance';
import { ColumnSeries } from '@amcharts/amcharts5/xy';
/** Apply 'forecasting' visualisation to a set of data points */
export class Forecast extends ChartComponent {
    getTags() {
        return ['forecast'];
    }
    constructor(settings) {
        super();
        this.forecastDataPoints = settings.forecastDataPoints;
        this.addDependency(settings.series);
    }
    render(context) {
        if (this.forecastDataPoints < 1)
            return;
        const series = context.get('series');
        if (series.isType(ColumnSeries.className))
            this.applyBarForecasting(series);
        else
            this.applyLineForecasting(series);
    }
    applyLineForecasting(series) {
        var _a;
        // for some reason it was decided that segments etc. of lineseries were to not get assigned the dataitem
        // they're related to, forcing us to having to alter our original dataset ))))
        for (let idx = series.dataItems.length - this.forecastDataPoints; idx < series.dataItems.length; idx++) {
            const dataItem = series.dataItems.at(idx);
            // @ts-ignore
            dataItem.dataContext._strokeSettings = Object.assign(Object.assign({}, (_a = dataItem.dataContext) === null || _a === void 0 ? void 0 : _a._strokeSettings), { strokeDasharray: 5 });
        }
    }
    applyBarForecasting(series) {
        var _a;
        (_a = series.columns.template) === null || _a === void 0 ? void 0 : _a.adapters.add('opacity', (value, target) => {
            var _a;
            const allItems = (_a = target.dataItem) === null || _a === void 0 ? void 0 : _a.component.dataItems;
            const targetIndex = allItems.indexOf(target.dataItem);
            const forecastStartIndex = allItems.length - this.forecastDataPoints;
            if (targetIndex >= forecastStartIndex)
                return 0.5;
            return value;
        });
    }
}

import { h, shallowRef, unref, watch } from 'vue';
import { T } from '../../../helpers/translationsProvider';
import { Duration } from 'luxon';
import format from '../../../utils/formatting';
import * as util from './util';
import { Time } from './datatypes';
// TODO: Refactor, get rid of the constant writing to component state, make calculations more 'atomic'/'pure'
const calculationDisplayTranslations = {
    get sum() {
        return T('QB_MOD_SUM');
    },
    get average() {
        return T('QB_MOD_AVERAGE');
    },
    get min() {
        return T('QB_MOD_MIN');
    },
    get max() {
        return T('QB_MOD_MAX');
    },
    get count() {
        return T('QB_MOD_COUNT');
    },
    get percentage() {
        return T('QB_MOD_PERCENTAGE');
    },
};
/**
 
 create shallowRef per column -> offload aggregation/calculation to background (generate token beforehand?)
 set summary values after completion if token was unchanged, new token = new summary
 token can be based on a hash of our applied filters, that way we can cache for a faster response
 
 */
function sum(key, parseValue, data) {
    let result = 0;
    for (const item of data) {
        const value = parseValue(String(item[key]));
        if (value !== undefined)
            result += value;
    }
    return result;
}
function average(key, parseValue, data) {
    // guard against 0 division
    if (data.length === 0)
        return 0;
    return sum(key, parseValue, data) / data.length;
}
function min(key, parseValue, data) {
    let result = Infinity;
    for (const item of data) {
        const value = parseValue(String(item[key]));
        if (value !== undefined)
            result = Math.min(result, value);
    }
    // no valid values found
    if (result === Infinity)
        return 0;
    return result;
}
function max(key, parseValue, data) {
    let result = -Infinity;
    for (const item of data) {
        const value = parseValue(String(item[key]));
        if (value !== undefined)
            result = Math.max(result, value);
    }
    // no valid values found
    if (result === -Infinity)
        return 0;
    return result;
}
function count(key, parseValue, data) {
    return data.length;
}
function noAggregator(key, parseValue, data) {
    return 0;
}
function parseNumberValue(value) {
    return util.isFinite(value) ? util.parseFloat(value) : undefined;
}
function parseTimeValue(value) {
    var _a;
    return (_a = Time.parse(value)) === null || _a === void 0 ? void 0 : _a.toMilliseconds();
}
// should formatters be part of our datatypes? -> datatype.formatter -> centralized location for determining how to format what in our datatable
/** Format as: xxxx.xx */
function formatNumberValue(value) {
    return format(value, 'number', { maxFraction: 2 });
}
/** Format as 1W, 3D, 18H, 2M, 18S, 0 values are skipped  */
function formatTime(value) {
    // this should be moved to a formatter
    // convert to shape: { weeks: 1, days: 3, hours: 18, minutes: 2, seconds: 18 }
    const duration = Duration.fromMillis(value).shiftToAll().toObject();
    return Object.entries(duration)
        .reduce((result, unitAndValue) => {
        const [unit, value] = unitAndValue;
        if (value > 0) {
            const unitShorthand = unit[0].toUpperCase();
            result.push(`${value}${unitShorthand}`);
        }
        return result;
    }, [])
        .join(', ');
}
function getParser(dataType) {
    if (dataType === 'time')
        return parseTimeValue;
    return parseNumberValue;
}
function getFormatter(dataType) {
    // todo: move to format function
    // add option to omit seconds?
    if (dataType === 'time')
        return formatTime;
    return formatNumberValue;
}
function getAggregator(calcType) {
    if (calcType === 'average')
        return average;
    if (calcType === 'sum')
        return sum;
    if (calcType === 'min')
        return min;
    if (calcType === 'count')
        return count;
    if (calcType === 'max')
        return max;
    // percentage is not implemented (yet)?
    return noAggregator;
}
function buildSummary(data, columns, getColumnType) {
    const summaries = {};
    for (const column of columns) {
        // summary not enabled
        if (!column.calcType) {
            summaries[util.keyOf(column)] = {
                colSpan: 1,
                value: h('span', ''),
            };
            continue;
        }
        const dataType = getColumnType(column.propertyName);
        const aggregate = getAggregator(column.calcType);
        // unsupported datatype
        if (dataType !== 'number' && dataType !== 'time') {
            summaries[util.keyOf(column)] = {
                colSpan: 1,
                value: h('span', ''),
            };
            continue;
        }
        // make datatype an interface
        // DataType.parse(), DataType.format()
        const parseValue = getParser(dataType);
        const formatter = getFormatter(dataType);
        const aggregation = aggregate(util.keyOf(column), parseValue, data);
        const formatted = aggregation === 0 ? '' : `${formatter(aggregation)} (${calculationDisplayTranslations[column.calcType]})`;
        // ColumnFactory.createSummary
        summaries[util.keyOf(column)] = {
            value: h('span', {
                style: { color: 'var(--text-secondary, #083856)', fontWeight: 'bold' },
            }, formatted),
            colSpan: 1,
        };
    }
    return summaries;
}
/** Create a set of VNodes for the summary rows for the differing columns */
export function useSummary(props, renderedColumns, getColumnType) {
    const summary = shallowRef(undefined);
    function updateSummary() {
        const data = props.data;
        const columns = unref(renderedColumns);
        if (!props.showSummary || !Array.isArray(data) || !Array.isArray(columns)) {
            summary.value = undefined;
            return;
        }
        const summaryValue = buildSummary(data, columns, getColumnType);
        summary.value = () => summaryValue;
    }
    // only update summary when column length has changed, summary goes over the entire table
    watch(() => [props.data, renderedColumns], updateSummary, { immediate: true });
    // not the prettiest solution, naive requires either undefined or a function for our summary
    return summary;
}

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { isRef, onUnmounted, ref, watch, unref } from 'vue';
import { useNotifications } from './notification';
import { debounce } from 'lodash';
/**
 * Retrieves the data from an api generated endpoint and exposes usable data. Only meant for getters, not post requests
 * @param method - The method to execute.
 * @param parameters - Parameters of the method could either be a tuple of values or a ref with the tuple, when using a ref it will automatically refetch when the value has been changed.
 * @param options - Optional handling options.
 */
const useDnxServerResponse = (method, parameters, options) => {
    const notification = useNotifications();
    const value = ref(options === null || options === void 0 ? void 0 : options.default);
    const isLoading = ref(false);
    const hasFailed = ref(false);
    let abortController = new AbortController();
    const fetch = () => __awaiter(void 0, void 0, void 0, function* () {
        const params = unref(parameters);
        //Don't fetch if params has not been defined
        if (!params)
            return;
        //Cancel if loading
        if (isLoading.value) {
            abortController.abort();
        }
        abortController = new AbortController();
        isLoading.value = true;
        hasFailed.value = false;
        //Pass abortcontroller
        params[method.length - 1] = params[method.length - 1]
            ? Object.assign(Object.assign({}, params[method.length - 1]), { signal: abortController.signal }) : { signal: abortController.signal };
        yield method(...params)
            .then(response => {
            if (!response.success) {
                value.value = undefined;
                if (options.showNotificationOnError)
                    notification.errorNotification(response.message);
                if (options === null || options === void 0 ? void 0 : options.callback) {
                    options.callback({ success: false, error: response.message, responseInfo: response });
                }
                hasFailed.value = true;
                return;
            }
            value.value = response.value;
            if (options === null || options === void 0 ? void 0 : options.callback) {
                options.callback({ success: true, data: response.value, responseInfo: response });
            }
            return response.value;
        })
            .catch(() => undefined)
            .finally(() => (isLoading.value = false));
    });
    onUnmounted(() => {
        abortController.abort();
    });
    if (isRef(parameters)) {
        const debounceOpts = typeof (options === null || options === void 0 ? void 0 : options.debounce) === 'number' ?
            { wait: options === null || options === void 0 ? void 0 : options.debounce, leading: false } :
            options === null || options === void 0 ? void 0 : options.debounce;
        if (debounceOpts === null || debounceOpts === void 0 ? void 0 : debounceOpts.wait) {
            watch(parameters, debounce(fetch, debounceOpts.wait, debounceOpts), { deep: true });
        }
        else {
            watch(parameters, fetch, { deep: true });
        }
        // immediate still takes `debounce` timeout into account, invoke manually
        fetch();
    }
    return { value, fetch, isLoading, failed: hasFailed };
};
export default useDnxServerResponse;

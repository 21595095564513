import { ref, reactive, onMounted, onUnmounted, computed, watch, } from 'vue';
export const useFormValidation = (schema) => {
    const dependencies = ref(new Map());
    const validate = (data) => {
        const childErrors = Array.from(dependencies.value.values()).reduce((errors, validate) => {
            const result = validate();
            if (!result.success) {
                errors.push(result.error.message);
            }
            return errors;
        }, []);
        const result = schema.safeParse(data);
        if (result.success && !childErrors.length) {
            return result;
        }
        else {
            let errors = [];
            if (!result.success) {
                errors = result.error.issues.map(issue => issue.message);
                childErrors.forEach(error => {
                    if (errors.includes(error)) {
                        errors = errors.filter(x => x == error);
                    }
                });
            }
            return { success: false, errors };
        }
    };
    return { validate, dependencies };
};
export const useFieldValidation = (schema, options) => {
    const modelValue = ref(options === null || options === void 0 ? void 0 : options.defaultValue);
    const validationMessage = ref();
    const validationType = ref();
    const id = crypto.randomUUID();
    onMounted(() => {
        if (options && Array.isArray(options.parentDependency)) {
            options.parentDependency.forEach(dependency => {
                dependency.dependencies.value.set(id, validate);
            });
        }
        else if (options && options.parentDependency && !Array.isArray(options.parentDependency)) {
            options.parentDependency.dependencies.value.set(id, validate);
        }
    });
    onUnmounted(() => {
        if (options && Array.isArray(options.parentDependency)) {
            options.parentDependency.forEach(dependency => {
                dependency.dependencies.value.set(id, validate);
            });
        }
        else if (options && options.parentDependency && !Array.isArray(options.parentDependency)) {
            options.parentDependency.dependencies.value.delete(id);
        }
    });
    const reset = () => {
        modelValue.value = undefined;
        validationType.value = undefined;
        validationMessage.value = undefined;
    };
    const emits = {
        focusout: (event) => {
            validate();
        },
        'update:modelValue': (value) => {
            modelValue.value = value === '' ? undefined : value;
            if (validationMessage.value) {
                validate();
            }
        },
    };
    const props = reactive({
        placeholder: (options === null || options === void 0 ? void 0 : options.placeholder) ? `${options.placeholder}${!(schema === null || schema === void 0 ? void 0 : schema.isOptional()) ? ' *' : ''}` : undefined,
        modelValue,
        validationMessage,
        validationType,
    });
    const validate = (updateState = true) => {
        validationMessage.value = undefined;
        validationType.value = undefined;
        const result = schema.safeParse(modelValue.value);
        if (!result.success && updateState) {
            validationMessage.value = result.error.issues[0].message;
            validationType.value = 'error';
        }
        return result;
    };
    return { emits, props, validate, schema, modelValue, reset };
};
//** Watches a ref based on a schema, and adds a error message if the value from that ref is invalid */
export const useRefValidation = (state, schema) => {
    const validationType = ref();
    const validationMessage = ref();
    watch(state, () => {
        validate();
    });
    const validate = () => {
        validationType.value = undefined;
        validationMessage.value = undefined;
        const result = schema.safeParse(state.value);
        if (result.success)
            return;
        validationType.value = 'error';
        validationMessage.value = result.error.issues[0].message;
    };
    return { validationType, validationMessage, onBlur: (event) => validate() };
};
export const useForm = (inputDefinitions) => {
    const inputs = Object.fromEntries(Object.entries(inputDefinitions).map(entry => {
        var _a;
        let key;
        let input;
        if (entry[1].schema) {
            key = entry[0];
            input = entry[1];
        }
        else {
            return [entry[0], useForm(entry[1]).inputs];
        }
        const modelValue = ref(input.defaultValue);
        const validationMessage = ref();
        const validate = (triggerMessage = true) => {
            validationMessage.value = undefined;
            const result = input.schema.safeParse(modelValue.value);
            if (!result.success) {
                validationMessage.value = result.error.issues[0].message;
            }
            return result;
        };
        const isValid = computed(() => {
            return input.schema.safeParse(modelValue.value).success;
        });
        const setValue = (value) => {
            modelValue.value = value;
        };
        const state = modelValue;
        const props = reactive({
            'onUpdate:modelValue': (value) => {
                modelValue.value = value === '' ? undefined : value;
                if (validationMessage.value) {
                    validate();
                }
            },
            placeholder: `${input.placeholder}${!((_a = input.schema) === null || _a === void 0 ? void 0 : _a.isOptional()) ? ' *' : ''}`,
            modelValue,
            validationMessage,
            validationType: computed(() => (validationMessage.value && 'error') || undefined),
            onFocusout: (event) => {
                validate();
            },
        });
        return [
            key,
            {
                props,
                validate,
                setValue,
                state,
                isValid,
            },
        ];
    }));
    const isValid = computed(() => {
        return !Object.values(inputs).some(x => !x.isValid.value);
    });
    const assignStateToNestedProperties = entry => {
        const nestedInputState = {};
        if (typeof entry != 'object')
            return;
        for (const [key, value] of Object.entries(entry)) {
            if (value.state) {
                nestedInputState[key] = value.state.value;
            }
            else {
                nestedInputState[key] = assignStateToNestedProperties(value);
            }
        }
        return nestedInputState;
    };
    const state = computed(() => {
        return Object.entries(inputs).reduce((accumulator, entry) => {
            var _a;
            const key = entry[0];
            const input = entry[1];
            accumulator[key] = entry[1].props ? (_a = input.state) === null || _a === void 0 ? void 0 : _a.value : assignStateToNestedProperties(entry[1]);
            return accumulator;
        }, {});
    });
    const validate = () => {
        const validateInputs = input => {
            if (input.state) {
                const result = input.validate();
                if (!result.success) {
                    return false;
                }
            }
            else {
                // If input doesn't have state, it might be nested
                for (const nestedInput of Object.values(input)) {
                    const nestedResult = validateInputs(nestedInput);
                    if (!nestedResult)
                        return false;
                }
            }
            return true;
        };
        // Check invalid entries
        const valid = Object.values(inputs).reduce((value, input) => {
            if (!validateInputs(input))
                value = false;
            return value;
        }, true);
        return {
            success: valid,
            data: valid ? state.value : undefined,
        };
    };
    return { inputs, validate, state, isValid };
};

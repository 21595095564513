<template>
  <td>
    <div class="data-items">
      <slot name="rowItems" />
    </div>
    <div class="table-actions">
      <slot name="rowActions" />
    </div>
  </td>
</template>

<script>
export default {
  name: 'XTableData',
};
</script>

<style lang="scss" scoped>
tr:nth-child(odd) td {
  background: var(--container-secondaryBackground, #edf3f8);
}
tr:nth-child(even) td {
  background: var(--main-background, #ffffff);
}

tr td {
  &:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  &:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

tr td {
  color: var(--text-primary, #313741);
  padding: 0.9rem 1.3rem 0.9rem 1.3rem;
  font-size: var(--font-md);
  transition: 0.3s all;
}

table tbody tr:hover td {
  background-color: var(--control-hoverBackground, #e7f5ff);
  color: var(--control-hoverColor, #838384);
  transition: 0.5s all;
  z-index: var(--zindex-popover);
}

table tr td {
  &:first-child {
    padding-left: 1rem;
  }
}

.table-actions {
  display: flex;
  justify-content: flex-end;
}

.table-actions {
  :deep(span) {
    cursor: pointer;
    transition: 0.3s all;
    margin-right: 1rem;
    &:last-child {
      margin-right: 0rem;
    }
    &:hover {
      transform: scale(1.2);
      transition: 0.3s all;
    }
  }
}

.data-items {
  :deep(.eye) {
    cursor: pointer;
    transition: 0.3s all;

    &:hover {
      transform: scale(1.2);
      transition: 0.3s all;
    }
  }
}
</style>

<docs>
  ```jsx
  <XTableData>
    <template #rowItems>Row 1 data 1</template>
  </XTableData>
  <XTableData>
    <template #rowItems>Row 1 data 2</template>
  </XTableData>
  <XTableData>
    <template #rowItems>Row 1 data 3</template>
  </XTableData>
  <XTableData>
    <template #rowItems>Row 1 data 4</template>
  </XTableData>
  <XTableData>
    <template #rowItems>Row 1 data 5</template>
  </XTableData>
  <XTableData>
    <template #rowItems>Row 1 data 6</template>
  </XTableData>
  <XTableData>
    <template #rowActions>
      <XIcon name="edit" size="small" />
      <XIcon name="delete" size="small" />
    </template>
  </XTableData>
  ```
</docs>

// Should we make this part of our context -> context.events.ready()?
/**
 * Execute a callback once the chart is ready
 *
 * @param {ChartRenderingContext} context Chart context
 * @param {() => any} cb Callback when chart is ready
 */
export function ready(context, cb) {
    // recommended way of waiting for an 'onready' according to amcharts docs
    let _debounceId = 0;
    const pollForReady = () => {
        clearTimeout(_debounceId);
        //@ts-ignore
        _debounceId = setTimeout(() => {
            // remove handler
            context.root.events.off('frameended', pollForReady);
            try {
                cb();
            }
            catch (e) {
                // swallow
            }
        }, 100);
    };
    context.root.events.on('frameended', pollForReady);
}

//Ensure we don't get conflicts when multiple components use the same prefix
let _idsPerPrefix = {};

/**
 * Create a dispenser for unique ids with a given prefix
 * @param {string} [prefix=_DNX_] Prefix to use for the generated ids
 * @returns {function(): string} Dispense a new unique identifier
 */
const createIdPrefixedDispenser = prefix => {
  prefix = prefix || '_DNX_';

  if (!_idsPerPrefix[prefix]) {
    _idsPerPrefix[prefix] = 1;
  }

  return () => `${prefix}${_idsPerPrefix[prefix]++}`;
};

export default createIdPrefixedDispenser;

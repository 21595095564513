import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_XChartTheme = _resolveComponent("XChartTheme");
    const _component_XChartToolbarExport = _resolveComponent("XChartToolbarExport");
    const _component_XChartNoDataMessage = _resolveComponent("XChartNoDataMessage");
    const _component_XChartUserLocale = _resolveComponent("XChartUserLocale");
    const _component_XAmWordCloudChartRoot = _resolveComponent("XAmWordCloudChartRoot");
    return (_openBlock(), _createBlock(_component_XAmWordCloudChartRoot, {
        data: _ctx.series.data
    }, {
        default: _withCtx(() => [
            _createVNode(_component_XChartTheme, { colors: _ctx.theme }, null, 8, ["colors"]),
            _createVNode(_component_XChartToolbarExport, { name: _ctx.name }, null, 8, ["name"]),
            _createVNode(_component_XChartNoDataMessage),
            _createVNode(_component_XChartUserLocale)
        ]),
        _: 1
    }, 8, ["data"]));
}

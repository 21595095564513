import { computed, ref, shallowRef, watch } from 'vue';
const defaultPageSize = 10;
export const usePagination = (props) => {
    const pageSize = ref((props.pagination && props.pagination.pageSize) || defaultPageSize);
    const currentPage = ref((props.pagination && props.pagination.page) || 0);
    watch(() => props.pagination, newValue => {
        if (!props.pagination)
            return;
        if (!props.pagination.page)
            return;
        currentPage.value = props.pagination.page;
    });
    const pagination = computed(() => {
        var _a, _b, _c;
        if (props.pagination == undefined && props.data && props.data.length < defaultPageSize)
            return false;
        if (props.pagination == false)
            return false;
        const isControlled = props.remote !== true;
        const defaultProps = {
            showSizePicker: ((_a = props.pagination) === null || _a === void 0 ? void 0 : _a.showSizePicker) || false,
        };
        if (isControlled) {
            return Object.assign(Object.assign({}, defaultProps), {
                page: currentPage.value || 1,
                pageSize: pageSize.value || defaultPageSize,
                pageSizes: [5, 10, 25, 50],
                onChange: (page) => {
                    currentPage.value = page;
                },
                onUpdatePageSize: (newSize) => {
                    pageSize.value = newSize;
                    currentPage.value = 1;
                },
            });
        }
        else {
            return Object.assign(Object.assign({}, defaultProps), {
                defaultPage: currentPage.value || 1,
                page: currentPage.value || 1,
                defaultPageSize: ((_b = props.pagination) === null || _b === void 0 ? void 0 : _b.pageSize) || defaultPageSize,
                pageCount: (_c = props.pagination) === null || _c === void 0 ? void 0 : _c.pageCount, // <-- required in uncontrolled mode
                showSizePicker: false, // <-- unsupported in uncontrolled mode for the time being, emit: update:pageSize?
            });
        }
    });
    const classes = shallowRef([]);
    watch(() => props.isLoading, value => {
        if (value) {
            classes.value = ['pagination-transition'];
            return;
        }
        // The pagination-transition-complete class triggers a 250ms animation on row creation
        // we only want this animation for our initial load after a page switch
        // remove on timeout for the time being, once we have a distinction between 'loading'/'starting'/'ready' we can bind this logic
        // to said state
        classes.value = ['pagination-transition-complete'];
        setTimeout(() => (classes.value = []), 500);
    });
    //TODO: remove this, rebuild this part
    // const updatePagination = () => {
    //   // assume controlled/uncontrolled won't change, should refactor table once this becomes a possibility
    //   const isControlled = props.remote !== true;
    //   const defaultProps = {
    //     showSizePicker: props.pagination?.showSizePicker || false,
    //   };
    //   const controlledProps = {
    //     page: props.pagination?.page || 1,
    //     pageSize: props.pagination?.pageSize || 5,
    //     pageSizes: [5, 10, 25, 50],
    //     onChange: (page: number) => {
    //       // @ts-ignore
    //       pagination.value.page = page;
    //     },
    //     onUpdatePageSize: (pageSize: number) => {
    //       // @ts-ignore
    //       pagination.value.pageSize = pageSize;
    //       // @ts-ignore
    //       pagination.value.page = 1;
    //     },
    //   };
    //   const uncontrolledProps = {
    //     defaultPage: props.pagination?.page || 1,
    //     //TODO: doublecheck this with Danny, seems to work fine
    //     page: props.pagination?.page || 1,
    //     defaultPageSize: props.pagination?.pageSize || 5,
    //     pageCount: props.pagination?.pageCount, // <-- required in uncontrolled mode
    //     showSizePicker: false, // <-- unsupported in uncontrolled mode for the time being, emit: update:pageSize?
    //   };
    //   pagination.value = {
    //     ...defaultProps,
    //     ...(isControlled ? controlledProps : uncontrolledProps),
    //   };
    // };
    // watch(
    //   () => props.pagination,
    //   pagination => {
    //     updatePagination();
    //   },
    //   {immediate: true}
    // );
    // watch(
    //   () => props.data,
    //   pagination => {
    //     updatePagination();
    //   },
    //   {immediate: true}
    // );
    return {
        pagination,
        classes,
    };
};

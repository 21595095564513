import { ref, watch } from 'vue';
export function useSelectFocusHandler(selectRef) {
    const isFocused = ref(false);
    const handleToggle = () => {
        isFocused.value = !isFocused.value;
    };
    const handleBlur = () => {
        isFocused.value = false;
    };
    const handleValueUpdate = () => {
        isFocused.value = false;
    };
    watch(isFocused, (newValue) => {
        var _a;
        if (!newValue) {
            (_a = selectRef.value) === null || _a === void 0 ? void 0 : _a.blur();
        }
    });
    return { isFocused, handleToggle, handleValueUpdate, handleBlur };
}

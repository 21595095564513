<template>
  <div class="chart" :key="chartOptions.xaxis.type">
    <apexchart ref="chart" type="line" :height="height" :width="width" :options="chartOptions" :series="dataSeries" />
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';

import {autoFitToGrid, normalizeApexSeries} from './util';
import {ToolbarProxy} from './toolbarProxy';

let _chartcounter = 0;
/**
 * lineType has 3 types to choose from: 'smooth', 'straight' and 'stepline'
 */
export default {
  name: 'XApexMixedChart',
  components: {
    apexchart: VueApexCharts,
  },
  inject: {
    ...ToolbarProxy.InjectionDefinition,
  },
  props: {
    height: {
      type: String,
      default: '360',
    },
    width: {
      type: String,
      default: '100%',
    },
    group: {
      type: String,
    },
    series: {
      type: [Array, Object],
      default: () => [],
    },
    xaxisType: {
      type: String,
      default: 'category',
    },
    dataLabels: {
      type: Boolean,
      default: false,
    },
    stacked: {
      type: Boolean,
      default: false,
    },
    zoom: {
      type: Boolean,
      default: false,
    },
    lineType: {
      type: String,
      default: 'smooth',
    },
    forecastDataPoints: {
      type: Number,
      default: 0,
    },
    theme: {
      type: Array,
      default: () => ['#cccccc', '#bbbbbb', '#eeeeee'],
    },
    dataUpdate: {
      type: [Array, Object],
      default: () => {},
    },

    /**
     * Name of the chart used in export filenames
     */
    name: {
      type: String,
      required: false,
    },
  },
  emits: ['export'],
  data() {
    return {
      chartOptions: this.generateChartOptions(),
    };
  },
  computed: {
    updateChart() {
      const {series, xaxisType, stacked, dataLabels, zoom, lineType, forecastDataPoints, theme, group} = this;
      return {
        series,
        xaxisType,
        stacked,
        dataLabels,
        zoom,
        lineType,
        forecastDataPoints,
        theme,
        group,
      };
    },
    dataSeries() {
      return normalizeApexSeries(this.series);
    },
  },
  watch: {
    updateChart(val) {
      this.chartOptions = {...this.generateChartOptions()};
    },
    dataUpdate(newVal, oldVal) {
      if (newVal?.update || JSON.stringify(oldVal) !== JSON.stringify(newVal)) {
        this.updateData(normalizeApexSeries(newVal));
      }
    },
  },
  methods: {
    updateData(data) {
      //DEV: Paths get morphed, so dont animate.
      //  The slide effect in the apex realtime demo is the result of a svgjs bug,
      //  When xaxis is fixed & larger than the series (using xaxis.range) svgjs will slide in stead of morph the data.
      //  When the series stops growing, or draws outside the graph boundary the data will start morphing.
      //  So keep adding datapoints AND set series head (data[0].x=data[1].x) to prevent drawing will keep svgjs in slide mode.
      //
      //  Ensuring this in the data is too much work, so we do not animate.
      if (data.append) this.$refs.chart.chart?.appendSeries(data.series, false);
      else this.$refs.chart.chart?.updateSeries(data.series, false);
    },
    generateChartOptions() {
      return autoFitToGrid({
        chart: {
          height: this.height,
          type: 'line',
          group: this.group ?? undefined,
          id: `mixed_${++_chartcounter}`,
          stacked: this.stacked,
          toolbar: {
            show: true,
            tools: {
              customIcons: [
                {
                  icon: '',
                  //Apex icons will be hidden at a later stage - available options depend on series types. Just default to 1 for now.
                  index: -1,
                  title: 'Export Excel',
                  class: 'custom-icon exportexcel',
                  click: () => {
                    this.$emit('export');
                  },
                },
              ],
            },
            export: {
              csv: {filename: this.name},
              svg: {filename: this.name},
              png: {filename: this.name},
            },
          },
          zoom: {
            enabled: this.zoom,
          },
          events: {
            mounted: ctx => this[ToolbarProxy.InjectionKey]?.update(ctx.el),
            updated: ctx => this[ToolbarProxy.InjectionKey]?.update(ctx.el),
          },
        },
        forecastDataPoints: {
          count: this.forecastDataPoints,
        },
        colors: this.theme,
        plotOptions: {
          bar: {
            borderRadius: 4,
          },
        },
        dataLabels: {
          enabled: this.dataLabels,
          hideOverflowingLabels: true,
          formatter: val => this.$format(val, 'widgetdatalabel'),
        },
        stroke: {
          width: 3, // Svg path border
          curve: this.lineType,
        },
        xaxis: {
          type: this.xaxisType,
          tickAmount: 'dataPoints',

          //there is no horizontal mixed chart, so to show toolbar tickplacement needs to be 'on'
          tickPlacement: 'on',
        },
        yaxis: [
          {
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
            },
            tooltip: {
              enabled: true,
            },
            labels: {
              formatter: val => this.$format(val, 'widgetdatalabel'),
            },
          },
        ],
        tooltip: {
          fixed: {
            enabled: true,
            position: 'topLeft',
            offsetY: 30,
            offsetX: 60,
          },
        },
        legend: {
          horizontalAlign: 'left',
          offsetX: 40,

          //By default Apex shows a legend when the chart has at least two series with at least one containing data
          //The legend may break in certain circumstances when collapsing a series (https://github.com/apexcharts/apexcharts.js/issues/3156)
          //As a workaround, we'll manually force our legend to be displayed when matching Apex' default conditions
          showForSingleSeries: this.series.length > 1 && this.series.some(series => series.data.length),
        },
        noData: {
          text: this.$t('WIDGET_NO_DATA'),
          style: {
            color: 'var(--text-primary, #313741)',
          },
        },
      });
    },
  },
};
</script>

<docs>
  ```jsx
    <XMixedChart
      height="360"
      width="700"
      :series="[
        {
          name: 'Conversie',
          type: 'column',
          data: [
            {
              x: 'DM',
              y: 249,
            },
            {
              x: 'DU',
              y: 792,
            },
            {
              x: 'EM',
              y: 454,
            },
            {
              x: 'OL',
              y: 1472
            },
            {
              x: 'PG',
              y: 1774
            },
            {
              x: 'Retentie',
              y: 2800
            },
            {
              x: 'Spontaan',
              y: 743
            },
          ]
        },
        {
          name: 'Target',
          type: 'column',
          data: [
            {
              x: 'DM',
              y: 230,
            },
            {
              x: 'DU',
              y: 190,
            }, 
            {
              x: 'EM',
              y: 410,
            },
            {
              x: 'OL',
              y: 1200,
            },
            {
              x: 'PG',
              y: 1600
            },
            {
              x: 'Retentie',
              y: 2977
            },
            {
              x: 'Spontaan',
              y: 491
            },
          ]
        },
        {
          name: 'Vorig week deze periode',
          type: 'line',
          data: [
            {
              x: 'DM',
              y: 330,
            },
            {
              x: 'DU',
              y: 845,
            },
            {
              x: 'EM',
              y: 810,
            },
            {
              x: 'OL',
              y: 1600,
            },
            {
              x: 'PG',
              y: 1800
            },
            {
              x: 'Retentie',
              y: 3000
            },
            {
              x: 'Spontaan',
              y: 501
            },
          ]
        }
      ]"
      xaxisType="category"
      :dataLabels="true"
      :stacked="false"
      :zoom="true"
      lineType="smooth"
      :forecastDataPoints="2"
      :theme="['#2EB2FF', '#10CB73', '#F2DF32', '#D32D85', '#0DD2D2', '#E04E2A', '7731BD', '#1FBB97']"
      name="Mixed"
    />
    ```
</docs>

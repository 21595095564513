<template>
  <div ref="component-wrapper" :class="['tooltip-container', `tooltip-pos-${tooltipPosition}`]">
    <div @mouseenter="showTooltip" @mouseleave="hideTooltip(false)">
      <slot />
    </div>
    <div ref="tooltip-wrapper" class="tooltip-outerbox-wrapper">
      <div class="tooltip-outerbox-wrapper-inner">
        <div
          :class="['tooltip-outerbox', componentStyle, tooltipAlignment]"
          ref="tooltip-outerbox"
          :style="{
            padding: padding.match(/(xs|sm|md|lg|xl|xxl)/) ? `var(--spacing-${padding})` : padding,
          }"
          @mouseover="showTooltip"
          @mouseleave="hideTooltip(false)"
          v-if="tooltipOpen"
        >
          <div class="tooltip-box">
            <div class="tooltip-header" v-if="title">
              <h4 class="heading">
                {{ title }}
              </h4>
            </div>
            <slot name="content" />
          </div>
          <div class="arrow" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {wait} from './../../helpers/util';

export default {
  name: 'XTooltip',
  props: {
    title: {
      type: String,
      default: '',
    },
    tooltipPosition: {
      type: String,
      default: 'top',
      validator: value => {
        return value.match(/(top|bottom|left|right)/);
      },
    },
    componentStyle: {
      type: String,
      default: 'white',
      validator: value => {
        return value.match(/(white|primary|black)/);
      },
    },
    padding: {
      type: String,
      default: 'lg',
    },
    tooltipAlignment: {
      type: String,
      default: 'center',
      validator: value => {
        return value.match(/(center|start|end)/);
      },
    },
  },
  data() {
    return {
      tooltipOpen: false,
      showTimeOutId: 0,
      hideTimeOutId: 0,
    };
  },
  watch: {
    tooltipOpen(newVal) {
      if (newVal) {
        this.updateTooltipPosition();
      }
    },
  },
  methods: {
    hideTooltip(instant) {
      window.clearTimeout(this.showTimeOutId);
      window.clearTimeout(this.hideTimeOutId);

      const hide = async () => {
        this.$refs['tooltip-outerbox']?.classList.add('hidden');
        //Wait for animation
        await wait(100);
        this.tooltipOpen = false;
      };

      if (instant) {
        hide();
      } else {
        this.hideTimeOutId = setTimeout(() => {
          hide();
        }, 500);
      }
    },
    showTooltip() {
      window.clearTimeout(this.hideTimeOutId);
      window.clearTimeout(this.showTimeOutId);
      this.showTimeOutId = setTimeout(() => {
        this.$refs['tooltip-outerbox']?.classList.remove('hidden');
        this.tooltipOpen = true;
      }, 300);
    },
    updateTooltipPosition() {
      const elementWrapper = this.$refs['component-wrapper'];
      const tooltipWrapper = this.$refs['tooltip-wrapper'];

      const boundingRect = elementWrapper.getBoundingClientRect();
      tooltipWrapper.style.top = `${boundingRect.top}px`;
      tooltipWrapper.style.left = `${boundingRect.left}px`;
      tooltipWrapper.style.height = `${boundingRect.height}px`;
      tooltipWrapper.style.width = `${boundingRect.width}px`;

      if (!this.tooltipOpen) return;

      window.requestAnimationFrame(this.updateTooltipPosition);
    },
  },
};
</script>

<style lang="scss" scoped>
@use '../../assets/styles/variables.scss' as *;
.tooltip-container {
  position: relative;
  width: fit-content;

  .tooltip-outerbox-wrapper {
    position: fixed;
    height: 100px;
    width: 100px;
    z-index: var(--zindex-popover);
    pointer-events: none;
  }

  .tooltip-outerbox-wrapper-inner {
    position: relative;
    height: 100%;
    width: 100%;
    pointer-events: none;
  }

  .tooltip-outerbox {
    display: block;
    position: absolute;
    background: var(--main-background, #ffffff);
    max-width: 25rem;
    width: max-content;
    border-radius: var(--radius-xs);
    box-shadow: var(--main-shadow, 0px 0px 7px 0px rgba(71, 82, 97, 0.2));
    animation: show 0.1s ease-out;
    pointer-events: initial;

    &.hidden {
      animation: hide 0.1s ease-in-out forwards;
    }

    .arrow {
      position: absolute;
      width: var(--tooltip-size);
      height: calc(var(--tooltip-size) / 2);
      overflow: hidden;

      &::after {
        content: '';
        position: absolute;
        width: calc(var(--tooltip-size) / 2);
        height: calc(var(--tooltip-size) / 2);
        background-color: var(--main-background, #ffffff);
        box-shadow: var(--main-shadow, 0px 0px 7px 0px rgba(71, 82, 97, 0.2));
        z-index: var(--zindex-popover);
      }
    }

    &.primary {
      background: var(--button-primary, #075087);
      color: var(--button-text-primary, #fff);

      .arrow::after {
        background-color: var(--button-primary, #075087);
      }
    }

    &.black {
      background: #000; // black also in dark mode. No variable required here
      color: var(--button-text-primary, #fff);

      .arrow::after {
        background-color: #000;
      }
    }
  }

  &:hover .tooltip-outerbox {
    display: block;
  }

  .tooltip-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 1rem;
  }

  &.tooltip-pos-top,
  &.tooltip-pos-bottom {
    .tooltip-outerbox {
      &.center {
        left: 50%;
        translate: -50%;
        .arrow {
          left: 50%;
          translate: -50%;
        }
      }

      &.start {
        left: 0;
        translate: 0;
      }

      &.end {
        right: 0;
        translate: 0;

        .arrow {
          right: 0;
          translate: -100%;
        }
      }
    }
  }

  &.tooltip-pos-bottom {
    .tooltip-outerbox {
      top: calc(100% + calc(var(--tooltip-size) / 2));
      transform-origin: 50% calc(0% - var(--tooltip-size) / 2);
      .arrow {
        bottom: 100%;
        transform: rotate(180deg);

        &::after {
          left: 50%;
          transform: translate(-50%, -50%) rotate(225deg);
        }
      }
    }
  }

  &.tooltip-pos-top {
    .tooltip-outerbox {
      bottom: calc(100% + calc(var(--tooltip-size) / 2));
      transform-origin: 50% calc(100% + var(--tooltip-size) / 2);
      .arrow {
        top: 100%;

        &::after {
          left: 50%;
          transform: translate(-50%, -50%) rotate(45deg);
        }
      }
    }
  }

  &.tooltip-pos-left,
  &.tooltip-pos-right {
    .tooltip-outerbox {
      top: 50%;
      translate: 0 -50%;
    }

    .arrow {
      top: 50%;
      translate: 0 -50%;
    }
  }

  &.tooltip-pos-left {
    .tooltip-outerbox {
      right: calc(100% + calc(var(--tooltip-size) / 2));
      transform-origin: calc(100% + var(--tooltip-size) / 2) 50%;

      .arrow {
        left: calc(100% - 0.3rem);
        transform: rotate(-90deg);

        &::after {
          left: 50%;
          transform: translate(-50%, -50%) rotate(45deg);
        }
      }
    }
  }

  &.tooltip-pos-right {
    .tooltip-outerbox {
      left: calc(100% + calc(var(--tooltip-size) / 2));
      transform-origin: calc(0% - var(--tooltip-size) / 2) 50%;

      .arrow {
        right: calc(100% - 0.3rem);
        transform: rotate(90deg);

        &::after {
          left: 50%;
          transform: translate(-50%, -50%) rotate(-45deg);
          box-shadow: var(--main-shadow, 0px 0px 7px 0px rgba(71, 82, 97, 0.2));
        }
      }
    }
  }
}

.heading {
  font-size: var(--font-h4);
  margin-bottom: var(--spacing-lg);
  font-weight: $weight-semi-bold;
  margin-top: 0;
}

@keyframes show {
  from {
    scale: 0%;
  }
  to {
    scale: 100%;
  }
}

@keyframes hide {
  from {
    scale: 100%;
  }
  to {
    scale: 0%;
  }
}
</style>

<docs>
  ```jsx
  // De div is alleen voor deze preview
  <div style="padding: 15rem 0; display:flex; flex-direction:row; gap:1rem">
    <XTooltip
      title="Titel"
      tooltip-position="top">
      <XButton>Tooltip top</XButton>
      <template #content>
        Lorem ipsum dolor sit amet, adipiscing elit. Vivamus nec metus facilisis, condimentum tortor vestibulum, commodo dolor. Nunc sit amet mauris eget sapien congue scelerisque. Donec ut ornare elit. Vestibulum vitae blandit urna, sed pellentesque neque. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.
      </template>
    </XTooltip>
    <XTooltip
      title="Titel"
      tooltip-position="right">
      <XButton>Tooltip right</XButton>
      <template #content>
        Lorem ipsum dolor sit amet, adipiscing elit. Vivamus nec metus facilisis, condimentum tortor vestibulum, commodo dolor. Nunc sit amet mauris eget sapien congue scelerisque. Donec ut ornare elit. Vestibulum vitae blandit urna, sed pellentesque neque. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.
      </template>
    </XTooltip>
    <XTooltip
      title="Titel"
      tooltip-position="bottom">
      <XButton>Tooltip bottom</XButton>
      <template #content>
        Lorem ipsum dolor sit amet, adipiscing elit. Vivamus nec metus facilisis, condimentum tortor vestibulum, commodo dolor. Nunc sit amet mauris eget sapien congue scelerisque. Donec ut ornare elit. Vestibulum vitae blandit urna, sed pellentesque neque. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.
      </template>
    </XTooltip>
    <XTooltip
      title="Titel"
      component-style="primary"
      tooltip-position="left">
      <XButton>Tooltip left</XButton>
      <template #content>
        I'm blue
      </template>
    </XTooltip>
    <XTooltip
      title="Titel"
      tooltipAlignment="end"
      tooltip-position="bottom">
      <XButton>Tooltip bottom right</XButton>
      <template #content>
        Lorem ipsum dolor sit amet, adipiscing elit. Vivamus nec metus facilisis, condimentum tortor vestibulum, commodo dolor. Nunc sit amet mauris eget sapien congue scelerisque. Donec ut ornare elit. Vestibulum vitae blandit urna, sed pellentesque neque. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.
      </template>
    </XTooltip>
    <XTooltip
      title="Titel"
      tooltipAlignment="start"
      tooltip-position="top">
      <XButton>Tooltip top left</XButton>
      <template #content>
        Lorem ipsum dolor sit amet, adipiscing elit. Vivamus nec metus facilisis, condimentum tortor vestibulum, commodo dolor. Nunc sit amet mauris eget sapien congue scelerisque. Donec ut ornare elit. Vestibulum vitae blandit urna, sed pellentesque neque. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.
      </template>
    </XTooltip>
  </div>
  ```
</docs>

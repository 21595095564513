import { useChartComponent } from "./composables";
import { RadarBand } from "./bands";
export default {
    name: 'XChartRadarBand',
    props: {
        min: {
            type: Number,
            required: true
        },
        max: {
            type: Number,
            required: true
        },
        color: {
            type: String,
            required: true
        }
    },
    setup(props) {
        useChartComponent(() => new RadarBand({
            min: props.min,
            max: props.max,
            color: props.color,
        }));
    },
    render() { }
};

import {designSystemContext} from './helpers/webpack-component-context.js';
import {setTranslationProvider} from './helpers/translationsProvider';
import {Theme} from './helpers/vue-theming.js';
import {$can} from './utils/dnxacl';

const resolveComponent = relativePath => designSystemContext(relativePath);

const install = (app, options) => {
  options = options || {};
  setTranslationProvider(options.setTranslationProvider);

  app.config.globalProperties.$can = $can;

  // Global theme
  window.Theme = Theme;
  Theme.applyTo();

  // Register our components as global components.
  designSystemContext.keys().forEach(relativePath => {
    const [, fileName] = /(\w+).vue$/.exec(relativePath);
    const componentName = fileName[0].toUpperCase() + fileName.substring(1);

    app.component(componentName, resolveComponent(relativePath));
  });
};

export default {
  install,
  version: '1.0.0',
};

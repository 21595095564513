import Fuse from 'fuse.js';
import { ref, shallowRef, watch } from 'vue';
import { debounce } from 'lodash';
// should we track our active search somewhere?
// should we have a composable providing scoping to vue?
// service should be able to accept arbitrary comparers for specific keys
/**
 * Service used for searching through local datasets, will use fuzzy search by default
 */
export class SearchService {
    constructor() {
        // noinspection JSMismatchedCollectionQueryUpdate
        this._records = [];
    }
    static create(options) {
        var _a;
        const service = new SearchService();
        service._records = options.records || [];
        service._engine = new Fuse(service._records, {
            // perform a case insensitive search
            isCaseSensitive: false,
            // automatically sort by by score
            shouldSort: true,
            threshold: 0.3,
            // either take explicitly provided properties, or infer from our data
            keys: options.searchableProperties || Object.keys((_a = service._records[0]) !== null && _a !== void 0 ? _a : {}),
        });
        return service;
    }
    /** Perform a simple search on the given term */
    search(term) {
        if ((term !== null && term !== void 0 ? term : '') === '')
            return this._records;
        // https://www.fusejs.io/api/query.html#logical-search-with-dotted-keys
        // we should expand our search to be a bit smarter, integrate with UI?
        // offer autocomplete? -> fuse allows for structured search query definitions
        // should we try to do something with sorting weights?
        return this._engine.search(term).map(x => x.item);
    }
}
/** Composable for easy consumption of generic filter based search */
export function useSearch(options) {
    const filteredRecords = shallowRef(options.records.value);
    let service = SearchService.create({
        records: options.records.value,
        searchableProperties: options.searchableProperties,
    });
    watch(() => options.records.value, () => {
        service = SearchService.create({
            records: options.records.value,
            searchableProperties: options.searchableProperties,
        });
        filteredRecords.value = options.records.value;
    });
    /** Perform a search action on the underlying records for the given term */
    function search(term) {
        filteredRecords.value = service.search(term);
    }
    // glue to prevent XInput from losing state when running in uncontrolled mode
    // should be removed once xinput doesn't autoclear anymore
    const query = ref('');
    watch(query, query => search(query));
    return {
        filteredRecords,
        search: debounce(search, 300),
        query,
    };
}

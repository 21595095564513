<template>
  <v-chart class="chart" :option="option" autoresize />
</template>

<script>
import {use} from 'echarts/core';
import {CanvasRenderer} from 'echarts/renderers';
import {GraphChart} from 'echarts/charts';
import {TitleComponent, TooltipComponent, LegendComponent} from 'echarts/components';
import VChart, {THEME_KEY} from 'vue-echarts';

use([CanvasRenderer, GraphChart, TitleComponent, TooltipComponent, LegendComponent]);
const cssVariableColors = getComputedStyle(document.documentElement);

export default {
  name: 'XGraphChart',
  components: {
    VChart,
  },
  props: {
    nodes: {
      type: Array,
      default: () => [],
    },
    links: {
      type: Array,
      default: () => [],
    },
    categories: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    option() {
      return {
        tooltip: {},
        legend: {
          y: 'bottom',
          // selectedMode: 'single',
          data: this.categories.map(function (a) {
            return a.name;
          }),
          textStyle: {
            color: cssVariableColors.getPropertyValue('--text-primary'),
          },
        },
        series: [
          {
            type: 'graph',
            layout: 'force',
            data: this.nodes,
            links: this.links,
            categories: this.categories,
            roam: true,
            label: {
              position: 'right',
            },
            force: {
              repulsion: 100,
            },
          },
        ],
      };
    },
  },
};
</script>

<style scoped>
.chart {
  height: 700px;
  width: 100%;
}
</style>

<docs>
  ```jsx
    <XGraphChart
      :nodes="[
        {
          'id': '0',
          'name': 'Myriel',
          'symbolSize': 25,
          'x': -50,
          'y': 25,
          'value': 30,
          'category': 0
        },
        {
          'id': '1',
          'name': 'Napoleon',
          'symbolSize': 10,
          'x': 50,
          'y': 25,
          'value': 20,
          'category': 0
        },
        {
          'id': '2',
          'name': 'MlleBaptistine',
          'symbolSize': 15,
          'x': 0,
          'y': -40,
          'value': 10,
          'category': 1
        },
        {
          'id': '3',
          'name': 'Tony',
          'symbolSize': 25,
          'x': 0,
          'y': 40,
          'value': 20,
          'category': 1
        }
      ]"

      :links="[
        {
          source: '1',
          target: '0'
        },
        {
          source: '1',
          target: '2'
        },
        {
          source: '2',
          target: '0'
        },
        {
          source: '2',
          target: '3'
        },
        {
          source: '3',
          target: '0'
        }
      ]"

      :categories="[
        {
          name: 'Categorie 1'
        },
        {
          name: 'Categorie 2'
        }
      ]"
    />
  ```
</docs>

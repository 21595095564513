import { ChartComponent } from "./chartInstance";
import nl_NL from '@amcharts/amcharts5/locales/nl_NL';
import en from '@amcharts/amcharts5/locales/en';
/** Render the chart in a specific locale */
export class Locale extends ChartComponent {
    getTags() { return ['locale']; }
    constructor(settings) {
        super();
        this.lcid = settings.lcid;
    }
    setup(context) {
        super.setup(context);
        context.root.locale = this.resolveLocale();
    }
    render(context) { }
    resolveLocale() {
        switch (this.lcid.toLowerCase()) {
            case 'nl':
            case 'nl_nl':
                return nl_NL;
            default:
                return en;
        }
    }
}

const light = {
  name: 'Light',
  className: 'theme-light',
};

const dark = {
  name: 'Dark',
  className: 'theme-dark',
};

export {light, dark};

export default {
  light,
  dark,
};

import format from '../../../../utils/formatting';
import { sanitizeText } from '../../../../helpers/util';
import { ChartComponent } from './chartInstance';
import { ColumnSeries } from '@amcharts/amcharts5/xy';
import { PieSeries } from '@amcharts/amcharts5/percent';
import { Tooltip } from '@amcharts/amcharts5';
import { ensureCursor, getSeriesComponent } from './util';
function createTargetFromSeries(context, series) {
    var _a, _b;
    const xyData = (_a = series.get('tooltipDataItem')) === null || _a === void 0 ? void 0 : _a.dataContext;
    return {
        dataItem: xyData && { category: xyData.x, value: xyData.y },
        color: series.get('stroke').toCSSHex(),
        name: (_b = getSeriesComponent(context, series)) === null || _b === void 0 ? void 0 : _b.name,
        _hide: xyData === null || xyData === void 0 ? void 0 : xyData._hide,
    };
}
function createTargetFromColumn(context, column) {
    var _a, _b, _c;
    const xyData = (_a = column.dataItem) === null || _a === void 0 ? void 0 : _a.dataContext;
    return {
        dataItem: {
            category: xyData.x,
            value: xyData.y,
        },
        color: column.get('fill').toCSSHex(),
        name: (_c = getSeriesComponent(context, (_b = column.dataItem) === null || _b === void 0 ? void 0 : _b.component)) === null || _c === void 0 ? void 0 : _c.name,
        _hide: xyData._hide,
    };
}
function createTargetFromSlice(slice) {
    var _a, _b, _c, _d;
    return {
        dataItem: (_a = slice === null || slice === void 0 ? void 0 : slice.dataItem) === null || _a === void 0 ? void 0 : _a.dataContext,
        color: (_b = slice === null || slice === void 0 ? void 0 : slice.get('fill')) === null || _b === void 0 ? void 0 : _b.toCSSHex(),
        name: (_d = (_c = slice === null || slice === void 0 ? void 0 : slice.dataItem) === null || _c === void 0 ? void 0 : _c.component) === null || _d === void 0 ? void 0 : _d.get('name'),
    };
}
/** Add tooltips to the given chart */
export class SeriesTooltips extends ChartComponent {
    getTags() {
        return ['tooltip'];
    }
    constructor(settings) {
        super();
        this.addDependency(settings.series);
    }
    render(context) {
        const series = context.get('series');
        if (!series) {
            console.warn('No series found on context, a dependency providing a "series" for labels to be rendered');
            return;
        }
        const categoryType = context.get('categoryType');
        const allSeries = context.get('seriesComponents');
        const chart = series.chart;
        // When dealing with lineseries, pivot to a strategy simply cramming all series together in a single tooltip
        const shouldUseCombinedTooltip = allSeries.some(series => series.getTags().includes('line-like-series'));
        if (series.isType(PieSeries.className)) {
            this.pieTooltip(context, series, categoryType);
            return;
        }
        if (shouldUseCombinedTooltip) {
            this.allSeriesTooltip(context, chart, categoryType);
            return;
        }
        if (series.isType(ColumnSeries.className))
            this.barTooltip(context, series, categoryType);
    }
    /** Apply a tooltip shown when hovering over a bar in a bar series */
    barTooltip(context, series, categoryType) {
        // echarts look and feel
        series.columns.template.adapters.add('tooltipHTML', (value, target) => this.buildTooltipHTMLContent(createTargetFromColumn(context, target), categoryType));
    }
    /** Apply a tooltip shown when hovering over a slice in a pie series */
    pieTooltip(context, series, categoryType) {
        const tooltip = Tooltip.new(context.root, {});
        series.slices.template.set('tooltip', tooltip);
        tooltip.adapters.add('labelHTML', (value, target) => this.buildTooltipHTMLContent(createTargetFromSlice(target.get('tooltipTarget')), categoryType));
    }
    /** Apply a single tooltip shared across all series */
    allSeriesTooltip(context, chart, categoryType) {
        // combining multiple series into a single tooltip by appending our tooltip to our chart's plotContainer:
        // https://codepen.io/team/amcharts/pen/ExXrXYo
        // https://www.amcharts.com/docs/v5/charts/xy-chart/xy-chart-containers/
        const plotContainer = chart.plotContainer;
        if (plotContainer.get('tooltip'))
            return;
        // plotcontainer tooltips require a cursor to be present, add an invisible one if not yet already setup on our chart
        if (!chart.get('cursor')) {
            const cursor = ensureCursor(chart);
            cursor.lineX.set('visible', false);
            cursor.lineY.set('visible', false);
        }
        const tooltip = Tooltip.new(context.root, {});
        // While the tutorial uses `label.text`.. and updates properly... putting an adapter on `label.html` will yield
        // constant invocations, with only the first result being cached and constantly reused
        // hooking into the labelHTML property of our tooltip itself seems to not have this issue
        // https://www.amcharts.com/docs/v5/concepts/common-elements/html-content/#Tooltips
        tooltip.adapters.add('labelHTML', () => this.buildTooltipHTMLContent(chart.series.values.map(series => createTargetFromSeries(context, series)), categoryType));
        plotContainer.setAll({
            tooltipPosition: 'pointer',
            tooltip: tooltip,
            // required for our provided tooltip to be invoked, also present in amcharts example
            tooltipHTML: 'a',
        });
    }
    buildTooltipHTMLContent(targets, categoryType) {
        // ensure we're dealing with 1 dimensional arrays for ease of use
        targets = [targets].flat().filter(target => target.dataItem && !target._hide);
        // return a valid html element when no tooltip could be created
        // amcharts crashes upon tooltip removal when an invalid element/nothing was provided
        // when `undefined` is returned, we may accidentally display a placeholder value
        if (targets.length === 0)
            return '<span />';
        const toCSS = (source) => Object.entries(source)
            .map(([key, value]) => `${key}: ${value};`)
            .join('\n');
        const containerStyle = {
            display: ' block',
            'border-style': ' solid',
            'white-space': ' nowrap',
            'z-index': ' 9999999',
            'box-shadow': ' rgba(0, 0, 0, 0.2) 1px 2px 10px',
            'background-color': ' rgb(255, 255, 255)',
            'border-width': ' 1px',
            'border-radius': ' 4px',
            color: ' rgb(102, 102, 102)',
            font: ' 14px / 21px Microsoft YaHei',
            'padding: 10px; top: 0px; left': ' 0px',
            'pointer-events': ' none',
        };
        const markerStyle = {
            display: 'inline-block',
            'margin-right': '4px',
            'border-radius': '10px',
            width: '10px',
            height: '10px',
        };
        // when dealing with a single targets, inherit border from targets
        if (targets.length === 1)
            containerStyle['border-color'] = targets[0].color;
        const categoryFormatter = categoryType === 'datetime' ? (value) => format(value, 'date') : (value) => value;
        // all items are expected to share the same category
        const category = categoryFormatter(targets[0].dataItem.category);
        // when we have more than 1 targets, or our single targets has had a name configured, we'll be displaying
        // our category in our header, and our targets names for our individual datapoint tooltips
        // otherwise we'll just show our category as datapoint label
        const categoryInHeader = targets.length > 1 || targets[0].name;
        const header = categoryInHeader ? `${sanitizeText(category)} <br />` : '';
        const seriesHTML = targets
            .map(target => {
            const label = categoryInHeader ? target.name : category;
            return `
        <span style="${toCSS(Object.assign(Object.assign({}, markerStyle), { 'background-color': target.color }))}"></span>
        ${sanitizeText(label)}: ${sanitizeText(target.dataItem.value)}
      `;
        })
            .join('\n<br />');
        return `
      <div style="${toCSS(containerStyle)}">
        ${header}
        ${seriesHTML}
      </div>
    `;
    }
}

import { Labels } from './labels';
import { useChartComponent, useSeries } from './composables';
export default {
    name: 'XChartLabels',
    setup() {
        const series = useSeries();
        // provide(labelTarget, seriesCmp)
        // provide(labelTarget, chartCmp)
        // should label support be a tag? -> tags supplied via interfaces/properties being available (configureLabels method = tag support?)
        // when placed within a series -> add label support for said, series, otherwise assume we're supposed to render for our value axis (only other usecase right now)
        useChartComponent(() => {
            if (series)
                return Labels.forSeries(series.value);
            return Labels.forValueAxis();
        });
    },
    render: () => { },
};

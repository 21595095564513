import { Theme } from './theme';
import { useChartComponent } from './composables';
export default {
    name: 'XChartTheme',
    props: {
        colors: {
            type: Array,
            default: () => [],
        }
    },
    setup(props) {
        useChartComponent(() => new Theme(props.colors));
    },
    render: () => undefined,
};

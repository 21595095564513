var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useConfirmStore } from '../stores/confirmation';
import { useEditorStore } from '../stores/editor';
// import {DefaultButton} from '@app/stores/types/Button';
import { T } from '../helpers/translationsProvider';
import { onMounted, onUnmounted, ref } from 'vue';
import { onBeforeRouteLeave } from 'vue-router';
export default () => {
    const isProlongedTimeActive = ref(false);
    let timeout;
    const editor = useEditorStore();
    onMounted(() => {
        timeout = window.setTimeout(() => {
            isProlongedTimeActive.value = true;
            window.onbeforeunload = e => {
                e.preventDefault();
                e.returnValue = '';
            };
        }, 5000);
    });
    onUnmounted(() => {
        clearTimeout(timeout);
        window.onbeforeunload = null;
    });
    onBeforeRouteLeave(() => __awaiter(void 0, void 0, void 0, function* () {
        if (isProlongedTimeActive.value && !editor.enforceSkipConfirmation) {
            const confirmed = yield useConfirmStore().prompt(T('ROUTE_LEAVE_CONFIRMATION_TITLE'), T('ROUTE_LEAVE_CONFIRMATION_MESSAGE'), 'warning'
            // {
            //   denyButton: DefaultButton.cancel,
            //   confirmButton: {...DefaultButton.close, iconName: 'clear'},
            // }
            );
            if (!confirmed)
                return false;
        }
    }));
};

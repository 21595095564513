import { SeriesTooltips } from './tooltips';
import { useChartComponent, useSeries } from './composables';
export default {
    name: 'XChartSeriesTooltips',
    setup() {
        const series = useSeries();
        useChartComponent(() => new SeriesTooltips({ series: series.value }));
    },
    render: () => undefined,
};

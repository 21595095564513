import { inject } from 'vue';
/**
 * Object used for interacting with wrapped element's Apex charts toolbar
 */
export class ToolbarProxy {
    constructor() {
        this.supportsZoom = false;
        this.supportsPan = false;
        this.supportsExport = false;
        this.supportsPng = false;
        this.toolbarElement = undefined;
        this.actionListeners = new Map();
        this.update(undefined);
    }
    /**
     * Update the wrapped Apex element, re-verify supported features
     * @param {HTMLElement} [wrappedElement]
     */
    update(wrappedElement) {
        this.toolbarElement = wrappedElement === null || wrappedElement === void 0 ? void 0 : wrappedElement.querySelector('.apexcharts-toolbar');
        const isSupported = (selector) => !!this._findToolbarElement(selector);
        this.supportsZoom = isSupported('.apexcharts-zoom-icon');
        this.supportsPan = isSupported('.apexcharts-pan-icon');
        this.supportsExport = isSupported('.exportexcel');
        this.supportsPng = isSupported('.exportPNG');
    }
    /**
     * Invoke a click on the Apex toolbar element matching the given action
     * @param {ToolbarAction} action
     */
    invoke(action) {
        const customListeners = this.actionListeners.get(action);
        if (customListeners && customListeners.length) {
            for (let handler of customListeners)
                handler();
            return;
        }
        if (action === 'reset')
            return this._clickToolbarElement('.apexcharts-reset-icon');
        if (action === 'zoom-in')
            return this._clickToolbarElement('.apexcharts-zoomin-icon');
        if (action === 'zoom-out')
            return this._clickToolbarElement('.apexcharts-zoomout-icon');
        if (action === 'zoom-selection')
            return this._clickToolbarElement('.apexcharts-zoom-icon');
        if (action === 'pan')
            return this._clickToolbarElement('.apexcharts-pan-icon');
        if (action === 'export')
            return this._clickToolbarElement('.exportexcel');
        if (action === 'exportPNG')
            return this._clickToolbarElement('.exportPNG');
    }
    /**
     * Check whether the given feature has been enabled for the wrapped chart
     * @param {ToolbarFeature} feature
     * @return {boolean}
     */
    supports(feature) {
        if (feature === 'zoom')
            return this.supportsZoom;
        if (feature === 'pan')
            return this.supportsPan;
        // if (feature === 'export') return this.supportsExport; disable for now
        if (feature === 'exportPNG')
            return this.supportsPng;
        return false;
    }
    /**
     * Toggle support for the given feature
     * @param {'zoom'|'pan'|'png'} feature Name of the feature
     * @param {Boolean} isSupported Indicator for the feature being supported
     */
    toggleFeature(feature, isSupported) {
        feature = feature.toLowerCase();
        // zoom -> supportsZoom
        feature = `supports${feature[0].toUpperCase()}${feature.slice(1)}`;
        this[feature] = isSupported;
    }
    /**
     * Attach a custom listener for the given toolbar action
     * @param {'reset'|'zoom-in'|'zoom-out'|'zoom-selection'|'pan'|'export'|'exportPNG'} action
     * @param {function()} listener
     */
    addActionListener(action, listener) {
        if (!this.actionListeners.has(action))
            this.actionListeners.set(action, []);
        this.actionListeners.get(action).push(listener);
    }
    /**
     * Clear all custom listeners, when a type is provided only listeners of said type will be cleared
     * @param {ToolbarAction} [action] Optional action, when provided only listeners for this action are cleared
     */
    clearActionListeners(action) {
        if (action)
            this.actionListeners.delete(action);
        else
            this.actionListeners.clear();
    }
    _clickToolbarElement(selector) {
        const element = this._findToolbarElement(selector);
        const clickEvent = new Event('click');
        return element === null || element === void 0 ? void 0 : element.dispatchEvent(clickEvent);
    }
    _findToolbarElement(selector) {
        if (!this.toolbarElement)
            return undefined;
        return this.toolbarElement.querySelector(selector);
    }
}
/**
 * Key used for Vue provide/inject pattern
 * @type {string}
 */
ToolbarProxy.InjectionKey = 'ToolbarProxy';
/**
 * Injection definition for injecting a toolbarproxy with valid default, used for Vue provide/inject pattern
 * @type {{Object}}
 */
ToolbarProxy.InjectionDefinition = {
    [ToolbarProxy.InjectionKey]: {
        //Default as to not break when
        default: () => new ToolbarProxy(),
    },
};
/**
 * Composable for consuming ToolbarProxy
 * @returns {ToolbarProxy}
 */
export function useToolbar() {
    return inject(ToolbarProxy.InjectionKey, () => new ToolbarProxy(), true);
}

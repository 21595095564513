<template>
  <div />
</template>

<script>
import {default as themes} from '@src/components/assets/scripts/themes.js';

export default {
  name: 'XSelectTheme',
  props: {
    selectedTheme: {
      type: String,
      default: undefined,
    },
  },
  emits: ['theme'],
  data() {
    return {
      theme: this.getInitialTheme(),
    };
  },
  created() {
    // Ensure our theme class is applied to the body element
    this.applyTheme(this.theme);
    this.$emit('theme', this.theme);
  },
  watch: {
    selectedTheme(newVal, oldVal) {
      this.handleChange(newVal);
      this.$emit('theme', this.theme);
    },
  },
  methods: {
    handleChange(theme) {
      this.applyTheme(this.findTheme(theme));
      this.$emit('theme', this.theme);
    },
    findTheme(name) {
      return Object.values(themes).find(theme => theme.name === name);
    },
    applyTheme(newTheme) {
      const currentTheme = this.theme;

      //When selected we need to first remove our old theme
      if (currentTheme) {
        document.body.classList.remove(currentTheme.className);
      }

      document.body.classList.add(newTheme.className);

      this.persistTheme(newTheme);
      this.theme = newTheme;
    },
    getInitialTheme() {
      // When a theme was selected on a previous visit; prefer that theme
      // Otherwise take the first available theme
      const themeName = this.useStorage((localStorage, key) => localStorage.getItem(key));

      if (themeName) {
        const theme = this.findTheme(themeName);

        if (theme) {
          return theme;
        }
      }

      return Object.values(themes)[0];
    },
    persistTheme(theme) {
      this.useStorage((localStorage, key) => localStorage.setItem(key, theme.name));
    },
    useStorage(action) {
      const key = '__SELECTED_THEME__';

      // LocalStorage is purely used for retrieval of/persistance of selected theme
      // if it isn't available, the component should not crash
      const localStorage = window.localStorage || {
        getItem: key => {},
        setItem: (key, value) => {},
      };

      try {
        return action(localStorage, key);
      } catch (err) {
        console.error(err);

        return undefined;
      }
    },
  },
};
</script>

<docs>
  ```jsx
  <XSelectTheme 
    selectedTheme="Light"
  />
  ```
</docs>

<template>
  <component :is="type" class="tab-shortcut">
    <div class="tab-content">
      <div class="journey-details">
        <XIcon name="journey" background="var(--module-journey-composer, #D21460)" />
        <XHeading level="h5" color="var(--module-journey-composer)">
          {{ journeyName }}
        </XHeading>
      </div>
      <div class="icons">
        <!-- <div class="full-screen">
          <XIcon 
            name="focus"
            size="small"
            color="grey"
            :hoverEffect="true"
            @click="$emit('toFullScreen', true)"
          />
        </div>   -->
        <div class="close-tab">
          <XIcon
            name="clear"
            size="small"
            color="var(--icon-primary, #868686)"
            :hoverEffect="true"
            @click="$emit('close', true)"
          />
        </div>
      </div>
    </div>
  </component>
</template>

<script>
export default {
  name: 'JTabShortcut',
  props: {
    type: {
      type: String,
      default: 'div',
    },
    journeyName: {
      type: String,
      default: 'Journey',
    },
  },
  emits: ['toFullScreen', 'close'],
};
</script>

<style lang="scss" scoped>
.tab-shortcut {
  box-sizing: border-box;
  width: 100%;
  height: 3rem;
  background: var(--main-background, #ffffff);
  border-radius: 0.5rem;
  border: 0;
  display: flex;
  align-items: center;
  box-shadow: var(--main-shadow, 0px 0px 7px 0px rgba(71, 82, 97, 0.2));

  .tab-content {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    .journey-details {
      display: flex;
      align-items: center;
      column-gap: 0.75rem;
      padding: 0.75rem;
    }

    .icons {
      display: flex;
      height: 100%;

      .full-screen,
      .close-tab {
        column-gap: 2.8rem;
        border-left: 1px solid var(--container-border, #e8e8e8);
        padding: 1rem;
      }
    }
  }
}
</style>
<docs>
  ```jsx
    <div style="width: 22.5rem">
      <JTabShortcut journeyName="test" />
    </div>
  ```
</docs>

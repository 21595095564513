import {ToolbarProxy} from './toolbarProxy';

export const BarChartMixin = {
  inject: {
    ...ToolbarProxy.InjectionDefinition,
  },
  props: {
    stacked: {
      type: Boolean,
      default: false,
    },
    stackType: {
      type: String,
      default: null,
    },

    horizontal: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: '360',
    },
    width: {
      type: String,
      default: '100%',
    },
    group: {
      type: String,
    },
    series: {
      type: [Array, Object],
      default: () => [],
    },
    xaxisType: {
      type: String,
      default: 'category',
    },
    dataLabels: {
      type: Boolean,
      default: false,
    },
    zoom: {
      type: Boolean,
      default: false,
    },
    forecastDataPoints: {
      type: Number,
      default: 0,
    },
    theme: {
      type: Array,
      default: () => ['#cccccc', '#bbbbbb', '#eeeeee'],
    },
    dataUpdate: {
      type: [Array, Object],
      default: () => {},
    },

    /**
     * Name of the chart used in export filenames
     */
    name: {
      type: String,
      required: false,
    },
  },
  emits: ['export'],
};

const LineChartMixin = {
  inject: {
    ...ToolbarProxy.InjectionDefinition,
  },
  props: {
    height: {
      type: String,
      default: '360',
    },
    width: {
      type: String,
      default: '100%',
    },
    group: {
      type: String,
    },
    series: {
      type: [Array, Object],
      default: () => [],
    },
    xaxisType: {
      type: String,
      default: 'category',
    },
    dataLabels: {
      type: Boolean,
      default: false,
      style: {
        colors: ['var(--widget-dataLabelColor, #FFFFFF)'],
      },
    },
    stacked: {
      type: Boolean,
      default: false,
    },
    zoom: {
      type: Boolean,
      default: false,
    },
    lineType: {
      type: String,
      default: 'smooth',
    },
    annotations: {
      type: Array,
      default: () => [],
    },
    forecastDataPoints: {
      type: Number,
      default: 0,
    },
    theme: {
      type: Array,
      default: () => ['#cccccc', '#bbbbbb', '#eeeeee'],
    },

    /**
     * Name of the chart used in export filenames
     */
    name: {
      type: String,
      required: false,
    },
    dataUpdate: {
      type: [Array, Object],
      default: () => {},
    },

    area: {
      type: Boolean,
      required: false,
    },
  },
  emits: ['export'],
};

export const BubbleChartMixin = {
  inject: {
    ...ToolbarProxy.InjectionDefinition,
  },
  props: {
    height: {
      type: String,
      default: '360',
    },
    width: {
      type: String,
      default: '100%',
    },
    group: {
      type: String,
    },
    series: {
      type: [Array, Object],
      default: () => [],
    },
    xaxisType: {
      type: String,
      default: 'numeric',
    },
    dataLabels: {
      type: Boolean,
      default: false,
      style: {
        colors: ['var(--widget-dataLabelColor, #000000)'],
      },
    },
    theme: {
      type: Array,
      default: () => ['#cccccc', '#bbbbbb', '#eeeeee'],
    },
    dataUpdate: {
      type: [Array, Object],
      default: () => {},
    },

    /**
     * Name of the chart used in export filenames
     */
    name: {
      type: String,
      required: false,
    },
  },
  emits: ['export'],
};

export const MixedChartMixin = {
  inject: {
    ...ToolbarProxy.InjectionDefinition,
  },
  props: {
    height: {
      type: String,
      default: '360',
    },
    width: {
      type: String,
      default: '100%',
    },
    group: {
      type: String,
    },
    series: {
      type: [Array, Object],
      default: () => [],
    },
    xaxisType: {
      type: String,
      default: 'category',
    },
    dataLabels: {
      type: Boolean,
      default: false,
    },
    stacked: {
      type: Boolean,
      default: false,
    },
    zoom: {
      type: Boolean,
      default: false,
    },
    lineType: {
      type: String,
      default: 'smooth',
    },
    forecastDataPoints: {
      type: Number,
      default: 0,
    },
    theme: {
      type: Array,
      default: () => ['#cccccc', '#bbbbbb', '#eeeeee'],
    },
    dataUpdate: {
      type: [Array, Object],
      default: () => {},
    },

    /**
     * Name of the chart used in export filenames
     */
    name: {
      type: String,
      required: false,
    },
  },
  emits: ['export'],
};

export const PieChartMixin = {
  inject: {
    ...ToolbarProxy.InjectionDefinition,
  },
  props: {
    height: {
      type: String,
      default: '340',
    },
    width: {
      type: String,
      default: '100%',
    },
    series: {
      type: [Array, Object],
      default: () => [],
    },
    categories: {
      type: [Array, Object],
      default: () => [],
    },
    theme: {
      type: Array,
      default: () => ['#cccccc', '#bbbbbb', '#eeeeee'],
    },
    dataLabels: {
      type: Boolean,
      default: true,
    },
    legend: {
      type: Boolean,
      default: true,
    },
    tooltip: {
      type: Boolean,
      default: true,
    },
    dataUpdate: {
      type: [Array, Object],
      default: () => {},
    },
    /**
     * Name of the chart used in export filenames
     */
    name: {
      type: String,
      required: false,
    },
    
    /** Category value datatype */
    xaxisType: {
      type: String,
      required: false
    }
  },
  emits: ['export'],
};

export const WordCloudChartMixin = {
  inject: {
    ...ToolbarProxy.InjectionDefinition,
  },
  props: {
    name: {
      type: String,
      required: false
    },
    rotationRange: {
      type: Array,
      default: () => [0, 0],
    },
    series: {
      type: [Array, Object],
      default: () => {
        return {data: [{name: 'Default', value: 1000}]};
      },
    },
    theme: {
      type: Array,
      default: () => ['#cccccc', '#bbbbbb', '#eeeeee'],
    },
  },
  emits: ['export'],
}

export const GaugeChartMixin = {
  inject: {
    ...ToolbarProxy.InjectionDefinition,
  },
  props: {
    name: {
      type: String,
      required: false
    },
    dataLabels: {
      type: Boolean,
      default: true,
    },
    gaugeConfig: {
      type: Object,
      default: () => {},
    },
    locale: {
      type: String,
    },
    series: {
      type: [Array, Object],
      default: () => {
        return {data: [{name: 'Default', value: 10}]};
      },
    },
    dataUpdate: {
      type: [Array, Object],
      default: () => {
        return {data: []};
      },
    },
  },
  emits: ['export'],
}

export {LineChartMixin, LineChartMixin as AreaChartMixin};

<template>
  <component
    :is="wrapper"
    :class="['textarea', {'textarea-expand': width === 'expand' && !customWidth}]"
    :title="valueModel"
  >
    <div
      :class="['textarea-container', 'input-box', {'has-value': hasValue(modelValue)}]"
      :style="{width: customWidth}"
    >
      <XInputPlaceholder :placeholder="placeholder" />
      <div v-if="maxLength" class="text-length">
        {{ modelValue === null || undefined ? 0 : modelValue.length }}/{{ maxLength }}
      </div>
      <textarea
        :id="id"
        :disabled="disabled"
        :class="state"
        :maxlength="maxLength"
        @focus="onFocus($event.target.value)"
        @blur="onBlur($event.target.value)"
        @keyup="onKeyup($event.target.value)"
        @keydown="onKeydown($event.target.value)"
        @change="onChange($event.target.value)"
        v-model="valueModel"
      />
    </div>
  </component>
</template>

<script>
import _ from 'lodash';

/**
 * Textareas are used to allow users to provide text input when the expected
 * input is long. Textarea has a range of options. For shorter input,
 * use the `Input` element.
 */
export default {
  name: 'XTextarea',
  props: {
    /**
     * Text value of the form textarea.
     */
    modelValue: {
      type: String,
      default: null,
    },
    /**
     * The placeholder value for the form textarea.
     */
    placeholder: {
      type: String,
      default: null,
    },
    /**
     * The html element name used for the wrapper.
     * `div, section`
     */
    wrapper: {
      type: String,
      default: 'div',
      validator: value => {
        return value.match(/(div|section)/);
      },
    },
    /**
     * The width of the form textarea.
     * `auto, expand`
     */
    width: {
      type: String,
      default: 'expand',
      validator: value => {
        return value.match(/(auto|expand)/);
      },
    },
    customWidth: {
      type: String,
      default: undefined,
    },
    /**
     * Whether the form textarea is disabled or not.
     * `true, false`
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Manually trigger various states of the textarea.
     * `hover, active, focus`
     */
    maxLength: {
      type: [String, Number],
    },
    state: {
      type: String,
      default: null,
      validator: value => {
        return value.match(/(hover|active|focus)/);
      },
    },
  },
  emits: ['update:modelValue', 'input', 'focus', 'blur', 'onkeyup', 'onkeydown', 'changed'],
  data() {
    return {
      id: null,
    };
  },
  computed: {
    valueModel: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  mounted() {
    this.id = _.uniqueId();
  },
  methods: {
    onFocus(value) {
      this.$emit('focus', value);
    },
    onBlur(value) {
      this.$emit('blur', value);
    },
    onKeyup(value) {
      this.$emit('onkeyup', value);
    },
    onKeydown(value) {
      this.$emit('onkeydown', value);
    },
    onChange(value) {
      this.$emit('changed', value);
    },
    hasValue(value) {
      return value !== '' && value !== null && value !== undefined;
    },
  },
};
</script>

<style lang="scss" scoped>
@use '../../assets/styles/variables.scss' as *;
* {
  box-sizing: border-box;
}

.textarea {
  font-weight: $weight-normal;
  font-family: $font-text;
  font-size: var(--font-md);
  line-height: $line-height-xs;
  width: auto;

  &-expand {
    width: 100%;
  }

  .textarea-container {
    position: relative;

    .text-length {
      position: absolute;
      right: 0.7rem;
      top: 0.39rem;
      font-family: $font-text;
      font-size: var(--font-xs);
      color: var(--control-color, #313741);
    }

    &:focus-within {
      textarea {
        &:focus-visible {
          outline: 1px solid var(--control-focusColor, #2783c6);
        }
      }
    }
  }

  textarea {
    transition: all 0.2s ease-in-out;
    resize: none;
    min-height: 6.25rem;
    height: auto;
    font-size: var(--font-md);
    font-family: $font-text;
    border-radius: var(--radius-xs);
    color: var(--control-color, #313741);
    width: calc(100% - 2px);
    border: none;
    border-top: 1.5rem solid var(--control-background, #ffffff);
    outline: 1px solid var(--control-border, #c9d3df);
    padding: 0rem 0.5rem;
    background: var(--control-background, #ffffff);

    &:focus,
    &.focus {
      outline: 1px solid var(--control-focusColor, #2783c6);
    }

    &[disabled] {
      cursor: not-allowed;
      opacity: 0.7;
    }
  }
}
</style>

<docs>

  ```jsx
  const value = "Example user input";
  
  <div style="display: flex; flex-direction: column; gap: 1rem;">
    <XTextarea 
      maxLength="200" 
      placeholder="Write your text (Default textarea)" 
      customWidth="25rem"  
      :modelValue="value" 
      @update:modelValue="value = $event" 
    />
    <XTextarea 
      state="focus" 
      placeholder="Write your text (:focus)" 
      width="expand" 
      :modelValue="value" 
      @update:modelValue="value = $event" 
    />
    <XTextarea 
      placeholder="[disabled]" 
      disabled 
      disabledValue="Write your text" 
      :modelValue="value" 
      @update:modelValue="value = $event" 
    />
  </div>
  
  ```
</docs>

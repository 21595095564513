﻿<template>
  <div class="chart" :key="chartOptions.xaxis.type">
    <apexchart type="bubble" ref="chart" :height="height" :width="width" :options="chartOptions" :series="dataSeries" />
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';

import {
  autoFitToGrid,
  normalizeApexSeries,
  widgetDataLabelContrastColor,
} from '@src/components/Data-display/Charts/util';
import {ToolbarProxy} from './toolbarProxy';
import {BubbleChartMixin} from './mixins';

let _chartcounter = 0;
/**
 * lineType has 3 types to choose from: 'smooth', 'straight' and 'stepline'
 */
export default {
  name: 'XApexBubbleChart',
  components: {
    apexchart: VueApexCharts,
  },
  mixins: [BubbleChartMixin],
  data() {
    return {
      chartOptions: this.generateChartOptions(),
    };
  },
  computed: {
    updateChart() {
      const {series, xaxisType, dataLabels, theme, group} = this;
      return {
        series,
        xaxisType,
        dataLabels,
        theme,
        group,
      };
    },
    dataSeries() {
      return normalizeApexSeries(this.series);
    },
  },
  watch: {
    updateChart(val) {
      this.chartOptions = {...this.generateChartOptions()};
    },
    dataUpdate(newVal, oldVal) {
      if (newVal?.update || JSON.stringify(oldVal) !== JSON.stringify(newVal)) {
        this.updateData(normalizeApexSeries(newVal));
      }
    },
  },
  methods: {
    generateChartOptions() {
      return autoFitToGrid({
        chart: {
          type: 'bubble',
          group: this.group ?? undefined,
          id: `bubble_${++_chartcounter}`,
          toolbar: {
            show: true,
            tools: {
              customIcons: [
                {
                  icon: '',
                  // set to -1 for fixed position so it wont break chart.
                  index: -1,
                  title: 'Export Excel',
                  class: 'custom-icon exportexcel',
                  click: () => {
                    this.$emit('export');
                  },
                },
              ],
            },
            export: {
              csv: {filename: this.name},
              svg: {filename: this.name},
              png: {filename: this.name},
            },
          },
          zoom: {
            type: 'xy',
          },
          events: {
            mounted: ctx => this[ToolbarProxy.InjectionKey]?.update(ctx.el),
            updated: ctx => this[ToolbarProxy.InjectionKey]?.update(ctx.el),
          },
        },
        colors: this.theme,
        dataLabels: {
          enabled: this.dataLabels,
          hideOverflowingLabels: true,
          style: {
            colors: widgetDataLabelContrastColor(this.series, this.theme),
          },
          formatter: val => this.$format(val, 'widgetdatalabel'),
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
        xaxis: {
          type: this.xaxisType,
          tickAmount: 'dataPoints',
          tickPlacement: 'on',
        },
        yaxis: {
          axisBorder: {
            show: true,
          },
          labels: {
            formatter: val => this.$format(val, 'widgetdatalabel'),
          },
        },
        fill: {
          opacity: 0.9,
        },
        noData: {
          text: this.$t('WIDGET_NO_DATA'),
          style: {
            color: 'var(--text-primary, #313741)',
          },
        },
        legend: {
          //By default Apex shows a legend when the chart has at least two series with at least one containing data
          //The legend may break in certain circumstances when collapsing a series (https://github.com/apexcharts/apexcharts.js/issues/3156)
          //As a workaround, we'll manually force our legend to be displayed when matching Apex' default conditions
          showForSingleSeries: this.series.length > 1 && this.series.some(series => series.data.length),
        },
      });
    },
    updateData(data) {
      //DEV: Paths get morphed, so dont animate.
      //  The slide effect in the apex realtime demo is the result of a svgjs bug,
      //  When xaxis is fixed & larger than the series (using xaxis.range) svgjs will slide in stead of morph the data.
      //  When the series stops growing, or draws outside the graph boundary the data will start morphing.
      //  So keep adding datapoints AND set series head (data[0].x=data[1].x) to prevent drawing will keep svgjs in slide mode.
      //
      //  Ensuring this in the data is too much work, so we do not animate.
      if (data.append) this.$refs.chart.chart?.appendSeries(data.series, false);
      else this.$refs.chart.chart?.updateSeries(data.series, false);
    },
  },
};
</script>

<docs>
```jsx
<XBubbleChart
  height="360"
  width="900"
  :series="[
        {
          name: 'Series 1',
          data: [
            {
              x: 50,
              y: 400,
              z: 15
            },
            {
              x: 52,
              y: 448,
              z: 12
            },
            {
              x: 55,
              y: 580,
              z: 5
            },
            {
              x: 56,
              y: 690,
              z: 7
            },
            {
              x: 57,
              y: 1100,
              z: 8
            },
            {
              x: 59,
              y: 1380,
              z: 12
            }
          ]
        },
        {
          name: 'Series 2',
          data: [
            {
              x: 51,
              y: 430,
              z: 25 
            },
            {
              x: 53,
              y: 470,
              z: 15
            },
            {
              x: 54,
              y: 540,
              z: 15
            },
            {
              x: 58,
              y: 1200,
              z: 15
            },
            {
              x: 59,
              y: 740,
              z: 15
            }
          ]
        },
        {
          name: 'Series 3',
          data: [
            {
              x: 51,
              y: 1430,
              z: 5 
            },
            {
              x: 53.5,
              y: 400,
              z: 15
            },
            {
              x: 54.7,
              y: 599,
              z: 15
            },
            {
              x: 60,
              y: 120,
              z: 15
            },
            {
              x: 55,
              y: 740,
              z: 15
            }
          ]
        }
      ]"
  xaxisType="numeric"
  :dataLabels="false"
  :theme="['#2EB2FF', '#10CB73', '#F2DF32', '#D32D85', '#0DD2D2', '#E04E2A', '7731BD', '#1FBB97']"
  name="Bubbly"
/>
```
</docs>

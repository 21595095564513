import { watch } from 'vue';
import { ToolbarExport } from './toolbar';
import { useToolbar } from '../toolbarProxy';
import { useChartComponent } from './composables';
export default {
    name: 'XChartToolbarExport',
    props: {
        name: {
            type: String,
            required: false,
        },
    },
    setup(props) {
        const toolbar = useToolbar();
        const toolbarExport = new ToolbarExport({ toolbar, name: props.name });
        // changing name should not trigger a full on re-render
        watch(() => props.name, name => (toolbarExport.name = name));
        useChartComponent(toolbarExport);
    },
    render: () => undefined,
};

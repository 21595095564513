/**
 * Calculate the luminance of the given color
 *
 * @see https://www.w3.org/TR/WCAG20-TECHS/G17.html#G17-procedure
 * @param {string} hex Hexidecimal RGB color
 */
export function luminance(hex) {
    let r = parseInt(hex.substring(1, 3), 16) / 255;
    let g = parseInt(hex.substring(3, 5), 16) / 255;
    let b = parseInt(hex.substring(5, 7), 16) / 255;
    r = r < 0.03928 ? r / 12.92 : Math.pow(((r + 0.055) / 1.055), 2.4);
    g = g < 0.03928 ? g / 12.92 : Math.pow(((g + 0.055) / 1.055), 2.4);
    b = b < 0.03928 ? b / 12.92 : Math.pow(((b + 0.055) / 1.055), 2.4);
    return 0.2126 * r + 0.7152 * g + 0.0722 * b;
}
/**
 * Calculate the contrast ratio between the two given colors
 *
 * @see https://www.w3.org/TR/WCAG20-TECHS/G17.html#G17-procedure
 * @param {string} left Hexidecimal RGB color
 * @param {string} right Hexidecimal RGB color
 */
export function contrastRatio(left, right) {
    const luminanceLeft = luminance(left);
    const luminanceRight = luminance(right);
    const lightest = Math.max(luminanceLeft, luminanceRight);
    const darkest = Math.min(luminanceLeft, luminanceRight);
    return (lightest + 0.05) / (darkest + 0.05);
}
/**
 * Find the darkest of two colors
 *
 * @param {string} left  Hexidecimal RGB color
 * @param {string} right Hexidecimal RGB color
 */
export function darkest(left, right) {
    return luminance(left) > luminance(right) ? right : left;
}
/**
 * Find the lightest of two colors
 *
 * @param {string} left  Hexidecimal RGB color
 * @param {string} right Hexidecimal RGB color
 */
export function lightest(left, right) {
    return luminance(left) > luminance(right) ? left : right;
}
// css -> registerProperty, functie exposen
/**
 * Calculate the most most fitting option to be used in contrast with the given color
 *
 * @param {string} color Hexadecimal RGB color
 * @param {string} option1 Hexadecimal RGB color
 * @param {string} option2 Hexadecimal RGB color
 */
export function contrastColor(color, option1, option2) {
    // am.Color.alternative
    // minimum ratio of 7/1 is advised
    if (contrastRatio(color, option1) >= 7)
        return option1;
    return option2;
}

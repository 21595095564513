import { ChartComponent } from './chartInstance';
import { color as amColor } from '@amcharts/amcharts5';
/**
 * Singular band/segment for a radar chart
 * @see https://www.amcharts.com/docs/v5/charts/radar-chart/gauge-charts/#Bands
 */
export class RadarBand extends ChartComponent {
    getTags() {
        return ['band'];
    }
    constructor(settings) {
        super();
        this.min = settings.min;
        this.max = settings.max;
        this.color = settings.color;
        this.addDependency(['radarchart', 'valueaxis']);
    }
    render(context) {
        const axis = context.get('valueaxis');
        const axisRange = axis.createAxisRange(axis.makeDataItem({
            value: this.min,
            endValue: this.max
        }));
        axisRange.get('axisFill').setAll({
            fill: amColor(this.color),
            strokeWidth: 3
        });
    }
}

<template>
  <div class="dynamicLoaded" :style="containerStyling" :key="chartOptions1.xaxis.type">
    <div class="chart-one" :style="{width: chartTwoIsActive ? '50%' : '100%'}">
      <apexchart :type="chartType1" :height="height" :width="width" :options="chartOptions1" :series="dataSeries" />
    </div>
    <div :id="divIdChartTwo" :style="chartTwoStyling">
      <apexchart :type="chartType2" :height="height" :width="width" :options="chartOptions2" :series="[]" />
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import {autoFitToGrid, normalizeApexSeries, widgetDataLabelContrastColor} from './util';
import _ from 'lodash';
import {ToolbarProxy} from './toolbarProxy';

export default {
  name: 'XDynamicLoadedChart',
  components: {
    apexchart: VueApexCharts,
  },
  inject: {
    ...ToolbarProxy.InjectionDefinition,
  },
  props: {
    chartType1: {
      type: String,
      default: 'bar',
    },
    chartType2: {
      type: String,
      default: 'bar',
    },
    height: {
      type: String,
      default: '360',
    },
    width: {
      type: String,
      default: '100%',
    },
    series: {
      type: [Array, Object],
      default: () => [],
    },
    xaxisType: {
      type: String,
      default: 'category',
    },
    zoom: {
      type: Boolean,
      default: false,
    },
    dataLabels: {
      type: Boolean,
      default: false,
      style: {
        colors: ['var(--widget-dataLabelColor, #FFFFFF)'],
      },
    },
    theme: {
      type: Array,
      default: () => ['#cccccc', '#bbbbbb', '#eeeeee'],
    },
    /**
     * Name of the chart used in export filenames
     */
    name: {
      type: String,
      required: false,
    },
  },
  emits: ['export'],
  data() {
    const chartOne = _.uniqueId();
    const chartTwo = _.uniqueId();

    return {
      divIdChartTwo: chartTwo,
      chartOne,
      chartTwo,
      chartTwoIsActive: false,
      chartOptions1: this.generateChartOptions1(chartOne, chartTwo),
      chartOptions2: this.generateChartOptions2(chartTwo),
    };
  },
  computed: {
    updateChart() {
      const {series, xaxisType, dataLabels, zoom, theme} = this;
      return {
        series,
        xaxisType,
        dataLabels,
        zoom,
        theme,
      };
    },
    dataSeries() {
      return normalizeApexSeries(this.series);
    },
    containerStyling() {
      if (this.chartTwoIsActive) {
        return {
          height: 'auto !important',
        };
      } else {
        return {
          height: '100%',
        };
      }
    },
    chartTwoStyling() {
      if (this.chartTwoIsActive) {
        return {
          opacity: '1',
          flex: '1',
          transition: '100ms ease-in-out',
        };
      } else {
        return {
          opacity: '0',
          width: '0%',
        };
      }
    },
  },
  watch: {
    updateChart(val) {
      this.chartOptions1 = {...this.generateChartOptions1(this.chartOne, this.chartTwo)};
      this.chartOptions2 = {...this.generateChartOptions2(this.chartTwo)};
    },
  },
  methods: {
    generateChartOptions1(chartOne, chartTwo) {
      const self = this;

      function updateChartTwo(self, sourceChart) {
        let series = [];
        let seriesIndex = 0;

        if (sourceChart.w.globals.selectedDataPoints[0]) {
          const selectedPoints = sourceChart.w.globals.selectedDataPoints;
          for (let i = 0; i < selectedPoints[seriesIndex].length; i++) {
            const selectedIndex = selectedPoints[seriesIndex][i];
            const yearSeries = sourceChart.w.config.series[seriesIndex];

            series.push({
              name: yearSeries.data[selectedIndex].x,
              data: yearSeries.data[selectedIndex].nested,
            });
          }

          // Find the equal theme color from the sourceChart so it will match in the second one
          const amountOfColors = self.theme.length;
          const colors = selectedPoints[seriesIndex].map(item => {
            if (item <= amountOfColors - 1) {
              return self.theme[item];
            } else {
              return self.theme[amountOfColors - item - 1];
            }
          });

          if (series.length === 0) {
            series = [
              {
                data: [],
              },
            ];
          }

          return ApexCharts.exec(self.chartOptions2.chart.id, 'updateOptions', {
            series: series,
            colors: colors,
            fill: {
              colors: colors,
            },
          });
        }
      }

      return {
        chart: {
          id: chartOne,
          type: this.chartType1,
          zoom: {
            enabled: this.zoom,
          },
          toolbar: {
            show: true,
            tools: {
              customIcons: [
                {
                  icon: '',
                  // set to -1 for fixed position so it wont break chart.
                  index: -1,
                  title: 'Export Excel',
                  class: 'custom-icon exportexcel',
                  click: () => {
                    this.$emit('export');
                  },
                },
              ],
            },
            export: {
              csv: {filename: this.name},
              svg: {filename: this.name},
              png: {filename: this.name},
            },
          },
          redrawOnParentResize: true,
          events: {
            mounted: ctx => {
              this[ToolbarProxy.InjectionKey]?.update(ctx.el);
            },
            dataPointSelection: (e, chart, opts) => {
              const divChartTwo = document.getElementById(chartTwo);

              if (opts.selectedDataPoints[0].length === 1) {
                if (divChartTwo.classList.contains('active')) {
                  updateChartTwo(self, chart);
                } else {
                  divChartTwo.classList.add('active');
                  this.chartTwoIsActive = true;
                  updateChartTwo(self, chart);
                }
              } else {
                updateChartTwo(self, chart);
              }

              if (opts.selectedDataPoints[0].length === 0) {
                divChartTwo.classList.remove('active');
                this.chartTwoIsActive = false;
              }
            },
            updated(chart) {
              updateChartTwo(self, chart);
            },
          },
        },
        colors: this.theme,
        plotOptions: {
          bar: {
            distributed: true,
            horizontal: true,
            dataLabels: {
              position: 'bottom',
            },
          },
        },
        dataLabels: {
          enabled: this.dataLabels,
          style: {
            colors: widgetDataLabelContrastColor(this.series, this.theme),
          },
        },
        states: {
          active: {
            allowMultipleDataPointsSelection: true,
            filter: {
              type: 'darken',
              value: 1,
            },
          },
        },
        fill: {
          opacity: 0.9,
        },
        markers: {
          size: 1,
          strokeWidth: 3,
        },
        xaxis: {
          type: this.xaxisType,
          // for rendering of toolbar menu, tickplacement has to be set to 'on' (bug apex)
          tickPlacement: 'between',
        },
        noData: {
          text: this.$t('WIDGET_NO_DATA'),
          style: {
            color: 'var(--text-primary, #313741)',
          },
        },
      };
    },

    generateChartOptions2(chartTwo) {
      return autoFitToGrid({
        chart: {
          id: chartTwo,
          type: this.chartType2,
          stacked: true,
          redrawOnParentResize: true,
        },
        plotOptions: {
          bar: {
            rangeBarGroupRows: true,
            horizontal: false,
          },
        },
        legend: {
          show: false,
        },
        colors: this.theme,
        grid: {
          yaxis: {
            lines: {
              show: false,
            },
          },
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        xaxis: {
          type: this.xaxisType,
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          tickAmount: 2,
        },
        noData: {
          text: this.$t('WIDGET_NO_DATA'),
          style: {
            color: 'var(--text-primary, #313741)',
          },
        },
      });
    },
  },
};
</script>

<style scoped>
.dynamicLoaded {
  display: flex;
  flex-wrap: nowrap;
}

.chart-one {
  min-width: 50%;
  flex: 1;
}
</style>

<docs>
  ```jsx
    const dummyData = [{
      name: 'Bar 1',
      data: [],
    }];

    const obj = {
      x: '2011',
      y: 400,
      nested: [{
        x: 'Q1',
        y: 120
      }, {
        x: 'Q2',
        y: 90
      }, {
        x: 'Q3',
        y: 100
      }, {
        x: 'Q4',
        y: 110
      }]
    };

    for (let i = 0; i < 8; i++) {
      dummyData[0].data.push({
        x: obj.x,
        y: obj.y,
        nested: obj.nested.map(item => ({
          x: item.x,
          y: item.y,
        })),
      });

      obj.x = parseInt(obj.x)+1;
      obj.x = obj.x.toString();
      obj.y = obj.y+22;

      obj.nested = obj.nested.map(item => ({
          x: item.x,
          y: item.y+20,
        })
      );
    }

    <XGraphWidget
      :widgetId="1234234"
      name="Een korte titel"
      information="Dit is een beschrijving van de widget grafiek."
      :edit-rights="true"
    >

      <XDynamicLoadedChart
        height="220"
        width="100%"
        :series="[
          {
            name: 'Bar 1',
            data: [
              {
                x: '2011',
                y: 400,
                nested: [
                  {
                    x: 'Q1',
                    y: 120
                  },
                  {
                    x: 'Q2',
                    y: 90
                  },
                  {
                    x: 'Q3',
                    y: 100
                  },
                  {
                    x: 'Q4',
                    y: 110
                  }
                ]
              },
              {
                x: '2012',
                y: 422,
                nested: [
                  {
                    x: 'Q1',
                    y: 140
                  },
                  {
                    x: 'Q2',
                    y: 110
                  },
                  {
                    x: 'Q3',
                    y: 120
                  },
                  {
                    x: 'Q4',
                    y: 130
                  }
                ]
              },
              {
                x: '2013',
                y: 444,
                nested: [
                  {
                    x: 'Q1',
                    y: 160
                  },
                  {
                    x: 'Q2',
                    y: 130
                  },
                  {
                    x: 'Q3',
                    y: 140
                  },
                  {
                    x: 'Q4',
                    y: 150
                  }
                ]
              },
              {
                x: '2014',
                y: 466,
                nested: [
                  {
                    x: 'Q1',
                    y: 180
                  },
                  {
                    x: 'Q2',
                    y: 150
                  },
                  {
                    x: 'Q3',
                    y: 160
                  },
                  {
                    x: 'Q4',
                    y: 170
                  }
                ]
              },
              {
                x: '2015',
                y: 488,
                nested: [
                  {
                    x: 'Q1',
                    y: 200
                  },
                  {
                    x: 'Q2',
                    y: 170
                  },
                  {
                    x: 'Q3',
                    y: 180
                  },
                  {
                    x: 'Q4',
                    y: 190
                  }
                ]
              },
              {
                x: '2016',
                y: 510,
                nested: [
                  {
                    x: 'Q1',
                    y: 220
                  },
                  {
                    x: 'Q2',
                    y: 190
                  },
                  {
                    x: 'Q3',
                    y: 200
                  },
                  {
                    x: 'Q4',
                    y: 210
                  }
                ]
              },
              {
                x: '2017',
                y: 532,
                nested: [
                  {
                    x: 'Q1',
                    y: 240
                  },
                  {
                    x: 'Q2',
                    y: 210
                  },
                  {
                    x: 'Q3',
                    y: 220
                  },
                  {
                    x: 'Q4',
                    y: 230
                  }
                ]
              },
              {
                x: '2018',
                y: 554,
                nested: [
                  {
                    x: 'Q1',
                    y: 260
                  },
                  {
                    x: 'Q2',
                    y: 230
                  },
                  {
                    x: 'Q3',
                    y: 240
                  },
                  {
                    x: 'Q4',
                    y: 250
                  }
                ]
              }
            ]
          }
        ]"
        xaxisType="category"
        :dataLabels="false"
        :zoom="true"
        :theme="['#2EB2FF', '#10CB73', '#F2DF32', '#D32D85', '#0DD2D2', '#E04E2A', '7731BD', '#1FBB97']"
        name="Raps"
      />
    </XGraphWidget>
    ```
</docs>

/** Manage the state of the given context menu  */
export function useContextMenu(props, menuBox, selectRow) {
    return function openRowContextMenu(row, event) {
        if (!Array.isArray(props.rowActions) || !props.rowActions.length)
            return;
        event.preventDefault();
        // upon checking multiple items, we would like to prevent context menu from showing, but only if the trigger is right click.
        // When we click on the toggle icon to open the menu (event.type is then 'click'), the menu should open as usual.
        if (event.type == 'contextmenu' && props.checkedRowKeys && props.checkedRowKeys.length > 1) {
            return;
        }
        if (!menuBox.value)
            return;
        selectRow(row);
        menuBox.value.openContextMenu(event.clientY, event.clientX);
    };
}

<template>
  <div class="XButtonGroupWrapper">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'XButtonGroupWrapper',
};
</script>

<style lang="scss" scoped>
.XButtonGroupWrapper {
  width: fit-content;
  display: flex;

  > :last-child :deep(.button) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  :first-child :deep(.button) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  :not(:last-child):not(:first-child) :deep(.button) {
    border-radius: 0;
  }
}
</style>

<docs>
  ```jsx
  <XButtonGroupWrapper>
    <XButton component-style="primary-action">
      <XIcon name="gear" size="custom" />
      Action
    </XButton>
    <XButton>
      <XIcon name="information-circle" size="custom" />
      Informatie
    </XButton>
    <XButton component-style="secondary-success">
      <XIcon name="plus-circle" size="custom" />
      Success
    </XButton>
  </XButtonGroupWrapper>
  ```
</docs>

<template>
  <div class="widget-outer-container">
    <XBox class="widget-content" :class="{fullscreen: widgetFullscreen}">
      <div :class="['graph-wrapper', {loading: opacityOnLoading}]">
        <XGraphWidgetNavbar
          ref="navbar"
          :name="name"
          :exportHandler="handleDownload"
          :isFullscreen="widgetFullscreen"
          :isFullwidth="widgetFullwidth"
          :isEditable="editRights"
          :isStacked="!!stackedValue"
          :supportsFullscreen="hasWidgetValue"
          :supportsStacking="stackedValue !== null && stackedValue !== undefined"
          :supportsForecast="showForecast"
          :supportsInformation="!!information"
          :supportsIdCopy="copyId"
          :information
          @update:isStacked="value => setStacked(value)"
          @toggleFullscreen="toggleFullscreen()"
          @toggleFullwidth="toggleFullwidth()"
          @toggleForecast="$emit('forecast')"
          @move="direction => $emit('move', id, direction)"
          @copyId="() => copyWidgetId(id)"
          @edit="() => editWidget(id)"
          @delete="() => deleteWidget(id)"
        />

        <div class="graph-container">
          <slot />
          <div v-if="!hasWidgetValue">
            <XLoadingSpinner :isLoading="true" :headingText="$t('DASHBOARD_LOADING_DATA')" />
          </div>
        </div>
      </div>
    </XBox>
  </div>
</template>

<script>
import {ToolbarProxy} from './toolbarProxy';
import XGraphWidgetNavbar from './XGraphWidgetNavbar.vue';
import {MdPreview} from 'md-editor-v3';
import {addInformationToast} from '../../../utils/toasts';

export default {
  name: 'XGraphWidget',
  provide() {
    //Expose toolbar to widgets rendered as our children
    //WidgetRenderingContext?
    return {
      [ToolbarProxy.InjectionKey]: this.toolbar,
    };
  },
  emits: ['stacked', 'move', 'edit', 'delete', 'update:informationToggle', 'fullwidth', 'fullscreen', 'forecast'],
  components: {XGraphWidgetNavbar, MdPreview},
  props: {
    widgetId: {
      type: [String, Number],
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    information: {
      type: String,
      default: null,
    },
    editRights: {
      type: Boolean,
      default: false,
    },
    copyId: {
      type: Boolean,
      default: true,
    },
    stackedValue: {
      type: [Boolean, String],
      default: undefined,
    },
    showForecast: {
      type: Boolean,
      default: false,
    },
    opacityOnLoading: {
      type: Boolean,
      default: false,
    },
    handleDownload: {
      type: Function,
    },
    hasWidgetValue: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      toolbar: new ToolbarProxy(),
      id: this.widgetId,
      widgetFullwidth: false,
      widgetFullscreen: false,
    };
  },
  methods: {
    toggleFullwidth() {
      this.widgetFullwidth = !this.widgetFullwidth;
      this.$emit('fullwidth', this.widgetFullwidth);
    },

    toggleFullscreen() {
      this.widgetFullscreen = !this.widgetFullscreen;
      this.$emit('fullscreen', this.widgetFullscreen);
    },

    async copyWidgetId(id) {
      let state;
      try {
        const permissionResult = await navigator.permissions.query({
          name: 'clipboard-write',
        });
        state = permissionResult.state;
      } catch {
        // Firefox froze while the permission is still granted.
        state = 'granted';
      }

      if (state === 'granted' || state === 'prompt') {
        await navigator.clipboard.writeText(id);
        addInformationToast(this.$t('XGRAPHWIDGET_COPYID_SUCCESS'));
      }
    },

    setStacked(value) {
      this.$emit('stacked', value);
    },
    moveWidget(id, position) {
      this.$emit('move', id, position);
    },
    editWidget(id) {
      this.$emit('edit', id);
    },
    deleteWidget(id) {
      this.$emit('delete', id);
    },
  },
};
</script>

<style lang="scss" scoped>
.graph-container {
  :deep(.apexcharts-toolbar) {
    display: none;
  }
  .information-content {
    padding: 1.25rem;
  }
}

.widget-outer-container {
  display: flex;
  position: relative;
  flex: 1;
  width: 100%;

  .box.widget-content {
    display: flex;
    flex-flow: column;
    height: 100%;
    min-width: 100%;

    :deep(.slot-content) {
      height: 100%;
      box-sizing: border-box;
    }

    //Center title for fullscreen widgets
    &.fullscreen {
      :deep(.left-items) {
        justify-content: center;
        width: 100%;
      }

      //Force content to occupy available width
      :deep(.slot-content) {
        flex-grow: 1;
      }
    }
  }

  .information-container {
    box-sizing: border-box;
    overflow: hidden;

    background-color: var(--container-background, #dde9f2);
    border-radius: var(--radius-md);

    height: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;

    position: absolute;
    top: 0;
    left: 0;

    z-index: -1;
    opacity: 0;

    &.close {
      animation: closeInfoPanel 0.3s forwards;
    }

    &.open {
      animation: openInfoPanel 0.3s forwards;
    }

    .icon {
      position: absolute;
      top: 0;
      right: 0;

      padding: 0.8rem;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .graph-wrapper {
    height: 100%;
    margin: -1rem; //Hack to undo padding added to content wrapper by XBox component

    &.loading {
      opacity: 0.5;
    }
  }

  .graph-container {
    width: 100%;
    height: 100%;
    transition: 1.3s all linear;
    padding: var(--spacing-sm);

    position: relative;
    display: flex;
    align-items: center;

    :deep {
      > div:not(.popup-wrapper) {
        width: 100%;
        height: 100%;
      }

      .popup-wrapper {
        position: absolute;
        right: 1rem;
        top: 1rem;
      }
    }
  }
}

@keyframes openInfoPanel {
  from {
    z-index: -1;
    opacity: 0;
  }
  to {
    z-index: initial;
    opacity: 12;
  }
}

@keyframes closeInfoPanel {
  from {
    z-index: initial;
    opacity: 1;
  }
  to {
    z-index: -1;
    opacity: 0;
  }
}
</style>

<docs>
  ```jsx
  <XGraphWidget
    :widgetId="1234234"
    name="Een korte titel"
    information="Dit is een beschrijving van de widget grafiek."
    :stackedValue="true"
    :edit-rights="true"
  >
    <XScatterPlot
      height="360"
      width="400"
      :series="[{name:'Series 1',data:[{x:50,y:400},{x:52,y:448},{x:55,y:580},{x:56,y:690},{x:57,y:1100},{x:59,y:1380}]},{name:'Series 2',data:[{x:51,y:430},{x:53,y:470},{x:54,y:540},{x:58,y:1200},{x:59,y:740}]}]"
      xaxisType="numeric"
      :dataLabels="false"
      :theme="['#2EB2FF', '#10CB73', '#F2DF32', '#D32D85', '#0DD2D2', '#E04E2A', '7731BD', '#1FBB97']"
      name="Shotgun"
    />
  </XGraphWidget>
  
    <XGraphWidget
      :widgetId="1234234"
      name="Een titel langer dan in de container past, hoewel afgekapt is de volledige titel nog steeds te zien door over het element heen te hoveren"
      information=""
      style="height:400px"
      :stackedValue="true"
      :edit-rights="true"
      :opacityOnLoading="true"
      :hasWidgetValue="false"
    >
      <XScatterPlot
        height="360"
        width="400"
        :series="[{name:'Series 1',data:[{x:50,y:400},{x:52,y:448},{x:55,y:580},{x:56,y:690},{x:57,y:1100},{x:59,y:1380}]},{name:'Series 2',data:[{x:51,y:430},{x:53,y:470},{x:54,y:540},{x:58,y:1200},{x:59,y:740}]}]"
        xaxisType="numeric"
        :dataLabels="false"
        :theme="['#2EB2FF', '#10CB73', '#F2DF32', '#D32D85', '#0DD2D2', '#E04E2A', '7731BD', '#1FBB97']"
        name="Shotgun"
      />
    </XGraphWidget>
  ```
</docs>

import { ChartComponent } from './chartInstance';
import { XYChart as AMXyChart } from '@amcharts/amcharts5/xy';
import { chartManager } from './chartManager';
import { ensureCursor } from './util';
import { ready } from './events';
export class XyChartComponent extends ChartComponent {
    getTags() {
        return ['chart', 'xychart'];
    }
    constructor(settings) {
        super();
        this.stackType = settings.stackType;
    }
    render(context) {
        const chart = context.root.container.children.push(AMXyChart.new(context.root, { layout: context.root.verticalLayout }));
        // should stacktype be set here? Maybe add a special 'contextprovider' component (throw in a dictionary, add dictionary to context)?
        context.set('stackType', this.stackType);
        context.set('chart', chart);
        this.chart = chart;
        // should this be part of our chart?
        chart.appear(1000, 100);
        this.initializeSynchronization(context);
    }
    initializeSynchronization(context) {
        ready(context, () => {
            var _a;
            for (const theirChartInstance of chartManager.findOthersInSameGroup(context.chart)) {
                const theirChart = (_a = theirChartInstance.resolveComponent('xychart')) === null || _a === void 0 ? void 0 : _a.chart;
                if (!theirChart)
                    continue;
                this.synchronizeWith(theirChart);
            }
        });
    }
    synchronizeWith(theirChart) {
        const myChart = this.chart;
        const myCursor = ensureCursor(myChart);
        const theirCursor = ensureCursor(theirChart);
        // known issue; positioning of the 'other' tooltips can be a bit wonky, it is consistent however, so no real issue
        // should happen via chartmanager, track synced charts over there etc
        myChart.plotContainer.events.on('pointerout', () => theirChart.plotContainer.hideTooltip());
        myCursor.events.on('cursormoved', () => theirChart.plotContainer.showTooltip(myCursor.getPrivate('point')));
        theirCursor.events.on('cursormoved', () => myChart.plotContainer.showTooltip(theirCursor.getPrivate('point')));
    }
}

<template>
  <component
    :is="type"
    class="box"
    :class="[
      {'box-below': boxBelow},
      {'box-top': boxTop},
      {'box-middle': boxMiddle},
      {'box-bottom': boxBottom},
      {'title-buttons': titleButtons},
      boxDropdown ? (toggle || toggleDropdown ? 'box-dropdown-open' : 'box-dropdown-closed') : null,
    ]"
    :style="[boxTab && {display: 'flex'}, (boxTop || boxMiddle || boxBottom) && {'z-index': 'auto'}]"
  >
    <div v-if="boxTab" class="box-tab">
      <p>
        {{ tabText }}
      </p>
    </div>
    <div
      class="box-body"
      v-if="mainIcon || boxTitleLarge || boxTitle || titleButtons"
      :class="[{'box-tab-custom': boxTab}, {box: boxTab}]"
      :style="cursorType"
      @click.self="setToggle(!toggle)"
    >
      <!-- Check if this is really needed :style="{'padding-right': boxDropdown ? '0' : undefined }" -->
      <div
        class="title-buttons"
        :style="cursorType"
        @click.self="boxDropdown ? (hasContent ? setToggle(!toggle) : null) : null"
      >
        <div
          class="left-items"
          :style="cursorType"
          @click="boxDropdown ? (hasContent ? setToggle(!toggle) : null) : null"
        >
          <XIcon v-if="mainIcon" :name="mainIcon" :color="mainIconColor" style="margin-top: -0.15rem" />
          <XHeading level="h2" v-if="boxTitleLarge" :style="{'margin-bottom': breadcrumbSlot ? '0.5rem' : undefined}">
            {{ boxTitleLarge }}
          </XHeading>
          <XHeading
            level="h4"
            v-if="boxTitle"
            :style="[
              {'padding-bottom': boxTitle ? '0.55rem' : undefined},
              {'padding-bottom': boxDropdown ? '0rem' : undefined},
            ]"
          >
            {{ boxTitle }}
          </XHeading>
        </div>
        <div class="right-items">
          <div class="title-slot" v-if="$resolveSlot($slots['title-buttons']) && titleButtons">
            <slot name="title-buttons" />
          </div>
          <div v-if="boxDropdown" class="box-dropdown-icon">
            <slot name="headerIcons" v-if="boxDropdown" />
            <XIcon
              :class="{disabled: !hasContent}"
              v-if="toggleDropdown ? !toggle && !toggleDropdown : !toggle"
              name="chevron-down"
              :hover-effect="hasContent"
              @click="hasContent ? setToggle(true) : null"
              style="padding-right: 1rem"
            />
            <XIcon
              v-if="toggleDropdown || toggle"
              name="chevron-up"
              :hover-effect="true"
              @click="setToggle(false)"
              style="padding-right: 1rem"
            />
          </div>
        </div>
      </div>
      <div class="breadcrumb-slot" v-if="$resolveSlot($slots['breadcrumb-slot']) && breadcrumbSlot">
        <p class="breadcrumb-text">
          <slot name="breadcrumb-slot" />
        </p>
      </div>

      <div v-if="toggleDropdown || (boxDropdown && toggle)" class="box-dropdown-open-spacing" />
    </div>
    <div
      class="slot-content"
      v-if="$resolveSlot($slots.default)"
      :style="{'margin-top': !boxTitle && !boxTitleLarge ? '0rem' : undefined}"
      v-show="!boxDropdown || (boxDropdown && toggle)"
    >
      <slot />
    </div>
  </component>
</template>

<script>
import resolveSlots from '@src/helpers/vue-slots';
import dropdownMixin from '../Mixins/dropdown-mixin';
/**
 * Visual container element, v0.0.1 is shaped like bootstrap  admin LTE box
 */
export default {
  name: 'XBox',
  mixins: [dropdownMixin],
  props: {
    type: {
      type: String,
      default: 'div',
    },
    titleButtons: {
      type: Boolean,
      default: false,
    },
    boxTop: {
      type: Boolean,
      default: false,
    },
    boxMiddle: {
      type: Boolean,
      default: false,
    },
    boxBottom: {
      type: Boolean,
      default: false,
    },
    boxDropdown: {
      type: Boolean,
      default: false,
    },
    boxTitleLarge: {
      type: String,
      default: '',
    },
    boxTitle: {
      type: String,
      default: '',
    },
    boxTab: {
      type: Boolean,
      default: false,
    },
    tabText: {
      type: String,
    },
    boxBelow: {
      type: Boolean,
      default: false,
    },
    breadcrumbSlot: {
      type: Boolean,
      default: false,
    },
    toggleDropdown: {
      type: Boolean,
      default: false,
    },
    mainIcon: {
      type: String,
      default: '',
    },
    mainIconColor: {
      type: String,
      default: '',
    },
  },
  created() {
    this.$resolveSlot = resolveSlots;
  },
  methods: {
    setToggle(bool) {
      this.toggle = bool;

      if (!this.boxDropdown || !this.hasContent) {
        return;
      }

      this.$emit('toggle', bool);
    },
  },
};
</script>

<style lang="scss" scoped>
@use '../../assets/styles/variables.scss' as *;
.box {
  position: relative;
  border-radius: 0.5rem;
  border-top: 3px solid var(--main-border, #c9d3df);
  width: 100%;
  box-sizing: border-box;
  word-break: break-all;
  border: 0;

  .box-body {
    .title-buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .right-items {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      justify-content: flex-start;
      column-gap: 1rem;
      margin-top: -0.18rem;
      align-items: center;
    }

    .left-items {
      display: flex;
      flex-direction: row;
      height: 4rem;
      align-items: center;
      padding-left: 1rem;
      gap: 0.5rem;
      justify-content: flex-start;
    }

    .title-slot {
      display: flex;
      flex-wrap: wrap;
      column-gap: 0.7rem;
    }

    .breadcrumb-slot {
      .breadcrumb-text {
        display: flex;
        flex-direction: row;
        column-gap: 0.4rem;
        margin: 0;
        font-family: $font-text;
        font-size: var(--font-sm);
        text-decoration: none;
        color: var(--control-placeholder, #868686);

        :deep(a) {
          color: var(--control-placeholder, #868686);
          text-decoration: none;

          &:visited,
          &:hover,
          &:active {
            color: var(--control-hoverColor, #838384);
            text-decoration: none;
          }
        }
      }
    }
  }

  .slot-content {
    padding: 1rem;
  }
}

.box-dropdown-open {
  height: fit-content;
  // animation: SlideOut 2s ease-in-out;
}

.box-dropdown-open-spacing {
  background: var(--container-background, #dde9f2);
  margin-left: -1rem;
  margin-right: -1rem;
  height: 0.15rem;
  width: calc(100% + 2rem);
}

@keyframes SlideOut {
  from {
    max-height: 4rem;
    overflow: hidden;
  }
  to {
    max-height: 100vh;
    overflow: unset;
  }
}

.box-dropdown-closed,
.box-dropdown-open {
  .box-body {
    .heading {
      user-select: none;
    }
  }
}

.box-dropdown-closed {
  max-height: 4rem;
  min-height: 4rem;
  overflow: hidden;
  // animation: SlideIn 2s ease-in-out;
}

@keyframes SlideIn {
  from {
    max-height: 100vh;
  }
  to {
    max-height: 4rem;
  }
}

.box-dropdown-icon {
  display: flex;
  gap: 1rem;

  .icon.color-default.disabled {
    cursor: default;

    :deep(svg) {
      path {
        stroke: var(--icon-primary, #868686);
      }
    }
  }
}

.box-tab {
  height: auto;
  width: 3.3rem;
  border-top-left-radius: var(--radius-md);
  border-bottom-left-radius: var(--radius-md);
  display: flex;
  align-items: center;
  overflow: hidden;

  p {
    position: absolute;
    transform: rotate(-90deg);
    transform-origin: 50% 35%;
    font-size: var(--font-md);
    font-weight: $weight-semi-bold;
    color: var(--text-primary, #313741);
  }
}

.box-tab-custom {
  width: 100%;
  border-top-left-radius: var(--radius-md);
  border-bottom-left-radius: var(--radius-md);
}

.box-top {
  border-bottom-left-radius: 0rem;
  border-bottom-right-radius: 0rem;
}

.box-middle {
  border-radius: 0rem;
}

.box-bottom {
  border-top-left-radius: 0rem;
  border-top-right-radius: 0rem;
  margin-top: 0.2rem;
}

.box-middle {
  margin-top: 0.2rem;
}

.box-below {
  position: static;
  margin-top: -1.1rem;
  padding-top: 1.3rem;
  padding-bottom: 0.25rem;
  overflow: hidden;
  background: var(--main-background, #ffffff);

  //While static should cause z-index to be unused
  //depending on the `display` property of our parent, a z-index defined on the `box` class may be used
  z-index: unset;
}

.box:not(.box-below) {
  background: var(--main-background, #ffffff);
}

@media screen and #{$media-query-m} {
  .box {
    .box-body {
      h2.heading,
      h4.heading {
        font-size: var(--font-h4);
      }
    }
  }
}

@media screen and #{$media-query-xs} {
  .box {
    &.box-body {
      h2.heading,
      h4.heading {
        font-size: var(--font-h4);
        margin-top: -0.24rem;
        margin-left: 0.05rem;
      }
    }
  }
}
</style>

<docs>
  ```jsx
  <XBox 
    boxTitleLarge="Titel Box"
    style="margin-bottom: 3rem;"
  >
  </XBox>
  
  <XBox 
    boxTitleLarge="Titel box met buttons"
    style="margin-bottom: 3rem;"
    :titleButtons="true"
  >
     <template #title-buttons>
      <XInput state="focus" customWidth="12rem" placeholder="Write your text" :model-value="value" @update:modelValue="value = $event" />
      <XButton component-style="white">
        <XIcon name="backspace" size="custom" />
        annuleren
      </XButton>
      <XButton component-style="secondary-success">
        <XIcon name="check-circle" size="custom" />
        opslaan
      </XButton>
    </template>
  </XBox>

  <XBox 
    boxTitleLarge="Titel box met breadcrumb"
    style="margin-bottom: 3rem;"
    :breadcrumbSlot="true"
  >
    <template #breadcrumb-slot>
      <router-link
        :to="`put/link/here`"
      >
        Crumb 1
      </router-link> 
      › 
      <router-link
        :to="`put/link/here`"
    >
        Crumb 2
      </router-link>
    </template>
  </XBox>

  <XBox 
    boxTitleLarge="Titel box met beide"
    :titleButtons="true"
    :breadcrumbSlot="true"
  >
    <template #breadcrumb-slot>
      <router-link
        :to="`put/link/here`"
      >
        Crumb 1
      </router-link> 
      › 
      <router-link
        :to="`put/link/here`"
    >
        Crumb 2
      </router-link>
    </template>
    <template #title-buttons>
      <XInput state="focus" customWidth="12rem" placeholder="Write your text" :model-value="value" @update:modelValue="value = $event" />
      <XButton component-style="white">
        <XIcon name="backspace" size="custom" />
        annuleren
      </XButton>
      <XButton component-style="secondary-success">
        <XIcon name="check-circle" size="custom" />
        opslaan
      </XButton>
    </template>
  </XBox>

  <XBox 
    boxTitleLarge="Titel box 1"
    :breadcrumbSlot="true"
    style="height:15rem; margin-top: 3rem;"
  >
    <XHeading level="h5">Grote titel met logo</XHeading>
    <template #breadcrumb-slot>
      <router-link
        :to="`put/link/here`"
      >
        Crumb 1
      </router-link> 
      › 
      <router-link
        :to="`put/link/here`"
      >
        Crumb 2
      </router-link>
    </template>
  </XBox>
  <XBox :boxBelow="true">
    <XHeading level="h5">Box below</XHeading>
  </XBox>

  <XBox 
    boxTitleLarge="Titel box 2"
    :boxTab="true"
    tabText="Group 1"
    style="height:15rem; margin-top: 3rem;"
  >
    <XHeading level="h5">Grote titel zonder logo met tab</XHeading>
  </XBox>

  <XBox 
    boxTitle="Titel box 3"
    style="height:15rem; margin-top: 3rem;"
  >
    <XHeading level="h5">Normale box met titel</XHeading>
  </XBox>

  <XBox 
    boxTitle="Box met dropdown"
    :boxDropdown="true"
    style="margin-top: 3rem;"
  >
    <XHeading level="h5"">Box uitgeklapt</XHeading>
    <XInput/>
  </XBox>

  <XBox 
    boxTitle="Titel box 4"
    :boxTop="true"
    style="height:10rem; margin-top: 3rem;"
  >
    <XHeading level="h5">Bovenste box</XHeading>
  </XBox>

  <XBox
    boxTitle="Titel box 5"
    :boxMiddle="true"
    style="height:10rem;"
  >
    <XHeading level="h5">Middenste box</XHeading>
  </XBox>

  <XBox 
    boxTitle="Titel box 6"
    :boxBottom="true"
    style="height:10rem;"
  >
    <XHeading level="h5">Onderste box</XHeading>
  </XBox>
  <XBox :boxBelow="true">
    <XHeading level="h5">Box below</XHeading>
  </XBox>

  <XBox :boxLine="false" 
    style="margin-top: 3rem;"
  >
    <XHeading level="h5"">Lege box</XHeading>
  </XBox>
  <XBox 
    boxTitle="Box met dropdown en header icons"
    :boxDropdown="true"
    style="margin-top: 3rem;"
     :titleButtons="true"
     mainIcon="plus-circle"
     mainIconColor="red"
    :open="false"
  >
    <XHeading level="h5">Box uitgeklapt</XHeading>
    <template #title-buttons>
  	<XInput/>
  </template>
    <template #headerIcons>
      <XIcon name="eye" size="medium" title="journey"/>
      <XIcon name="screen-zoom" size="medium" title="journey"/>
    </template>
  </XBox>
  ```
</docs>

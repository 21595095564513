// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("source/assets/icons/file-download.svg?url", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.apexcharts-toolbar{z-index:0 !important}.apexcharts-toolbar-custom-icon.custom-icon{background:url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat;background-size:1.15rem;margin-left:.3rem}.apexcharts-legend-text{color:var(--text-primary, #313741) !important}.apexcharts-xaxis-label,.apexcharts-yaxis-label{fill:var(--text-primary, #313741)}`, "",{"version":3,"sources":["webpack://./src/components/Data-display/Charts/XApexBarChart.vue"],"names":[],"mappings":"AACA,oBACE,oBAAA,CAGF,4CACE,4DAAA,CACA,uBAAA,CACA,iBAAA,CAGF,wBACE,6CAAA,CAGF,gDAEE,iCAAA","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_XChartRadarBand = _resolveComponent("XChartRadarBand");
    const _component_XChartClockHand = _resolveComponent("XChartClockHand");
    const _component_XChartToolbarExport = _resolveComponent("XChartToolbarExport");
    const _component_XChartValueAxisTicks = _resolveComponent("XChartValueAxisTicks");
    const _component_XChartLabels = _resolveComponent("XChartLabels");
    const _component_XChartUserLocale = _resolveComponent("XChartUserLocale");
    const _component_XChartTheme = _resolveComponent("XChartTheme");
    const _component_XRadarChartRoot = _resolveComponent("XRadarChartRoot");
    return (_openBlock(), _createBlock(_component_XRadarChartRoot, {
        min: _ctx.gaugeConfig.min,
        max: _ctx.gaugeConfig.max
    }, {
        default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.bands, (band) => {
                return (_openBlock(), _createBlock(_component_XChartRadarBand, {
                    color: band.color,
                    min: band.min,
                    max: band.max
                }, null, 8, ["color", "min", "max"]));
            }), 256)),
            _createVNode(_component_XChartClockHand, {
                value: $setup.value,
                color: $setup.activeBand.color
            }, null, 8, ["value", "color"]),
            _createVNode(_component_XChartToolbarExport, { name: _ctx.name }, null, 8, ["name"]),
            _createVNode(_component_XChartValueAxisTicks),
            (_ctx.dataLabels)
                ? (_openBlock(), _createBlock(_component_XChartLabels, { key: 0 }))
                : _createCommentVNode("", true),
            _createVNode(_component_XChartUserLocale),
            _createVNode(_component_XChartTheme)
        ]),
        _: 1
    }, 8, ["min", "max"]));
}

import { sanitizeText, replaceAll } from './util';
/** Forcefully transform the given text to a string */
function toString(text) {
    return typeof text === 'string' ? text : text === null || text === void 0 ? void 0 : text.toString();
}
/** Small utility to satisfy TS... */
function isString(text) {
    return typeof text === 'string';
}
// todo: introduce chain syntax, TextStyles.bold.color('green')(text) = TextStyles.bold(TextStyles.color('green', text))
/** Helper class for applying styles to chunks of text */
export class TextStyles {
    /**
     * Mark a part of the given text with the given style
     *
     * @param text {string} Text to apply styling to
     * @param predicate {string} Text to search for, all instances will be replaced
     * @param style {string} Style to apply
     */
    static stylePart(text, predicate, style) {
        text = toString(text);
        if (!isString(text))
            return '';
        if (!predicate)
            return text;
        return replaceAll(text, predicate, match => this.style(match, style));
        // we should allow for custom extractors at a later point in time, for now a simple string search will suffice
        // return text.replaceAll(predicate, part => this.style(part, style))
    }
    /**
     * Mark the given text with the style
     *
     * @param text {string} Text to apply styling to
     * @param style {string} Style to apply
     */
    static style(text, style) {
        text = toString(text);
        if (!isString(text))
            return '';
        if (style === 'bold')
            return this.bold(text);
        if (style === 'italic')
            return this.italic(text);
        if (style === 'bolditalic')
            return this.boldItalic(text);
        return text;
    }
    /** Make the give text italic */
    static italic(text) {
        return `__${text}__`;
    }
    /** Make the given text bold */
    static bold(text) {
        return `**${text}**`;
    }
    /** Make the given text bold and italic */
    static boldItalic(text) {
        return `_*${text}*_`;
    }
    // alternative color implementation: https://www.npmjs.com/package/markdown-it-color
    /** Assign the given color to the text, for reference: https://github.com/ScopiCisco/markdown-it-inline-text-color#use */
    static color(text, color) {
        return `{color:${color}}${text}{color}`;
    }
    // fixme: add support for background, class, etc.
    /** Remove markup, etc. from the given text */
    plain(text) {
        return text;
    }
    /** Render the given text as HTML, replacing styles with HTML tags */
    static render(text) {
        if (!isString(text))
            return text;
        text = sanitizeText(text);
        // make our individuals styles a type -> match, wrapHTML, apply?
        const bold = { matcher: /\*\*(.+?)\*\*/g, wrap: '<span style="font-weight: 600">$1</span>' };
        const italic = { matcher: /__(.+?)__/g, wrap: '<em>$1</em>' };
        const boldItalic = { matcher: /_\*(.+?)\*_/g, wrap: '<em style="font-weight: 600">$1</em>' };
        const color = { matcher: /{color:(.+?[^}])}(.+?){color}/g, wrap: '<span style="color: $1">$2</span>' };
        return text
            .replaceAll(bold.matcher, bold.wrap)
            .replaceAll(italic.matcher, italic.wrap)
            .replaceAll(boldItalic.matcher, boldItalic.wrap)
            .replaceAll(color.matcher, color.wrap);
    }
}

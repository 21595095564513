import {Comment, Text} from 'vue';

function resolveSlot(slot) {
  return slot && slot().some(c => c.type !== Comment);
}

/** Attempt to retrieve text content from a given slot, will return undefined for invalid values  */
function resolveSlotTextContent(slot) {
  // safeguard against unexpected types
  if (typeof slot !== 'function') return undefined;

  slot = slot();

  // we only support single slots for the time being
  // ensure our slot consists of text content, other content types we can't deal with yet
  if (slot.length !== 1 || slot[0].type !== Text || typeof slot[0].children !== 'string') {
    return undefined;
  }

  // remove leading/trailing whitespace, slot content may be directly yoinked from the DOM
  return slot[0].children.trim();
}

export default resolveSlot;
export {resolveSlotTextContent};

<template>
  <div class="chart" :key="chartOptions.xaxis.type">
    <apexchart ref="chart" type="area" :height="height" :width="width" :options="chartOptions" :series="dataSeries" />
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';

import {autoFitToGrid, normalizeApexSeries, widgetDataLabelContrastColor} from './util';

import {ToolbarProxy} from './toolbarProxy';
import {AreaChartMixin} from './mixins';

let _chartcounter = 0;
/**
 * lineType has 3 types to choose from: 'smooth', 'straight' and 'stepline'
 */
export default {
  name: 'XApexAreaChart',
  components: {
    apexchart: VueApexCharts,
  },
  mixins: [AreaChartMixin],
  data() {
    return {
      chartOptions: this.generateChartOptions(),
    };
  },
  computed: {
    updateChart() {
      const {series, xaxisType, dataLabels, stacked, zoom, lineType, forecastDataPoints, theme, group} = this;
      return {
        series,
        xaxisType,
        dataLabels,
        stacked,
        zoom,
        lineType,
        forecastDataPoints,
        theme,
        group,
      };
    },
    dataSeries() {
      return normalizeApexSeries(this.series);
    },
  },
  watch: {
    updateChart(val) {
      this.chartOptions = {...this.generateChartOptions()};
    },
    dataUpdate(newVal, oldVal) {
      if (newVal?.update || JSON.stringify(oldVal) !== JSON.stringify(newVal)) {
        this.updateData(normalizeApexSeries(newVal));
      }
    },
  },
  methods: {
    updateData(data) {
      //DEV: Paths get morphed, so dont animate.
      //  The slide effect in the apex realtime demo is the result of a svgjs bug,
      //  When xaxis is fixed & larger than the series (using xaxis.range) svgjs will slide in stead of morph the data.
      //  When the series stops growing, or draws outside the graph boundary the data will start morphing.
      //  So keep adding datapoints AND set series head (data[0].x=data[1].x) to prevent drawing will keep svgjs in slide mode.
      //
      //  Ensuring this in the data is too much work, so we do not animate.
      if (data.append) this.$refs.chart.chart?.appendSeries(data.series, false);
      else this.$refs.chart.chart?.updateSeries(data.series, false);
    },
    generateChartOptions() {
      return autoFitToGrid({
        chart: {
          type: 'area',
          stacked: this.stacked,
          group: this.group ?? undefined,
          id: `area_${++_chartcounter}`,
          zoom: {
            enabled: this.zoom,
          },
          toolbar: {
            show: true,
            tools: {
              customIcons: [
                {
                  icon: '',
                  index: -1,
                  title: 'Export Excel',
                  class: 'custom-icon exportexcel',
                  click: () => {
                    this.$emit('export');
                  },
                },
              ],
            },
            export: {
              csv: {filename: this.name},
              svg: {filename: this.name},
              png: {filename: this.name},
            },
          },

          events: {
            mounted: ctx => {
              //HACK - tldr, workaround for Apex attempting to convert our xaxis type to numeric

              /*
                Upon options being altered, Apex will in certain circumstances forcefully convert the xaxis type to numeric
                one of the preconditions for this conversion is the type not already being numeric
                this conversion in and of itself shouldn't pose too much of a problem, were it not for the fact that Apex seemingly
                filters out non-numeric labels, for example '2017', '', '', '2018' would leave us with '2017', '2018'
                Apex internally combines its label count with its data point count for a given series upon creating SVG path commands for drawing a given series
                having less labels than data points causes Apex to never actually mark the generated path commands for the given series as completed, and thus never pushed to its internal
                collection of 'paths to paint on the chart area' leaving us with an empty chart

                verifying whether this conversion is deemed necessary happens upon initializing/updating options
                this yields the result of the conversion always happening when our type is not already set to numeric

                as a workaround for this behavior, we'll attempt to force an options update with an xaxis.type value already set to numeric when said conversion has happened
                this will force our underlying ApexCharts component to destroy + rebuild the chart using the by Apex considered 'correct' xaxis type
                when supplied directly, Apex seemingly doesn't screw with the labels and manages to render correctly

                For reference, see:
                https://github.com/apexcharts/apexcharts.js/blob/v3.35.0/src/modules/settings/Defaults.js#L420
                https://github.com/apexcharts/apexcharts.js/blob/v3.35.0/src/charts/Line.js#L575
                https://github.com/apexcharts/apexcharts.js/blob/v3.35.0/src/modules/Data.js#L557

                Minimal reproduction:
                https://codepen.io/dnx_db/pen/VwQRbYa
              */

              this[ToolbarProxy.InjectionKey]?.update(ctx.el);

              if (ctx.opts.xaxis.convertedCatToNumeric) {
                //Trigger chart recreation by our ApexChart component using the 'correct' axis type
                this.chartOptions = {...this.generateChartOptions()};
                this.chartOptions.xaxis.type = 'numeric';

                //TODO: Look for a more permanent solution
                //Tickplacement `between` will ensure all datapoints get properly rendered. This comes at the cost of a small bit of whitespace (~10-20px) on both left and right sides of our chart
                this.chartOptions.xaxis.tickPlacement = 'between';
                return;
              }
            },
            updated: ctx => this[ToolbarProxy.InjectionKey]?.update(ctx.el),
          },
        },
        forecastDataPoints: {
          count: this.forecastDataPoints,
        },
        colors: this.theme,
        dataLabels: {
          enabled: this.dataLabels,
          hideOverflowingLabels: true,
          formatter: val => this.$format(val, 'widgetdatalabel'),
        },
        stroke: {
          curve: this.lineType,
        },
        xaxis: {
          type: this.xaxisType,
          tickAmount: 'dataPoints',
          tickPlacement: 'on',
        },
        yaxis: {
          labels: {
            formatter: val => this.$format(val, 'widgetdatalabel'),
          },
          max: this.stackType === '100%' ? 100 : undefined,
          axisBorder: {
            show: true,
          },
        },
        fill: {
          opacity: 0.9,
          type: 'gradient',
        },
        noData: {
          text: this.$t('WIDGET_NO_DATA'),
          style: {
            color: 'var(--text-primary, #313741)',
          },
        },
        legend: {
          //By default Apex shows a legend when the chart has at least two series with at least one containing data
          //The legend may break in certain circumstances when collapsing a series (https://github.com/apexcharts/apexcharts.js/issues/3156)
          //As a workaround, we'll manually force our legend to be displayed when matching Apex' default conditions
          showForSingleSeries: this.series.length > 1 && this.series.some(series => series.data.length),
        },
      });
    },
  },
};
</script>

<docs>
  ```jsx
  <XGraphWidget
      :widgetId="123234"
      name="Een korte titel"
      information="Dit is een beschrijving van de widget grafiek."
      :edit-rights="true"
    >
    <XAreaChart
      height="360"
      width="400"
      :series="[
        {
          name: 'Bar 1',
          data: [
            {
              x: 'South Korea',
              y: 400.10
            },
            {
              x: 'Canada',
              y: 430  
            },
            {
              x: 'United Kingdom',
              y: 448 
            },
            {
              x: 'Netherlands',
              y: 470
            },
            {
              x: 'Italy',
              y: 540
            },
            {
              x: 'France',
              y: 580
            },
            {
              x: 'Japan',
              y: 690
            },
            {
              x: 'United States',
              y: 1100
            },
            {
              x: 'China',
              y: 1200
            },
            {
              x: 'Germany',
              y: 1380
            }
          ]
        }
      ]"
      xaxisType="category"
      :dataLabels="true"
      :stacked="false"
      :zoom="true"
      lineType="smooth"
      :forecastDataPoints="2"
      :theme="['#2EB2FF', '#10CB73', '#F2DF32', '#D32D85', '#0DD2D2', '#E04E2A', '7731BD', '#1FBB97']"
      name="Area chart"
    />
  </XGraphWidget>
    ```
</docs>

<template>
  <div
    :class="[
      'outer-container',
      {
        'bars-on-border': barsOn,
        'bars-on-border-clicked': barsOn && onActive,
        hover: (onHover || hoverOnConnection) && !onActive && barsOn,
        disabled,
      },
    ]"
    :style="customColor"
    @click="(onActive = !onActive), $emit('active', onActive)"
    @mouseover="(onHover = true), $emit('hovered', true)"
    @mouseleave="(onHover = false), $emit('hovered', false)"
  >
    <div
      :class="[
        'icon-container',
        {'datapoint-active': onActive},
        {'datapoint-small': size === 'small' && !onActive},
        {'datapoint-small-active': onActive && size === 'small'},
      ]"
      :style="[
        {height},
        {
          'min-height':
            size === 'small' ? (onActive || onHover || hoverOnConnection ? '1.5rem' : '1.25rem') : '3.75rem',
        },
        {width: size === 'small' ? (onActive || onHover || hoverOnConnection ? '1.5rem' : '1.25rem') : '3.75rem'},
        {
          'border-width':
            (onHover || hoverOnConnection || onActive) && !barsOn
              ? '4px'
              : onActive && size === 'small'
              ? '4px'
              : '2px',
        },
        {'padding-top': (onHover || hoverOnConnection || onActive) && !barsOn ? '7px' : '15%'},
      ]"
    >
      <XIcon
        v-if="size === 'large'"
        :name="iconName"
        size="large"
        :hover-effect="false"
        :color="onActive ? '#ffffff' : 'var(--customColor)'"
      />
    </div>
    <div v-if="barsOn" class="bar-container">
      <div v-if="barAccomplished" class="bar accomplished" :style="{width: `${barAccomplished}%`}" />
      <div v-if="barSideEntry" class="bar side-entry" :style="{width: `${barSideEntry}%`}" />
      <div v-if="barOutflow" class="bar outflow" :style="{width: `${barOutflow}%`}" />
    </div>
  </div>
</template>

<script>
import convertToHsl from '@src/helpers/hexToHslColor.js';

export default {
  name: 'JReportDatapoint',
  props: {
    size: {
      type: String,
      default: 'large',
      validator: value => {
        return value.match(/(small|medium|large)/);
      },
    },
    height: {
      type: String,
      default: '3.75rem',
    },
    color: {
      type: String,
      default: '#2783C6',
    },
    active: {
      type: Boolean,
      default: false,
    },
    iconName: {
      type: String,
      default: 'goal-afspraak',
    },
    barAccomplished: {
      type: Number,
      default: undefined,
    },
    barSideEntry: {
      type: Number,
      default: undefined,
    },
    barOutflow: {
      type: Number,
      default: undefined,
    },
    greyedOut: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hover: {
      type: Boolean,
      default: false,
    },
    hoverOnConnection: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['hovered', 'active'],
  data() {
    return {
      onHover: this.hover,
      onActive: this.active,
    };
  },
  computed: {
    customColor() {
      let color = this.color;

      if (this.onHover && color.startsWith('#')) {
        // We only want to accept hex colors to prevent any checking for variables because it can be any color
        color = convertToHsl(color);
      }

      return {
        '--customColor': this.greyedOut ? 'var(--icon-primary, #868686)' : color,
      };
    },
    barsOn() {
      return this.barAccomplished || this.barOutflow || this.barSideEntry;
    },
  },
};
</script>

<style lang="scss" scoped>
.outer-container {
  background-color: var(--main-background, #ffffff);
  border-radius: 0.625rem;
  width: fit-content;
  height: fit-content;
}

.outer-container.bars-on-border {
  padding: 5px;
}

.outer-container.hover {
  box-sizing: border-box;
  padding: 4px;
  border: solid var(--customColor) 4px;
}

.bars-on-border-clicked {
  border: solid var(--customColor) 2px;
}

.icon-container {
  background-color: var(--main-background, #ffffff);
  border-radius: 0.5rem;
  box-sizing: border-box;
  border: solid var(--customColor);
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.datapoint-active {
  background-color: var(--customColor);
  border: solid #ffffff;
  cursor: initial;
}

.datapoint-small {
  background-color: var(--customColor);
  cursor: initial;
}

.datapoint-small-active {
  background-color: #ffffff;
  border: solid var(--customColor);
}

.bar-container {
  padding: 0.25rem 0.12rem 0rem 0.12rem;
  display: flex;
  flex-direction: column;
  row-gap: 0.125rem;
}

.bar {
  height: 0.25rem;
  border-radius: 0.25rem;
}

.accomplished {
  background-color: var(--main-success, #00b866);
}

.side-entry {
  background-color: var(--main-primary, #2783c6);
}

.outflow {
  background-color: var(--main-border, #c9d3df);
}

.disabled {
  opacity: 0.45;
  pointer-events: none;
}
</style>

<docs>
  ```jsx
  <div style="padding: 3rem; display: flex; gap: 2.3rem; flex-wrap: wrap;">

    <JReportDatapoint 
      size="small"
      height="1.25rem"
    />

    <JReportDatapoint 
      size="small"
      height="1.25rem"
      :hoverOnConnection="true"
    />

    <JReportDatapoint 
      size="small"
      height="1.25rem"
      :active="true"
    />
    
    <JReportDatapoint 
      size="medium"
    />
    
    <JReportDatapoint 
      size="large"
    />

    <JReportDatapoint 
      size="large"
      :hover="false"
      height="9rem"
    />

    <JReportDatapoint 
      size="large"
      :hover="true"
      height="9rem"
    />

    <JReportDatapoint 
      size="large"
      :hoverOnConnection="true"
      height="9rem"
    />

    <JReportDatapoint 
      size="large"
      :active="true"
    />

    <JReportDatapoint 
      size="large"
      color="#e74d00"
    />
    

    <JReportDatapoint 
      size="large"
      :barAccomplished="20"
      :barSideEntry="50"
      :barOutflow="80"
    />

    <JReportDatapoint 
      size="large"
      :hover="true"
      :barAccomplished="20"
      :barSideEntry="50"
      :barOutflow="80"
    />

    <JReportDatapoint 
      size="large"
      :hoverOnConnection="true"
      :barAccomplished="20"
      :barSideEntry="50"
      :barOutflow="80"
    />

    <JReportDatapoint 
      size="large"
      :active="true"
      :barAccomplished="20"
      :barSideEntry="50"
      :barOutflow="80"
    />

    <JReportDatapoint 
      size="large"
      :disabled="true"
    />

    <JReportDatapoint 
      size="large"
      :greyedOut="true"
    />
    
  </div>
  ```
</docs>

<template>
  <div class="XInputWrapper">
    <slot />
    <div class="icons" v-if="deleteAction || addAction">
      <XIcon
        v-if="addAction"
        name="plus-circle"
        color="var(--icon-secondary)"
        size="xsmall"
        :hoverEffect="true"
        @click="$emit('add', true)"
      />

      <XIcon
        v-if="!minusIcon && deleteAction"
        name="delete"
        color="var(--icon-primary)"
        size="xsmall"
        :hoverEffect="true"
        @click="$emit('delete', true)"
      />
      <XIcon
        v-if="minusIcon && deleteAction"
        name="minus-square"
        color="var(--icon-primary)"
        size="xsmall"
        :hoverEffect="true"
        @click="$emit('delete', true)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'XInputWrapper',
  emits: ['add', 'delete'],
  props: {
    addAction: {
      type: Boolean,
      default: true,
    },
    deleteAction: {
      type: Boolean,
      default: true,
    },
    minusIcon: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.XInputWrapper {
  width: fit-content;
  display: flex;
  column-gap: var(--spacing-sm);
  background: var(--container-secondaryBackground, #edf3f8);
  padding: var(--spacing-sm) 0;
  align-items: center;

  .icons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 0.3rem;
  }
}
</style>

<docs>
```jsx
  <XInputWrapper>
    <XButton componentStyle="white">
      <XIcon name="store" color="var(--icon-primary)"/>
    </XButton>
    <XInput placeholder="User Input 1"/>
    <XInput placeholder="User Input 2"/>
    <XInput placeholder="User Input 3"/>
  </XInputWrapper>
  <br />
  <XInputWrapper :minusIcon="true">
    <XButton componentStyle="white">
      <XIcon name="store"/>
    </XButton>
    <XInput placeholder="User Input 1"/>
    <XInput placeholder="User Input 2"/>
    <XInput placeholder="User Input 3"/>
  </XInputWrapper>
```
</docs>

import { computed } from 'vue';
function isPredefinedSize(value) {
    return ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'].includes(value);
}
const convertToLayoutCssValue = (value) => {
    if (isPredefinedSize(value)) {
        return `var(--spacing-${value})`;
    }
    else {
        return value;
    }
};
const createStyleCompositor = (cssProperties) => {
    return (props) => {
        const styles = new Map();
        for (const [cssKey, propKey] of Object.entries(cssProperties)) {
            const propValue = props[propKey];
            const cssValue = convertToLayoutCssValue(propValue);
            if (cssValue) {
                styles.set(cssKey, cssValue);
            }
        }
        return computed(() => Object.fromEntries(styles));
    };
};
export const usePadding = createStyleCompositor({
    padding: 'padding',
    paddingBottom: 'paddingBottom',
    paddingLeft: 'paddingLeft',
    paddingRight: 'paddingRight',
    paddingTop: 'paddingTop',
});
export const useMargin = createStyleCompositor({
    margin: 'margin',
    marginBottom: 'marginBottom',
    marginLeft: 'marginLeft',
    marginRight: 'marginRight',
    marginTop: 'marginTop',
});
export const useGap = createStyleCompositor({
    gap: 'gap',
    rowGap: 'rowGap',
    columnGap: 'columnGap',
});
export const useBorderRadius = createStyleCompositor({
    borderRadius: 'borderRadius',
    borderTopLeftRadius: 'borderTopLeftRadius',
    borderTopRightRadius: 'borderTopRightRadius',
    borderBottomLeftRadius: 'borderBottomLeftRadius',
    borderBottomRightRadius: 'borderBottomRightRadius',
});

<template>
  <div class="step-bar-outer-container">
    <div
      v-for="step in steps"
      :key="step.color"
      class="step-items"
      :style="[step.active ? {'background-color': `${step.color}`, color: '#ffffff'} : null]"
    >
      {{ step.name }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'XStepsBar',
  props: {
    steps: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
@use '../../assets/styles/variables.scss' as *;
.step-bar-outer-container {
  width: 100%;
  display: flex;
  .step-items {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex: 1;
    font-size: var(--font-md);
    color: $light-text-input-color;
    background-color: $light-bg-color;
    border-radius: var(--radius-xl);
    height: 2rem;
    margin-right: -1.5rem;
    box-shadow: $light-shadow-color;
    &:nth-of-type(1) {
      z-index: 4;
    }
    &:nth-of-type(2) {
      z-index: 3;
    }
    &:nth-of-type(3) {
      z-index: 2;
    }
    &:nth-of-type(4) {
      z-index: 1;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
</style>

<docs>
  ```jsx
    <XStepsBar
      :steps="[
        {
          name: 'Gegevens', 
          color: '#4fa0cf', 
          active: true
        },
        {
          name: 'Vormgeving', 
          color: '#4fcf93', 
          active: true
        },
        {
          name: 'Informatie', 
          color: '#f0c255', 
          active: false
        },
        {
          name: 'Extra\'s', 
          color: '#cf4f93', 
          active: false
        },
      ]"
    />
  ```
</docs>

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import XStack from './../components/Layout/XStack.vue';
import { T, createEditor, usePopupService, useRefValidation, z } from '..';
import XParagraph from './../components/Typography/XParagraph.vue';
import XInput from './../components/Inputs/XInput.vue';
import XSelect from './../components/Inputs/XSelect.vue';
import XMultiSelect from './../components/Inputs/XMultiSelect.vue';
import { nextTick, ref, triggerRef } from 'vue';
export const promptString = (options) => {
    const schema = z.string().min(1);
    return prompt(Object.assign(Object.assign({}, options), { schema: schema, default: options.default || '', body: state => {
            const { validationMessage, validationType, onBlur } = useRefValidation(state, schema);
            return (<XStack direction="vertical" padding="md">
          <XParagraph>{options.description}</XParagraph>
          <XInput placeholder={options.placeholder} validationMessage={validationMessage.value} validationType={validationType.value} v-model={state.value} autoFocus onBlur={onBlur}/>
        </XStack>);
        } }));
};
export const promptStringSelect = (options) => {
    const schema = z.string().min(1);
    return prompt(Object.assign(Object.assign({}, options), { schema: z.string(), body: state => {
            const { validationMessage, validationType, onBlur } = useRefValidation(state, schema);
            return (<XStack direction="vertical" padding="md">
          <XParagraph>{options.description}</XParagraph>
          <XSelect placeholder={options.placeholder} options={options.selectOptions} clearable={options.clearable} v-model={state.value} autoFocus validationMessage={validationMessage.value} validationType={validationType.value} onBlur={onBlur}/>
        </XStack>);
        } }));
};
export const promptStringMultiSelect = (options) => {
    const schema = z.array(z.string()).min(1, T('REQUIRED'));
    return prompt(Object.assign(Object.assign({}, options), { schema: schema, default: [], body: state => {
            const { validationMessage, validationType, onBlur } = useRefValidation(state, schema);
            return (<XStack direction="vertical" padding="md">
          <XParagraph>{options.description}</XParagraph>
          <XMultiSelect placeholder={options.placeholder} options={options.selectOptions} v-model={state.value} autoFocus validationMessage={validationMessage.value} validationType={validationType.value} createTags={false} onBlur={onBlur}/>
        </XStack>);
        } }));
};
/**
 * Base functionality for prompting an input from a player, this is a generic function, specific implementations can be found above.
 * But you can create your own implementation, this function is exposed
 */
export const prompt = (options) => {
    var _a, _b, _c, _d;
    const popupService = usePopupService();
    const popupElement = ref();
    const state = ref(options.default);
    const { save, close } = createEditor({
        ref: state,
        validator: value => {
            const success = options.schema ? options.schema.safeParse(value).success : value != '' || value != undefined;
            if (!success) {
                triggerRef(state);
            }
            return success;
        },
        save: (value) => __awaiter(void 0, void 0, void 0, function* () {
            if ('then' in options.onConfirm) {
                yield options.onConfirm(value);
            }
            else {
                options.onConfirm(value);
            }
        }),
        close: () => {
            instance.close();
        },
        element: popupElement,
    });
    const instance = popupService.create({
        title: options.title,
        foreground: true,
        body: (<form onSubmit={event => {
                event.preventDefault();
                save();
            }}>
        {options.body(state)}
      </form>),
        buttons: [
            { label: ((_b = (_a = options.buttons) === null || _a === void 0 ? void 0 : _a.cancelButton) === null || _b === void 0 ? void 0 : _b.label) || T('CANCEL'), handler: close, variant: 'outlined' },
            {
                label: ((_d = (_c = options.buttons) === null || _c === void 0 ? void 0 : _c.confirmButton) === null || _d === void 0 ? void 0 : _d.label) || T('CONFIRM'),
                handler: save,
                color: 'success',
                icon: 'check-circle',
            },
        ],
        closeHandler: close,
    });
    nextTick(() => {
        popupElement.value = document.querySelector('#dnx-modal');
    });
};

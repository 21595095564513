import {translate} from '../../../helpers/translationsProvider';

//Merge the given functions into a single function
function merge() {
  const fns = Array.from(arguments).filter(fn => typeof fn === 'function');

  return function () {
    for (let fn of fns) fn.apply(this, arguments);
  };
}

function calculateXAxisStart(ctx) {
  //Horizontal bars are a special case, Apex swaps the X and Y axis for horizontal bars
  //making it so a static X axis offset will suffice
  if (ctx.bar?.isHorizontal) return 0.5;

  //Chart canvas area - our series are drawn on this element
  //Don't use context method for obtaining canvas, perform lookup ourselves. Apex may not be ready yet yielding an exception.
  const canvas = ctx.el.querySelector('.apexcharts-graphical');

  //Y Axis area - our Y Axis datalabels are drawn on this element
  const yAxis = ctx.el.querySelector('.apexcharts-yaxis');

  //Can we calculate the zero index of our X axis? If not, default to an offset of 1
  if (
    !yAxis ||
    !canvas ||
    canvas.transform.baseVal[0]?.matrix?.e === undefined ||
    yAxis.transform.baseVal[0]?.matrix?.e === undefined
  ) {
    return 1;
  }

  //Our yAxis is positioned using a CSS transform, take the X value of the transform + the element width
  const yAxisXOffset = yAxis.transform.baseVal[0].matrix.e + yAxis.getBBox().width + yAxis.getBBox().x;

  //Our canvas is also positonined using a CSS transform, we can subtract our yAxisXOffset from it to determine our relative X axis start
  return -(canvas.transform.baseVal[0].matrix.e - yAxisXOffset);
}

function fitToGrid(ctx) {
  fitToAxis(ctx.el, 'x', calculateXAxisStart(ctx));
  fitToAxis(ctx.el, 'y', 1);
}

function fitToAxis(el, axis, minOffset) {
  //Fix transform -> ,apexcharts-yaxis + [^SvgjsLine] x
  const sizeProp = axis === 'y' ? 'height' : 'width';

  //Apex uses clippaths to prevent bubbles from getting rendered outside of the grid area defined for the chart
  //the calculation determining the borders of this path seem semi-buggy however, resulting in the outer most bubbles
  //very slightly clipping outside of the defined grid area
  //this seems to be caused by a clippath axis value below 0
  //we can fix this by looking up the relevant clippath elements -> setting its axis value close to '1' to clip at our border
  const gridAlignmentClipPaths = [
    el.querySelector('[id^="gridRectMarkerMask"] rect'),
    el.querySelector('[id^="gridRectMask"] rect'),
  ];

  for (let gridAlignmentClipPath of gridAlignmentClipPaths) {
    if (!gridAlignmentClipPath) continue;

    const axisValue = gridAlignmentClipPath[axis].baseVal.value;
    const sizeValue = gridAlignmentClipPath[sizeProp].baseVal.value;

    //Render just on top/next to our grid lines
    if (axisValue >= minOffset) return;
    gridAlignmentClipPath.setAttribute(axis, minOffset);
    gridAlignmentClipPath.setAttribute(sizeProp, sizeValue + axisValue * 2 - minOffset);
  }
}

/**
 * Force the chart rendered based on the given config to not exceed the bounds of the grid it is rendered on
 * Prevent bars from being clipped
 * @param chartConfig Apex charts configuration
 * @return {{chart}}
 */
function autoFitToGrid(chartConfig) {
  if (!chartConfig.chart) chartConfig.chart = {};
  if (!chartConfig.chart.events) chartConfig.chart.events = {};

  chartConfig.chart.events.updated = merge(chartConfig.chart.events.updated, fitToGrid);
  chartConfig.chart.events.mounted = merge(chartConfig.chart.events.mounted, fitToGrid);

  return chartConfig;
}

/**
 * Apex can't handle `null` names, `undefined` names are fine however, modify the given series collection to transform `null` names to `undefined`
 *
 * Apex may not be able to handle quantities of data for a given series, ensure a max of 1000 datapoints/series
 * @param series
 */
function normalizeApexSeries(series) {
  if (!Array.isArray(series)) return series;

  //Sample first record to check if our series consists of valuetypes (eg. pie chart)
  //valuetype series shouldn't be transformed to objects
  if (typeof series[0] !== 'object') return series.slice(0, 1000);

  return series.map((s, sIndex) => ({
    ...s,
    name: s.name ?? `${translate('QUERYBUILDER_DATASET_NUMBER')} ${sIndex + 1}`,

    //Ensure max 1000 datapoints
    data: (s.data ?? []).slice(0, 1000),
  }));
}

function widgetDataLabelContrastColor(series, themeList) {
  if (series.length > 0 && themeList.length > 0) {
    const contrastColors = [];

    for (const serieIndex in series) {
      if (serieIndex <= themeList.length - 1) {
        contrastColors.push(getContrast(themeList[serieIndex]));
      }
    }

    return contrastColors;
  } else {
    return ['var(--widget-dataLabelColor, #000000)'];
  }
}

function getContrast(hexcolor) {
  // If a # is provided, remove it
  if (hexcolor.startsWith('#')) {
    hexcolor = hexcolor.slice(1);
  }

  // If a three-character hexcode, make six-character
  if (hexcolor.length === 3) {
    hexcolor = hexcolor
      .split('')
      .map(function (hex) {
        return hex + hex;
      })
      .join('');
  }

  // Convert to RGB value
  let r = parseInt(hexcolor.substr(0, 2), 16);
  let g = parseInt(hexcolor.substr(2, 2), 16);
  let b = parseInt(hexcolor.substr(4, 2), 16);

  // Get YIQ ratio
  let yiq = (r * 299 + g * 587 + b * 114) / 1000;

  // Check contrast
  return yiq >= 128 ? '#000000' : '#ffffff';
}

export {normalizeApexSeries, autoFitToGrid, widgetDataLabelContrastColor};

/** Less type restrictive version of isFinite */
const isFinite = (value) => Number.isFinite(Number.parseInt(value));
const parseFloat = window.parseFloat;
//https://www.markdownguide.org/basic-syntax#links
//[My link!](https://example.com)
//After `[`, match everything until `]`
//After `(` match everything until `)`
class MarkdownLinkRegex {
    // reset lastIndex after every invocation, ensure a 'clean slate' for every invocation
    /** Return the first match of the result of an `exec` */
    static execFirst(input) {
        this._regex.lastIndex = 0;
        return this._regex.exec(input);
    }
    /** Test the given input against the underlying regex */
    static test(input) {
        this._regex.lastIndex = 0;
        return this._regex.test(input);
    }
    /** Get the underlying regex object */
    static regexp() {
        this._regex.lastIndex = 0;
        return this._regex;
    }
}
MarkdownLinkRegex._regex = /\[([^\[]+)\]\(([^)]+)\)/g;
/** Destructure the first encountered link in the given text */
function destructureLink(unsanitizedText) {
    var _a;
    if (typeof unsanitizedText !== 'string')
        return undefined;
    const [, text, link] = (_a = MarkdownLinkRegex.execFirst(unsanitizedText)) !== null && _a !== void 0 ? _a : [];
    // no match
    if (!link)
        return undefined;
    return { link: link, text: text || link };
}
/** Detect whether the given input is a link */
export function isLink(unsanitizedText) {
    return typeof unsanitizedText === 'string' && MarkdownLinkRegex.test(unsanitizedText);
}
/** Extract the link part of the first encountered link in the given text */
export function extractLink(unsanitizedText) {
    var _a, _b;
    return (_b = (_a = destructureLink(unsanitizedText)) === null || _a === void 0 ? void 0 : _a.link) !== null && _b !== void 0 ? _b : '';
}
/** Extract the text part of the first encountered link in the given text */
export function extractLinkText(unsanitizedText) {
    var _a, _b;
    return (_b = (_a = destructureLink(unsanitizedText)) === null || _a === void 0 ? void 0 : _a.text) !== null && _b !== void 0 ? _b : '';
}
/** Sanitize the input, will prevent script injection etc. for HTML  */
export function sanitizeHTML(unsanitizedText) {
    if (typeof unsanitizedText !== 'string')
        return unsanitizedText;
    const links = [];
    //Step 1: Find links -> replace with placeholders which won't get jangled by HTML sanitization
    unsanitizedText = unsanitizedText.replaceAll(MarkdownLinkRegex.regexp(), function (fullMatch, text, href) {
        const anchor = document.createElement('a');
        anchor.href = href;
        anchor.innerText = text;
        //Attempt to open in new tab/window, should be determined based on syntax later on
        anchor.target = '__blank';
        const placeholder = '__LINK_PLACEHOLDER__' + links.length;
        links.push(anchor.outerHTML);
        return placeholder;
    });
    //Step 2: Perform actual sanitization on our text
    //Assign our unsanitized text as element text, let the browser escape any unwanted characters
    const span = document.createElement('span');
    // @ts-ignore
    span.innerText = unsanitizedText;
    //Step 3: Replace our link placeholders with our parsed links
    let sanitizedText = span.innerHTML;
    for (let i in links) {
        sanitizedText = sanitizedText.replace('__LINK_PLACEHOLDER__' + i, links[i]);
    }
}
/** Check whether the text contained in the given cell contains ellipsis */
export function hasEllipsis(cell) {
    if (cell === undefined)
        return false;
    // check if ellipsis are applied, when applied, a tooltip is to be shown displaying the entire text
    const ellipsisElement = cell.matches('.n-ellipsis,.tooltipped')
        ? cell
        : cell.querySelector('.n-ellipsis,.tooltipped');
    // https://stackoverflow.com/a/10017343/3697715
    // our offsetWidth being smaller than our scrollWidth implies ellipsis being present
    if (!ellipsisElement || ellipsisElement.offsetWidth >= ellipsisElement.scrollWidth) {
        return false;
    }
    return true;
}
/** Read the title of the given column */
export function titleOf(column) {
    return column.title || column.displayName;
}
/** Read the column key for the given column */
export function keyOf(column) {
    return column.key || column.propertyName;
}
export { isFinite, parseFloat };

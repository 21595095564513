/*

Should give some thought to how we want to properly utilize/setup inter-chart communication/allow external components to control our charts, aka our chart public API
Charts should be locatable via an id/element/unique aspect via our manager, where charts will register themselves

 */
/** Class keeping track of all individual charts, can be used to coordinate behaviour across different charts */
class ChartManager {
    constructor() {
        /** Collection of all registered charts + their settings */
        this._charts = [];
    }
    /** Track the given chart */
    register(chart) {
        this._charts.push(chart);
        // should we set up sync groups here, or let chart implementations handle it themselves?
        // if setting up here, the chart should have some kind of event/hook we can invoke
        // the alternative is our chart simply requesting other charts belonging to the same group
        // and handling interaction syncing like that
    }
    /** Stop tracking the given chart */
    unRegister(chart) {
        const idx = this._charts.indexOf(chart);
        if (idx !== -1)
            this._charts.splice(idx, 1);
    }
    /** Find all charts belonging to a specific sync group */
    findByGroup(group) {
        return this._charts.filter(x => x.settings.syncGroup === group);
    }
    /** Find all other charts in the same group */
    findOthersInSameGroup(chart) {
        if (!chart.settings.syncGroup)
            return [];
        return this._charts.filter(x => x !== chart && x.settings.syncGroup === chart.settings.syncGroup);
    }
}
const chartManager = new ChartManager();
export { chartManager };

import XIcon from '../../Data-display/XIcon.vue';
import XText from '../../Typography/XText.vue';
import XButton from '../../Buttons/XButton.vue';
import XRouterLink from '../../Navigation/XRouterLink.vue';
import { NodeBuilder } from './nodeBuilder';
import { T } from '../../../helpers/translationsProvider';
/** Utility for creating renderers for individual actions, colors, etc. are inferred from action name where possible */
export class ActionRenderers {
    /** Render a collection of actions as fragment */
    static renderGroup(style, actions) {
        // render as fragment, can include grouping logic etc. at a later stage
        return () => actions.map(action => this.render(style, action));
    }
    /** Render an action as a VNode */
    static render(style, action) {
        // should take permissions into account, maybe accept an optional context object?
        // if we know we're rendering actions for X, and X is a known permission, and our action is named 'Y'.. we can skip rendering entirely if the user lacks permission Y for X
        // for example, context = maps, action = create, no maps create permission > skip render
        // could even resolve from action name, eg. maps-create
        var _a;
        const root = this.createRoot(action);
        const buildMap = {
            button: this.makeButton,
            contextmenuitem: this.makeContextMenuItem,
            // only do this in dev mode?
            unknown: () => NodeBuilder.create('div', { style: 'background-color: red' }, `Unknown style: ${style}`),
        };
        const build = (_a = buildMap[style]) !== null && _a !== void 0 ? _a : buildMap.unknown;
        build(root, action);
        return root.build();
    }
    /** Make the current action a context menu item */
    static makeContextMenuItem(root, action) {
        var _a, _b, _c, _d;
        root.appendClass('menu-item');
        root.appendClass(`action-${action.name}`);
        const editAttributes = { label: T('EDIT'), icon: { name: 'edit', color: 'primary' } };
        const activeAttributes = { label: T('ACTIVATE'), icon: { name: 'lock-open', color: 'var(--main-warning, #F1A400)' } };
        const deactiveAttributes = {
            label: T('DEACTIVATE'),
            icon: { name: 'lock-closed', color: 'var(--main-warning, #F1A400)' },
        };
        const deleteAttributes = {
            // style should only be applied when there are other items present, fine for now
            root: { style: 'border-top: 1px solid var(--main-border, #dde9f2);' },
            label: T('DELETE'),
            icon: { name: 'delete', color: 'color: "var(--button-danger, #D21460)"' },
        };
        let attributes = { label: action.name || '', icon: { name: 'check', color: 'default', size: 'small' } };
        if ((_a = action.name) === null || _a === void 0 ? void 0 : _a.startsWith('delete'))
            attributes = deleteAttributes;
        if ((_b = action.name) === null || _b === void 0 ? void 0 : _b.startsWith('edit'))
            attributes = editAttributes;
        if ((_c = action.name) === null || _c === void 0 ? void 0 : _c.startsWith('activate'))
            attributes = activeAttributes;
        if ((_d = action.name) === null || _d === void 0 ? void 0 : _d.startsWith('deactivate'))
            attributes = deactiveAttributes;
        root.child(XIcon, Object.assign(Object.assign({}, attributes.icon), action.icon));
        root.child(XText, action.label || attributes.label);
        if ('root' in attributes)
            root.mergeAttributes(attributes.root);
    }
    /** Make the current action a button */
    static makeButton(root, action) {
        var _a, _b, _c, _d;
        const createAttributes = {
            color: 'success',
            icon: { name: 'plus-circle', color: 'default' },
            label: T('CREATE'),
        };
        const deleteAttributes = {
            color: 'error',
            icon: { name: 'delete', color: 'default' },
            label: T('DELETE'),
        };
        const ghostAttributes = {
            variant: 'outlined',
            icon: { name: ((_a = action.icon) === null || _a === void 0 ? void 0 : _a.name) || 'gear', color: 'default' },
        };
        let attributes = {
            color: undefined,
            variant: undefined,
            icon: action.icon || { name: 'check' },
            label: '',
        };
        if ((_b = action.name) === null || _b === void 0 ? void 0 : _b.startsWith('create'))
            attributes = createAttributes;
        if ((_c = action.name) === null || _c === void 0 ? void 0 : _c.startsWith('delete'))
            attributes = deleteAttributes;
        if ((_d = action.name) === null || _d === void 0 ? void 0 : _d.startsWith('ghost'))
            attributes = ghostAttributes;
        const button = root.child(XButton, {
            color: attributes.color,
            variant: attributes.variant,
            icon: attributes.icon.name,
        });
        button.child(XText, action.label || attributes.label);
    }
    static createRoot(action) {
        if ('link' in action)
            return NodeBuilder.create(XRouterLink, { to: action.link });
        return NodeBuilder.create('div', { onClick: action.handler });
    }
}

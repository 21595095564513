import { Locale } from './locale';
import { useChartComponent } from './composables';
export default {
    name: 'XChartUserLocale',
    setup() {
        useChartComponent(() => {
            var _a, _b, _c;
            return new Locale({
                lcid: (_c = (_b = (_a = Window.runtime.store) === null || _a === void 0 ? void 0 : _a.getters['locale/activeLocale']) === null || _b === void 0 ? void 0 : _b.language) !== null && _c !== void 0 ? _c : 'en',
            });
        });
    },
    render() { },
};

import { AxisRendererX, AxisRendererY, CategoryAxis as AMCategoryAxis, DateAxis as AMDateAxis, ValueAxis as AMValueAxis, } from '@amcharts/amcharts5/xy';
import { AxisRendererCircular } from "@amcharts/amcharts5/radar";
import * as am from '@amcharts/amcharts5';
import { ChartComponent } from './chartInstance';
import { categoryAxisSwitch, collectUniqueCategories } from './util';
import { DateTime, Interval } from 'luxon';
export class ValueAxis extends ChartComponent {
    getTags() {
        return ['axis', 'valueaxis'];
    }
    constructor() {
        super();
        this.addDependency('xychart');
    }
    render(context) {
        const chart = context.get('chart');
        const renderer = createRenderer(context, 'valueaxis');
        renderer.setAll({
            // ensure all labels are shown
            // minGridDistance: 10,
            // create some space between clusters
            cellStartLocation: 0.1,
            cellEndLocation: 0.9,
        });
        const stackType = context.get('stackType');
        let min;
        let max;
        let calculateTotals;
        const shouldStartAtZero = () => {
            var _a, _b;
            const tags = (_b = (_a = context.get('seriesComponents')) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.getTags();
            if (!tags)
                return false;
            const applicableTags = ['barseries', 'lineseries', 'areaseries'];
            return applicableTags.some(x => tags.includes(x));
        };
        if (stackType == '100%') {
            min = 0;
            max = 100;
            calculateTotals = true;
        }
        //TODO: ask Danny how this can be done better, this is a temporary solution, ideally we always want to have the baseline (0) in view;
        else if (shouldStartAtZero()) {
            min = 0;
        }
        // console.log(context.get<BarSeries[]>('seriesComponents')!, context, chart);
        const valueAxis = AMValueAxis.new(context.root, {
            renderer: renderer,
            min,
            max,
            calculateTotals,
        });
        // noinspection JSSuspiciousNameCombination
        categoryAxisSwitch(context, chart.yAxes, chart.xAxes).push(valueAxis);
        context.set('valueaxis', valueAxis);
    }
}
export class CategoryAxis extends ChartComponent {
    getTags() {
        return ['axis', 'categoryaxis'];
    }
    constructor(settings) {
        var _a;
        super();
        this.axis = settings.axis;
        this.categoryType = (_a = settings.categoryType) !== null && _a !== void 0 ? _a : 'category';
        this.addDependency('xychart');
    }
    setup(context) {
        super.setup(context);
        context.setGlobal('categoryaxistype', this.axis); // <-- categoryaxis is used by us to provide our concrete axis, should think of a better name?
        context.setGlobal('categorytype', this.categoryType);
    }
    render(context) {
        const chart = context.get('chart');
        const renderer = createRenderer(context, 'categoryaxis');
        renderer.setAll({
            // ensure all labels are shown
            minGridDistance: 10,
            // create some space between clusters
            cellStartLocation: 0.1,
            cellEndLocation: 0.9,
        });
        const categoryAxis = this.createAxis(context, renderer);
        categoryAxisSwitch(context, chart.xAxes, chart.yAxes).push(categoryAxis);
        // Slight rotation, reduce chance for overlap
        renderer.labels.template.setAll({
            rotation: -45,
            centerY: categoryAxisSwitch(context, am.p0, am.p100),
            centerX: am.p100,
        });
        context.set('categoryaxis', categoryAxis);
    }
    createAxis(context, renderer) {
        if (this.categoryType === 'datetime')
            return this.createDateAxis(context, renderer);
        return this.createCategoryAxis(context, renderer);
    }
    createDateAxis(context, renderer) {
        const allSeries = context.get('seriesComponents');
        // should take the smallest of all -> AMCharts applies scaling itself based on visible datapoints/range
        // see: https://www.amcharts.com/docs/v5/charts/xy-chart/axes/date-axis/#default-grid-intervals
        const settings = determineTimeSeriesSettings(allSeries[0].data.map(x => x.x));
        return AMDateAxis.new(context.root, {
            // our timeUnit determines the unit for which our datapoints are created
            // eg. timeUnit: 'month', 2023-01-23 -> januari 2023
            baseInterval: { timeUnit: settings.unit, count: 1 },
            renderer: renderer,
            // don't display different labels for period transitions (eg. first of january -> new year)
            markUnitChange: false,
            dateFormats: settings.formats,
            // render all datapoints as-is (instead of letting AMCharts determine scale etc)
            // groupData: false
        });
    }
    createCategoryAxis(context, renderer) {
        const categoryAxis = AMCategoryAxis.new(context.root, {
            categoryField: 'category',
            renderer: renderer,
        });
        const categories = collectUniqueCategories(context.get('seriesComponents')).map(x => ({ category: x }));
        categoryAxis.data.setAll(categories);
        return categoryAxis;
    }
}
/** Circular axis, used for radar charts etc */
export class CircularAxis extends ChartComponent {
    getTags() {
        return ['axis', 'valueaxis'];
    }
    constructor(settings) {
        super();
        this.min = settings.min;
        this.max = settings.max;
        this.addDependency('radarchart');
    }
    render(context) {
        const chart = context.get('chart');
        const renderer = AxisRendererCircular.new(context.root, {
            innerRadius: -5, // width of 5 pixels
        });
        // hide datalabels + grid (looks like ticks)
        renderer.labels.template.set('visible', false);
        renderer.grid.template.set('visible', false);
        const axis = chart.xAxes.push(AMValueAxis.new(context.root, {
            min: this.min,
            max: this.max,
            // force respecting min/max values
            strictMinMax: true,
            renderer: renderer,
        }));
        context.set('valueaxis', axis);
    }
}
/** Create a renderer for the given axis */
function createRenderer(context, axis) {
    // valueaxis
    if (axis === 'valueaxis') {
        return categoryAxisSwitch(context, () => AxisRendererY.new(context.root, {}), () => AxisRendererX.new(context.root, {}))();
    }
    // categoryaxis
    return categoryAxisSwitch(context, () => AxisRendererX.new(context.root, {}), () => AxisRendererY.new(context.root, {}))();
}
/**
 * Obtain settings to use for a time series renderer
 *
 * @param {number[]} initialValues
 */
function determineTimeSeriesSettings(initialValues) {
    // for diplay formats, see: https://www.amcharts.com/docs/v5/concepts/formatters/formatting-dates/
    const availableFormats = Object.assign([
        { unit: 'year', type: 'years', format: { display: 'yyyy', comparison: 'yyyy' } },
        { unit: 'month', type: 'months', format: { display: "MMM ''yy", comparison: 'yyyy MM' } },
        {
            unit: 'week',
            type: 'weeks',
            format: { display: 'MMM dd', comparison: 'yyyy MM d' },
        } /* ww -> ISO weeknumber/ww ''yy -> ISO weeknumber + 'shortyear ? */,
        { unit: 'day', type: 'days', format: { display: 'MMM dd', comparison: 'yyyy MM d' } },
        { unit: 'hour', type: 'hours', format: { display: 'HH:mm', comparison: 'yyyy MM d HH' } },
        { unit: 'minute', type: 'minutes', format: { display: 'HH:mm', comparison: 'yyyy MM d HH mm' } },
        { unit: 'second', type: 'seconds', format: { display: 'HH:mm:ss', comparison: 'yyyy MM d HH mm ss' } },
    ], {
        findByType: (type) => availableFormats.find(format => format.type === type),
        distance: (left, right) => Math.abs(availableFormats.indexOf(left) - availableFormats.indexOf(right)),
        isGreater: (left, right) => availableFormats.indexOf(right) > availableFormats.indexOf(left),
    });
    // initial values represent our neutral state - an entire dataset
    return {
        unit: determineFormat(initialValues, undefined).unit,
        // should make this a static list instead of constantly remapping
        formats: Object.fromEntries(availableFormats.map(x => [x.unit, x.format.display])),
    };
    /** Determine in which format the labels are to be displayed */
    function determineFormat(values, currentFormat) {
        // default to years, no need to perform any actual logic
        // take either current format when present (eg. zoom in), or default to years
        if (values.length <= 1)
            return currentFormat || availableFormats.findByType('years');
        const smallRangeSample = [values[0], values[1]];
        const largeRangeSample = [values[0], values.at(-1)];
        // attempt to guess the best way to represent our data based on distance between our data
        const preferredFormat = findFormatForSample(smallRangeSample);
        const dayFormat = availableFormats.findByType('days');
        // when dealing with a range spanning more than 3 days -> use days as smallest format
        if (availableFormats.isGreater(dayFormat, preferredFormat) && distanceForFormat(dayFormat, largeRangeSample) >= 3) {
            return dayFormat;
        }
        return preferredFormat;
    }
    /** Calculate the distance in the given format between two timestamps */
    function distanceForFormat(format, [timestamp1, timestamp2]) {
        const distance = Interval.fromDateTimes(DateTime.fromMillis(timestamp1), DateTime.fromMillis(timestamp2)).toDuration();
        return distance.as(format.unit);
    }
    /** Determine a format based on the distance between two timestamps */
    function findFormatForSample([timestamp1, timestamp2]) {
        const distance = Interval.fromDateTimes(DateTime.fromMillis(timestamp1), DateTime.fromMillis(timestamp2)).toDuration();
        //@ts-ignore
        const format = availableFormats.find(({ type }) => distance.as(type) >= 1);
        return format || availableFormats.findByType('minutes');
    }
}

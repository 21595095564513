<template>
  <div class="main-box">
    <div v-if="hoverType.length > 0" :class="['hover', `type-${hoverType}`]">
      <XParagraph componentStyle="small">
        {{ toolTypes[hoverType].translation }}
      </XParagraph>
      <div class="altkey">
        <XParagraph componentStyle="small"> ALT </XParagraph>
      </div>
      <XParagraph componentStyle="small"> + </XParagraph>
      <div class="key">
        <XParagraph componentStyle="small">
          {{ toolTypes[hoverType].shortcutKey }}
        </XParagraph>
      </div>
    </div>
    <XParagraph>
      {{ $t('JEDITORTOOLBAR_TOOLS') }}
    </XParagraph>
    <XButton
      component-style="white"
      :state="toolMode === 1 ? 'active' : undefined"
      @click="$emit('cursorSelect', true), (toolMode = 1)"
    >
      <XIcon name="cursor" @mouseover="hoverType = 'select'" @mouseleave="hoverType = ''" />
    </XButton>
    <XButton
      component-style="white"
      :state="toolMode === 2 ? 'active' : undefined"
      @click="$emit('addSelect', true), (toolMode = 2)"
    >
      <XIcon name="plus" @mouseover="hoverType = 'add'" @mouseleave="hoverType = ''" />
    </XButton>
    <XButton
      component-style="white"
      :state="toolMode === 3 ? 'active' : undefined"
      @click="$emit('navSelect', true), (toolMode = 3)"
    >
      <XIcon name="hand" @mouseover="hoverType = 'nav'" @mouseleave="hoverType = ''" />
    </XButton>
  </div>
</template>

<script>
export default {
  name: 'JEditorToolbar',
  emits: ['cursorSelect', 'addSelect', 'navSelect'],
  props: {
    isActive: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return {
      toolMode: this.isActive,
      selectActive: 'select',
      addActive: 'add',
      navActive: 'nav',
      hoverType: {},
      toolTypes: {
        select: {
          translation: this.$t('JEDITORTOOLBAR_TOOL_SELECT'),
          shortcutKey: 'V',
        },
        add: {
          translation: this.$t('JEDITORTOOLBAR_TOOL_ADD'),
          shortcutKey: 'A',
        },
        nav: {
          translation: this.$t('JEDITORTOOLBAR_TOOL_NAV'),
          shortcutKey: 'H',
        },
      },
    };
  },
  created() {
    window.addEventListener('keydown', this.onKeyPress);
  },
  methods: {
    onKeyPress(e) {
      if (e.altKey && e.code) {
        return e.code === 'KeyV'
          ? (this.$emit('cursorSelect', true), (this.toolMode = 1))
          : e.code === 'KeyA'
          ? (this.$emit('addSelect', true), (this.toolMode = 2))
          : e.code === 'KeyH'
          ? (this.$emit('navSelect', true), (this.toolMode = 3))
          : undefined;
      }
    },
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.onKeyPress);
  },
};
</script>

<style lang="scss" scoped>
@use '../../assets/styles/variables.scss' as *;
.main-box {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  padding: 1rem 0.843rem 1.375rem 0.843rem;
  background: var(--main-background, #ffffff);
  border-top-right-radius: var(--radius-md);
  border-bottom-right-radius: var(--radius-md);
  gap: 0.625rem;

  :deep(.button) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.625rem;
    height: 2.5rem;
    padding: 0;

    .inner {
      span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2.625rem;
        height: 2.5rem;
        z-index: 2;
      }
    }

    &:focus {
      outline: 0;
    }
  }

  :deep(p) {
    font-size: var(--font-xs);
    font-weight: $weight-semi-bold;
    color: var(--text-primary, #313741);
  }

  .hover {
    visibility: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    white-space: nowrap;
    width: fit-content;
    padding: 0.375rem 0.5rem;
    border-radius: var(--radius-xs);
    color: var(--control-selectedColor, #ffffff);
    background-color: var(--control-selectedBackground, #2783c6);
    gap: 0.5rem;
    animation-name: hoverDelay;
    animation: hoverDelay 1s;
    animation-direction: reverse;
    animation-delay: 0.8s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

    &.type-select {
      top: 2.65rem;
      left: 4rem;
    }

    &.type-add {
      top: 5.75rem;
      left: 4rem;
    }

    &.type-nav {
      top: 8.9rem;
      left: 4rem;
    }

    @keyframes hoverDelay {
      from {
        opacity: 100;
        visibility: visible;
      }
      to {
        opacity: 0;
        visibility: hidden;
      }
    }

    :deep(p) {
      display: flex;
      align-self: center;
      color: #ffffff;
      font-weight: 400;
    }

    .altkey {
      display: flex;
      justify-content: center;
      width: 2rem;
      height: 1.25rem;
      border: solid 1px var(--control-border, #c9d3df);
      border-radius: 4px;
    }

    .key {
      display: flex;
      justify-content: center;
      width: 1.25rem;
      height: 1.25rem;
      border: solid 1px var(--control-border, #c9d3df);
      border-radius: 4px;
    }
  }
}
</style>

<docs>
  ```jsx
  <JEditorToolbar 
    :isActive="1"
  />
  ```
</docs>

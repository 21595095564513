import { computed, inject, isRef, onBeforeUnmount, provide, unref, watch } from 'vue';
/** Provide/Inject the given series component */
export function useSeries(series) {
    if (!series)
        return inject('series', undefined);
    if (!isRef(series))
        series = computed(series);
    provide('series', series);
    return series;
}
/** Provide/Inject the given chart instance */
export function useChart(chart) {
    if (!chart)
        return inject('chart');
    provide('chart', chart);
    return chart;
}
/**
 * Composable for adding components to a chart instance, automatically takes care of lifecycle management etc.
 *
 * @param factoryOrComponent Component or component factory, when a factory function/computed is provided, a watcher will be placed on it providing automatic replacement on the chart instance
 * @param chart Chart instance to add component to, when nothing is provided value will be injected from parent context
 * */
export function useChartComponent(factoryOrComponent, chart) {
    if (!chart)
        chart = inject('chart');
    if (!chart) {
        console.warn('No chart instance found, expected chart instance to either be provided by parent, or provided as parameter.');
        return;
    }
    const component = typeof factoryOrComponent === 'function' ? computed(() => factoryOrComponent()) : factoryOrComponent;
    chart.add(unref(component));
    // not all provided components have to be refs etc. raw components may also be passed in (eg. no reactive deps, no need to rerender chart on change)
    if (isRef(component)) {
        watch(component, (newValue, oldValue) => chart.replace(oldValue, newValue));
    }
    onBeforeUnmount(() => chart.remove(unref(component)));
}

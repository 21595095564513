<template>
  <div class="grid-container">
    <slot />
  </div>
</template>

<script>
/**
 *  .grow-{var} where var is the grid size within a max of 12
 *
 *  The width of the element is 12 / {var}
 */
export default {
  name: 'XGridLayout',
};
</script>

<style scoped lang="scss">
@use '../../assets/styles/variables.scss' as *;
@use 'sass:math';

.grid-container {
  display: grid;
  column-gap: 0.75rem;
  row-gap: 0.75rem;

  grid-template-columns: repeat(12, minmax(0, 1fr));
  grid-auto-rows: 7.813rem;

  @for $grid-column from 1 through 12 {
    :deep(.grid-1-#{$grid-column}) {
      grid-column: span $grid-column;

      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }

  @for $grid-row from 1 through 120 {
    :deep(.grid-row-#{$grid-row}) {
      grid-row: span $grid-row;
      height: 100%;
    }
  }
}

@media screen and #{$media-query-s} {
  .grid-container {
    @for $grid-column from 1 through 12 {
      :deep(.grid-1-#{$grid-column}) {
        grid-column: span 12;
      }
    }
  }
}
</style>

<docs>
```jsx
  <XGridLayout>
    <XBox boxTitle="Hello1" class="grid-1-4 grid-row-2">
      <XHeading level="h5">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Culpa nisi perferendis reiciendis soluta! Aperiam
      blanditiis distinctio harum molestias quaerat rem soluta temporibus. Doloremque facere mollitia odit repellendus
      tempora? Hic, repellendus.</XHeading>
    </XBox>

    <div class="grid-1-3 grid-row-3">
      <XBox boxTitle="Hello2" />
      <XBox :boxBelow="true" />
    </div>

    <div class="grid-1-3 grid-row-1">
      <XBox boxTitle="Hello3" />
      <XBox :boxBelow="true">
        <XButton component-style="primary-action">
          <XIcon name="gear" size="custom" />
          Action2
        </XButton>
      </XBox>
    </div>

    <XBox boxTitle="Hello4" class="grid-1-2" />
    <XBox boxTitle="Hello5" class="grid-1-3" />
    <XBox boxTitle="Hello6" class="grid-1-2" />
    <XBox boxTitle="Hello7" class="grid-1-4 grid-row-5" />
    <XBox boxTitle="Hello8" class="grid-1-2 grid-row-3" />
    <XBox boxTitle="Hello9" class="grid-1-2 grid-row-2" />

    <XBox boxTitle="Hello10" class="grid-1-1" />
    <XBox boxTitle="Hello11" class="grid-1-1" />
    <XBox boxTitle="Hello12" class="grid-1-1" />
    <XBox boxTitle="Hello13" class="grid-1-1" />
    <XBox boxTitle="Hello14" class="grid-1-1" />
    <XBox boxTitle="Hello15" class="grid-1-1" />
    <XBox boxTitle="Hello16" class="grid-1-1" />
  </XGridLayout>
```
</docs>

<template>
  <div class="chart">
    <apexchart ref="chart" type="donut" :height="height" :width="width" :options="chartOptions" :series="dataSeries" />
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import {ToolbarProxy} from './toolbarProxy';
import {
  autoFitToGrid,
  normalizeApexSeries,
  widgetDataLabelContrastColor,
} from '@src/components/Data-display/Charts/util';
import {PieChartMixin} from './mixins';

export default {
  name: 'XApexPieChart',
  components: {
    apexchart: VueApexCharts,
  },
  mixins: [PieChartMixin],

  data() {
    return {
      chartOptions: this.generateChartOptions(),
    };
  },
  computed: {
    updateChart() {
      const {series, categories, dataLabels, theme} = this;
      return {
        series,
        categories,
        dataLabels,
        theme,
      };
    },
    dataSeries() {
      return normalizeApexSeries(this.series);
    },
  },
  watch: {
    updateChart() {
      this.chartOptions = {...this.generateChartOptions()};
    },
    dataUpdate(newVal, oldVal) {
      if (newVal?.update || JSON.stringify(oldVal) !== JSON.stringify(newVal)) {
        this.updateData(normalizeApexSeries(newVal));
      }
    },
  },
  methods: {
    updateData(data) {
      //DEV: Paths get morphed, so dont animate.
      //  The slide effect in the apex realtime demo is the result of a svgjs bug,
      //  When xaxis is fixed & larger than the series (using xaxis.range) svgjs will slide in stead of morph the data.
      //  When the series stops growing, or draws outside the graph boundary the data will start morphing.
      //  So keep adding datapoints AND set series head (data[0].x=data[1].x) to prevent drawing will keep svgjs in slide mode.
      //
      //  Ensuring this in the data is too much work, so we do not animate.
      if (data.append) this.$refs.chart.chart?.appendSeries(data.series, false);
      else this.$refs.chart.chart?.updateSeries(data.series, false);
    },
    generateChartOptions() {
      return autoFitToGrid({
        chart: {
          width: this.width,
          type: 'donut',
          events: {
            mounted: ctx => this[ToolbarProxy.InjectionKey]?.update(ctx.el),
            updated: ctx => this[ToolbarProxy.InjectionKey]?.update(ctx.el),
          },
          toolbar: {
            show: true,
            tools: {
              customIcons: [
                {
                  icon: '',
                  index: -1,
                  title: 'Export Excel',
                  class: 'custom-icon exportexcel',
                  click: () => {
                    this.$emit('export');
                  },
                },
              ],
            },
            export: {
              csv: {filename: this.name},
              svg: {filename: this.name},
              png: {filename: this.name},
            },
          },
        },
        colors: this.theme,
        dataLabels: {
          enabled: this.dataLabels,
          style: {
            colors: widgetDataLabelContrastColor(this.series, this.theme),
          },
        },
        fill: {
          opacity: 1,
        },
        labels: normalizeApexSeries(this.categories),
        legend: {
          show: this.legend,
          position: 'bottom',
        },
        stroke: {
          width: 0,
        },
        noData: {
          text: this.$t('WIDGET_NO_DATA'),
          style: {
            color: 'var(--text-primary, #313741)',
          },
        },
        tooltip: {
          enabled: this.tooltip,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
:deep([filter]) {
  filter: drop-shadow(0 0 0rem);
}
</style>

<style lang="scss">
.apexcharts-canvas svg {
  transition: 1.3s all linear;
  background: transparent !important;
}
</style>
<!-- 
<docs>
  ```jsx
  <XGraphWidget
      :widgetId="1234234"
      name="Een korte titel"
      information="Dit is een beschrijving van de pie grafiek."
      :edit-rights="true"
    >
    <XPieChart
      height="350"
      width="300"
      :series="[44, 55, 13, 43, 22]"
      :categories="['Team A', 'Team B', 'Team C', 'Team D', 'Team E']"
      :theme="['#2EB2FF', '#10CB73', '#F2DF32', '#D32D85', '#0DD2D2', '#E04E2A', '7731BD', '#1FBB97']"
      :dataLabels="true"
      :legend="true"
      :tooltip="true"
    />
  </XGraphWidget>
    ```
</docs> -->

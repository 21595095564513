<template>
  <div :class="['text-box', `arrow-pos-${arrowPosition}`]">
    <p>
      <slot />
    </p>
    <div class="arrow" />
  </div>
</template>

<script>
export default {
  name: 'XTextBalloon',
  props: {
    arrowPosition: {
      type: String,
      default: 'bottom',
      validator: value => {
        return value.match(/(fixed|top|bottom|left|right)/);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@use '../../assets/styles/variables.scss' as *;
.text-box {
  display: flex;
  justify-content: center;
  position: relative;
  width: fit-content;
  padding: 0.7rem;
  background: #083856;
  border-radius: var(--radius-xs);
  white-space: nowrap;

  p {
    font-size: var(--font-md);
    font-family: $font-text;
    font-weight: $weight-normal;
    color: white;
    margin: 0;
    z-index: 10;
  }

  .arrow {
    content: '';
    width: 0px;
    height: 0px;
    position: absolute;
    align-self: center;
    transform: rotate(225deg);
    border-left: 10px solid #083856;
    border-right: 10px solid #083856;
    border-top: 10px solid #083856;
    border-bottom: 10px solid #083856;
  }

  &.arrow-pos-fixed {
    min-width: 3.5rem;
    .arrow {
      bottom: -0.1rem;
      left: 2.5rem;
    }
  }

  &.arrow-pos-right {
    .arrow {
      right: -0.1rem;
    }
  }

  &.arrow-pos-left {
    .arrow {
      left: -0.1rem;
    }
  }

  &.arrow-pos-top {
    .arrow {
      top: -0.1rem;
    }
  }

  &.arrow-pos-bottom {
    .arrow {
      bottom: -0.1rem;
    }
  }
}
</style>

<docs>
  ```jsx
  //div is just for demonstration purpose, not needed when using component.
  <div style="padding: 4rem 24rem;">
    <XTextBalloon 
      arrow-position="bottom"
    >
      <p>Just a text balloon</p>
    </XTextBalloon>
  </div>
  ```
</docs>

<template>
  <div>
    <!-- Success message -->
    <div v-if="alertType === 'success'" class="alert-container success" :class="alignment">
      <div class="alert-icon">
        <XIcon name="check-circle" color="green" size="medium" />
      </div>
      <div class="alert-message">
        <h3>{{ $t('GENERIC_ALERT_SUCCESS') }}</h3>
        <p>
          <slot />
        </p>
      </div>
      <div class="close-message">
        <div>
          <XIcon name="clear" size="small" class="close-alert" @click="closeAlert()" />
        </div>
      </div>
    </div>
    <!-- Warning message -->
    <div v-if="alertType === 'warning'" class="alert-container warning" :class="alignment">
      <div class="alert-icon">
        <XIcon name="warning-light" color="orange" size="medium" />
      </div>
      <div class="alert-message">
        <h3>{{ $t('GENERIC_ALERT_WARNING') }}</h3>
        <p><slot /></p>
      </div>
      <div class="close-message">
        <XIcon name="clear" size="small" class="close-alert" @click="closeAlert()" />
      </div>
    </div>
    <!-- Alert message -->
    <div v-if="alertType === 'error'" class="alert-container error" :class="alignment">
      <div class="alert-icon">
        <XIcon name="clear" color="red" size="medium" />
      </div>
      <div class="alert-message">
        <h3>{{ $t('GENERIC_ALERT_ERROR') }}</h3>
        <p>
          <slot />
        </p>
      </div>
      <div class="close-message">
        <XIcon name="clear" size="small" class="close-alert" @click="closeAlert()" />
      </div>
    </div>
    <!-- Information message -->
    <div v-if="alertType === 'information'" class="alert-container information" :class="alignment">
      <div class="alert-icon">
        <XIcon name="information-circle" color="blue" size="medium" />
      </div>
      <div class="alert-message">
        <h3>{{ $t('GENERIC_ALERT_INFORMATION') }}</h3>
        <p>
          <slot />
        </p>
      </div>
      <div class="close-message">
        <XIcon name="clear" size="small" class="close-alert" @click="closeAlert()" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'XToast',
  props: {
    type: {
      type: String,
      default: null,
      validator: value => {
        return value.match(/(success|warning|error|information)/);
      },
    },
    alignment: {
      type: String,
      default: 'top-right',
      validator: value => {
        return value.match(/(top-right|bottom-right)/);
      },
    },
    // to be added later
    time: {
      type: Number,
      default: null,
    },
  },
  emits: ['close'],
  data() {
    return {
      alertType: this.type,
    };
  },
  mounted() {
    setTimeout(() => {
      this.$emit('close');
    }, 9300);
  },
  methods: {
    closeAlert() {
      this.alertType = null;
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
@use '../../assets/styles/variables.scss' as *;
.alert-container {
  display: flex;
  align-items: stretch;
  min-height: fit-content;
  width: 30rem;
  justify-content: space-between;
  z-index: var(--zindex-alert);
  border-radius: var(--radius-xs);
  background: var(--main-background, #ffffff);
  box-shadow: var(--main-shadow, 0px 0px 10px 0px rgba(26, 26, 27, 0.4));
}

.alert-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 4rem;
}

.close-message {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  min-width: 3.56rem;
  border-left: 1px solid var(--container-border, #c9d3df);
}

.top-right {
  top: 1rem;
  right: 2rem;
}

.bottom-right {
  bottom: 2rem;
  right: 2rem;
}

.alert-message {
  margin-top: -0.2rem;
  margin-right: auto;
  font-size: var(--font-md);
  font-weight: $weight-semi-bold;
  padding: 1rem 0rem;
}

h3 {
  color: var(--text-primary, #313741);
  margin: 0.1rem;
}

p {
  color: var(--control-placeholder, #868686);
  margin: 0.1rem;
  font-weight: 400;
}

.close-alert {
  cursor: pointer;
  :deep(svg) {
    path {
      stroke: var(--control-color, #313741);
    }
  }
}

.success {
  border-left: 6px solid var(--main-success, #00b866);
}

.warning {
  border-left: 6px solid var(--main-warning, #f1a400);
}

.error {
  border-left: 6px solid var(--main-error, #d21460);
}

.information {
  border-left: 6px solid var(--main-primary, #2783c6);
}
</style>

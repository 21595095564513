import { ChartComponent } from './chartInstance';
import { Bullet, Label, p50, color as amColor, RoundedRectangle, p100 } from '@amcharts/amcharts5';
import { contrastColor, luminance } from './color';
import { categoryAxisSwitch } from './util';
import { LineSeries } from '@amcharts/amcharts5/xy';
import { PieSeries } from '@amcharts/amcharts5/percent';
import { AxisRendererCircular } from "@amcharts/amcharts5/radar";
/** Add datalabels (bullets) to the given chart series */
export class Labels extends ChartComponent {
    getTags() {
        return ['series-enhancer', 'datalabels'];
    }
    constructor(settings) {
        super();
        this.addDependency(settings.target);
    }
    static forSeries(series) { return new Labels({ target: series }); }
    static forValueAxis() { return new Labels({ target: 'valueaxis' }); }
    render(context) {
        const series = context.get('series');
        const valueAxis = context.get('valueaxis');
        if (series)
            return this.renderForSeries(context, series);
        return this.renderForValueAxis(context, valueAxis);
    }
    renderForValueAxis(context, valueAxis) {
        const renderer = valueAxis.get('renderer');
        if (!renderer.isType(AxisRendererCircular.className))
            return;
        renderer.labels.template.setAll({
            // for now, we only officially support circular renderers (= gauge etc.)
            visible: true,
            // move labels to inside our axis
            inside: true,
            // don't overlap with ticks (should use tick length/text size for this)
            radius: 35
        });
        const numberFormatter = context.root.numberFormatter;
        renderer.labels.template.adapters.add('text', (value, target) => {
            var _a;
            const dataItemValue = (_a = target.dataItem) === null || _a === void 0 ? void 0 : _a.get('value');
            if (Number.isFinite(dataItemValue))
                value = numberFormatter.format(dataItemValue);
            return value;
        });
        this.applyBaseStyling(renderer.labels.template);
    }
    renderForSeries(context, series) {
        if (series.isType(PieSeries.className)) {
            this.pieLabels(series);
            return;
        }
        this.xyLabels(context, series);
    }
    xyLabels(context, series) {
        const stackType = context.get('stacktype');
        // horizontal
        const xLabel = stackType === '100%' ? "{valueYTotalPercent.formatNumber('#.##')}%" : '{valueY}';
        // vertical
        const yLabel = stackType === '100%' ? "{valueXTotalPercent.formatNumber('#.##')}%" : '{valueX}';
        const text = categoryAxisSwitch(context, xLabel, yLabel);
        series.bullets.push(root => {
            // position in center
            const label = Label.new(root, {
                text: text,
                populateText: true,
                centerY: p50,
                centerX: p50,
            });
            // should this be part of our theme?
            label.adapters.add('fill', (value) => {
                // (target.dataItem.component as Series)
                const seriesColor = series.get('fill').toCSSHex();
                // am.Color.alternative
                return amColor(contrastColor(seriesColor, value.toCSSHex(), '#ffffff'));
            });
            this.applyBaseStyling(label);
            // hide items with a `_hide` attribute
            label.adapters.add('forceHidden', (value, target) => { var _a, _b, _c; return (_c = (_b = (_a = target.dataItem) === null || _a === void 0 ? void 0 : _a.dataContext) === null || _b === void 0 ? void 0 : _b._hide) !== null && _c !== void 0 ? _c : value; });
            if (series.isType(LineSeries.className))
                this.applyLineSeriesStyling(context, series, label);
            return Bullet.new(root, {
                sprite: label,
            });
        });
    }
    pieLabels(series) {
        // required for custom label positioning, overrides textType
        series.set('alignLabels', false);
        series.labels.template.setAll({
            text: '{valuePercentTotal.formatNumber("0.0")}%',
            // align inside center of our slices
            inside: true,
            radius: 0,
            baseRadius: p50,
            textType: 'regular',
        });
        // label styling should be handled by an adapter, fine for now, having this be done by an adapter requires delaying setting the data for our series
        // this requires a rework of some aspects of our charting setup
        series.labels.values.forEach(label => {
            this.applyBaseStyling(label);
            // only hide labels with a small percentage
            label.dataItem.on(
            // @ts-ignore
            'valuePercentTotal', value => label.set('forceHidden', value < 3));
        });
        // ensure label is readable on slice background
        series.slices.each(slice => {
            slice.on('fill', fill => {
                if (!fill)
                    return;
                const label = findLabelFor(slice);
                if (!label)
                    return;
                const labelColor = label.get('fill');
                // am.Color.alternative
                label.set('fill', amColor(contrastColor(fill.toCSSHex(), labelColor.toCSSHex(), '#ffffff')));
            });
            // center on hover (scale change)
            slice.on('scale', () => stickLabelToSlice(slice));
            // center on click (slice move)
            slice.events.on('positionchanged', (evt) => stickLabelToSlice(evt.target));
        });
        /** Move the label alongside with the slice it's providing data for */
        function stickLabelToSlice(slice) {
            const label = findLabelFor(slice);
            if (!label)
                return;
            const sliceRadius = slice.get('radius');
            const shiftRadius = slice.get('shiftRadius');
            const sliceScale = slice.get('scale');
            // pixels shifted by change in scale
            const scaleShift = sliceRadius - (sliceRadius * sliceScale);
            label.set('radius', scaleShift - shiftRadius);
        }
        /** Find the label object associated with the slice */
        function findLabelFor(slice) {
            return series.labels.values.find(label => label.dataItem === slice.dataItem);
        }
    }
    applyBaseStyling(label) {
        // dark shadow for light colors, light shadow for dark colors
        label.adapters.add('shadowColor', (value, target) => {
            var _a;
            const fill = (_a = target.get('fill')) === null || _a === void 0 ? void 0 : _a.toCSSHex();
            if (!fill)
                return value;
            return luminance(fill) >= 0.7 ? amColor('#000000') : amColor('#ffffff');
        });
        label.set('shadowBlur', 2);
    }
    applyLineSeriesStyling(context, series, label) {
        // line series labels are rendered on top of our lines, give them a background to make them more visible
        const background = label.set('background', RoundedRectangle.new(context.root, {
            fill: series.get('fill'),
            cornerRadiusBL: 3,
            cornerRadiusBR: 3,
            cornerRadiusTL: 3,
            cornerRadiusTR: 3,
            // slightly smaller box while still remaining centered
            maxHeight: 20,
            dy: 3,
        }));
        // position above, allow bullets etc. to still show
        label.set('centerY', p100);
        // stroke color is inherited from label which isn't known yet at this point
        background.set('strokeWidth', 0.5);
        background.adapters.add('stroke', () => label.get('fill'));
    }
}

/**
 * @typedef ChartFeature
 *
 * @property {string} name Name of the feature
 * @property {function(): boolean} isEnabled Marks whether the feature is enabled or not
 * @property {function(config: object)} configure Enhance the input configuration by adding feature specific options
 * @property toolbar Container toolbar configuration
 * @property {string[]} toolbar.features Toolbar features supported by the ChartFeature, toolbar features will be automatically enabled/disabled based on the ChartFeature enabled state
 * @property {Object} toolbar.listeners Toolbar event listeners for the ChartFeature
 */

/**
 * @typedef ChartAnnotationPoint
 *
 * @property {ChartAnnotationTypes.Point} type
 * @property {number} x
 * @property {number} y
 */

/**
 * @typedef ChartAnnotationHorizontalLine
 *
 * @property {ChartAnnotationTypes.HorizontalLine} type
 * @property {number} x
 */

/**
 * @typedef ChartAnnotationHorizontalRange
 *
 * @property {ChartAnnotationTypes.HorizontalRange} type
 * @property {number} x
 * @property {number} x2
 */

/**
 * @typedef ChartAnnotationVerticalLine
 *
 * @property {ChartAnnotationTypes.VerticalLine} type
 * @property {number} y
 */

/**
 * @typedef ChartAnnotationVerticalRange
 *
 * @property {ChartAnnotationTypes.VerticalRange} type
 * @property {number} y
 * @property {number} y2
 */

/**
 * @typedef ChartAnnotationBase
 *
 * @property {String} text
 * @property {String} color
 * @property {Boolean} isInvisible
 */

/**
 * @typedef {
 * (
 *      ChartAnnotationPoint |
 *      ChartAnnotationHorizontalLine |
 *      ChartAnnotationHorizontalRange |
 *      ChartAnnotationVerticalLine |
 *      ChartAnnotationVerticalRange
 * ) & ChartAnnotationBase
 * } ChartAnnotation
 */

import {T} from '../../../helpers/translationsProvider';
import {isEmpty, slug, calculateTextDimensions, sanitizeText} from '../../../helpers/util';
import {ChartManager} from './chartManager';
import {DateTime, Interval} from 'luxon';
import {connect as connectCharts} from 'echarts/core';

const ChartAnnotationTypes = {
  Point: 1,
  HorizontalLine: 2,
  HorizontalRange: 3,
  VerticalLine: 4,
  VerticalRange: 5,
};

/** Gather all unique categories present for all series in the given configuration  */
function collectUniqueCategories(config) {
  const data = new Set();

  for (const series of config.series) {
    for (const datapoint of series.data) {
      data.add(datapoint._category());
    }
  }

  return data;
}

/**
 *  Gather all actual rendered categories for an instantiated chart, assumes all series use the same datapoints
 *
 *  @param {ChartManager} manager
 */
function collectUniqueRenderedCategories(manager) {
  const chartInstance = manager.get('chart');
  if (!chartInstance) return new Set();

  // no consistent way to access original datapoints to use the `_category` function, determine our category dimension based on axis type
  const categoryDimension = manager.get('primaryAxis') === 'xAxis' ? 'x' : 'y';

  const data = new Set();

  // for now, assume all series share the same datasource
  for (const series of chartInstance.getModel().getSeries()) {
    series.getData().each(categoryDimension, value => data.add(value));
  }

  return data;
}

/**
 * Build a formatter function for tooltips, ensuring consistent display of tooltips
 * @param {ChartManager} manager
 * */
function buildTooltipFormatter(manager) {
  return args => {
    args = Array.isArray(args) && args.length === 1 ? args[0] : args;

    return Array.isArray(args) ? formatMultiSeriesTooltip(args) : formatSingleSeriesTooltip(args);
  };

  function formatSingleSeriesTooltip(args) {
    if (!args.data) return '';

    const category = getCategory(args);
    const seriesName = getSeriesName(args);
    const value = getDisplayedValue(args);

    return seriesName
      ? `${category}<br />${args.marker} ${seriesName}: ${value}`
      : `${args.marker} ${category}: ${value}`;
  }

  function formatMultiSeriesTooltip(args) {
    args = args.filter(x => x.data && x.data._tooltipDisabled !== true);
    if (!args.length) return '';

    return [
      getCategory(args),
      ...args.map(args => `${args.marker} ${getSeriesName(args)}: ${getDisplayedValue(args)}`),
    ].join('<br />');
  }

  function getCategory(args) {
    // all categories are expected to be the same
    args = Array.isArray(args) ? args[0] : args;

    const axisType = manager.get('primaryAxisType');
    const category = args.data._category();
    return axisType === 'datetime' ? manager.get('formatter')(category, 'date') : category;
  }

  function getSeriesName(args) {
    // echarts internally names series when no name has been provided - we dont want those names to show on hover
    // explicitly look at our own series object
    const series = manager.get('series');
    const name = series[args.data._seriesIndex ?? args.seriesIndex]?.name ?? '';
    return sanitizeText(name);
  }

  function getDisplayedValue(args) {
    const value = args.data.hasOwnProperty('_originalValue') ? args.data._originalValue : args.data._value();

    return sanitizeText(value);
  }
}

/**
 * Build a formatter for time series dataset labels with differing formats based on distance between contained timestamps
 *
 * @param {ChartManager} manager
 * @param {number[]} initialValues
 */
function buildTimeSeriesLabelFormatter(manager, initialValues) {
  // threshold determining when echarts should takeover determining which labels are eligible to be rendered
  const ECHARTS_RENDERING_HANDOVER_THRESHOLD = 75;

  // list of types for which we should always handover rendering to echarts due to granularity
  const ECHARTS_RENDERING_HANDOVER_TYPES = ['hours', 'minutes', 'seconds'];

  const ONE_HOUR_IN_MS = 3600000;

  const availableFormats = [
    {type: 'years', format: {display: '{yyyy}', comparison: 'yyyy'}},
    {type: 'months', format: {display: "{MMM} '{yy}", comparison: 'yyyy MM'}},
    {type: 'weeks', format: {display: '{d}', comparison: 'yyyy MM d'}},
    {type: 'days', format: {display: '{d}', comparison: 'yyyy MM d'}},
    {type: 'hours', format: {display: '{HH}:{mm}', comparison: 'yyyy MM d HH'}},
    {type: 'minutes', format: {display: '{HH}:{mm}', comparison: 'yyyy MM d HH mm'}},
    {type: 'seconds', format: {display: '{HH}:{mm}:{ss}', comparison: 'yyyy MM d HH mm ss'}},
  ];

  availableFormats.findByType = type => availableFormats.find(format => format.type === type);
  availableFormats.distance = (left, right) =>
    Math.abs(availableFormats.indexOf(left) - availableFormats.indexOf(right));

  // initial values represent our neutral state - an entire dataset
  let currentFormat = determineFormat(initialValues, undefined);
  const initialFormat = currentFormat;

  const initialShouldRenderPotentialLabel = createShouldRenderPotentialLabel(initialValues);
  let shouldRenderPotentialLabel = initialShouldRenderPotentialLabel;

  manager.configureEvent('datazoom', () => {
    const renderedValues = [...collectUniqueRenderedCategories(manager)];

    currentFormat = determineFormat(renderedValues, currentFormat);
    shouldRenderPotentialLabel = createShouldRenderPotentialLabel(renderedValues);

    rerender();
  });

  manager.configureEvent('restore', () => {
    currentFormat = initialFormat;
    shouldRenderPotentialLabel = initialShouldRenderPotentialLabel;
    rerender();
  });

  return value => {
    if (!shouldRenderPotentialLabel(value)) return '';
    return currentFormat.format.display;
  };

  /** Build a function determining whether a label is to be rendered or not, potential labels are decided by echarts */
  function createShouldRenderPotentialLabel(values) {
    const rules = [firstOfFormatRule()];

    if (
      values.length < ECHARTS_RENDERING_HANDOVER_THRESHOLD &&
      !ECHARTS_RENDERING_HANDOVER_TYPES.includes(currentFormat.type)
    ) {
      rules.push(closeToDatapointRule());
    }

    return value => {
      for (const rule of rules) {
        if (!rule(value)) return false;
      }

      return true;
    };

    function closeToDatapointRule() {
      // as an easy workaround to prevent issues with timezones etc. simply take a range of 24 hours
      // our label render prevention mechanism is mostly aimed at datasets consisting of large steps, so this souldn't pose an issue
      // TODO: Check https://echarts.apache.org/en/option.html#useUTC

      const renderableValues = [];

      for (let i = 0; i < values.length; i++) {
        const value = values[i];
        for (let hourOffset = -24; hourOffset < 24; hourOffset++) {
          renderableValues.push(value + hourOffset * ONE_HOUR_IN_MS);
        }
      }

      return value => renderableValues.includes(value);
    }

    function firstOfFormatRule() {
      const firstValuesPerFormat = new Map();

      return value => {
        const comparable = DateTime.fromMillis(value).toFormat(currentFormat.format.comparison);
        if (!firstValuesPerFormat.has(comparable)) firstValuesPerFormat.set(comparable, value);
        return firstValuesPerFormat.get(comparable) === value;
      };
    }
  }

  /** Determine in which format the labels are to be displayed */
  function determineFormat(values, currentFormat) {
    // default to years, no need to perform any actual logic
    // take either current format when present (eg. zoom in), or default to years
    if (values.length <= 1) return currentFormat || availableFormats.findByType('years');

    // attempt to guess the best way to represent our data based on distance between our data
    const smallRangeSample = [values[0], values[1]];
    const largeRangeSample = [values[0], values.at(-1)];

    const format1 = findFormatForSample(smallRangeSample);
    const format2 = findFormatForSample(largeRangeSample);

    // take our first format when our small/large formats are relatively close
    // and the distance between our large range is relatively small
    if (availableFormats.distance(format1, format2) <= 2 && distanceForFormat(format2, largeRangeSample) <= 3) {
      return format1;
    }

    // pivot to a larger format when our dataset spans a long enough period
    const format1Idx = availableFormats.indexOf(format1);
    const format2Idx = availableFormats.indexOf(format2);

    // from large to small, find the first format where the distance between our large range sample data covers at least 2 datapoints
    // when our large dataset spans exactly 1 unit, only one single label will be displayed, this yields an unclear chart and is thus a scenario we want to avoid
    for (const format of availableFormats.slice(format2Idx, format1Idx)) {
      const distance = distanceForFormat(format, largeRangeSample);
      if (distance >= 2) {
        return format;
      }
    }

    return format2;
  }

  /** Calculate the distance in the given format between two timestamps */
  function distanceForFormat(format, [timestamp1, timestamp2]) {
    const distance = Interval.fromDateTimes(
      DateTime.fromMillis(timestamp1),
      DateTime.fromMillis(timestamp2)
    ).toDuration();

    return distance.as(format.type);
  }

  /** Determine a format based on the distance between two timestamps */
  function findFormatForSample([timestamp1, timestamp2]) {
    const distance = Interval.fromDateTimes(
      DateTime.fromMillis(timestamp1),
      DateTime.fromMillis(timestamp2)
    ).toDuration();

    const format = availableFormats.find(({type}) => distance.as(type) >= 1);
    return format || availableFormats.findByType('minutes');
  }

  /** Force the current chart to rerender, updating the labels etc. */
  function rerender() {
    manager.get('chart').resize({animation: {duration: 500}});
  }
}

// move to labels class? Labels.SolidBackground( labelconfig )
/** Make the given label solid by filling its background */
function makeSolidLabelBackground(label = {}) {
  label.borderType = 'solid';
  label.borderColor = '#FFFFFF';
  label.borderWidth = 1;
  label.borderRadius = 2;

  label.color = '#FFFFFF';

  label.backgroundColor ??= 'inherit';
  label.fontSize = 12;
  label.padding = 3;
  label.opacity = 1;

  return label;
}

/**
 * @param {ChartManager} manager
 * @return {ChartFeature}
 */
export function zoomFeature(manager) {
  const getCurrentZoom = () => {
    // Theres currently no API exposing the current zoom...
    // https://stackoverflow.com/questions/42503988/echarts-datazoom-event-does-not-return-timestamp-but-only-percentages
    const chartModel = manager.get('chart').getModel();
    return chartModel.get('dataZoom')[0];
  };

  const triggerZoom = step => {
    let {start, end} = getCurrentZoom();

    // prevent start/end from being the same value
    // prevent start from exceeding end, prevent end from exceeding start
    const newStart = Math.max(Math.min(start + step, end - step * 2), 0);
    const newEnd = Math.min(Math.max(end - step, start + step * 2), 100);

    manager.get('chart').dispatchAction({
      type: 'dataZoom',
      start: newStart,
      end: newEnd,
    });
  };

  const isEnabled = () => manager.get('zoom') && !manager.hasFeatureEnabled('nodata');

  const toolbar = {
    features: ['zoom'],
    listeners: {
      // zoom in x elements
      'zoom-in': () => triggerZoom(10),

      // zoom out x elements
      'zoom-out': () => triggerZoom(-10),

      // toggle zoom mode
      'zoom-selection': () =>
        manager.get('chart').dispatchAction({
          type: 'takeGlobalCursor',
          key: 'dataZoomSelect',
          dataZoomSelectActive: true,
        }),

      // should this be part of our zoom feature?
      reset: () => manager.get('chart').dispatchAction({type: 'restore'}),
    },
  };

  const configure = config => {
    config.toolbox ??= {};
    config.toolbox.show = true;
    config.toolbox.feature ??= {};
    config.toolbox.feature.dataZoom = {
      // disable filtering on secondary index
      [`${manager.get('secondaryAxis')}Index`]: [],
      show: true,
      title: {zoom: '', back: ''},

      icon: {
        // hide icons (transparent pixel)
        zoom: 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII=',
        back: 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII=',
      },

      type: 'inside',
      orient: 'horizontal',

      // remove items outside of zoom bounds
      filterMode: 'none',
      [`${manager.get('secondaryAxis')}Index`]: false,
    };
  };

  return {
    name: 'zoom',
    isEnabled,
    toolbar,
    configure,
  };
}

/**
 * @param {ChartManager} manager
 * @return {ChartFeature}
 */
export function panFeature(manager) {
  // bind to zoom for the time being
  const isEnabled = () => manager.get('zoom') && !manager.hasFeatureEnabled('nodata');

  const setPanMode = () => {
    manager.get('chart').dispatchAction({
      type: 'takeGlobalCursor',
      key: 'dataZoomSelect',
      dataZoomSelectActive: false,
    });

    manager.get('chart').setOption(
      {
        dataZoom: {disabled: false},
      },
      {silent: true}
    );
  };

  const toolbar = {
    features: ['pan'],
    listeners: {
      pan: () => setPanMode(),
    },
  };

  const configure = config => {
    // panning
    config.dataZoom = {
      type: 'inside',

      // aka enabled...
      disabled: false,

      // prevent zoom when scrolling over the chart instance
      zoomOnMouseWheel: false,

      // prevent any and all scroll events from being cancelled
      zoomLock: true,

      // prevent data from getting yeeted on zoom
      orient: manager.hasFeatureEnabled('horizontal') ? 'vertical' : 'horizontal',
      filterMode: 'filter',
    };
  };

  return {
    isEnabled,
    toolbar,
    configure,
  };
}

/**
 * @param {ChartManager} manager
 * @return {ChartFeature}
 */
export function stackFeature(manager) {
  const isEnabled = () => manager.get('stacked');
  const configure = config => {
    for (let idx = 0; idx < config.series.length; idx++) {
      const isFinalSeries = idx === config.series.length - 1;
      const series = config.series[idx];

      series.stack = 'stack';
      series.stackStrategy = 'all';

      // disable corner rounding for data items part of all but the final series
      if (isFinalSeries) continue;

      for (const datapoint of series.data) {
        if (datapoint.itemStyle) datapoint.itemStyle.borderRadius = undefined;
      }
    }
  };

  return {
    name: 'stack',
    isEnabled,
    configure,
  };
}

/**
 * @param {ChartManager} manager
 * @return {ChartFeature}
 */
export function stack100Feature(manager) {
  const tooltipFormatter = buildTooltipFormatter(manager);

  // stack 100 is an extension of stack, we can only ever be enabled if stack has been enabled
  const isEnabled = () => manager.hasFeatureEnabled('stack') && manager.get('stackType') === '100%';
  const configure = config => {
    config.series ??= [];

    const totalsPerCategory = config.series.reduce((valuePerCategory, serie) => {
      for (const dp of serie.data) {
        valuePerCategory[dp._category()] ??= 0;
        valuePerCategory[dp._category()] += dp._value();
      }

      return valuePerCategory;
    }, {});

    // when working with a 100% stack, we need to transform our values to a percentage of the total based on our x value
    for (const serie of config.series) {
      for (const datapoint of serie.data) {
        const absoluteValue = datapoint._value();
        const categoryTotal = totalsPerCategory[datapoint._category()];
        const percentageValue = Math.round((absoluteValue / categoryTotal) * 100 * 10) / 10; // round to 1 decimal

        datapoint._value(percentageValue);
        datapoint._originalValue = absoluteValue;
      }

      // display original values in tooltip
      serie.tooltip ??= {};
      serie.tooltip.formatter = tooltipFormatter;
    }

    // force a range of 0% - 100% for 100% stacks
    const secondaryAxis = (config[manager.get('secondaryAxis')] ??= {});
    secondaryAxis.max = 100;
    secondaryAxis.min = 0;
  };

  return {
    isEnabled,
    configure,
  };
}

/**
 * @param {ChartManager} manager
 * @return {ChartFeature}
 */
export function axisFeature(manager) {
  // axis aren't locked behind a flag, any chart supporting axis will automatically get axis configured
  return config => {
    // thresholds for us forcing echarts to display labels for all datapoints, echarts tends to leave gaps even for smaller datasets
    const ECHARTS_LABEL_POSITIONING_MAX_THRESHOLD = 25;
    const ECHARTS_LABEL_POSITIONING_MIN_THRESHOLD = 2;

    const primaryAxis = (config[manager.get('primaryAxis')] ??= {});
    const secondaryAxis = (config[manager.get('secondaryAxis')] ??= {});

    let primaryAxisType = 'category';

    if (manager.get('primaryAxisType') === 'datetime') primaryAxisType = 'time';
    if (manager.get('primaryAxisType') === 'category') primaryAxisType = 'category';
    if (manager.get('primaryAxisType') === 'numeric') primaryAxisType = 'category';

    primaryAxis.axisLabel ??= {};
    secondaryAxis.axisLabel ??= {};

    // match Apex styling
    secondaryAxis.axisLabel.color = 'var(--text-primary, #313741)';
    secondaryAxis.axisLabel.fontWeight = '400';
    secondaryAxis.axisLabel.fontSize = '12';
    secondaryAxis.axisLabel.fontFamily = 'Helvetica, Arial, sans-serif';

    // match Apex styling
    primaryAxis.axisLabel.color = 'var(--text-primary, #313741)';
    primaryAxis.axisLabel.fontWeight = '400';
    primaryAxis.axisLabel.fontSize = '12';
    primaryAxis.axisLabel.fontFamily = 'Helvetica, Arial, sans-serif';

    // configure axis
    primaryAxis.type = primaryAxisType;

    // Attempt to force 1 tick/unique category, this is required for time series to correctly show all labels
    // disable when dealing with a large amount of datapoints - let echarts algorithm decide what to show
    const uniqueCategories = collectUniqueCategories(config);

    // disabled for 2 categories or less... seems to prioritize the wrong labels in time series charts
    if (
      uniqueCategories.size > ECHARTS_LABEL_POSITIONING_MIN_THRESHOLD &&
      uniqueCategories.size < ECHARTS_LABEL_POSITIONING_MAX_THRESHOLD
    ) {
      primaryAxis.splitNumber = uniqueCategories.size;
    }

    // https://echarts.apache.org/en/option.html#xAxis.interval
    // https://echarts.apache.org/en/option.html#xAxis.axisTick.alignWithLabel
    // https://echarts.apache.org/en/option.html#xAxis.axisTick.interval
    // https://echarts.apache.org/en/option.html#xAxis.axisLabel.hideOverlap

    // Attempt to evenly distribute width across all labels, labels exceeding the max width will be automatically truncated
    primaryAxis.axisLabel.width = Math.max(manager.get('width') / (uniqueCategories.size || 1), 100); // prevent 0 division
    primaryAxis.axisLabel.overflow = 'truncate';

    // force display of all labels
    primaryAxis.axisLabel.interval = 0;

    // rotate labels 45 degrees
    primaryAxis.axisLabel.rotate = 45;

    // Use Apex style formatting for month ticks
    primaryAxis.axisLabel.formatter = label => label?.toString() || ''; // forcefully stringify label

    if (primaryAxisType === 'time') {
      // assume uniqueCategories are sorted for the time being
      primaryAxis.axisLabel.formatter = buildTimeSeriesLabelFormatter(manager, [...uniqueCategories]);

      // force a min/max upon our chart when working with dates
      // helps nudging the renderer in the right direction in regards to which labels to render
      if (uniqueCategories.size) {
        primaryAxis.min = Math.min(...uniqueCategories);
        primaryAxis.max = Math.max(...uniqueCategories);
      }
    }

    // render label as-is
    secondaryAxis.axisLabel.formatter = label => label;
  };
}

/**
 * @param {ChartManager} manager
 * @return {ChartFeature}
 */
export function horizontalPrimaryAxisFeature(manager) {
  const isEnabled = () => manager.get('horizontal');
  const configure = config => {
    // [top left, top right, bottom left, bottom right]
    const borderRadius = [0, 5, 5, 0];

    for (const series of config.series) {
      // horizontal series require flipping our value/category
      for (const datapoint of series.data) {
        datapoint._flipValueAndCategory();
        if (datapoint.itemStyle?.borderRadius) datapoint.itemStyle.borderRadius = borderRadius;
      }
    }
  };

  return {
    name: 'horizontal',

    isEnabled,
    configure,
  };
}

/**
 * @param {ChartManager} manager
 * @return {ChartFeature}
 */
export function tooltipFeature(manager) {
  const formatter = buildTooltipFormatter(manager);

  // featureFactory? -> createTooltipFeature( tooltipStyle = item | primaryAxis )
  // style would determine an extra layer of config, said layer would determine both the trigger and axispointer.type

  return config => {
    config.tooltip ??= {};
    config.tooltip.axisPointer ??= {};

    config.tooltip.show = true;

    // lines trigger tooltips for the entire axis, other types only when hovering over an individual item
    config.tooltip.trigger = config.series.some(series => series.type === 'line') ? 'axis' : 'item';

    // shadow surrounding tooltipped bar
    if (config.tooltip.trigger === 'item') config.tooltip.axisPointer.type = 'shadow';

    config.tooltip.formatter = formatter;
  };
}

/**
 * @param {ChartManager} manager
 * @return {ChartFeature}
 */
export function legendFeature(manager) {
  // enabled when we have at least one named series
  const isEnabled = () => manager.get('series').some(s => s.name);

  const configure = config => {
    const legend = (config.legend ??= {});
    legend.textStyle ??= {};

    // display our legend at the bottom of our chart
    legend.show = true;
    legend.bottom = 0;
    legend.textStyle.color = 'var(--text-primary, #313741)';
  };

  return {
    name: 'legend',
    isEnabled,
    configure,
  };
}

/**
 * @param {ChartManager} manager
 * @return {ChartFeature}
 */
export function gridLinesFeature(manager) {
  const configure = config => {
    const grid = (config.grid ??= {});

    // prevent chart/labels from rendering over legend with legend
    // horizontal -> just enough to not clash with chart renderer
    // vertical ->   base margin + highest text, fixme: take into account formatting
    let bottomMargin = 0;
    let horizontalMargin = 0;

    if (manager.hasFeatureEnabled('horizontal')) bottomMargin = 25;
    else {
      // hmm... should likely use some type of descriptor for providing label rotation, sluggification, etc.
      const uniqueCategoryHeights = Array.from(collectUniqueCategories(config)).map(
        x => calculateTextDimensions(slug(x, 25), {rotation: 45}).height
      );

      if (manager.hasFeatureEnabled('legend')) {
        // base margin of 10
        bottomMargin = 10;
        bottomMargin += Math.max(...uniqueCategoryHeights);
      }

      // when taking over horizontal positioning, our first label may be rendered partially outside of the visible chart area
      // ensure enough margin for this to not happen
      horizontalMargin = uniqueCategoryHeights[0];
    }

    // include labels as part of grid, prevent labels from being cut off
    grid.containLabel = true;

    // manually take over our margins, default leaves too much whitespace on both left and right sides
    grid.left = horizontalMargin;
    grid.right = 25;

    // small offset, fit labels?
    grid.bottom = bottomMargin;

    grid.top = 25;
    grid.show = true; // show grid lines
  };

  return {
    name: 'grid',
    configure,
  };
}

/**
 * @param {ChartManager} manager
 * @return {ChartFeature}
 */
export function dataLabelsFeature(manager) {
  const isEnabled = () => manager.get('dataLabels');
  const configure = config => {
    const makeSolid = config.series.some(series => series.type === 'line');

    for (const series of config.series) {
      const label = (series.label ??= {});

      // display value label inside series
      label.show = true;

      // match Apex styling
      label.fontWeight = '600';
      label.fontSize = '10';
      label.fontFamily = 'Helvetica, Arial, sans-serif';

      if (makeSolid) makeSolidLabelBackground(label);
    }
  };

  return {
    isEnabled,
    configure,
  };
}

/**
 * @param {ChartManager} manager
 * @return {ChartFeature}
 */
export function forecastingFeature(manager) {
  const configure = config => {
    for (const series of config.series.slice()) {
      if (series.type !== 'line') {
        markSeriesDataPointsAsForecast(series);
        continue;
      }

      makeLineSeriesForecast(config, series);
    }
  };

  return {
    isEnabled: () => manager.get('forecastDataPoints') >= 1,
    configure,
  };

  /** Alter all forecast datapoints to have a distinct visualisation */
  function markSeriesDataPointsAsForecast(series) {
    const forecastDataPoints = manager.get('forecastDataPoints');

    for (let idx = series.data.length - forecastDataPoints; idx < series.data.length; idx++) {
      const datapoint = series.data[idx];
      datapoint.itemStyle ??= {};

      // lower our opacity for forecast datapoints
      datapoint.itemStyle.opacity = 0.5;
    }
  }

  /** Move the forecast datapoints from the given series to a second series with a distinct visualisation */
  function makeLineSeriesForecast(config, series) {
    if (!series.data.length) return;

    // Worth looking into: https://echarts.apache.org/en/option.html#series-line.smoothMonotone
    // https://github.com/apache/echarts/issues/10233

    const forecastDataPoints = manager.get('forecastDataPoints');

    // When our entire series consists of forecast datapoints, simply update our series
    if (series.data.length <= forecastDataPoints) {
      series.lineStyle = makeForecastLineStyle(series.lineStyle);
      return;
    }

    // echarts internally links our series based on name, automatically linking our 'dashed' forecast variant with the solid variant
    config.series.push({
      ...series,
      lineStyle: makeForecastLineStyle(series.lineStyle),
      data: [
        // make forecast line connect by copying the last non-forecast item
        {
          ...series.data[series.data.length - forecastDataPoints - 1],

          // force disable labels
          label: {show: false},

          // prevent duplicate tooltips
          _tooltipDisabled: true,
        },
        ...series.data.splice(series.data.length - forecastDataPoints, forecastDataPoints).map(data => ({
          ...data,
          _seriesIndex: config.series.indexOf(series),
        })),
      ],
    });
  }

  function makeForecastLineStyle(lineStyle = {}) {
    return {...lineStyle, type: 'dashed'};
  }
}

/**
 * @param {ChartManager} manager
 * @return {ChartFeature}
 */
export function groupLinkFeature(manager) {
  const isEnabled = () => !!manager.get('group');

  // link all charts with the given group together upon initialization
  const configure = () =>
    manager.configureEvent('dnx:initialized', () => {
      const group = manager.get('group');
      manager.get('chart').group = group;

      // update bindings for our group
      connectCharts(group);
    });

  return {
    isEnabled,
    configure,
  };
}

/**
 * @param {ChartManager} manager
 * @return {ChartFeature}
 */
export function annotationsFeature(manager) {
  const isEnabled = () => {
    // annotations rely on a grid being present for positioning
    if (!manager.hasFeatureEnabled('grid')) return false;

    const annotations = manager.get('annotations');
    return Array.isArray(annotations) && annotations.length > 0;
  };

  // should expand with options to stick to specific values etc
  // https://echarts.apache.org/en/option.html#series-line.markPoint
  const configure = () => {
    manager.configureEvent('rendered', () => setAnnotations(), {once: true});

    // our options get removed upon restoration, attempt to re-attach
    manager.configureEvent('restore', () => setAnnotations());
  };

  return {
    isEnabled,
    configure,
  };

  /** Update the chart instance with the configured annotations */
  function setAnnotations() {
    /** @type {ChartAnnotation[]} */
    const annotations = manager.get('annotations');
    const chart = manager.get('chart');

    // https://echarts.apache.org/en/option.html#series-line.markPoint
    // https://echarts.apache.org/en/option.html#series-line.markLine
    // https://echarts.apache.org/en/option.html#series-line.markArea
    const markPoints = [];
    const markLines = [];
    const markAreas = [];

    for (const annotation of annotations) {
      switch (annotation.type) {
        case ChartAnnotationTypes.Point:
          markPoints.push(createPointAnnotation(annotation));
          break;

        case ChartAnnotationTypes.HorizontalLine:
        case ChartAnnotationTypes.VerticalLine:
          markLines.push(createLineAnnotation(annotation));
          break;

        case ChartAnnotationTypes.HorizontalRange:
        case ChartAnnotationTypes.VerticalRange:
          markAreas.push(createRangeAnnotation(annotation));
          break;
      }
    }

    // annotations on our side are controlled on a per-chart basis, simply assign them to our first encountered series for now
    // prevent chart modification while still busy
    window.requestAnimationFrame(() =>
      chart.setOption({
        series: [
          {
            markPoint: {silent: true, data: markPoints},
            markLine: {silent: true, data: markLines},
            markArea: {silent: true, data: markAreas},
          },
        ],
      })
    );
  }

  /** @param {ChartAnnotation} annotation */
  function createPointAnnotation(annotation) {
    const {gridXStart, gridYStart} = getGridDimensions();
    const {padding: labelPadding} = makeSolidLabelBackground();

    return {
      x: gridXStart + annotation.x,
      y: gridYStart + annotation.y,
      symbol: 'circle',
      label: makeSolidLabelBackground({
        show: true,
        distance: labelPadding,
        position: 'top',
        backgroundColor: annotation.color,
        formatter: () => annotation.text,
      }),
      symbolSize: 9,
      itemStyle: {
        color: '#FFFFFF',
        borderColor: annotation.color,
        borderWidth: 3,
      },
    };
  }

  /** @param {ChartAnnotation} annotation */
  function createLineAnnotation(annotation) {
    const {gridXStart, gridYStart, gridXEnd, gridYEnd} = getGridDimensions();
    const {padding: labelPadding} = makeSolidLabelBackground();
    const {width: textWidth, height: textHeight} = calculateTextDimensions(annotation.text);

    const {xStart, yStart, xEnd, yEnd} =
      annotation.type === ChartAnnotationTypes.HorizontalLine
        ? {xStart: gridXStart + annotation.x, yStart: gridYStart, xEnd: gridXStart + annotation.x, yEnd: gridYEnd}
        : {xStart: gridXStart, yStart: gridYStart + annotation.y, xEnd: gridXEnd, yEnd: gridYStart + annotation.y};

    // attempt to position horizontal labels on the lines left side with some margin at the top
    // attempt to position vertical labels on the lines right side, with some margin to the right
    const label =
      annotation.type === ChartAnnotationTypes.HorizontalLine
        ? {
            position: 'start',
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            offset: [-(textWidth + labelPadding * 6), -(textHeight + labelPadding)],
          }
        : {
            position: 'end',
            align: 'center',
            verticalAlign: 'middle',
            offset: [-(textWidth + labelPadding * 6), -(textHeight + labelPadding)],
          };

    return [
      {
        name: annotation.text,
        x: xStart,
        y: yStart,
        symbol: 'none',
        label: makeSolidLabelBackground({show: true, ...label}),
        itemStyle: {color: annotation.color, opacity: 0.5},
      },
      {
        x: xEnd,
        y: yEnd,
        symbol: 'none',
      },
    ];
  }

  /** @param {ChartAnnotation} annotation */
  function createRangeAnnotation(annotation) {
    const {gridXStart, gridYStart, gridXEnd, gridYEnd} = getGridDimensions();
    const {padding: labelPadding} = makeSolidLabelBackground();
    const {height: textHeight, width: textWidth} = calculateTextDimensions(annotation.text);

    const {xStart, yStart, xEnd, yEnd} =
      annotation.type === ChartAnnotationTypes.HorizontalRange
        ? {xStart: gridXStart + annotation.x, yStart: gridYStart, xEnd: gridXStart + annotation.x2, yEnd: gridYEnd}
        : {xStart: gridXStart, yStart: gridYStart + annotation.y, xEnd: gridXEnd, yEnd: gridYStart + annotation.y2};

    // attempt to position horizontal labels on the areas left side with some margin at the top
    // attempt to position vertical labels on the areas right side, with some margin to the right
    const label =
      annotation.type === ChartAnnotationTypes.HorizontalRange
        ? {
            position: 'insideTopLeft',
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            offset: [-(textWidth + labelPadding * 2), -(textHeight + labelPadding * 2)],
          }
        : {
            position: 'insideTopRight',
            align: 'center',
            verticalAlign: 'middle',
            offset: [-textWidth, -(textHeight + labelPadding * 2)],
          };

    return [
      {
        name: annotation.text,
        x: xStart,
        y: yStart,
        symbol: 'none',
        label: makeSolidLabelBackground({show: true, ...label}),
        itemStyle: {color: annotation.color, opacity: 0.5},
      },
      {
        x: xEnd,
        y: yEnd,
        symbol: 'none',
      },
    ];
  }

  function getGridDimensions() {
    const chart = manager.get('chart');

    // our grid represents our allows us to determine our 'zero' point, no grid = no annotations
    // width/height don't take into account any offsets - full grid dimensions can be calculated by adding our start values
    const gridDimensions = chart.getModel().getComponent('grid').coordinateSystem.getRect();
    const gridXStart = gridDimensions.x;
    const gridYStart = gridDimensions.y;
    const gridXEnd = gridXStart + gridDimensions.width;
    const gridYEnd = gridYStart + gridDimensions.height;

    return {gridXStart, gridYStart, gridXEnd, gridYEnd};
  }
}

/**
 * @param {ChartManager} manager
 * @return {ChartFeature}
 */
export function exportFeature(manager) {
  const isEnabled = () => !manager.hasFeatureEnabled('nodata');

  const toolbar = {
    features: ['Png'],
    listeners: {
      exportPNG: () => {
        const width = manager.get('chart').getWidth();
        const height = manager.get('chart').getHeight();

        // remove toolbox
        const data = manager.get('chart').getDataURL({excludeComponents: ['toolbox']});

        const img = new Image();
        img.src = data;

        // image needs to finish loading before we can draw it on our canvas
        img.onload = () => {
          // https://stackoverflow.com/a/64800570
          // use a canvas element to convert svg -> png
          const canvas = document.createElement('canvas');
          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext('2d');

          // white background
          ctx.fillStyle = '#FFFFFF';
          ctx.fillRect(0, 0, width, height);

          ctx.drawImage(img, 0, 0);

          canvas.toBlob(blob => {
            if (!blob) {
              console.warn('Export failed!');
              return;
            }

            const blobUrl = URL.createObjectURL(blob);

            const anchor = document.createElement('a');

            anchor.download = `${manager.get('name') ?? 'download'}.png`;
            anchor.href = blobUrl;
            anchor.click();

            URL.revokeObjectURL(blobUrl);
          }, 'image/png');
        };
      },
    },
  };

  return {
    isEnabled,
    toolbar,
  };
}

/**
 * @param {ChartManager} manager
 * @return {ChartFeature}
 */
export function noDataMessageFeature(manager) {
  const isEnabled = () => {
    const series = manager.get('series');
    return isEmpty(series) || series.every(s => isEmpty(s.data));
  };

  // replace entire config, stay consistent across all chart implementations
  const configure = () => ({
    title: {
      text: T('WIDGET_NO_DATA'),
      left: 'center',
      top: 'center',

      textStyle: {
        // required for overflow to work
        width: manager.get('width'),
        color: 'var(--text-primary, #313741)',
        overflow: 'break',
      },
    },
  });

  return {
    isEnabled,
    configure,

    name: 'nodata',
  };
}

import { addToast } from '..';
function useNotifications() {
    const vuex = Window.runtime.store;
    function triggerNotification(type, messageOrMessages, messages) {
        addToast(type, messageOrMessages);
        // vuex.commit('notifications/setAlertMessages', notification);
    }
    /** Display a success notification */
    const successNotification = (messageOrMessages, messages) => triggerNotification('success', messageOrMessages, messages);
    /** Display an information notification */
    const informationNotification = (messageOrMessages, messages) => triggerNotification('information', messageOrMessages, messages);
    /** Display a warning notification */
    const warningNotification = (messageOrMessages, messages) => triggerNotification('warning', messageOrMessages, messages);
    /** Display an error notification */
    const errorNotification = (messageOrMessages, messages) => triggerNotification('error', messageOrMessages, messages);
    return {
        successNotification,
        informationNotification,
        warningNotification,
        errorNotification,
    };
}
export { useNotifications };

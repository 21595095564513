import { useToastsStore } from './../stores/toasts';
export function addToast(type, content) {
    const notification = {
        type,
        content,
    };
    //@ts-ignore sigh
    useToastsStore().addToast(notification);
}
export const addSuccessToast = (content) => addToast('success', content);
export const addInformationToast = (content) => addToast('information', content);
export const addWarningToast = (content) => addToast('warning', content);
export const addErrorToast = (content) => addToast('error', content);

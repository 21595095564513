import ThemeAnimated from '@amcharts/amcharts5/themes/Animated';
import ThemeResponsive from '@amcharts/amcharts5/themes/Responsive';
import { Theme as AMTheme, Graphics, color as amColor } from '@amcharts/amcharts5';
import { ChartComponent } from './chartInstance';
export class Theme extends ChartComponent {
    getTags() {
        return ['theme'];
    }
    constructor(colors) {
        super();
        this.colors = colors;
    }
    // perform in setup to ensure theme is defined prior to first chart creation
    // should move this back to the render function once we have optional dependencies up and running
    setup(context) {
        super.setup(context);
        const customTheme = AMTheme.new(context.root);
        // setup formatters (display)
        // https://www.amcharts.com/docs/v5/concepts/formatters/formatting-numbers/
        context.root.numberFormatter.set('numberFormat', '#'); // render numbers as is, no thousand separator
        // colorsets allow us to define a list of colors amcharts will pick from
        // https://www.amcharts.com/docs/v5/reference/ColorSet/
        // example: https://github.com/amcharts/amcharts5/blob/master/src/.internal/themes/DatavizTheme.ts
        // should we use InterfaceColors for this? -> https://www.amcharts.com/docs/v5/reference/interfacecolors/
        // see: https://github.com/amcharts/amcharts5/blob/master/src/.internal/themes/DatavizTheme.ts
        if (this.colors.length) {
            customTheme.rule('ColorSet').setAll({
                colors: this.colors.map(color => amColor(color)),
                // prevent generating new colors based on our provided colorset
                reuse: false,
            });
        }
        // match apex/echarts
        customTheme.rule('Label').setAll({
            fontWeight: '600',
            fontSize: '10',
            fontFamily: 'Helvetica, Arial, sans-serif',
        });
        // customTheme.rule('PointedRectangle').setAll({
        //   stroke: amColor('#ff00ff'),
        //   fill:   amColor('#ff00ff'),
        // });
        // our content will be HTML based, remove AM container
        // prevent inheriting background from target element
        customTheme.rule('Tooltip').adapters.add('background', () => Graphics.new(context.root, {})); // <-- only apply to barcharts?
        // enable animations + make chart scale based on available space
        // conceptually, themes are just bags of default settings
        // Useful for later -> custom themes https://www.amcharts.com/docs/v5/concepts/themes/creating-themes/
        context.root.setThemes([
            // integrate animations
            ThemeAnimated.new(context.root),
            // autofit
            ThemeResponsive.new(context.root),
            // use our own colorscheme
            customTheme,
        ]);
    }
    render(context) {
        /* noop */
    }
}

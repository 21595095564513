import { computed } from 'vue';
const useUser = () => {
    const store = Window.runtime.store;
    const user = computed(() => store.getters['users/getCurrentUser']);
    return user;
};
const useUserMutations = () => {
    const store = Window.runtime.store;
    const setMetadataValue = (name, value) => store.dispatch('users/setCurrentUserMetadataValue', { name: name, value: value });
    return {
        setMetadataValue
    };
};
export default useUser;
export { useUserMutations, useUser };

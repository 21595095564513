import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["id"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", {
        id: $setup.chartId,
        style: _normalizeStyle({ minWidth: $props.width, minHeight: $props.height })
    }, [
        _renderSlot(_ctx.$slots, "default")
    ], 12, _hoisted_1));
}

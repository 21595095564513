<template>
  <div>
    <div
      class="main-box"
      :style="hasContent ? 'cursor: pointer' : 'cursor: default'"
      @click.self="hasContent ? (toggle = !toggle) : null"
    >
      <div
        class="box-header"
        :style="hasContent ? 'cursor: pointer' : 'cursor: default'"
        @click.self="hasContent ? (toggle = !toggle) : null"
      >
        <div class="header-icon">
          <slot name="headerIcons" />
          <XIcon
            :class="{disabled: !hasContent}"
            :name="toggle ? 'chevron-up' : 'chevron-down'"
            size="small"
            :hoverEffect="true"
            @click="(toggle = !toggle), setToggle(toggle)"
          />
        </div>
        <div
          :style="hasContent ? 'cursor: pointer' : 'cursor: default'"
          class="dropdown-header"
          @click="(toggle = !toggle), setToggle(toggle)"
        >
          <div class="main-title">
            <XIcon v-if="mainIcon" :name="mainIcon" :color="mainColor" size="medium" style="margin-top: -0.25rem" />
            <div class="title">
              <XHeading v-if="title" :color="mainColor" level="h6">
                {{ title }}
              </XHeading>
              <XTextStyle component-style="default">
                {{ subTitle }}
              </XTextStyle>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content" :class="{isClosed: !toggle, initialized}">
      <Transition name="slide">
        <div v-if="toggle">
          <slot />
        </div>
      </Transition>
    </div>
  </div>
</template>

<script>
import dropdownMixin from '../Mixins/dropdown-mixin';

export default {
  name: 'JDropdownBox',
  emits: ['toggle'],
  mixins: [dropdownMixin],
  props: {
    title: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
    mainIcon: {
      type: String,
      default: '',
    },
    mainColor: {
      type: String,
      default: '',
    },
    iconLeft: {
      type: String,
      default: '',
    },
    iconCenter: {
      type: String,
      default: '',
    },
    iconRight: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
@use '../../assets/styles/variables.scss' as *;
.main-box {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  word-break: break-all;
  background: var(--container-secondaryBackground, #edf3f8);
  display: flex;
  min-height: 3.7rem;
  align-items: center;
  border-top: 1px solid var(--container-border, #e8e8e8);
  border-bottom: 1px solid var(--container-border, #e8e8e8);

  .box-header {
    max-height: 3.75rem;
    box-sizing: border-box;
    padding: 1rem;
    background: var(--container-secondaryBackground, #edf3f8);
    width: 100%;
  }

  .header-icon {
    display: flex;
    gap: 0.3rem;
    position: absolute;
    top: 1.38rem;
    right: 0.5rem;

    .icon.color-default.disabled {
      cursor: default;

      :deep(svg) {
        path {
          stroke: var(--icon-primary, #868686);
        }
      }
    }
  }

  .main-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.9rem;

    .title {
      display: flex;
      flex-direction: column;
      gap: 0.2rem;

      h6.heading {
        font-size: var(--font-h6);
        font-family: $font-text;
        padding-right: 4.3rem;
      }

      :deep(.text-style) {
        color: var(--text-primary, #313741);
        font-family: $font-heading;
        font-size: var(--font-xs);
        margin: 0;
        margin-top: -0.1rem;
      }
    }
  }
}

.content {
  background: var(--main-background, #ffffff);
  padding: 0.5rem 0.7rem 1.5rem;
  border-radius: 0;
  max-height: fit-content;

  &.initialized {
    animation-duration: 1s;
  }

  animation: Slideout ease-in-out;
  animation-fill-mode: inherit;
}

@keyframes Slideout {
  from {
    max-height: 0rem;
    overflow: hidden;
  }
  to {
    max-height: 100vh;
    overflow: unset;
    border-radius: 0 0 0.25rem 0.25rem;
  }
}

.isClosed {
  max-height: 0rem;
  overflow: hidden;
  padding: 0rem 0.7rem;

  &.initialized {
    animation-duration: 0.3s;
  }

  animation: SlideIn ease-out;
}

@keyframes SlideIn {
  from {
    max-height: 100vh;
    padding: 0.5rem 0.7rem 1.5rem;
  }
  to {
    padding: 0rem 0.7rem;
    max-height: 0rem;
  }
}

@keyframes SlideOut {
  from {
    max-height: 3.75rem;
    overflow: hidden;
  }
  to {
    max-height: 100vh;
    overflow: unset;
  }
}

.slide-enter-active,
.slide-leave-active {
  transition: opacity 0.5s ease;
}

.slide-enter-from,
.slide-leave-to {
  opacity: 0;
}

.icon {
  width: 23px;
}
</style>

<docs>
  ```jsx
  <JDropdownBox 
    title="Dropdown 01"
    subTitle="This is a sub Title"
    mainIcon="2-persons"
    mainColor="#00B866"
    :toggleOnHeaderClick="true"
    :open="true"
  >
    <template #headerIcons>
      <XIcon name="delete" size="small" title="journey" :hoverEffect="true" />
    </template>
    <XHeading level="h3" style="margin-top: 1rem;">This is a dropdown box with a border on the bottom.</XHeading>
    <XHeading level="h3" style="margin-top: 1rem;">This is a dropdown box with a border on the bottom.</XHeading>
    <XHeading level="h3" style="margin-top: 1rem;">This is a dropdown box with a border on the bottom.</XHeading>
    <XHeading level="h3" style="margin-top: 1rem;">This is a dropdown box with a border on the bottom.</XHeading>
    <XHeading level="h3" style="margin-top: 1rem;">This is a dropdown box with a border on the bottom.</XHeading>
    <XHeading level="h3" style="margin-top: 1rem;">This is a dropdown box with a border on the bottom.</XHeading>
  </JDropdownBox>
  ```
</docs>

<template>
  <div class="toggle-area">
    <div
      :class="[
        `selected-box`,
        disabled ? 'unselected-area-box-disabled' : '',
        isChecked ? 'selected-area-box' : 'unselected-area-box',
      ]"
      @click="selectToggleArea()"
    >
      <XCheckbox class="check-box" :modelValue="isChecked" :disabled="disabled" />
      <div class="content-area">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'XSelectableBox',
  emits: ['selected'],
  props: {
    activeState: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isChecked: false,
    };
  },
  watch: {
    activeState(newVal) {
      this.isChecked = newVal;
    },
  },
  mounted() {
    this.isChecked = this.activeState;
  },
  methods: {
    selectToggleArea() {
      if (this.disabled) {
        return;
      }
      this.isChecked = !this.isChecked;
      this.$emit('selected', this.isChecked);
    },
  },
};
</script>

<style lang="scss" scoped>
.toggle-area {
  display: flex;
  padding-bottom: 0.5rem;
}
.selected-box {
  display: flex;
  flex-direction: row;
  padding: 0.5rem 0.75rem 0.5rem 0.75rem;
  gap: 0.75rem;
  border-radius: 0.5rem;
}
.selected-area-box {
  background-color: var(--container-secondaryBackground, #edf3f8);
  cursor: pointer;
}
.unselected-area-box {
  &:hover,
  &:active {
    background-color: var(--control-hoverBackground, #e7f5ff);
    cursor: pointer;
  }
}
.unselected-area-box-disabled {
  &:hover {
    cursor: not-allowed;
  }
}
.check-box {
  align-self: flex-start;
}
</style>

<docs>

  ```jsx
let temp =true;
  <XStack direction="vertical">
    <XBox style="padding:1rem;">
    <XSelectableBox v-for="index in 4" :activeState="temp" >
      <XHeading level="h5"> Text field {index} Awesome stuff Like for whooop whoop real - just amaazing great </XHeading>
      <XParagraph> saj;laksdf ;lakjsd ;lkjasd;f l;asdf;l kaj sd;flka asdfasd fasd fasdf asdf asdf asdf asdf asd fasd fasd fasd fasdf asd f
        saj;laksdf ;lakjsd ;lkjasd;f l;asdf;l kaj sd;flka asdfasd fasd fasdf asdf asdf asdf asdf asd fasd fasd fasd fasdf asd f
        saj;laksdf ;lakjsd ;lkjasd;f l;asdf;l kaj sd;flka asdfasd fasd fasdf asdf asdf asdf asdf asd fasd fasd fasd fasdf asd f
        asd fasd fasd fasdf asd f
        saj;laksdf ;lakjsd ;lkjasd;f l;asdf;l kaj sd;flka asdfasd fasd fasdf asdf asdf asdf asdf asd fasd fasd fasd fasdf asd f
        saj;laksdf ;lakjsd ;lkjasd;f l;asdf;l kaj sd;flka asdfasd fasd fasdf asdf asdf asdf asdf asd fasd fasd fasd fasdf asd f
         </XParagraph>
    </XSelectableBox>

    <XSelectableBox v-for="index in 4">
      <XHeading level="h5"> Text field {index} Awesome stuff Like for whooop whoop real - just amaazing great </XHeading>
      <XParagraph> saj;laksdf ;lakjsd ;lkjasd;f l;asdf;l kaj sd;flka asdfasd fasd fasdf asdf asdf asdf asdf asd fasd fasd fasd fasdf asd f
        saj;laksdf ;lakjsd ;lkjasd;f l;asdf;l kaj sd;flka asdfasd fasd fasdf asdf asdf asdf asdf asd fasd fasd fasd fasdf asd f
        saj;laksdf ;lakjsd ;lkjasd;f l;asdf;l kaj sd;flka asdfasd fasd fasdf asdf asdf asdf asdf asd fasd fasd fasd fasdf asd f
        asd fasd fasd fasdf asd f
        saj;laksdf ;lakjsd ;lkjasd;f l;asdf;l kaj sd;flka asdfasd fasd fasdf asdf asdf asdf asdf asd fasd fasd fasd fasdf asd f
        saj;laksdf ;lakjsd ;lkjasd;f l;asdf;l kaj sd;flka asdfasd fasd fasdf asdf asdf asdf asdf asd fasd fasd fasd fasdf asd f
         </XParagraph>
    </XSelectableBox>
    </XBox>
  </XStack>
  ```
</docs>

﻿<template>
  <div class="chart" :style="{height: `${height}px`}" />
</template>

<script>
import {LineChartMixin} from './mixins';
import {createEChartsComponent, mapAreaSeries} from './echarts';
import {
  zoomFeature,
  panFeature,
  exportFeature,
  axisFeature,
  tooltipFeature,
  legendFeature,
  gridLinesFeature,
  dataLabelsFeature,
  annotationsFeature,
  stackFeature,
  forecastingFeature,
  groupLinkFeature,
  horizontalPrimaryAxisFeature,
} from './chartFeatures';

export default createEChartsComponent({
  type: 'area',
  mapSeries: mapAreaSeries,
  optionsMixin: LineChartMixin,
  features: [
    horizontalPrimaryAxisFeature,

    zoomFeature,
    panFeature,
    exportFeature,

    axisFeature,
    tooltipFeature,
    legendFeature,
    gridLinesFeature,
    dataLabelsFeature,
    annotationsFeature,

    stackFeature,

    forecastingFeature,
    groupLinkFeature,
  ],
});
</script>

<style scoped>
.chart {
  padding: 0.5rem;

  /* FIXME: undo .5rem removal by XGraphWidget */
  height: calc(100% + 0.5rem);
}
</style>

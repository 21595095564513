import { ToolbarZoom } from './toolbar';
import { useToolbar } from '../toolbarProxy';
import { useChartComponent } from './composables';
export default {
    name: 'XChartToolbarZoom',
    setup() {
        const toolbar = useToolbar();
        useChartComponent(new ToolbarZoom({ toolbar }));
    },
    render: () => undefined,
};

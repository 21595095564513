<template>
  <div class="pagination-container">
    <div :class="['change-page', {disabled: selectedPage <= 1}]">
      <XIcon
        name="chevron-left"
        color="var(--icon-primary, #868686)"
        :hoverEffect="selectedPage > 1"
        @click="previousPage()"
      />
    </div>
    <template v-for="(page, index) in pages">
      <div
        :key="index"
        :class="['page', {selected: page === selectedPage}]"
        v-if="isVisible(page) && !isReplacedByPlaceholder(page)"
        @click="selectedPage = page"
      >
        {{ page }}
      </div>
      <div class="page place-holder" v-if="isVisiblePlaceholder(page)">...</div>
    </template>
    <div :class="['change-page', {disabled: selectedPage >= pageCount}]">
      <XIcon
        name="chevron-right"
        color="var(--icon-primary, #868686)"
        :hoverEffect="selectedPage < pageCount"
        @click="nextPage()"
      />
    </div>
  </div>
</template>

<script>
const pagesShown = 3;
export default {
  name: 'XPagination',
  emits: ['update:modelValue'],
  props: {
    pageCount: {
      type: Number,
      default: 0,
    },
    currentPage: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    pages: {
      get() {
        // We do this because it to keep the array small and doable for the browser :)
        const maxLastSelectablePage = Math.min(this.lastSelectablePage, this.pageCount);
        const minFirstSelectablePage = Math.max(this.firstSelectablePage, 1);

        let arrayOfPages = Array.from(
          Array(maxLastSelectablePage - minFirstSelectablePage),
          (_, currentItemIndex) => minFirstSelectablePage + currentItemIndex + 1
        );

        // Adding 1 2 and 3 because the check for the dotted prefix is based on those pages
        if (this.pageCount > 3 && !arrayOfPages.includes(3)) arrayOfPages.unshift(3);
        if (this.pageCount > 2 && !arrayOfPages.includes(2)) arrayOfPages.unshift(2);
        if (!arrayOfPages.includes(1)) arrayOfPages.unshift(1);

        // Adding last page also for checking if the dotted suffix is supposed tho be displayed
        if (this.pageCount > 1 && !arrayOfPages.includes(this.pageCount - 1)) arrayOfPages.push(this.pageCount - 1);
        if (!arrayOfPages.includes(this.pageCount)) arrayOfPages.push(this.pageCount);

        while (arrayOfPages[arrayOfPages.length - 1] > this.pageCount) arrayOfPages.pop();

        // Returning because we need it :)
        return arrayOfPages;
      },
    },
    selectedPage: {
      get() {
        return this.currentPage;
      },
      set(page) {
        this.$emit('update:modelValue', page);
      },
    },
    firstSelectablePage: {
      get() {
        let firstSelectablePage = this.currentPage - pagesShown;
        const lastSelectablePage = this.currentPage + pagesShown;

        if (lastSelectablePage >= this.pageCount) firstSelectablePage -= lastSelectablePage - this.pageCount + 1;

        return firstSelectablePage;
      },
    },
    lastSelectablePage: {
      get() {
        const firstSelectablePage = this.currentPage - pagesShown;
        let lastSelectablePage = this.currentPage + pagesShown;

        if (firstSelectablePage <= 1) lastSelectablePage += Math.abs(firstSelectablePage - 2);

        return lastSelectablePage;
      },
    },
  },
  methods: {
    previousPage() {
      if (this.selectedPage - 1 >= 1) {
        this.selectedPage = this.selectedPage - 1;
      }
    },
    nextPage() {
      if (this.selectedPage + 1 <= this.pageCount) {
        this.selectedPage = this.selectedPage + 1;
      }
    },
    isVisible(page) {
      if (this.pageCount <= 9) return true;
      if (page === 1 || this.selectedPage === page || page === this.pageCount) return true;
      if (page <= this.lastSelectablePage && page >= this.firstSelectablePage) return true;

      return false;
    },
    isVisiblePlaceholder(page) {
      return (page === 2 && !this.isVisible(page)) || (page === this.pageCount - 1 && !this.isVisible(page));
    },
    isReplacedByPlaceholder(page) {
      return (
        (page === this.firstSelectablePage && this.isVisible(this.firstSelectablePage) && page >= 3) ||
        (page === this.lastSelectablePage && this.isVisible(this.lastSelectablePage) && page <= this.pageCount - 2)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@use '../../assets/styles/variables.scss' as *;
.pagination-container {
  display: flex;
  flex-direction: row;
  font-size: var(--font-md);
  font-family: $font-text;
  user-select: none;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  :deep(.icon) {
    height: 100%;

    svg {
      height: 100%;
    }
  }

  .page {
    background: transparent;
    padding: 0.2rem 0.5rem;
    border-radius: 4px;
    color: var(--control-color, #313741);

    &.selected {
      background: var(--control-selectedBackground, #2783c6);
      color: var(--control-selectedColor, #ffffff);
    }

    &:not(.selected):not(.place-holder):hover {
      background-color: var(--control-hoverBackground, #e7f5ff);
      cursor: pointer;
    }
  }

  .change-page {
    :deep(.icon) {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        height: 10px;
      }
    }

    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
}
</style>

<docs>
  ```jsx
  let currentPage = 1;
  <XPagination :page-count="50" :current-page="currentPage" @update:modelValue="currentPage = $event;"/>
  ```
</docs>

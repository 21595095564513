import { h } from 'vue';
import format from '../../../utils/formatting';
import XLink from '../../Navigation/XLink.vue';
import XText from '../../Typography/XText.vue';
import * as util from './util';
import { isDateTime, isNumber } from './datatypes';
import { isString } from 'lodash';
import { T } from './../../../helpers/translationsProvider';
class RendererRegistry {
    constructor() {
        this._renderers = [];
    }
    /** Register a new renderer implementation */
    register(renderer) {
        this._renderers.push(renderer);
    }
    /** Find the most appropriate renderer for the given value */
    resolve(value) {
        // find the first renderer that can... render the given value
        for (const renderer of this._renderers) {
            if (renderer.canRender(value)) {
                return renderer;
            }
        }
        // Default, render as plain text
        return {
            canRender(value) {
                return true;
            },
            render(value) {
                return h('span', value);
            },
        };
    }
}
// should maybe make these stackable? V2.. ?
const rendererRegistry = new RendererRegistry();
// Link renderer
rendererRegistry.register({
    canRender(value) {
        return util.isLink(value);
    },
    render(value) {
        const link = util.extractLink(value);
        const text = util.extractLinkText(value);
        return h(XLink, { href: link, target: '_blank' }, () => text);
    },
});
// Date renderer
rendererRegistry.register({
    canRender(value) {
        return isDateTime(value);
    },
    render(value) {
        return h(XText, () => format(value, 'datetime', { optionalTime: true }));
    },
});
// Number renderer
rendererRegistry.register({
    canRender(value) {
        return isNumber(value);
    },
    render(value) {
        return h(XText, () => format(value, 'number', { maxFraction: 2 }));
    },
});
// Nested data (legacy)
rendererRegistry.register({
    canRender(value) {
        return Array.isArray(value);
    },
    render(value) {
        // simply render 'nested' values as-is
        return h(XText, () => JSON.stringify(value));
    },
});
//Default renderer
rendererRegistry.register({
    canRender(value) {
        return isString(value);
    },
    render(value) {
        const isTranslationKey = /^[A-Z_]+$/.test(value);
        return h(XText, () => (isTranslationKey ? T(value) : value));
    },
});
export { rendererRegistry };

import { computed, ref, watch, reactive, unref } from 'vue';
import useDnxServerResponse from './useDnxServerResponse';
const useDnxServerList = (method, parameters, options) => {
    const pageCount = ref();
    const currentPage = ref(1);
    const itemCount = ref(0);
    const totalItemCount = ref(0);
    const filter = reactive(new Map());
    const sort = ref();
    const internalParams = computed(() => {
        var _a, _b, _c;
        const xArgs = [unref(parameters)].flat().map(param => unref(param));
        const filterValue = Array.from(filter.entries())
            .map(entry => {
            if (Array.isArray(entry[1])) {
                const values = entry[1].map(value => `(${entry[0]}${value})`).join('&&');
                return `(${values})`;
            }
            else {
                return `(${entry[0]}${entry[1]})`;
            }
        })
            .join('&&') || undefined;
        const queryParameters = {
            PageNumber: currentPage.value.toString(),
            PageSize: (_a = options === null || options === void 0 ? void 0 : options.pageSize) === null || _a === void 0 ? void 0 : _a.toString(),
            Filter: filterValue,
            Sort: (sort.value && `${((_b = sort.value) === null || _b === void 0 ? void 0 : _b.direction) == 'ascend' ? '-' : '+'}${(_c = sort.value) === null || _c === void 0 ? void 0 : _c.key}`) || undefined,
        };
        xArgs[method.length - 1] = Object.assign(Object.assign({}, xArgs[method.length - 1]), { queryParameters }) || {
            queryParameters,
        };
        return xArgs;
    });
    const { value, isLoading, failed, fetch } = useDnxServerResponse(method, internalParams, Object.assign(Object.assign({}, options), { callback: response => {
            if (!response.responseInfo.pagingInfo)
                return;
            const pagingInfo = response.responseInfo.pagingInfo;
            pageCount.value = pagingInfo.pageCount;
            itemCount.value = pagingInfo.itemCount;
            totalItemCount.value = pagingInfo.totalItemCount;
        } }));
    const setFilter = (key, value) => {
        if (key != 'PageNumber') {
            filter.set('PageNumber', '1');
            currentPage.value = 1;
        }
        if (value) {
            filter.set(key, value);
        }
        else {
            filter.delete(key);
        }
    };
    const setSort = (key, direction) => {
        if (!direction) {
            sort.value = undefined;
            return;
        }
        sort.value = { key, direction };
    };
    const reset = () => {
        currentPage.value = 1;
        sort.value = undefined;
        filter.clear();
        //TODO: implement
        return;
    };
    watch(currentPage, newValue => {
        if (newValue < 1) {
            currentPage.value = 1;
        }
        else if (newValue > pageCount.value) {
            currentPage.value = pageCount.value;
        }
    });
    return {
        pageCount: computed(() => pageCount.value),
        currentPage,
        itemCount: computed(() => itemCount.value),
        totalItemCount: computed(() => totalItemCount.value),
        data: computed(() => value.value),
        fetch,
        isLoading,
        failed,
        setFilter,
        setSort,
        reset,
        pageSize: options === null || options === void 0 ? void 0 : options.pageSize,
    };
};
//Specific DNXList implementation for tables (use v-bind to add these values)
export const useDnxServerTableList = (method, parameters, options) => {
    const { isLoading, currentPage, data, itemCount, totalItemCount, pageCount, pageSize, setFilter, setSort } = useDnxServerList(method, parameters, options);
    const props = computed(() => {
        return {
            setFilter,
            setSort,
            loading: isLoading.value,
            remote: true,
            pagination: {
                pageSize: pageSize,
                page: currentPage.value,
                defaultPage: currentPage.value,
                pageCount: pageCount.value,
                itemCount: itemCount.value,
                totalItemCount: totalItemCount.value,
            },
            data: data.value,
            onCurrentPage: event => (currentPage.value = event),
            'onUpdate:filters': event => {
                setFilter(event.columnKey, event.value);
            },
            'onUpdate:sorter': event => {
                setSort(event.columnKey, event.order);
            },
        };
    });
    props.setFilter = setFilter;
    props.setSort = setSort;
    props.isLoading = isLoading;
    props.setPage = (number) => (currentPage.value = number);
    return props;
};
export default useDnxServerList;

<template>
  <div v-if="isLoading" class="loading-active">
    <XHeading level="h4" v-if="headingText" class="heading-loader">
      {{ headingText }}
    </XHeading>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.44 44.78" width="100%" height="100%" class="image-styling">
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path class="cls-1" d="M35.76,33.34A44.58,44.58,0,0,0,2.29,14.29L2.52,11A47.91,47.91,0,0,1,38.47,31.45Z" />
          <path class="cls-1" d="M2.8,34l-.27-3.29A44.67,44.67,0,0,0,35.58,11.69l2.7,1.89A47.94,47.94,0,0,1,2.8,34Z" />
          <path
            class="cls-1"
            d="M18.49,43a47.58,47.58,0,0,1-4.9-21.16A47.66,47.66,0,0,1,17.92,1.86l3,1.37a44.86,44.86,0,0,0,.53,38.29Z"
          />
          <path
            class="cls-1"
            d="M19.72,44.7a2.91,2.91,0,0,1-1.48-.4L1.48,34.63A3,3,0,0,1,0,32.06V12.71a3,3,0,0,1,1.48-2.56L18.24.47a3,3,0,0,1,3,0L38,10.14a3,3,0,0,1,1.48,2.57V32.06A3,3,0,0,1,38,34.63L21.2,44.3A2.86,2.86,0,0,1,19.72,44.7Zm-.91-4.55h0ZM4.8,31l14.92,8.62L34.64,31V13.77L19.72,5.16,4.8,13.77Zm14-26.37h0Zm1.83,0Z"
          />
          <path class="cls-2" d="M39.43,32.39A2.39,2.39,0,1,1,37,30a2.39,2.39,0,0,1,2.39,2.39" />
          <path class="cls-2" d="M22.11,42.39A2.39,2.39,0,1,1,19.72,40a2.39,2.39,0,0,1,2.39,2.39" />
          <path class="cls-2" d="M4.79,32.39A2.39,2.39,0,1,1,2.4,30a2.39,2.39,0,0,1,2.39,2.39" />
          <path class="cls-2" d="M4.79,12.39A2.39,2.39,0,1,1,2.4,10a2.39,2.39,0,0,1,2.39,2.39" />
          <path class="cls-2" d="M22.11,2.39A2.39,2.39,0,1,1,19.72,0a2.39,2.39,0,0,1,2.39,2.39" />
          <path class="cls-2" d="M39.43,12.39A2.39,2.39,0,1,1,37,10a2.39,2.39,0,0,1,2.39,2.39" />
          <path class="cls-2" d="M17.35,15.69A1.67,1.67,0,1,1,15.69,14a1.66,1.66,0,0,1,1.66,1.66" />
          <path class="cls-2" d="M29.29,22.44a1.67,1.67,0,1,1-1.66-1.66,1.66,1.66,0,0,1,1.66,1.66" />
          <path class="cls-2" d="M17.58,29.32a1.67,1.67,0,1,1-1.66-1.67,1.66,1.66,0,0,1,1.66,1.67" />
          <path class="cls-1" d="M35.76,33.34A44.58,44.58,0,0,0,2.29,14.29L2.52,11A47.91,47.91,0,0,1,38.47,31.45Z" />
          <path class="cls-1" d="M2.8,34l-.27-3.29A44.67,44.67,0,0,0,35.58,11.69l2.7,1.89A47.94,47.94,0,0,1,2.8,34Z" />
          <path
            class="cls-1"
            d="M18.49,43a47.58,47.58,0,0,1-4.9-21.16A47.66,47.66,0,0,1,17.92,1.86l3,1.37a44.86,44.86,0,0,0,.53,38.29Z"
          />
          <path
            class="cls-1"
            d="M19.72,44.7a2.91,2.91,0,0,1-1.48-.4L1.48,34.63A3,3,0,0,1,0,32.06V12.71a3,3,0,0,1,1.48-2.56L18.24.47a3,3,0,0,1,3,0L38,10.14a3,3,0,0,1,1.48,2.57V32.06A3,3,0,0,1,38,34.63L21.2,44.3A2.86,2.86,0,0,1,19.72,44.7Zm-.91-4.55h0ZM4.8,31l14.92,8.62L34.64,31V13.77L19.72,5.16,4.8,13.77Zm14-26.37h0Zm1.83,0Z"
          />
          <path class="cls-2" d="M39.43,32.39A2.39,2.39,0,1,1,37,30a2.39,2.39,0,0,1,2.39,2.39" />
          <path class="cls-2" d="M22.11,42.39A2.39,2.39,0,1,1,19.72,40a2.39,2.39,0,0,1,2.39,2.39" />
          <path class="cls-2" d="M4.79,32.39A2.39,2.39,0,1,1,2.4,30a2.39,2.39,0,0,1,2.39,2.39" />
          <path class="cls-2" d="M4.79,12.39A2.39,2.39,0,1,1,2.4,10a2.39,2.39,0,0,1,2.39,2.39" />
          <path class="cls-2" d="M22.11,2.39A2.39,2.39,0,1,1,19.72,0a2.39,2.39,0,0,1,2.39,2.39" />
          <path class="cls-2" d="M39.43,12.39A2.39,2.39,0,1,1,37,10a2.39,2.39,0,0,1,2.39,2.39" />
          <path class="cls-2" d="M17.35,15.69A1.67,1.67,0,1,1,15.69,14a1.66,1.66,0,0,1,1.66,1.66" />
          <path class="cls-2" d="M29.29,22.44a1.67,1.67,0,1,1-1.66-1.66,1.66,1.66,0,0,1,1.66,1.66" />
          <path class="cls-2" d="M17.58,29.32a1.67,1.67,0,1,1-1.66-1.67,1.66,1.66,0,0,1,1.66,1.67" />
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'XLoadingSpinner',
  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },
    headingText: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.cls-1 {
  fill: #2783c6;
}
.cls-2 {
  fill: #7db5dd;
}
.loading-active {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  .heading-loader {
    max-width: 90%;
    overflow-x: clip;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex: none;
  }
  .image-styling {
    max-width: 5rem;
    max-height: 5rem;
    animation: spinning 2.5s ease-in-out infinite;
  }
}

@keyframes spinning {
  0% {
    transform: scale(0.5) rotate(45deg);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.5) rotate(45deg);
  }
}
</style>

<docs>
  ```jsx
  <div style="background:white;height:200px; width:400px; margin-left:50px">
    <XLoadingSpinner
    :data="['data1', 'data2', 'data3']"
    headingText="Something Amazing!"
  />
  </div>
  <div style="background:white;height:40px; width:150px; margin-left:50px">
  <XLoadingSpinner
    :data="null"
    heading="true"
    headingText="JjPpqQgG! Amazing"
  />
  </div>
  <div style="background:white;height:150px; width:500px; margin-left:50px">
  <XLoadingSpinner
    :data="null"
    heading="true"
    headingText="Something Amazing!"
  />
  </div>
  <XLoadingSpinner
    :data="null"
  />
  ```
</docs>

<template>
  <v-chart class="chart" :option="option" autoresize />
</template>

<script>
import {use} from 'echarts/core';
import {CanvasRenderer} from 'echarts/renderers';
import {GraphChart} from 'echarts/charts';
import {TitleComponent, TooltipComponent, LegendComponent} from 'echarts/components';
import VChart from 'vue-echarts';

use([CanvasRenderer, GraphChart, TitleComponent, TooltipComponent, LegendComponent]);

export default {
  name: 'XFunnelChart',
  components: {
    VChart,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    option() {
      return {
        tooltip: {},
        legend: {
          y: 'bottom',
          // selectedMode: 'single',
          textStyle: {
            color: ['var(--widget-dataLabelColor, #000000)'],
          },
          itemStyle: {
            borderWidth: 0,
          },
        },
        textStyle: {
          textBorderWidth: 0,
          textBorderColor: 'red',
        },
        series: [
          {
            type: 'funnel',
            data: this.data,
            roam: true,
            label: {
              position: 'right',
            },
          },
        ],
      };
    },
  },
};
</script>

<style scoped>
.chart {
  height: 350px;
  width: 100%;
}
</style>

<docs>
  ```jsx
    <XFunnelChart
      :data="[
        {
          'name': 'Myriel',
          'value': 30
        },
        {
          'name': 'Napoleon',
          'value': 20
        },
        {
          'name': 'MlleBaptistine',
          'value': 10
        },
        {
          'name': 'Tony',
          'value': 20
        }
      ]"
    />
  ```
</docs>

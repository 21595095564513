import {use as enableEchartsPlugin} from 'echarts/core';
import {
  TooltipComponent,
  LegendComponent,
  ToolboxComponent,
  DataZoomComponent,
  DataZoomInsideComponent,
} from 'echarts/components';
import {SVGRenderer} from 'echarts/renderers';
import {BarChart, LineChart} from 'echarts/charts';
import {DateTime} from 'luxon';

import {ChartManager} from './chartManager';
import {capitalize} from '../../../helpers/util';
import {T} from '../../../helpers/translationsProvider';
import {noDataMessageFeature} from './chartFeatures';

// register available options
enableEchartsPlugin([
  BarChart,
  LineChart,

  TooltipComponent,
  LegendComponent,
  ToolboxComponent,
  DataZoomComponent,
  DataZoomInsideComponent,
  SVGRenderer,
]);

function mapEchartsDatapoint(manager, datapoint) {
  let category = datapoint.x;
  const value = datapoint.y;
  const categoryType = manager.get('primaryAxisType');

  // echarts requires datetime datapoints to be provided in the form of timestamps
  if (categoryType === 'datetime' && typeof category === 'string') category = DateTime.fromISO(category).toMillis();

  return {
    // should figure out a better way to do this.. required for consistent access to values/categories, even after a horizontal flip
    _valueIndex: 1,
    _categoryIndex: 0,

    // explicitly define properties
    _tooltipDisabled: false,
    _seriesIndex: undefined,

    _flipValueAndCategory() {
      const valueIndex = this._valueIndex;
      this._valueIndex = this._categoryIndex;
      this._categoryIndex = valueIndex;

      this.value.reverse();
    },

    _value() {
      if (arguments.length === 0) return this.value[this._valueIndex];
      this.value[this._valueIndex] = arguments[0];
    },
    _category() {
      if (arguments.length === 0) return this.value[this._categoryIndex];
      this.value[this._categoryIndex] = value;
    },

    value: [category, value],
  };
}

/**
 * Assign default names for series when not already provided
 *
 * @param allSeries
 */
export function normalizeSeries(allSeries) {
  if (!Array.isArray(allSeries)) return allSeries;

  return allSeries.map((series, index) => {
    let name = series.name;
    if (!name && allSeries.length > 1) name = `${T('QUERYBUILDER_DATASET_NUMBER')} ${index + 1}`;

    return {
      ...series,
      name: name,
    };
  });
}

/**  @typedef {(manager: ChartManager, series: *, seriesIndex: number) => *} echartsSeriesMapper Map an input series to an echarts series */

/**
 * @type {echartsSeriesMapper}
 * @param {ChartManager} manager
 * @param {*} series
 *
 * @return {Object}
 */
export function mapLineSeries(manager, series) {
  return {
    type: 'line',
    name: series.name,
    data: series.data.map(datapoint => mapEchartsDatapoint(manager, datapoint)),

    smooth: manager.get('lineType') === 'smooth',
    step: manager.get('lineType') === 'stepline',
    symbol: null, // disable permanent 'datapoint marker' dots on our line
    lineStyle: {
      width: 4,
    },
  };
}

/**
 * @type {echartsSeriesMapper}
 * @param {ChartManager} manager
 * @param {*} series
 *
 * @return {Object}
 */
export function mapAreaSeries(manager, series, seriesIndex) {
  const theme = manager.get('theme');

  return {
    ...mapLineSeries(manager, series),

    // a line is transformed to an area the moment an `areaStyle` key is present in the configuration
    areaStyle: {
      // https://echarts.apache.org/en/option.html#color
      // 0 = start, 1 = end
      color: {
        type: 'linear',
        x: 0,
        y: 0,

        x2: 0,
        y2: 1,
        colorStops: [
          {offset: 0, color: '#ffffff'},
          {offset: 0.9, color: theme[seriesIndex % theme.length]},
        ],
      },
      opacity: 0.25,
    },
  };
}

/**
 * @type {echartsSeriesMapper}
 * @param {ChartManager} manager
 * @param {*} series
 *
 * @return {Object}
 */
export function mapBarSeries(manager, series) {
  // [top left, top right, bottom left, bottom right]
  const borderRadius = [5, 5, 0, 0];

  return {
    type: 'bar',
    name: series.name,
    data: series.data.map(datapoint => ({
      ...mapEchartsDatapoint(manager, datapoint),

      // itemStyle allows us to style one specific chart element
      itemStyle: {
        opacity: 1,

        // rounded corners
        borderRadius: borderRadius,
      },
    })),

    barGap: 0, // have bars hug eachother
    clip: true,
    roundCap: true,
  };
}

/**
 * @type {echartsSeriesMapper}
 *
 * @return {Object}
 */
export function mapMixedSeries() {
  /** @type {Object<string, echartsSeriesMapper>} */
  const mappers = {
    column: mapBarSeries,
    line: mapLineSeries,
    area: mapAreaSeries,
  };

  const series = arguments[1];
  const type = series.type?.trim()?.toLowerCase();

  const mapper = mappers[type] || mappers.column;
  return mapper(...arguments);
}

/**
 * @param {Object} opts Object containing configuration for the chart component
 * @param {string} opts.type Chart type, eg. bar, line, area, etc.
 * @param {*} opts.optionsMixin Mixin defining accepted options
 * @param {function(manager: ChartManager, configuration: Object): *} [opts.configure] Hook for adding extra customization to chart configuration
 * @param {echartsSeriesMapper} opts.mapSeries Function mapping input series to echarts compatible series
 * @param {Array<function(): ChartFeature>} opts.features List of chart features to add to this component instance
 */
export function createEChartsComponent(opts) {
  // we should break this up into composables after migrating to vue3
  // <XEchartsHost>
  //   Instance, manager, etc. provided
  // <XEchartsHost />

  return {
    name: `XECharts${capitalize(opts.type.toLowerCase())}Chart`,
    mixins: [opts.optionsMixin],

    computed: {
      configuration() {
        return this.manager.configure({
          color: this.manager.get('theme'),
          series: this.manager.get('series').map((series, index) => opts.mapSeries(this.manager, series, index)),
        });
      },
    },

    created() {
      this.manager = new ChartManager(this, {
        configFactory: () => this.configuration,
      });

      // should we work with feature bundles? -> addLine(), all supported line features, addBar(), all supported bar features etc
      this.manager.addFeatures([
        ...opts.features,
        ...(opts.features.includes(noDataMessageFeature) ? [] : [noDataMessageFeature]),
      ]);
    },

    mounted() {
      this.manager.setupToolbar();
      this.manager.setupChart();
    },

    beforeUnmount() {
      this.manager.dispose();
    },
  };
}

import { useSecurityStore } from '../stores/security';
/**
 * Enum representing the differing grant types for an individual permission
 */
export var PermissionGrantType;
(function (PermissionGrantType) {
    /**
     * Permission to see resources
     * @type {PermissionGrantType.Read}
     */
    PermissionGrantType[PermissionGrantType["Read"] = 1] = "Read";
    /**
     * Permission to altering existing resources
     * @type {PermissionGrantType.Write}
     */
    PermissionGrantType[PermissionGrantType["Write"] = 2] = "Write";
    /**
     * Permission for creating new resources
     * @type {PermissionGrantType.Create}
     */
    PermissionGrantType[PermissionGrantType["Create"] = 4] = "Create";
    /**
     * Permission to delete resources
     * @type {PermissionGrantType.Delete}
     */
    PermissionGrantType[PermissionGrantType["Delete"] = 8] = "Delete";
})(PermissionGrantType || (PermissionGrantType = {}));
export const dnxPermissions = {
    //<-- TODO: Generate from APIConsumerGenerator?
    Dashboarding: '3a7bf240-324c-4994-bfa4-eb92a85c2edd',
    WidgetThemes: '8e92b85b-9e90-407a-8b77-0b033426f2da',
    WidgetCustomQueries: 'e2705ca4-8032-4fcb-be06-7ab4e8f98f64',
    Users: '69D58517-2D06-4BED-8140-A7299798A2F5',
    ImpersonateUser: 'a3e38213-0845-406d-8777-8cfeed2e30ae',
    JourneyComposer: 'C631638B-067A-42ED-B9A1-9E273AEE0B45',
    JourneyComposerReview: '60832130-CD96-4BE4-8719-3FB9A49D4BA6',
    TfaAdmin: '78145e12-54f7-4b0b-8ee9-44b8b17f1d27',
    JourneyBypassReviews: '0581C040-C633-485F-AC5E-5E9823ECEC5B',
};
const _knownPermissions = new Map();
const permissions = {
    //Start with a list of well known permissions
    users: dnxPermissions.Users,
    impersonation: dnxPermissions.ImpersonateUser,
    dashboards: dnxPermissions.Dashboarding,
    widgetqueries: dnxPermissions.WidgetCustomQueries,
    widgetthemes: dnxPermissions.WidgetThemes,
    snowplowtrackers: 'B13246D1-C0E0-4BA0-973A-0343F085EB64',
    datasets: 'EEF9E77D-46BA-4802-B043-359E0C41755E',
    roles: '3805D0A8-AB76-4F3A-B64D-319716821270',
    platformthemes: '73D34661-D470-4BDE-BAF3-5075AFE69B46',
    journeyreport: '43E3B5FA-A0BA-4000-A17C-922EEC228396',
    tfaAdmin: dnxPermissions.TfaAdmin,
    divisions: '8510C354-DD9D-483C-A1CF-AD2F5B91F769',
    //Module JC
    journeys: dnxPermissions.JourneyComposer,
    journeyreviews: dnxPermissions.JourneyComposerReview,
    activatejourneys: '9EFAA8EC-827C-4B2F-9EB3-6D98D4357FF6',
    bypassReviews: dnxPermissions.JourneyBypassReviews,
    //Module CM
    customermanager: 'e994acbd-9ea3-4ae3-8a06-80000fcee316',
    businesscustomers: '16e8cee6-64f9-478a-ad17-52c069b0b4a7',
    privatecustomers: '4bd53f06-c74b-4789-9bb8-ca8ea32253ca',
    dashboardExports: 'ffe6ac6a-6a02-421a-98d7-91116edf2dc0',
    //Old DNX2 permissions for users to assign themselves certain items, we should likely generalize this a bit more
    //is there any reason for a user to be allowed to modify someone else's dashboards but not their own?
    ownpasswordpolicy: '4AF563CF-57E3-4EF9-B37D-6B6237574810',
    ownroles: '4AF563CF-57E3-4EF9-B37D-6B6237574810',
    owndashboards: '4AF563CF-57E3-4EF9-B37D-6B6237574810',
    maps: 'C8167E22-B675-4CC3-B8AF-31A3A56831DC',
    emdm: '33623226-D563-410B-ACAD-6CED9A26B6A1',
    dmscenarios: '2E20167A-AE9C-4C16-BF03-C15145C24DED',
    manglConversations: '73df0039-7863-4e15-9b8f-e002c7a29590',
    manglQmForm: 'B49D6212-46B0-44AA-B205-56758D84565B',
};
//Register all permissions
Object.entries(permissions).forEach(entry => {
    registerPermission(entry[0], entry[1]);
});
/**
 * Expose the given permission within the DNX acl using the given name
 * @param {string} name Friendly name
 * @param {string} id Permission id
 */
function registerPermission(name, id) {
    name = cleanKey(name);
    if (!name) {
        console.warn(`Attempted to register permission with id: ${id} using invalid name`);
        return;
    }
    _knownPermissions.set(name, id);
}
//Clean name (lowercase, remove space & dashes)
function cleanKey(key) {
    return key === null || key === void 0 ? void 0 : key.toLowerCase().replace(/ -/gi, '');
}
//$can.read.users
//$can.not.read.users
function createDnxAcl() {
    function proxiedGetter(getter) {
        const proxy = new Proxy({}, {
            get: (_, permission) => getter(permission),
        });
        //Force any, keys are dynamic
        return proxy;
    }
    const isGranted = (permission, grantType) => {
        permission = cleanKey(permission);
        const id = _knownPermissions.get(permission) || permission;
        const securityStore = useSecurityStore();
        return securityStore.permissionIsGranted(id, grantType);
    };
    const isNotGranted = (permission, grantType) => !isGranted(permission, grantType);
    //Verb aliasing:
    //read   -> see?
    //write  -> alter/modify
    //if($can.alter.dashboards)
    //if($can.see.dashboards)
    /*
      $is interface for checking whether a user is in a specific role? -> $is.dashboardadmin/dnxadmin
      for this interface to work we'd need to introduce general platform roles. The ids of these roles would be the same
      across all instances of the platform
      
      alternatively we could introduce an $is interface for checking role-like permissions
      for example a dashboardadmin permission, for this we'd check all granttypes
      
      depending on the way we decide to implement this, individual modules could each contain their own `admin` roles/permission
      
      the $is interface would offer the following benefits:
      - reads more natural (our primary goal)
      - makes it possible to enable/disable UI aspects outside of the regular permission system (if we go with a role based approach)
     */
    return {
        read: proxiedGetter(permission => isGranted(permission, PermissionGrantType.Read)),
        write: proxiedGetter(permission => isGranted(permission, PermissionGrantType.Write)),
        create: proxiedGetter(permission => isGranted(permission, PermissionGrantType.Create)),
        delete: proxiedGetter(permission => isGranted(permission, PermissionGrantType.Delete)),
        not: {
            read: proxiedGetter(permission => isNotGranted(permission, PermissionGrantType.Read)),
            write: proxiedGetter(permission => isNotGranted(permission, PermissionGrantType.Write)),
            create: proxiedGetter(permission => isNotGranted(permission, PermissionGrantType.Create)),
            delete: proxiedGetter(permission => isNotGranted(permission, PermissionGrantType.Delete)),
        },
    };
}
/*
  Research notes.

  Preventing elements from being created via a custom directive is not possible, nor does Vue seem to natively offer
  hooks to hook into VNode creation and the likes. This prevents us from properly implementing our template ACL via custom directives.

  Transforming the VDom in such a manner components never even get instantiated will require either modifying Vue's source code; providing ourselves
  the necessary hooks, or implementing our directive at compile time the same way v-if is implemented (<-- transform to v-if in webpack prior to being processed?).
  
  
  Thus, for the time being, we'll just have to stick with `v-if` for component level alterations and `route.meta` for route guarding
 */
/**
 * Object for accessing the DNX ACL
 */
const $can = createDnxAcl();
/**
 * Composable function for accessing the DNX ACL in composition based components
 * @return {DnxAcl}
 */
function useDnxAcl() {
    return $can;
}
export { 
//Used for wiring new modules
registerPermission, 
//Composable
useDnxAcl, 
//Regular value getters
$can, };

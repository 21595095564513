﻿<template>
  <div style="height: 1px; width: 100%" :class="['divider', `${color}`]" />
</template>

<script>
export default {
  name: 'XDivider',
  props: {
    color: {
      type: String,
      default: 'dark',
      validator: val => ['dark', 'light'].includes(val),
    },
  },
};
</script>

<style lang="scss" scoped>
.divider {
  height: 1px;
  width: 100%;

  &.dark {
    background-color: var(--container-divider-primary, #215b7c);
  }

  &.light {
    background-color: var(--container-divider-secondary, #476b83);
  }
}
</style>

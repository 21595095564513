import {init} from 'echarts/core';
import {debounce} from 'lodash';

import {ToolbarProxy} from './toolbarProxy';
import {normalizeSeries} from './echarts';

/*
  ChartManager
    ctor(component)
  
    addFeature(...) -> {
      feature anatomy: {
        name: ... <-- optional?
      
        isEnabled: function, watchable
        toolbar -> features + actions, bind to component lifecycle
        configure -> transform a chart configuration, embed feature specific configuration
        
        listeners, allow features to define a list of listeners, other features may fire these events
        allow features to define their own properties (runtime?) for 
      }
    }
    
    setupToolbar() -> install toolbar features, setup watch etc.
    
    // chart context should be a private thing?
    buildContext() -> {
      // keep track of special list of properties -> primaryAxis/secondaryAxis in case of axis based charts
      // allow for chartDescriptors/contextBuilders? a descriptor/contextbuilder would provide its own set of properties based on the component
      get(property) => component[property]
    }
 */

/**
 * Manage the features and configuration enhancement for a DNX Charting component
 */
export class ChartManager {
  /**
   * @private
   * @type {ChartFeature[]}
   * */
  _features = [];

  /** @private */
  _instance = undefined;

  /** @private */
  _resizeObserver = undefined;

  /**
   * @private
   * @type {function(): Object}
   * */
  _configFactory = undefined;

  /** @private */
  _series = undefined;

  /**
   * Events set on the current instance by individual features
   * @private */
  _instanceEvents = new Map();

  /**
   * Bit indicating whether the 'configure' function is currently executing, certain functionality is explicitly gated behind us configuring
   * @private */
  _isConfiguring = false;

  /**
   * Create a new ChartManager instance for the given Vue component
   *
   * @param component Vue component, will be used as a source for settings and reactivity API access
   * @param opts Miscellaneous options
   * @param {function(): Object} opts.configFactory Factory function for creating fully fledged chart configuration, assumed to be reactive
   */
  constructor(component, opts) {
    this.component = component;

    this._configFactory = opts.configFactory;
  }

  /**
   * Read an individual setting value
   * @param {string} setting Name of the setting
   * @return {*|string}
   */
  get(setting) {
    const primaryAxis = this.component.horizontal ? 'yAxis' : 'xAxis';
    const secondaryAxis = this.component.horizontal ? 'xAxis' : 'yAxis';
    const primaryAxisType = this.component.xaxisType;
    const chart = this._instance;
    const series = this._series || this.component.series;
    const formatter = this.component.$format;

    if (setting === 'primaryAxis') return primaryAxis;
    if (setting === 'secondaryAxis') return secondaryAxis;
    if (setting === 'primaryAxisType') return primaryAxisType;
    if (setting === 'chart') return chart;
    if (setting === 'series') return normalizeSeries(series);
    if (setting === 'width') return this.component.$el.getBoundingClientRect().width;
    if (setting === 'height') return this.component.$el.getBoundingClientRect().height;
    if (setting === 'formatter') return formatter;

    return this.component[setting];
  }

  /**
   * Check whether a feature with the given name is present and enabled
   * @param {string} name
   * @return {boolean}
   */
  hasFeatureEnabled(name) {
    return this._features.some(feature => feature.name === name && feature.isEnabled());
  }

  /**
   * Add a set of features for the given chart, features themselves will have to determine whether they're enabled or not
   * @param {(function(): ChartFeature)[]} features
   */
  addFeatures(features) {
    // todo: infer features based on chart interface
    features = Array.isArray(features) ? features : [features];

    for (let feature of features) {
      // function features
      if (typeof feature === 'function') feature = feature(this);

      // simple configurators may just output a function
      if (typeof feature === 'function') feature = {configure: feature};

      this._features.push({
        // no isEnabled specified = auto enabled
        isEnabled: () => true,
        configure: () => {},
        ...feature,
      });
    }
  }

  /**
   * Perform binding for registered feature toolbar definitions
   */
  setupToolbar() {
    /** @type {ToolbarProxy} */
    const toolbar = this.component[ToolbarProxy.InjectionKey];
    if (!toolbar) return;

    // In case our container is reused - clear previous listeners
    toolbar.clearActionListeners();

    for (const feature of this._features) {
      if (!feature.toolbar) continue;

      // bind feature <> toolbar buttons
      for (const toolbarFeature of feature.toolbar.features || []) {
        this._attachImmediateWatcher(
          () => feature.isEnabled(),
          enabled => toolbar.toggleFeature(toolbarFeature, enabled)
        );
      }

      // bind toolbar event listeners
      for (const evt in feature.toolbar.listeners) {
        toolbar.addActionListener(evt, feature.toolbar.listeners[evt]);
      }
    }
  }

  /**
   * Initialize the chart instance, will automatically re-render on configuration changes and resizing
   */
  setupChart() {
    let _firstResize = true;

    const render = configuration => {
      // preserve previous configuration when nothing was provided
      configuration = configuration || this._instance?.getOption();

      // quick and dirty - full rebuild
      this._instance?.dispose();
      this._instance = init(this.component.$el, null, {renderer: 'svg'});
      this._instance.setOption(configuration);

      const instance = this._instance;

      for (const [name, events] of this._instanceEvents) {
        for (const event of events) {
          const originalHandler = event.handler;

          let handler = originalHandler;

          // automatically unbind self when `once` option is provided
          if (event.options.once) {
            handler = function () {
              instance.off(name, handler);
              originalHandler.apply(this, ...arguments);
            };
          }

          this._instance.on(name, handler);
        }
      }

      /** Notify chart initialization */
      this._dispatchCustomAction('initialized');
    };

    // use JS API instead of Vue package, components created by Vue package seem to get linked.. ?
    // triggering a re-render on chart A seemed to also trigger a rerender of chart B
    // should research the cause at a later point in time
    this._attachImmediateWatcher(
      () => this._configFactory(),
      configuration => render(configuration)
    );

    // listen for (partial) data updates
    // should find a cleaner way to handle this - biggest obstacle will be the series transformations applied by features
    // should we make data transformation its own pipeline?
    this._attachWatcher(
      () => this.component.dataUpdate,
      (newVal, oldVal) => {
        if (newVal?.update || JSON.stringify(oldVal) !== JSON.stringify(newVal)) {
          if (newVal.append) this._series = [this.get('series'), ...newVal.series];
          else this._series = newVal.series;
          render(this._configFactory());
        }
      }
    );

    // autoresize
    this._resizeObserver = new ResizeObserver(
      debounce(() => {
        // first render seems to trigger a resize of some sorts - ignore
        if (!_firstResize) this._instance?.resize();
        _firstResize = false;
      }, 500)
    );

    this._resizeObserver.observe(this.component.$el);
  }

  /**
   * Enhance a chart configuration by layering enabled feature configurations on top of it
   * @param configuration
   * @return {{}}
   */
  configure(configuration = {}) {
    this._instanceEvents.clear();

    this._isConfiguring = true;

    // TODO: Should we allow features to optionally define a weight for earlier/later execution regardless of registration order? - useful for 'EmptyDataMessageFeature', etc.
    for (const feature of this._features) {
      if (!feature.isEnabled()) continue;
      configuration = feature.configure(configuration) ?? configuration;
    }

    this._isConfiguring = false;

    return configuration;
  }

  /**
   * Attach an event for the instance being currently configured
   * @param {string} event
   * @param handler
   * @param options
   * @param {boolean=false} options.once Automatically remove the event after firing
   *
   * @see https://echarts.apache.org/en/api.html#events
   */
  configureEvent(event, handler, options = undefined) {
    // replace with feature events ( always fired if `isEnabled` = true ) and instance events ( explicitly registered during configure step )
    // allow feature definitions to expose an events key?

    // replace with configurationcontext? -> contains events + other information only relevant during configure step
    // configureEvent is only safe to invoke during certain instances, not truly part of the manager

    if (!this._isConfiguring)
      console.warn(
        `[ChartManager]: Event ${event}, configureEvent was called outside of configure function! Registered events will likely not be registered on the chart instance.`
      );
    if (!this._instanceEvents.has(event)) this._instanceEvents.set(event, []);

    this._instanceEvents.get(event).push({
      handler: handler,
      options: {
        once: false,
        ...options,
      },
    });
  }

  /**
   * Dispose transient resources contained by this instance
   */
  dispose() {
    this._resizeObserver?.disconnect();
    this._instance?.dispose();
  }

  /** @private */
  _attachWatcher(watched, callback) {
    this.component.$watch(watched, callback);
  }

  /** @private */
  _attachImmediateWatcher(watched, callback) {
    this.component.$watch(watched, callback, {immediate: true});
  }

  /** @private */
  _dispatchCustomAction(name) {
    name = `dnx:${name}`;
    const events = this._instanceEvents.get(name) || [];

    for (const event of events) {
      event.handler();
    }
  }
}

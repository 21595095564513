<template>
  <tr>
    <slot class="row-items" />
  </tr>
</template>

<script>
export default {
  name: 'XTableRow',
};
</script>

<docs>
  ```jsx
  <XTableRow>
    Table row markup
  </XTableRow>
  ```
</docs>

import { defineStore } from 'pinia';
import { ref } from 'vue';
export default defineStore('context-menu', () => {
    const currentOptions = ref([]);
    const isShowing = ref(false);
    const currentPosition = ref();
    const show = (options, position) => {
        currentOptions.value = options;
        currentPosition.value = { x: position.x, y: position.y };
        isShowing.value = true;
    };
    const close = () => (isShowing.value = false);
    return {
        isShowing,
        show,
        close,
        options: currentOptions,
        currentPosition,
    };
});

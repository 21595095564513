<template>
  <div class="multibox-container" :style="{height: containerHeight}">
    <div
      class="multibox-part"
      v-for="(layoutPart, index) in boxLayout"
      :style="{width: layoutPart.width}"
      :key="`${layoutPart.name}-${index}`"
    >
      <div class="multibox-header" v-if="showHeader">
        <div :class="['title', `${layoutPart.name.toLowerCase()}HeaderTitle`]" style="width: 100%">
          <slot :name="`${layoutPart.name.toLowerCase()}HeaderTitle`" />
        </div>
        <div
          :class="['buttons', `${layoutPart.name.toLowerCase()}HeaderButtons`]"
          v-if="$slots[`${layoutPart.name.toLowerCase()}HeaderButtons`]"
        >
          <slot :name="`${layoutPart.name.toLowerCase()}HeaderButtons`" />
        </div>
      </div>

      <div
        :class="['multibox-body', `${layoutPart.name.toLowerCase()}Body`]"
        :style="{
          display: layoutPart.contentDisplayType,
          'flex-grow': layoutPart.contentDisplayType == 'flex' ? '1' : undefined,
        }"
      >
        <slot :name="`${layoutPart.name.toLowerCase()}Body`" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'XMultiBox',
  props: {
    boxLayout: {
      type: Array,
      default() {
        return [
          {
            name: 'toolbar',
            width: '30%',
            contentDisplayType: undefined,
          },
          {
            name: 'content',
            width: '70%',
            contentDisplayType: undefined,
          },
        ];
      },
    },
    containerHeight: {
      type: String,
      default: undefined,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.multibox-container {
  width: 100%;
  border-radius: var(--radius-md);
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  background: var(--main-background, #ffffff);

  * {
    box-sizing: border-box;
  }

  .multibox-part {
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
      border-right: 1px solid var(--container-border, #e8e8e8);
    }

    .multibox-header {
      height: 3.75rem;
      min-height: 3.75rem;
      padding: 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-bottom: 1px solid var(--container-border, #e8e8e8);

      .title {
        padding: 1.25rem;
      }

      .buttons {
        white-space: nowrap;
      }
    }
  }
}

.contentHeaderButtons {
  padding-right: var(--spacing-md);
}
</style>

<docs>
  Toolbar defaults is toolbar 30%, content 70%.  
  Slot-names of the header and body are, nameOfPart_header
  and nameOfPart_body.
  
  ### Props
  * containerHeight: optional, String with value like (20rem)
  * boxLayout: Array[Objects], Contains a Array of Objects with following setup:
    * {'name': 'nameOfThePart', 'width': '20%'}
    * Width must be in percentages here.
  
  ```jsx
  const MultiBoxConfig = [
    {
      name: 'toolbar',
      width: '30%',
      contentDisplayType: 'flex'
    },
    {
      name: 'content',
      width: '70%',
      contentDisplayType: 'flex'
    },
  ];
  
  <XMultiBox :boxLayout="MultiBoxConfig" containerHeight="20rem">
    <template #toolbarHeaderTitle>Hello world</template>
    <template #toolbarHeaderButtons>Hello world</template>
    <template #toolbarBody>Hello world23456</template>
    <template #contentHeaderTitle>Hello world</template>
    <template #contentHeaderButtons>Hello world</template>
    <template #contentBody>Hello world23456</template>
  </XMultiBox>
  ```
</docs>

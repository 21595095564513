<template>
  <component :is="wrapper" class="radio-btn">
    <label>
      <input type="radio" :name="name" :value="option" :id="id" :disabled="disabled" v-model="model" />
      <span :for="id" :class="{'box-disabled': disabled}" v-if="label">
        {{ label }}
      </span>
    </label>
  </component>
</template>

<script>
import createIdPrefixedDispenser from '@src/helpers/create-prefixed-id-dispenser';
const getId = createIdPrefixedDispenser('RADIO_');

/**
 * A radiobutton allows you to select one value for submission in a form.
 */
export default {
  name: 'XRadioButton',
  props: {
    /**
     * The html element name used for the wrapper.
     * `div, section`
     */
    wrapper: {
      type: String,
      default: 'div',
      validator: value => {
        return value.match(/(div|section)/);
      },
    },
    /**
     * Unique identifier of the form input field.
     */
    id: {
      type: String,
      default: getId,
    },
    /**
     * The selected value
     */
    modelValue: {
      type: String,
      default: null,
    },
    /**
     * The radio option to be used as the input element value.
     */
    option: {
      type: String,
      default: null,
    },
    /**
     * Name for the radio group
     */
    name: {
      type: String,
      default: null,
    },
    /**
     * The label of the form input field.
     */
    label: {
      type: String,
      default: null,
    },
    /**
     * Whether the form input field is disabled or not.
     * `true, false`
     */
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    /**
     * Model that emits value to parent.
     */
    model: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
label {
  display: flex;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  input {
    position: absolute;
    left: -9999px;
    &:checked + span {
      background-color: var(--control-background, #ffffff);
      &:before {
        box-shadow: inset 0 0 0 0.35em var(--control-selectedBackground, #2783c6);
      }
    }
  }
  span {
    display: flex;
    align-items: center;
    padding: 0.375em 0.75em 0.375em 0.375em;
    border-radius: 5rem;
    color: var(--control-color, #313741);
    transition: 0.25s ease;
    font-size: var(--font-md);

    &:hover {
      background: var(--control-hoverBackground, #e7f5ff);
    }

    &:before {
      display: flex;
      flex-shrink: 0;
      content: '';
      background-color: var(--control-background, #ffffff);
      width: 1.33rem;
      height: 1.313rem;
      border-radius: 50%;
      margin-right: 0.77rem;
      transition: 0.25s ease;
      box-shadow: inset 0 0 0 0.115em var(--control-selectedBackground, #2783c6);
    }
  }
}

.box-disabled {
  cursor: not-allowed;
  color: var(--control-disabledColor, #7f8389);
  &:before {
    box-shadow: inset 0 0 0 0.12rem var(--control-disabledColor, #7f8389);
  }
}
</style>

<docs>
```
<XRadioButton />
<XRadioButton disabled label="disabled" name="group" option="0" />
<XRadioButton label="some label" name="group" option="1" />
<XRadioButton label="some other label" name="group" option="2" id="radio-static-id" />
```
</docs>

/** Add custom action listeners, etc. to  */
export function useRowProps(selectRow, // <-- do we want this here?
openContextMenu) {
    /*
    
    TableInternals.selectRow(row) {
      emit: ('rowSelected', row)
      TableInternals.selectedRow = row; <-- shallowref
    }
    
     */
    // check for injection key 'openContextMenu'? Append via builder? later stage..
    let previousTextSelection = '';
    return (row, index) => {
        return {
            style: 'cursor: pointer;',
            onClick: () => {
                // In a scenario that the user is selecting first one text and then a different text - this condition is ruling it out by confirming
                // that the state.selectedText is pointing to the last selected text. If that is indeed the case, we fire the emitted function.
                // also, the second click initially would remove the selection but not trigger the emit, therefore we are checking if selectedText is already containing a value, to determine
                // what the next click should do.
                var _a, _b;
                const activeTextSelection = (_b = (_a = window.getSelection()) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : '';
                if (!activeTextSelection || previousTextSelection === activeTextSelection) {
                    selectRow(row);
                }
                previousTextSelection = activeTextSelection;
            },
            onContextmenu: (event) => openContextMenu(row, event),
        };
    };
}
